import axios from "axios";
import { adminLoginURL } from "./endpoints";
export async function AdminLoginAPIs(
  email: string,
  password: string
): Promise<any> {
  const payload = {
    email,
    password,
  };
  try {
    const response = await axios.post(adminLoginURL, payload);

    return response.data;
  } catch (error) {
    console.error("Error checking Admin Login:", error);
    throw error;
  }
}
