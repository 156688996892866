import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { grey } from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: "30px",
  padding: "6px",
  color: grey[900],
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

interface BaggageTableProps {
  baggageDetails: any;
  combinedBaggage: any[];
  baggageDetailsReturn?: any;
  combinedBaggageReturn?: any[];
}

const BaggageTable: React.FC<BaggageTableProps> = ({
  baggageDetails,
  combinedBaggage,
  baggageDetailsReturn,
  combinedBaggageReturn,
}) => {
  return (
    <div className="mt-3 baggage">
      <div className="bagger-section">
        {combinedBaggage ? (
          <>
            <Table
              component={Paper}
              className="mb-2"
              sx={{ minWidth: 650 }}
              aria-label="Baggage Table"
            >
              {baggageDetails?.Journey &&
                combinedBaggage?.map((bag, index) => (
                  <React.Fragment key={index}>
                    {bag?.SSR?.map(
                      (ssrBag: any, ssrIndex: number) =>
                        ssrBag?.Code === "BAG" && (
                          <React.Fragment key={ssrIndex}>
                            <TableHead style={{ backgroundColor: "#fff" }}>
                              <TableRow>
                                <TableCell className="active">
                                  Sector/Flight
                                </TableCell>
                                <TableCell className="active">
                                  Check in Baggage
                                </TableCell>
                                <TableCell className="active">
                                  Cabin Baggage
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }}>
                                  {bag.DepartureCode} - {bag.ArrivalCode}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {
                                    ssrBag?.Description?.replace(
                                      /\s/g,
                                      ""
                                    ).split(",")[0]
                                  }{" "}
                                  (
                                  {ssrBag?.PTC === "ADT"
                                    ? "Adult"
                                    : ssrBag?.PTC === "CHD"
                                    ? "Child"
                                    : "Infant"}
                                  )
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {
                                    ssrBag?.Description?.replace(
                                      /\s/g,
                                      ""
                                    ).split(",")[1]
                                  }{" "}
                                  (
                                  {ssrBag?.PTC === "ADT"
                                    ? "Adult"
                                    : ssrBag?.PTC === "CHD"
                                    ? "Child"
                                    : "Infant"}
                                  )
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </React.Fragment>
                        )
                    )}
                  </React.Fragment>
                ))}
            </Table>

            {/* Inclusions Section */}
            {baggageDetails?.Journey &&
              combinedBaggage?.map((bag, index) => (
                <React.Fragment key={index}>
                  {bag?.SSR?.map(
                    (ssrBag: any, ssrIndex: number) =>
                      ssrBag?.Code === "BRAD" && (
                        <Box
                          key={ssrIndex}
                          sx={{
                            borderRadius: 2,
                            bgcolor: "background.default",
                            display: "block",
                            gap: 2,
                            margin: "14px 0px",
                          }}
                        >
                          <Typography style={{ fontSize: "16px" }}>
                            Inclusions ( {bag.DepartureCode} - {bag.ArrivalCode}
                            )
                          </Typography>
                          <Paper elevation={2}>
                            <Item elevation={2}>
                              <Typography
                                style={{
                                  border: "2px solid rgb(255 97 0)",
                                  borderRadius: "8px",
                                  padding: "2px 12px",
                                  background: "rgb(255 97 0)",
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}{" "}
                                :
                              </Typography>
                              {ssrBag?.Description?.split("|")?.map(
                                (item: string, index: number) => (
                                  <Typography
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    <DoneIcon
                                      color="disabled"
                                      fontSize="small"
                                    />
                                    <span style={{ marginRight: "6px" }}>
                                      {item.trim()}
                                    </span>
                                  </Typography>
                                )
                              )}
                            </Item>
                          </Paper>
                        </Box>
                      )
                  )}
                </React.Fragment>
              ))}
          </>
        ) : null}

        {combinedBaggageReturn ? (
          <>
            <Table
              component={Paper}
              className="mb-2"
              sx={{ minWidth: 650 }}
              aria-label="Baggage Table"
            >
              {baggageDetailsReturn?.Journey &&
                combinedBaggageReturn?.map((bag, index) => (
                  <React.Fragment key={index}>
                    {bag?.SSR?.map(
                      (ssrBag: any, ssrIndex: number) =>
                        ssrBag?.Code === "BAG" && (
                          <React.Fragment key={ssrIndex}>
                            <TableHead style={{ backgroundColor: "#fff" }}>
                              <TableRow>
                                <TableCell className="active">
                                  Sector/Flight
                                </TableCell>
                                <TableCell className="active">
                                  Check in Baggage
                                </TableCell>
                                <TableCell className="active">
                                  Cabin Baggage
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ textAlign: "center" }}>
                                  {bag.ArrivalCode} - {bag.DepartureCode}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {
                                    ssrBag?.Description?.replace(
                                      /\s/g,
                                      ""
                                    ).split(",")[0]
                                  }{" "}
                                  (
                                  {ssrBag?.PTC === "ADT"
                                    ? "Adult"
                                    : ssrBag?.PTC === "CHD"
                                    ? "Child"
                                    : "Infant"}
                                  )
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {
                                    ssrBag?.Description?.replace(
                                      /\s/g,
                                      ""
                                    ).split(",")[1]
                                  }{" "}
                                  (
                                  {ssrBag?.PTC === "ADT"
                                    ? "Adult"
                                    : ssrBag?.PTC === "CHD"
                                    ? "Child"
                                    : "Infant"}
                                  )
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </React.Fragment>
                        )
                    )}
                  </React.Fragment>
                ))}
            </Table>

            {/* Inclusions Section Return */}
            {baggageDetailsReturn?.Journey &&
              combinedBaggageReturn?.map((bag, index) => (
                <React.Fragment key={index}>
                  {bag?.SSR?.map(
                    (ssrBag: any, ssrIndex: number) =>
                      ssrBag?.Code === "BRAD" && (
                        <Box
                          key={ssrIndex}
                          sx={{
                            borderRadius: 2,
                            bgcolor: "background.default",
                            display: "block",
                            gap: 2,
                            margin: "14px 0px",
                          }}
                        >
                          <Typography style={{ fontSize: "16px" }}>
                            Inclusions ( {bag.ArrivalCode} - {bag.DepartureCode}
                            )
                          </Typography>
                          <Paper elevation={2}>
                            <Item elevation={2}>
                              <Typography
                                style={{
                                  border: "2px solid rgb(255 97 0)",
                                  borderRadius: "8px",
                                  padding: "2px 12px",
                                  background: "rgb(255 97 0)",
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {ssrBag?.PTC === "ADT"
                                  ? "Adult"
                                  : ssrBag?.PTC === "CHD"
                                  ? "Child"
                                  : "Infant"}{" "}
                                :
                              </Typography>
                              {ssrBag?.Description?.split("|")?.map(
                                (item: string, index: number) => (
                                  <Typography
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    <DoneIcon
                                      color="disabled"
                                      fontSize="small"
                                    />
                                    <span style={{ marginRight: "6px" }}>
                                      {item.trim()}
                                    </span>
                                  </Typography>
                                )
                              )}
                            </Item>
                          </Paper>
                        </Box>
                      )
                  )}
                </React.Fragment>
              ))}
          </>
        ) : null}

        {/* Disclaimer Section */}
        <div className="short-bagger">
          <ul className="list-group">
            <li className="list-item">
              The information presented above is as obtained from the airline
              reservation system. Maashree.com does not guarantee the accuracy
              of this information.
            </li>
            <li className="list-item">
              The baggage allowance may vary according to stop-overs, connecting
              flights, and changes in airline rules.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BaggageTable;
