import { useEffect, useState } from "react";
import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { TalkToAgents } from "../components/TalkToAgent";

import { UpcomingTrips } from "../components/forms/my-travel/UpcomingTrips";
import { CompletedTrips } from "../components/forms/my-travel/CompletedTrips";
import { CanceledTrips } from "../components/forms/my-travel/CanceledTrips";

export const MyTravels = () => {
  const [toggleTrips, setToggleTrips] = useState("upcoming");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <AppLayout>
      <main className="container">
        <div className="row">
          <div className="col-sm-12 pt-3">
            <h1 className="h1-inner mb-0">My Travel</h1>
          </div>
        </div>
        <div className="select-tab-container gap-0">
          <div
            className="select-tab"
            onClick={() => setToggleTrips("upcoming")}
          >
            <h3
              className={
                toggleTrips === "upcoming"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="upcoming_trips"
            >
              Upcoming Trips
            </h3>
          </div>
          <div
            className="select-tab"
            onClick={() => setToggleTrips("completed")}
          >
            <h3
              className={
                toggleTrips === "completed"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="completed_trips"
            >
              Completed Trips
            </h3>
          </div>
          <div
            className="select-tab"
            onClick={() => setToggleTrips("canceled")}
          >
            <h3
              className={
                toggleTrips === "canceled"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="cancelled_trips"
            >
              Cancelled Trips
            </h3>
          </div>
        </div>
        {toggleTrips === "upcoming" && <UpcomingTrips />}
        {toggleTrips === "completed" && <CompletedTrips />}
        {toggleTrips === "canceled" && <CanceledTrips />}
      </main>
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
