import React, { createContext, useContext, useState, ReactNode } from "react";

interface AuthContextType {
  isAuthenticated: boolean;
  isAdminAuthenticated:boolean;
  login: () => void;
  logout: () => void;
  adminLogin: () => void;
  adminLogout: () => void;
  updateAuthStatus: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState<boolean>(
    !!localStorage.getItem("admin_token")
  );


  const updateAuthStatus = (value: boolean) => {
    setIsAuthenticated(value);
  };

  const storedToken = localStorage.getItem("token");

  const login = () => {
    localStorage.setItem("token", String(storedToken));
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  const adminToken = localStorage.getItem("admin_token");

  const adminLogin = () => {
    localStorage.setItem("admin_token", String(adminToken));
    setIsAdminAuthenticated(true);
  };

  const adminLogout = () => {
    localStorage.removeItem("admin_token");
    setIsAdminAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAdminAuthenticated, login, logout, adminLogin, adminLogout, updateAuthStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
