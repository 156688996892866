import { Key, useEffect, useState } from "react";

interface SidebarFilterProps {
  priceRange: number;
  selectedFlights: string[];
  selectedArrivalAirport: string[];
  setPriceRange: React.Dispatch<React.SetStateAction<number>>;
  setSelectedFlights: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedArrivalAirport: React.Dispatch<React.SetStateAction<string[]>>;
  handleNoOfStopsChange: any;
  selectedStops: any;
  uniqueConnectingAirports: any;
  getHightestPrice: number;
  setToggleFlightPrice: any;
  refundable: boolean;
  setRefundable: any;
  handleArrivalTimeChange: any;
  selectedArrivalTimes: any;
  handleDepartureTimeChange: any;
  selectedDepartureTimes: any;
  flightList: any;
  upToFlights: any;
  setUpToFlights: any;
  minPriceRange: any;
  includeOtherFare?: boolean;
  currentIndex?: any;
  otherFare?: any;
  setOtherFare?: any;
  filteredFlightsList?: any;
  index?: any;
  oneWay?: boolean;
}

export const SidebarFilter = ({
  priceRange,
  selectedFlights,
  selectedArrivalAirport,
  setPriceRange,
  setSelectedFlights,
  setSelectedArrivalAirport,
  handleNoOfStopsChange,
  selectedStops,
  uniqueConnectingAirports,
  getHightestPrice,
  setToggleFlightPrice,
  refundable,
  setRefundable,
  includeOtherFare,
  otherFare,
  setOtherFare,
  handleArrivalTimeChange,
  selectedArrivalTimes,
  handleDepartureTimeChange,
  selectedDepartureTimes,
  flightList,
  upToFlights,
  setUpToFlights,
  minPriceRange,
  filteredFlightsList,
  index,
  oneWay,
}: SidebarFilterProps) => {
  const [uniqueAirline, setUniqueAirline] = useState<any>([]);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleFlightCheckboxChange = (flightId: string) => {
    if (selectedFlights.includes(flightId)) {
      setSelectedFlights(selectedFlights.filter((id) => id !== flightId));
    } else {
      setSelectedFlights([...selectedFlights, flightId]);
    }
  };

  const handleArrivalCheckboxChange = (arrival: string) => {
    if (selectedArrivalAirport.includes(arrival)) {
      setSelectedArrivalAirport(
        selectedArrivalAirport.filter((item) => item !== arrival)
      );
    } else {
      setSelectedArrivalAirport([...selectedArrivalAirport, arrival]);
    }
  };

  function removeDuplicatesByFlightNo(arr: any[]) {
    const uniqueFlightNos = new Set();

    return arr.filter((item: any) => {
      const onwardFlight = item?.OnwardFlight;
      if (onwardFlight && !uniqueFlightNos.has(onwardFlight.Index)) {
        uniqueFlightNos.add(onwardFlight.Index);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    const airlinesList = [...removeDuplicatesByFlightNo(flightList)];
    const uniqueAirlineNames = Object.values(
      airlinesList.reduce((acc, obj) => {
        const airlineName = obj.OnwardFlight.AirlineName?.split("|")[1];
        if (!acc[airlineName]) {
          acc[airlineName] = {
            id: obj.OnwardFlight.FlightNo,
            name: airlineName,
            counts: 1,
          };
        } else {
          acc[airlineName].counts++;
        }
        return acc;
      }, {})
    );

    setUniqueAirline(uniqueAirlineNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightList]);

  const uniqueAirlineNamesOneWay = [
    ...new Set(
      flightList.map(
        (flight: { AirlineName: string }) => flight.AirlineName?.split("|")[0]
      )
    ),
  ];

  const visibleFlights = showMore ? uniqueAirline : uniqueAirline.slice(0, 4);

  const visibleFlightsOneWay = showMore
    ? uniqueAirlineNamesOneWay
    : uniqueAirlineNamesOneWay.slice(0, 4);

  const filterSection = (
    <>
      <div className="block">
        <h3 className="h3-bold">One Way Price</h3>
        <div className="slidecontainer">
          <span style={{ margin: "4px" }}>
            Up to ₹{upToFlights?.toLocaleString("en-IN")}
          </span>
          <input
            type="range"
            min={minPriceRange}
            max={getHightestPrice}
            value={upToFlights}
            step="1"
            onChange={(e) => {
              const selectedPrice = Number(e.target.value);
              setUpToFlights(selectedPrice);
              setToggleFlightPrice(true);
              setPriceRange(selectedPrice);
            }}
          />
          <span className=""> ₹{minPriceRange?.toLocaleString("en-IN")}</span>
          <span className="">₹{getHightestPrice?.toLocaleString("en-IN")}</span>
        </div>

        {/* No. of stops */}
        <div className="block" style={{ marginTop: "1.5rem" }}>
          <h3 className="h3-bold">No. of stops</h3>
          <ul>
            <li className="py-1">
              <div className="form-check">
                <input
                  style={{ border: "1px solid" }}
                  type="checkbox"
                  className="form-check-input"
                  id="spots0"
                  name="stops"
                  value="0"
                  checked={selectedStops.includes("0")}
                  onChange={() => handleNoOfStopsChange("0")}
                />
                <label
                  className="form-check-label"
                  htmlFor="spots0"
                  style={{ marginTop: "3px" }}
                >
                  0 Stop
                </label>
              </div>
            </li>
            <li className="py-1">
              <div className="form-check">
                <input
                  style={{ border: "1px solid" }}
                  type="checkbox"
                  className="form-check-input"
                  id="spots1"
                  name="stops"
                  value="1"
                  checked={selectedStops.includes("1")}
                  onChange={() => handleNoOfStopsChange("1")}
                />
                <label
                  className="form-check-label"
                  htmlFor="spots1"
                  style={{ marginTop: "3px" }}
                >
                  1 Stop
                </label>
              </div>
            </li>
            <li className="py-1">
              <div className="form-check">
                <input
                  style={{ border: "1px solid" }}
                  type="checkbox"
                  className="form-check-input"
                  id="stops2"
                  name="stops"
                  value="1+"
                  checked={selectedStops.includes("1+")}
                  onChange={() => handleNoOfStopsChange("1+")}
                />
                <label
                  className="form-check-label"
                  htmlFor="stops2"
                  style={{ marginTop: "3px" }}
                >
                  1+ Stop
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div className="block" style={{ marginTop: "1.5rem" }}>
          <h3 className="h3-bold">Fare Type</h3>
          <div className="form-check">
            <input
              style={{ border: "1px solid" }}
              type="checkbox"
              className="form-check-input"
              id="refundable1"
              name="refundable"
              value="Refundable"
              checked={refundable}
              onChange={() => setRefundable(!refundable)}
            />
            <label
              className="form-check-label"
              htmlFor="refundable1"
              style={{ marginTop: "3px" }}
            >
              Refundable
            </label>
          </div>

          {includeOtherFare && (
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="otherFare1"
                name="other"
                value="Others"
                checked={otherFare}
                onChange={() => {
                  // if (
                  //   filteredFlightsList &&
                  //   index === 0 &&
                  //   filteredFlightsList.length > 0
                  // ) {
                  setOtherFare(!otherFare);
                  // }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="otherFare1"
                style={{ marginTop: "3px" }}
              >
                Others
              </label>
            </div>
          )}
        </div>
      </div>

      {/* Airlines */}
      <div className="block">
        <h3 className="h3-bold">Airlines</h3>
        {oneWay ? (
          <ul>
            {visibleFlightsOneWay.map(
              (flight: any, index: Key | null | undefined) => (
                <li className="py-1" key={index}>
                  <div className="form-check" id={`same-address${index}`}>
                    <input
                      style={{ border: "1px solid" }}
                      type="checkbox"
                      className="form-check-input"
                      id={`same-address${index}`}
                      checked={selectedFlights.includes(flight)}
                      onChange={() => handleFlightCheckboxChange(flight)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`same-address${index}`}
                      style={{ marginTop: "3px" }}
                    >
                      {flight}
                      {/* ({flight.counts}) */}
                    </label>
                  </div>
                </li>
              )
            )}
            {uniqueAirlineNamesOneWay.length > 4 && (
              <li className="py-1">
                <span
                  className="text-end"
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={handleShowMore}
                >
                  {showMore ? "- Less" : "+ More"}
                </span>
              </li>
            )}
          </ul>
        ) : (
          <ul>
            {visibleFlights.map(
              (
                flight: { id: string; name: string; counts: number },
                index: Key | null | undefined
              ) => (
                <li className="py-1" key={index}>
                  <div className="form-check">
                    <input
                      style={{ border: "1px solid" }}
                      type="checkbox"
                      className="form-check-input"
                      id={`same-address${index}`}
                      checked={selectedFlights.includes(flight.name)}
                      onChange={() => handleFlightCheckboxChange(flight.name)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`same-address${index}`}
                      style={{ marginTop: "3px" }}
                    >
                      {flight.name}({flight.counts})
                    </label>
                  </div>
                </li>
              )
            )}
            {uniqueAirline.length > 4 && (
              <li className="py-1">
                <span
                  className="text-end"
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={handleShowMore}
                >
                  {showMore ? "- Less" : "+ More"}
                </span>
              </li>
            )}
          </ul>
        )}
      </div>
      {/* <!-- Departure --> */}
      <div className="block">
        <h3 className="h3-bold">Departure Time</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-address4-21"
                name="departure-time"
                value="5-12"
                checked={selectedDepartureTimes.includes("05-12")}
                onChange={() => handleDepartureTimeChange("05-12")}
              />
              <label
                className="form-check-label"
                htmlFor="same-address4-21"
                style={{ marginTop: "3px" }}
              >
                05 - 12
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-address4-18"
                name="departure-time"
                value="12-18"
                checked={selectedDepartureTimes.includes("12-18")}
                onChange={() => handleDepartureTimeChange("12-18")}
              />
              <label
                className="form-check-label"
                htmlFor="same-address4-18"
                style={{ marginTop: "3px" }}
              >
                12 - 18
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-address4-23"
                name="departure-time"
                value="18-23"
                checked={selectedDepartureTimes.includes("18-23")}
                onChange={() => handleDepartureTimeChange("18-23")}
              />
              <label
                className="form-check-label"
                htmlFor="same-address4-23"
                style={{ marginTop: "3px" }}
              >
                18 - 23
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-address4-27"
                name="departure-time"
                value="23-05"
                checked={selectedDepartureTimes.includes("23-05")}
                onChange={() => handleDepartureTimeChange("23-05")}
              />
              <label
                className="form-check-label"
                htmlFor="same-address4-27"
                style={{ marginTop: "3px" }}
              >
                23 - 05
              </label>
            </div>
          </li>
        </ul>
      </div>
      {/* <!-- Arrival  --> */}
      <div className="block">
        <h3 className="h3-bold">Arrival Time</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-checkarrival5"
                name="arrival-time"
                value="05-12"
                checked={selectedArrivalTimes.includes("05-12")}
                onChange={() => handleArrivalTimeChange("05-12")}
              />
              <label
                className="form-check-label"
                htmlFor="same-checkarrival5"
                style={{ marginTop: "3px" }}
              >
                05 - 12
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-checkarrival4"
                name="arrival-time"
                value="12-18"
                checked={selectedArrivalTimes.includes("12-18")}
                onChange={() => handleArrivalTimeChange("12-18")}
              />
              <label
                className="form-check-label"
                htmlFor="same-checkarrival4"
                style={{ marginTop: "3px" }}
              >
                12 - 18
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="same-checkarrival3"
                name="arrival-time"
                value="18-23"
                checked={selectedArrivalTimes.includes("18-23")}
                onChange={() => handleArrivalTimeChange("18-23")}
              />
              <label
                className="form-check-label"
                htmlFor="same-checkarrival3"
                style={{ marginTop: "3px" }}
              >
                18 - 23
              </label>
            </div>
          </li>

          <li className="py-1">
            <div className="form-check">
              <input
                style={{ border: "1px solid" }}
                type="checkbox"
                className="form-check-input"
                id="checkArrival2"
                name="arrival-time"
                value="23-05"
                checked={selectedArrivalTimes.includes("23-05")}
                onChange={() => handleArrivalTimeChange("23-05")}
              />
              <label
                className="form-check-label"
                htmlFor="checkArrival2"
                style={{ marginTop: "3px" }}
              >
                23 - 05
              </label>
            </div>
          </li>
        </ul>
      </div>

      {/* Arrival Airport */}
      {uniqueConnectingAirports?.length > 0 && (
        <div className="block">
          <h3 className="h3-bold">Connecting Airports</h3>
          <ul>
            {uniqueConnectingAirports.map((arrival: string, index: number) => (
              <li className="py-1" key={index}>
                {arrival !== undefined && (
                  <div className="form-check">
                    <input
                      style={{ border: "1px solid" }}
                      type="checkbox"
                      className="form-check-input"
                      name="arrival-airport"
                      id={`arrival-${index}`}
                      checked={selectedArrivalAirport.includes(arrival)}
                      onChange={() => handleArrivalCheckboxChange(arrival)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`arrival-${index}`}
                      style={{ marginTop: "3px" }}
                    >
                      {arrival}
                    </label>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="col-sm-3 sticky-top" style={{ top: "163px" }}>
        <div className="left-side left_section">{filterSection}</div>
      </div>

      <div
        className="modal fade left-flight-siderbar"
        id="flight-exampleModal_flight_search"
        tabIndex={-1}
        aria-labelledby="flight-exampleModal_flight_search"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="left-side">{filterSection}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
