import calender from "../../../assets/images/calender.svg";
import location from "../../../assets/images/location.png";
import LazyImage from "../../../pages/LazyImage";
import bufferGif from "../../../assets/images/buffergif.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import "./travelAndHotel.css";
import { useEffect, useState } from "react";
import { completedTrips } from "../../../api/UserProfile";
import moment from "moment";

export const CompletedTrips = () => {
  const [loading, setLoading] = useState(true);
  const [completedTripsList, setCompletedTripsList] = useState<any[]>([]);

  useEffect(() => {
    completedTrips()
      .then((res) => {
        setLoading(false);
        setCompletedTripsList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="upcoming_section mt-4" id="upcoming_trips_section">
      {/* <!-- first --> */}
      <div className="card card_upcmoing">
        <div className="card-body">
          {completedTripsList.length > 0 ? (
            <div className="row">
              {completedTripsList.map((trip) => (
                <div className="card card_upcmoing border-0" key={trip._id}>
                  <div className="card-body px-0">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="travel_section mb-3">
                          <div className="text-left d-flex gap-2">
                            <div className="image_bookin">
                              <span className="image_bookin">
                                <FontAwesomeIcon icon={faPlaneUp} />{" "}
                              </span>
                            </div>
                            <h3 className="name-title mb-0">
                              {trip.flightBookingResponseData.ContactInfo[0]
                                .FName +
                                " " +
                                trip.flightBookingResponseData.ContactInfo[0]
                                  .LName}{" "}
                              x {trip.flightBookingResponseData.Pax.length}
                              <span
                                style={{
                                  padding: "0px 10px",
                                }}
                              >
                                Ref. No
                              </span>
                              <span>{trip.TransactionID}</span>
                            </h3>
                          </div>
                          <div className="block_btn d-flex align-items-center">
                            <div className="date_icons">
                              <LazyImage
                                className="date_imatge"
                                src={calender}
                                alt=""
                              />
                              <span className="date_text">
                                {" "}
                                {moment(
                                  trip.flightBookingResponseData.BookingDate
                                ).format("Do MMM YYYY")}
                              </span>
                            </div>

                            <div className="card-content">
                              <button
                                className="btn btn-completed"
                                type="button"
                              >
                                {" "}
                                Completed
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="main_content col_section">
                          {trip.flightBookingResponseData.Trips.map(
                            (flight: any) => (
                              <>
                                {flight.Journey.map((journey: any) => (
                                  <div
                                    className="main_content "
                                    style={{ columnGap: "110px" }}
                                  >
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={location} alt="" />
                                          </span>
                                          Departure city{" "}
                                        </p>
                                        <h3 className="block_heading">
                                          {" "}
                                          {
                                            journey.Segments[0].Flight.DepAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Arrival city
                                        </p>
                                        <h3 className="block_heading">
                                          {
                                            journey.Segments[0].Flight.ArrAirportName.split(
                                              "|"
                                            )[1]
                                          }
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Arrival Date
                                        </p>
                                        <h3 className="block_heading">
                                          {moment(
                                            journey.Segments[0].Flight
                                              .ArrivalTime
                                          ).format("DD MMM YYYY")}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                          <span className="image_bookin">
                                            <LazyImage src={calender} alt="" />
                                          </span>
                                          Departure Date
                                        </p>
                                        <h3 className="block_heading">
                                          {moment(
                                            journey.Segments[0].Flight
                                              .DepartureTime
                                          ).format("DD MMM YYYY")}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <h3 className="block_heading">
                                          Flight No.{" "}
                                        </h3>
                                        <p className="card-text">
                                          {" "}
                                          {journey.Segments[0].Flight.FlightNo}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="block">
                                      <div className="card-content">
                                        <h3 className="block_heading">
                                          Travel Class
                                        </h3>
                                        <p className="card-text">
                                          {" "}
                                          {journey.Segments[0].Flight.Cabin ===
                                          "E"
                                            ? "Economy"
                                            : journey.Segments[0].Flight
                                                .Cabin === "PE"
                                            ? "Premium Economy"
                                            : journey.Segments[0].Flight
                                                .Cabin === "B"
                                            ? "Business"
                                            : "First Class"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h5> No Completed Trips</h5>
          )}
        </div>
      </div>
      {/* second card  Hotel*/}
      {/* <div className="card card_upcmoing">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="travel_section mb-3">
                <div className="text-left d-flex gap-2">
                  <div className="image_bookin">
                    <span className="image_bookin">
                      <FontAwesomeIcon icon={faHotel} />{" "}
                    </span>
                  </div>
                  <h3 className="name-title mb-0">
                    James Smith x 2
                    <span
                      style={{
                        padding: "0px 10px",
                      }}
                    >
                      Ref. No
                    </span>
                    <span>123456788</span>
                  </h3>
                </div>
                <div className="block_btn d-flex align-items-center">
                  <div className="date_icons">
                    <LazyImage className="date_imatge" src={calender} alt="" />
                    <span className="date_text"> Wed, 16 Aug</span>
                  </div>

                  <div className="card-content">
                    <button className="btn btn-completed" type="button">
                      {" "}
                      Completed
                    </button>
                  </div>
                </div>
              </div>
              <div className="main_content col_section">
                <div className="block">
                  <div className="card-content">
                    <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                      CHECK IN
                    </p>
                    <h3 className="block_heading"> 14 Aug 23 </h3>
                    <p className="mb-0"> 12:00 PM</p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                      CHECK OUT
                    </p>
                    <h3 className="block_heading">15 Aug 23</h3>
                    <p className="mb-0"> 2:00 PM</p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                      <span className="image_bookin">
                        <LazyImage src={booking} alt="" />
                      </span>
                      Booked
                    </p>
                    <h3 className="block_heading">Hotel </h3>
                    <p className="card-text">Sheraton, Paris</p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <h3 className="block_heading">Room Category</h3>
                    <p className="card-text">luxury </p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <h3 className="block_heading">No. Of Rooms Booked</h3>
                    <p className="card-text">2</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {loading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 17%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <LazyImage src={bufferGif} alt="buffer" />
        </div>
      )}
    </section>
  );
};
