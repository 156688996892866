import React from "react";
import hotelLoader from "../../assets/images/animated-hotel.gif";

const HotelLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 17%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "99999999",
      }}
    >
      <img src={hotelLoader} alt="hotel" style={{ height: "30%" }} />
    </div>
  );
};

export default HotelLoader;
