export const emailRegex =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,}){1,2}$/;
export const mobileRegex = /^\d{10}$/;

export const fullNameRegex = /^[a-zA-Z](?:[a-zA-Z\s]*[a-zA-Z])?$/;

export const pinCodeRegex = /^[1-9][0-9]{5}$/;

export const cityRegex = /^(?!.* {2})[a-zA-Z]+(?: [a-zA-Z]+)*(?<! {2})$/;
export const stateRegex = /^(?!.* {2})[a-zA-Z]+(?: [a-zA-Z]+)*(?<! {2})$/;
export const streetRegex = /^(?! )(?![\s\S]* $)[a-zA-Z0-9\s,'-]*$/;
export const gstRegex =
  /^(?!\s)\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}(?<!\s)$/;

export const passportRegex = /^[A-Z0-9<]+$/i;

export const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
