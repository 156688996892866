import React, { useState, useEffect, useRef } from "react";
import { CiUser, CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import "react-responsive-modal/styles.css";

// export const AvailableCount = () => {
export const AvailableCount: React.FC = () => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="available-form">
      <div
        className="wrapper"
        onClick={toggleOpen}
        style={{ position: "relative" }}
        ref={wrapperRef}
      >
        <CiUser />
        <input type="text" />
      </div>
      {open && (
        <div className="dropdown-section">
          {/* Content of the dropdown section */}
          <p>You can select up to 15 travelers in total.</p>
          <div className="dropdown-wrapper">
            <ul>
              <li>
                <div className="wrapper-one">
                  <p className="title">Adult (11-120)</p>
                  <p className="subtitle">Minimum: 1, Maximum: 15</p>
                </div>
                <div className="wrapper-two">
                  <CiCircleMinus />
                  <p>2</p>
                  <CiCirclePlus />
                </div>
              </li>
              <li>
                <div className="wrapper-one">
                  <p className="title">Adult (11-120)</p>
                  <p className="subtitle">Minimum: 1, Maximum: 15</p>
                </div>
                <div className="wrapper-two">
                  <CiCircleMinus />
                  <p>2</p>
                  <CiCirclePlus />
                </div>
              </li>
              <li>
                <div className="wrapper-one">
                  <p className="title">Adult (11-120)</p>
                  <p className="subtitle">Minimum: 1, Maximum: 15</p>
                </div>
                <div className="wrapper-two">
                  <CiCircleMinus />
                  <p>2</p>
                  <CiCirclePlus />
                </div>
              </li>
            </ul>
            <button>Apply</button>
          </div>
        </div>
      )}
    </div>
  );
};
