import React, { useEffect } from "react";

function AffordabilityWidget({ key, amount }) {
  function loadWidget() {
    const widgetConfig = { key: key ? key : "JYtYXl", amount };
    // Ensure payuAffordability is defined
    if (typeof window.payuAffordability !== "undefined") {
      window.payuAffordability.init(widgetConfig);
    } else {
      console.error("payuAffordability is not available on window object.");
    }
  }

  function appendScript() {
    let myScript = document.getElementById("payu-affordability-widget");
    if (!myScript) {
      myScript = document.createElement("script");
      myScript.setAttribute(
        "src",
        "https://jssdk.payu.in/widget/affordability-widget.min.js"
      );
      myScript.id = "payu-affordability-widget";
      document.body.appendChild(myScript);
    } else {
      console.log("PayU script already exists.");
    }
    myScript.addEventListener("load", loadWidget, true);
  }

  useEffect(() => {
    appendScript();
  }, []);

  return <div id="payuWidget" style={{ padding: "10px" }}></div>;
}

export default AffordabilityWidget;
