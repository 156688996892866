import { Dispatch, SetStateAction, useState } from "react";

interface SideBarHotelFilterProps {
  minPrice: number;
  maxPrice: number;
  upToFlights: number;
  setUpToFlights: Dispatch<SetStateAction<number>>;
  setSearchHotel: any;
}

export const SideBarHotelFilter = ({
  minPrice,
  maxPrice,
  upToFlights,
  setUpToFlights,
  setSearchHotel,
}: SideBarHotelFilterProps) => {
  const [selectedStars, setSelectedStars] = useState<any[]>([]);
  const [amenities, setAmenities] = useState({
    wifi: false,
    pool: false,
    gym: false,
    parking: false,
  });
  const [selectedCategories, setSelectedCategories] = useState<any>({
    hotel: false,
    resort: false,
    hostel: false,
    apartment: false,
  });

  const [selectedCategoriesHouse, setSelectedCategoriesHouse] = useState({
    petFriendly: false,
    familyFriendly: false,
    businessRooms: false,
  });
  const [wheelchairAccChecked, setWheelchairAccChecked] = useState(false);
  const [spaAccChecked, setSpaAccChecked] = useState(false);
  const [swimmingAccChecked, setSwimmingAccChecked] = useState(false);

  const handleStarCategoryChange = (star: string) => {
    const updatedSelectedStars: any = [...selectedStars];

    const index = updatedSelectedStars.indexOf(star);

    if (index !== -1) {
      updatedSelectedStars.splice(index, 1);
    } else {
      updatedSelectedStars.push(star);
    }

    setSelectedStars(updatedSelectedStars);
  };

  const handleCheckboxChange = (e: { target: { id: any; checked: any } }) => {
    const amenityName = e.target.id;
    const updatedAmenities = { ...amenities, [amenityName]: e.target.checked };
    setAmenities(updatedAmenities);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategories({
      ...selectedCategories,
      [category]: !selectedCategories[category],
    });
  };

  const handleCategoryAccessibilityChange = (
    category: any,
    isChecked: boolean | ((prevState: boolean) => boolean)
  ) => {
    switch (category) {
      case "wheelchair":
        setWheelchairAccChecked(isChecked);
        break;
      case "spa":
        setSpaAccChecked(isChecked);
        break;
      case "swimming":
        setSwimmingAccChecked(isChecked);
        break;
      default:
        break;
    }
  };

  const handleCategoryHouseRulesChange = (event: {
    target: { id: any; checked: any };
  }) => {
    const { id, checked } = event.target;

    setSelectedCategoriesHouse({
      ...selectedCategoriesHouse,
      [id]: checked,
    });
  };

  const filterJSX = (
    <>
      <div className="block">
        <h3 className="h3-bold">Price Range</h3>
        <span style={{ margin: "4px" }}>Up to ₹{upToFlights}</span>
        <div className="slidecontainer">
          <input
            type="range"
            min={minPrice}
            max={maxPrice}
            value={upToFlights}
            step={1}
            onChange={(e) => {
              const selectedPrice = Number(e.target.value);
              setUpToFlights(selectedPrice);
            }}
          />
          <span>₹{minPrice}</span>
          <span>₹{maxPrice}</span>
        </div>
      </div>

      <div className="block">
        <h3 className="h3-bold">Search hotel</h3>

        <div className="slidecontainer">
          <input
            type="text"
            placeholder="Search by name"
            style={{
              borderRadius: "7px",
              border: "1px solid #ccc",
              padding: "6px",
            }}
            onChange={(e) => setSearchHotel(e.target.value)}
          />
        </div>
      </div>

      {/* Star Category */}
      <div className="block">
        <h3 className="h3-bold">Star Category</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="3-star"
                checked={selectedStars.includes("3-star")}
                onChange={() => handleStarCategoryChange("3-star")}
              />
              <label className="form-check-label" htmlFor="3-star">
                3-star
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="4-star"
                checked={selectedStars.includes("4-star")}
                onChange={() => handleStarCategoryChange("4-star")}
              />
              <label className="form-check-label" htmlFor="4-star">
                4-star
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="5-star"
                checked={selectedStars.includes("5-star")}
                onChange={() => handleStarCategoryChange("5-star")}
              />
              <label className="form-check-label" htmlFor="5-star">
                5-star
              </label>
            </div>
          </li>
        </ul>
      </div>
      {/* Amenities */}
      <div className="block">
        <h3 className="h3-bold">Amenities</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="wifi"
                checked={amenities.wifi}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="wifi">
                Wi-Fi
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="pool"
                checked={amenities.pool}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="pool">
                Pool
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="gym"
                checked={amenities.gym}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="gym">
                Gym
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="parking"
                checked={amenities.parking}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="parking">
                Parking
              </label>
            </div>
          </li>
        </ul>
      </div>
      {/* Accommodation */}
      <div className="block">
        <h3 className="h3-bold">Accommodation</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="acc-hotel"
                checked={selectedCategories.hotel}
                onChange={() => handleCategoryChange("hotel")}
              />
              <label className="form-check-label" htmlFor="acc-hotel">
                Hotel
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="acc-resort"
                checked={selectedCategories.resort}
                onChange={() => handleCategoryChange("resort")}
              />
              <label className="form-check-label" htmlFor="acc-resort">
                Resort
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="acc-hostel"
                checked={selectedCategories.hostel}
                onChange={() => handleCategoryChange("hostel")}
              />
              <label className="form-check-label" htmlFor="acc-hostel">
                Hostel
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="acc-apartment"
                checked={selectedCategories.apartment}
                onChange={() => handleCategoryChange("apartment")}
              />
              <label className="form-check-label" htmlFor="acc-apartment">
                Apartment
              </label>
            </div>
          </li>
        </ul>
      </div>
      {/* Accessibility */}
      <div className="block">
        <h3 className="h3-bold">Accessibility</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="wheelchair-acc"
                checked={wheelchairAccChecked}
                onChange={(e) =>
                  handleCategoryAccessibilityChange(
                    "wheelchair",
                    e.target.checked
                  )
                }
              />
              <label className="form-check-label" htmlFor="wheelchair-acc">
                Wheelchair
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="spa-acc"
                checked={spaAccChecked}
                onChange={(e) =>
                  handleCategoryAccessibilityChange("spa", e.target.checked)
                }
              />
              <label className="form-check-label" htmlFor="spa-acc">
                Spa
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="swimming-acc"
                checked={swimmingAccChecked}
                onChange={(e) =>
                  handleCategoryAccessibilityChange(
                    "swimming",
                    e.target.checked
                  )
                }
              />
              <label className="form-check-label" htmlFor="swimming-acc">
                Swimming Pool
              </label>
            </div>
          </li>
        </ul>
      </div>
      {/* House Rules */}
      <div className="block">
        <h3 className="h3-bold">House Rules</h3>
        <ul>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="petFriendly"
                checked={selectedCategoriesHouse.petFriendly}
                onChange={handleCategoryHouseRulesChange}
              />
              <label className="form-check-label" htmlFor="petFriendly">
                Pet-Friendly
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="familyFriendly"
                checked={selectedCategoriesHouse.familyFriendly}
                onChange={handleCategoryHouseRulesChange}
              />
              <label className="form-check-label" htmlFor="familyFriendly">
                Family-Friendly
              </label>
            </div>
          </li>
          <li className="py-1">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="businessRooms"
                checked={selectedCategoriesHouse.businessRooms}
                onChange={handleCategoryHouseRulesChange}
              />
              <label className="form-check-label" htmlFor="businessRooms">
                Business rooms
              </label>
            </div>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <div className="col-sm-3 sticky-top" style={{ top: "81px" }}>
        <div className="left-side left_section">{filterJSX}</div>
      </div>

      <div
        className="modal fade px-0"
        tabIndex={-1}
        id="exampleModal_hotel_search"
        aria-labelledby="exampleModalLabel_hotel_search"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="left-side left_section"
                style={{ display: "block" }}
              >
                {filterJSX}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
