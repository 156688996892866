import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";

export const CookiePolicy = () => {
  return (
    <AppLayout>
      <div className="container" style={{ marginTop: "5rem" }}>
        <h1 className="mb-4" style={{ textAlign: "center" }}>
          Cookie Policy
        </h1>

        <div className="container mt-5">
          Welcome to the Cookie Policy of <a href="/">Maashree T&T World</a>
          <p>
            This policy explains how we use cookies and similar technologies on
            our website to enhance your browsing experience. By continuing to
            use our website, you consent to the use of cookies as described
            below.
          </p>
          <div style={{ marginBottom: "28px" }}>
            <h3>1. What Are Cookies:</h3>
            <p>
              Cookies are small text files that are placed on your device when
              you visit a website. They help us gather information about your
              interactions with our site, remember your preferences, and improve
              your overall experience.
            </p>
          </div>
          <div style={{ marginBottom: "28px" }}>
            <h3>2. Types of Cookies We Use:</h3>
            <ul style={{ margin: "10px 26px" }}>
              <li>
                <strong>Essential Cookies:</strong> These cookies are necessary
                for the basic functioning of our website. They enable you to
                navigate the site and use its features.
              </li>
              <li>
                <strong>Analytics Cookies:</strong> We use these cookies to
                collect anonymous data about how visitors use our website. This
                information helps us analyze trends and make improvements.
              </li>
              <li>
                <strong>Functionality Cookies:</strong> These cookies remember
                your preferences, such as language and location settings, to
                provide a personalized experience.
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: "28px" }}>
            <h3>3. How We Use Cookies:</h3>
            <ul style={{ margin: "10px 26px" }}>
              <li>
                <strong>Improving User Experience:</strong> Cookies allow us to
                remember your preferences, making it easier for you to navigate
                our site and access the content you're interested in.
              </li>
              <li>
                <strong>Website Analytics:</strong> We use cookies to understand
                how users interact with our website, which pages are visited
                most frequently, and where improvements can be made.
              </li>
              <li>
                <strong>Personalization:</strong> Cookies enable us to offer you
                personalized content, such as recommended travel destinations
                and relevant offers.
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: "28px" }}>
            <h3>4. Managing Cookie Preferences:</h3>
            <p>
              You have the option to manage your cookie preferences. You can:
            </p>
            <ul style={{ margin: "10px 26px" }}>
              <li>
                <strong>Accept Cookies:</strong> By continuing to use our
                website, you consent to the use of cookies as described in this
                policy.
              </li>
              <li>
                <strong>Modify Settings:</strong> Most web browsers allow you to
                control how cookies are handled. You can choose to block or
                delete cookies from your device.
              </li>
              <li>
                <strong>Opt-Out of Tracking:</strong> You can opt-out of certain
                types of cookies, such as advertising cookies, by using the
                settings provided by third-party advertising networks.
              </li>
            </ul>
            <p>
              Please note that blocking or deleting cookies may affect your
              ability to use certain features of our website.
            </p>
          </div>
          <div style={{ marginBottom: "28px" }}>
            <h3>5. Third-Party Cookies:</h3>
            <p>
              Some cookies on our website may be placed by third-party service
              providers, such as analytics or advertising partners. These
              parties may use cookies to collect information about your online
              activities over time and across different websites.
            </p>
            <h3>6. Changes to this Policy:</h3>
            <p>
              We may update this Cookie Policy from time to time to reflect
              changes in technology or legal requirements. Any changes will be
              posted on our website with an updated effective date.
            </p>
            <p>
              Thank you for choosing <a href="/"> Maashree T&T World</a>. We use
              cookies to provide you with an improved online experience while
              ensuring your privacy is respected. If you have any questions or
              concerns about our use of cookies, please{" "}
              <a href="/support" target="_blank">
                contact us
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <hr style={{ margin: "4%" }} />
      <Footer />
    </AppLayout>
  );
};
