import LazyImage from "../../pages/LazyImage";
import { useEffect, useRef, useState } from "react";
import {
  OTPResendEmail,
  OTPResendMobile,
  OTPVerifyEmail,
  OTPVerifyMobile,
} from "../../api/LoginAndSignInAPIs";
import airportImage from "../../assets/images/airport.jpg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { emailRegex } from "../../utils/validation";
import { useRefreshUser } from "../context/RefreshUserContext";
import { useAuth } from "../context/AuthenticationContext";

interface OTPModalprops {
  resetValues: () => void;
}

export const OTPModal = ({ resetValues }: OTPModalprops) => {
  const [otpValue, setOtpValue] = useState("");
  const [validOTP, setValidOTP] = useState(true);
  const [modalToggle, setModalToggle] = useState("");
  const [modalTarget, setModalTarget] = useState("");
  const [modalDismiss, setModalDismiss] = useState("");
  const [isNewUser, setIsNewUser] = useState("");

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const buttonRefClose = useRef<HTMLButtonElement | null>(null);

  const { setRefreshUser } = useRefreshUser();
  const { updateAuthStatus } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!validOTP && buttonRef.current && isNewUser === "2") {
      buttonRef.current.click();
    } else if (!validOTP && buttonRefClose.current && isNewUser === "1") {
      buttonRefClose.current.click();
      setRefreshUser(true);
      navigate(0);
    } else if (!validOTP && buttonRefClose.current) {
      buttonRefClose.current.click();
      setRefreshUser(true);
      navigate(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validOTP, isNewUser]);

  const submitOtpHandler = () => {
    const getUserId = localStorage.getItem("loginId");
    const countryCode = localStorage.getItem("country-code");

    if (emailRegex.test(getUserId as string)) {
      OTPVerifyEmail(true, String(getUserId), otpValue)
        .then((response) => {
          // set user status, if already registered or not
          setIsNewUser(String(response.data.status));

          console.log(response, "after login");

          if (isNewUser === "1") {
            updateAuthStatus(true);
          }
          localStorage.setItem("token", response.data.token);

          setValidOTP(false);
          setModalToggle("modal");
          setModalTarget("#exampleModal3");
          setModalDismiss("modal");
          toast.success(response.message, {
            toastId: "successToastLogin",
          });
        })
        .catch((error) => {
          setValidOTP(true);
          toast.error(error.response.data.message, {
            toastId: "errorToast",
          });
        });
    } else {
      OTPVerifyMobile(true, String(getUserId), otpValue, String(countryCode))
        .then((response) => {
          // set user status, if already registered or not
          setIsNewUser(String(response.data.status));

          console.log(response, "after login");

          if (isNewUser === "1") {
            updateAuthStatus(true);
          }
          localStorage.setItem("token", response.data.token);
          setValidOTP(false);
          setModalToggle("modal");
          setModalTarget("#exampleModal3");
          setModalDismiss("modal");
          toast.success(response.message, {
            toastId: "successToastLogin",
          });
        })
        .catch((error) => {
          setValidOTP(true);
          toast.error(error.response.data.message, {
            toastId: "errorToast",
          });
        });
    }
  };

  const resendOtpHandler = () => {
    const getUserId = localStorage.getItem("loginId");
    const countryCode = localStorage.getItem("country-code");

    if (emailRegex.test(getUserId as string)) {
      OTPResendEmail(String(getUserId)).then((response) => {
        console.log("Resend OTP details", response);
        toast.info(response.message, {
          toastId: "errorInfoResendOnLogin2",
        });
      });
    } else {
      OTPResendMobile(String(getUserId), countryCode as string).then(
        (response) => {
          toast.info(response.message, {
            toastId: "errorInfoResendOnLogin1",
          });
          console.log("Resend OTP details", response);
        }
      );
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModal2"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-xl  modal-dialog-centered ">
        <div className="modal-content site-modal">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOtpValue("");
                resetValues();
                localStorage.clear();
              }}
            ></button>

            <div className="row modal-row1">
              {" "}
              <div className="col-sm-6">
                <div className="modal-left">
                  <h2 className="modal-head">Login or Register</h2>
                  <div className="mb-4 text-left1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label2 mb-3"
                    >
                      OTP has been sent to email or mobile no.
                    </label>
                    <span className="otp">
                      {" "}
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          fontSize: "12px",
                          marginTop: "8px",
                          right: "9px",
                          cursor: "pointer",
                        }}
                        onClick={resendOtpHandler}
                      >
                        Resend OTP
                      </span>{" "}
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter OTP"
                        value={otpValue}
                        onChange={(e) => setOtpValue(e.target.value)}
                      />
                    </span>
                  </div>

                  <div className="mb-4 text-left">
                    {validOTP ? (
                      <button
                        className="btn lg-btn"
                        onClick={() => submitOtpHandler()}
                        disabled={otpValue === ""}
                      >
                        Login
                      </button>
                    ) : !validOTP && isNewUser === "2" ? (
                      <button
                        ref={buttonRef}
                        className="btn lg-btn custom-class"
                        data-bs-toggle={modalToggle}
                        data-bs-target={modalTarget}
                        data-bs-dismiss={modalDismiss}
                      >
                        Login
                      </button>
                    ) : (
                      <button
                        ref={buttonRefClose}
                        className="btn lg-btn custom-class"
                        data-bs-dismiss={modalDismiss}
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="modal-right">
                  <LazyImage src={airportImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
