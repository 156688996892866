import React, { createContext, useContext, useState } from "react";

interface RefreshUserContextType {
  refreshUser: boolean;
  setRefreshUser: React.Dispatch<React.SetStateAction<boolean>>;
  refreshAdmin: boolean;
  setRefreshAdmin: React.Dispatch<React.SetStateAction<boolean>>;
}

const RefreshUserContext = createContext<RefreshUserContextType | undefined>(
  undefined
);

export function useRefreshUser() {
  const context = useContext(RefreshUserContext);
  if (context === undefined) {
    throw new Error("useRefreshUser must be used within a RefreshUserProvider");
  }
  return context;
}

interface RefreshUserProviderProps {
  children: React.ReactNode;
}

export function RefreshUserProvider({ children }: RefreshUserProviderProps) {
  const [refreshUser, setRefreshUser] = useState(false);
  const [refreshAdmin, setRefreshAdmin] = useState(false);
  

  return (
    <RefreshUserContext.Provider value={{ refreshUser, setRefreshUser, refreshAdmin, setRefreshAdmin }}>
      {children}
    </RefreshUserContext.Provider>
  );
}
