import { useEffect, useState } from "react";
import moment from "moment";

interface FilterFlightsProps {
  flightList: any[];
  toggleFLightPrice: boolean;
  priceRange: number;
  getHightestPrice: number;
  selectedStops: string[];
  selectedFlights: string[];
  selectedDepartureTimes: string[];
  selectedArrivalTimes: string[];
  selectedArrivalAirport: string[];
  refundable: boolean;
}

function useFilterFlights({
  flightList,
  toggleFLightPrice,
  priceRange,
  getHightestPrice,
  selectedStops,
  selectedFlights,
  selectedDepartureTimes,
  selectedArrivalTimes,
  selectedArrivalAirport,
  refundable,
}: FilterFlightsProps) {
  const [filteredFlights, setFilteredFlights] = useState<any[]>([]);

  function removeDuplicatesByFlightNo(arr: any[]) {
    const uniqueFlightNos = new Set();
    return arr.filter((item) => {
      if (!uniqueFlightNos.has(item.Index)) {
        uniqueFlightNos.add(item.Index);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    const result = removeDuplicatesByFlightNo(flightList)?.filter(
      (flight: {
        Duration: string;
        AirlineName: string;
        DepartureTime: string;
        ArrivalTime: string;
        NetFare: number;
        Connections: Array<any>;
        Refundable: string;
      }) => {
        const airlineName = flight.AirlineName?.split("|")[0];
        const departureTimeList = moment(flight.DepartureTime);
        const departureHrs = departureTimeList.format("H");

        const arrivalTimeList = moment(flight.ArrivalTime);
        const arrivalHrs = arrivalTimeList.format("H");

        const priceValue = flight?.NetFare;
        const priceRangeMin = toggleFLightPrice ? priceRange : getHightestPrice;

        const numberOfConnections = flight.Connections?.length;
        const hasNoStopsSelected = selectedStops?.length === 0;
        const hasZeroStopsSelected = selectedStops.includes("0");
        const hasOneStopSelected = selectedStops.includes("1");
        const hasMoreThanOneStopSelected = selectedStops.includes("1+");

        const isConnectionValid =
          (hasNoStopsSelected && numberOfConnections === 0) ||
          (hasZeroStopsSelected && numberOfConnections === 0) ||
          (hasOneStopSelected && numberOfConnections === 1) ||
          (hasMoreThanOneStopSelected && numberOfConnections > 1);

        const hasConnectionToAirports = flight?.Connections?.some(
          (connection: { ArrAirportName: string }) => {
            const airportsInConnection =
              connection &&
              connection?.ArrAirportName?.split("|")[1]?.split(",");
            return airportsInConnection?.some((airport) =>
              selectedArrivalAirport.includes(airport.trim())
            );
          }
        );

        const timeSections: {
          [key: string]: { min: number; max: number };
        } = {
          "05-12": { min: 5, max: 12 }, // 5 am - 12 pm
          "12-18": { min: 12, max: 18 }, // 12 pm - 6 pm
          "18-23": { min: 18, max: 23 }, // 6 pm - 11 pm
          "23-05": { min: 23, max: 29 }, // 11 pm - 5 am (next day)
        };

        const isArrivalTimeValid =
          selectedArrivalTimes?.length === 0 ||
          selectedArrivalTimes?.some((selectedTime) => {
            const selectedArrivalTimeRange = timeSections[selectedTime];
            return (
              selectedArrivalTimeRange &&
              selectedArrivalTimeRange.min <= Number(arrivalHrs) &&
              Number(arrivalHrs) < selectedArrivalTimeRange.max
            );
          });

        const isDepartureTimeValid =
          selectedDepartureTimes?.length === 0 ||
          selectedDepartureTimes?.some((selectedTime) => {
            const selectedDepartureTimeRange = timeSections[selectedTime];
            const isValid =
              selectedDepartureTimeRange &&
              selectedDepartureTimeRange.min <= Number(departureHrs) &&
              Number(departureHrs) < selectedDepartureTimeRange.max;

            return isValid;
          });

        return (
          // Airline conditions
          (selectedFlights?.length === 0 ||
            selectedFlights?.includes(airlineName)) &&
          // Departure time conditions
          isDepartureTimeValid &&
          // Arrival time conditions
          isArrivalTimeValid &&
          // Refundable
          (refundable ? flight.Refundable === "Y" : true) &&
          // Price and connection conditions
          priceValue <= getHightestPrice &&
          priceValue <= priceRangeMin &&
          // No. of stops and no connections condition
          (selectedStops?.length === 0 || isConnectionValid) &&
          // Connected airports
          (selectedArrivalAirport?.length === 0 || hasConnectionToAirports)
        );
      }
    );

    setFilteredFlights(result);
  }, [
    flightList,
    toggleFLightPrice,
    priceRange,
    getHightestPrice,
    selectedStops,
    selectedFlights,
    selectedDepartureTimes,
    selectedArrivalTimes,
    selectedArrivalAirport,
    refundable,
  ]);

  return filteredFlights;
}

export default useFilterFlights;
