import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { TalkToAgents } from "../components/TalkToAgent";
import phoneImage from "../assets/images/phone.png";
import emailImage from "../assets/images/email.png";
import clockImage from "../assets/images/Clock.png";
import counterImage from "../assets/images/counter.png";
import LazyImage from "../pages/LazyImage";

export const CustomerSupport = () => {
  return (
    <AppLayout>
      <main className="container">
        <section className="upcoming_section mt-4" id="upcoming_trips_section">
          <div className="card card_upcmoing">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-8 col-md-8 m-auto">
                  <h1 className="h1-inner text-center">Customer Support</h1>
                  <div className="row">
                    <div className="col-sm-6 pb-4">
                      <div className="text-center">
                        <div className="contact-section mb-1">
                          <LazyImage
                            className="img-fluid"
                            src={phoneImage}
                            alt="information"
                          />
                        </div>
                        <h3 className="block_heading">Phone numbers</h3>
                        <p className="card-text mb-1">
                          <a
                            className="support-contact"
                            target="_blank"
                            href="tel:+919028129888"
                          >
                            <span className="support-country px-2 fw-bold">
                              IN
                            </span>
                            +91 9028 129 888
                          </a>
                        </p>
                        <p className="card-text mb-1">
                          <a
                            className="support-contact"
                            target="_blank"
                            href="tel:+442039129888"
                          >
                            <span className="support-country px-2 fw-bold">
                              UK
                            </span>
                            +44 2039 129 888
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 pb-4">
                      <div className="text-center">
                        <div className="contact-section mb-1">
                          <LazyImage
                            className="img-fluid"
                            src={emailImage}
                            alt="information"
                          />
                        </div>
                        <h3 className="block_heading">Email address</h3>
                        <p className="card-text">
                          <a
                            className="support-contact"
                            target="_blank"
                            href="mailto:enquiry@maashree.in"
                          >
                            enquiry@maashree.in
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 pb-4">
                      <div className="text-center">
                        <div className="contact-section mb-1">
                          <LazyImage
                            className="img-fluid"
                            src={clockImage}
                            alt="information"
                          />
                        </div>
                        <h3 className="block_heading">Operating hours</h3>
                        <p className="card-text mb-1">10:00 AM - 07:00 PM</p>
                        <p className="card-text">emergency support 24X7</p>
                      </div>
                    </div>
                    <div className="col-sm-6 pb-4">
                      <div className="text-center">
                        <div className="contact-section mb-1">
                          <LazyImage
                            className="img-fluid"
                            src={counterImage}
                            alt="information"
                          />
                        </div>
                        <h3 className="block_heading">Time Zone</h3>
                        <p className="card-text">India (GMT+5:30)</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 pb-4 pt-6">
                    <div className="text-center">
                      <h3 className="block_heading">
                        <b>Correspondence Address:</b>
                      </h3>
                      <p className="card-text mb-1">
                        E - 29 Rock Avenue Opp ICICI Bank Hindustan Naka Charkop
                        Kandivali West Mumbai - 400067 INDIA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
