import { useState } from "react";
import React from "react";
import { CiCalendar, CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import "./DestinationFilter.component.css";

function valuetext(value: number) {
  return `${value}`;
}

export const DestinationFilter = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [value, setValue] = useState<number[]>([20, 80]);

  const handleToggle = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(newValue);
    }
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <>
      <div className="des-filter-container">
        <div className="des-filter">
          <div className="filter-cal-main">
            <h3>When are you travelling?</h3>
            <div className="filter-cal" onClick={toggleCalendar}>
              <CiCalendar />
              <input type="text" placeholder="Select Dates" />
            </div>
            {showCalendar && (
              <div className="calendar-container">
                <Calendar />
              </div>
            )}
          </div>
        </div>
        <div className="des-filter-rest">
          {/* Filter Accordian */}
          <div className="filter-acco-section">
            <h3>All Paris Tours</h3>
            {/* If only single point is available */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Accordion 1
              </AccordionSummary>
              <AccordionDetails>Dance Lessons</AccordionDetails>
            </Accordion>
            {/* If only single point is available */}

            {/* If multiple points are available */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Accordion 2
              </AccordionSummary>
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Sub Accordion
                  </AccordionSummary>
                  <AccordionDetails>Dance Lessons</AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Sub Accordion
                  </AccordionSummary>
                  <AccordionDetails>Dance Lessons</AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            {/* If multiple points are available */}
          </div>
          {/* Filter Accordian */}

          {/* Time Section */}
          <div className="filter-time-section">
            <h3 className="head-two">All Paris Tours</h3>
            <div className="filter-time">
              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <h3>Morning</h3>
                  <p>Starts before 12pm</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <h3>Morning</h3>
                  <p>Starts before 12pm</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <h3>Morning</h3>
                  <p>Starts before 12pm</p>
                </div>
              </div>
            </div>
          </div>
          {/* Time Section */}

          {/* Duration Section */}
          <div className="filter-time-section">
            <h3 className="head-two">All Paris Tours</h3>
            <div className="filter-time">
              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              {showMore && (
                <div className="filter-section">
                  <input type="checkbox" />
                  <div className="filter-box">
                    <p className="title-two">Morning</p>
                  </div>
                </div>
              )}

              <button onClick={handleToggle} className="toggle-button">
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          {/* Duration Section */}

          {/* Price Section */}
          <div className="filter-time-section">
            <h3 className="head-two">All Paris Tours</h3>
            <div className="range-section">
              <Box sx={{ width: 300, position: "relative" }}>
                <div className="range-labels">
                  <span className="range-value left">₹{value[0]}</span>
                  <span className="range-value right">₹{value[1]}</span>
                </div>
                <Slider
                  getAriaLabel={() => "Price range"}
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="off"
                  getAriaValueText={valuetext}
                  min={0}
                  max={100}
                />
              </Box>
            </div>
          </div>
          {/* Price Section */}

          {/* Ratings Section */}
          <div className="filter-time-section">
            <h3 className="head-two">All Paris Tours</h3>
            <div className="ratings-section">
              <div className="rating">
                <input type="radio" />
                <div className="rating-stars">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>

              <div className="rating">
                <input type="radio" />
                <div className="rating-stars">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <CiStar />
                </div>
                <p className="rating-text">& up</p>
              </div>

              <div className="rating">
                <input type="radio" />
                <div className="rating-stars">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <CiStar />
                  <CiStar />
                </div>
                <p className="rating-text">& up</p>
              </div>
            </div>
          </div>
          {/* Ratings Section */}

          {/* Specials Section */}
          <div className="filter-time-section last">
            <h3 className="head-two">All Paris Tours</h3>
            <div className="filter-time">
              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              <div className="filter-section">
                <input type="checkbox" />
                <div className="filter-box">
                  <p className="title-two">Morning</p>
                </div>
              </div>

              {showMore && (
                <div className="filter-section">
                  <input type="checkbox" />
                  <div className="filter-box">
                    <p className="title-two">Morning</p>
                  </div>
                </div>
              )}

              <button onClick={handleToggle} className="toggle-button">
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          {/* Specials Section */}
        </div>
      </div>
    </>
  );
};
