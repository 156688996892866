import { useState } from "react";
import roomImage from "../../../assets/images/room_image.png";
import floorImg from "../../../assets/images/GridFour.png";
import bedImage from "../../../assets/images/Bed.png";
import xPng from "../../../assets/images/X.png";
import rightImg from "../../../assets/images/right.png";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../../pages/LazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface RoomDetailsProps {
  roomContentBoard: any;
}
export const RoomDetails = ({ roomContentBoard }: RoomDetailsProps) => {
  const navigate = useNavigate();
  const [showRoom, setShowRoom] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const initialItemsToShow = 2;

  const itemsToShow = showAll
    ? roomContentBoard && roomContentBoard?.length
    : initialItemsToShow;

  const toggleImage = () => {
    setShowRoom(!showRoom);
  };

  return (
    <>
      <section className="res_hide" id="room_section">
        <div className="row pt-3">
          <h3 className="h3-bold py-2">Select Rooms</h3>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{
                color: "rgb(83 83 83)",
                position: "absolute",
                marginTop: "7px",
                marginRight: "10px",
              }}
            />
            <input
              placeholder="Search rooms"
              style={{
                borderRadius: "8px",
                paddingLeft: "10px",
                border: "2px solid #d3d3d3",
              }}
            />
          </div>
        </div>
        <div className="inline-form">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="breakfast-included"
            />
            <label className="form-check-label" htmlFor="breakfast-included">
              Breakfast Included
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="breakfast-and-lunch-dinner-included"
            />
            <label
              className="form-check-label"
              htmlFor="breakfast-and-lunch-dinner-included"
            >
              Breakfast & Lunch/Dinner Included
            </label>
          </div>
        </div>
        <div className="row row_grid">
          <div className="col-sm-4 px-0 cols_border">
            <div className="body-container">
              <div className="image-section">
                <LazyImage className="img-fluid" src={roomImage} alt="star" />
              </div>
              <div className="content-body">
                <h3 className="h3-bold py-3 mb-0">Luxe Twin Room</h3>
              </div>
              <div className="image-body d-flex">
                <LazyImage src={floorImg} alt="" />
                <span className="text-left text-body">210 sq.ft</span>
              </div>
              <div className="image-body d-flex">
                <LazyImage src={floorImg} alt="" />
                <span className="text-left text-body">City View</span>
              </div>
              <div className="image-body d-flex">
                <LazyImage src={bedImage} alt="" />
                <span className="text-left text-body">
                  1 Queen bed or 2 Twin Bed(s)
                </span>
              </div>
              <div className="image-body d-flex">
                <span className="text-left text-body">Work Desk</span>
              </div>
              <div className="image-body">
                <div className="accordion">
                  <p className="text-left text_body">
                    More details
                    <span>
                      <LazyImage
                        className="active-img"
                        id="image_first"
                        width={20}
                        height={20}
                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                        alt="collapse-arrow"
                      />
                    </span>
                  </p>
                </div>
                <div className="panel"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-8 px-0">
            <div className="row row_panel">
              <div className="col-sm-6 px-0 panel-container panel_container">
                <div className="body-container pt-0">
                  <div className="row body-container">
                    <div className=" py-2 d-flex">
                      <button className="room-btn">RECOMMENDED</button>
                    </div>
                    <div className="content-body">
                      <h3 className="h3-bold py-3 mb-0">Room Only</h3>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage src={xPng} alt="" />
                      <span className="text-left text_body">
                        Non-Refundable
                      </span>
                    </div>
                    <span style={{ backgroundColor: "white", zIndex: "99" }}>
                      {roomContentBoard
                        ?.slice(0, itemsToShow)
                        .map(({ node }: any) => (
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              {node.boardData.texts[0].text}
                            </span>
                          </div>
                        ))}
                    </span>

                    {!showAll &&
                      roomContentBoard &&
                      roomContentBoard?.length > initialItemsToShow && (
                        <div className="image-body">
                          <div className="accordion">
                            <p
                              className="text-left text_body"
                              onClick={() => setShowAll(true)}
                            >
                              More details
                              <span>
                                <LazyImage
                                  className="active-img"
                                  id="image_first"
                                  width={20}
                                  height={20}
                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                  alt="collapse-arrow"
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      )}

                    {showAll &&
                      roomContentBoard &&
                      roomContentBoard?.length > initialItemsToShow && (
                        <div className="image-body">
                          <div className="accordion">
                            <p
                              className="text-left text_body"
                              onClick={() => setShowAll(false)}
                            >
                              Less
                              <span>
                                <LazyImage
                                  className="active-img"
                                  id="image_first"
                                  width={20}
                                  height={20}
                                  src="https://img.icons8.com/sf-regular/48/expand-arrow.png"
                                  alt="expand-arrow"
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 px-0 panel-container panel_section">
                <div className="body-container">
                  <div className="row body-container">
                    <div className="price_section">
                      <div className="price-content-left">
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">Per Night</span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            <del>+₹4,799</del>
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            +₹518 taxes & fees
                          </span>
                        </div>
                      </div>
                      <div
                        className="price-right"
                        onClick={() => navigate("/hotel-passenger")}
                      >
                        <button className="btn btn-login">Select Room</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-0" />
              {/* second-column */}
              <div className="col-sm-6 px-0 panel-container panel_container">
                <div className="body-container pt-0">
                  <div className="row body-container">
                    <div className="content-body">
                      <h3 className="h3-bold py-3 mb-0">Room with Breakfast</h3>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage src={xPng} alt="" />
                      <span className="text-left text_body">
                        Non-Refundable
                      </span>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage
                        className="right-image-icon"
                        src={rightImg}
                        alt=""
                      />
                      <span className="text-left text-body">
                        Free Welcome Drink on Arrival
                      </span>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage
                        className="right-image-icon"
                        src={rightImg}
                        alt=""
                      />
                      <span className="text-left text-body">
                        Free Breakfast
                      </span>
                    </div>
                    <div className="image-body">
                      <div className="accordion">
                        <p className="text-left text_body">
                          More details
                          <span>
                            <LazyImage
                              className="active-img"
                              id="image_first"
                              width={20}
                              height={20}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          </span>
                        </p>
                      </div>
                      <div className="panel"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 px-0 panel-container panel_section">
                <div className="body-container">
                  <div className="row body-container">
                    <div className="price_section">
                      <div className="price-content-left">
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">Per Night</span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            <del>+₹4,799</del>
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            +₹518 taxes & fees
                          </span>
                        </div>
                      </div>
                      <div
                        className="price-right"
                        onClick={() => navigate("/hotel-passenger")}
                      >
                        <button className="btn btn-login">Select Room</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-0" />
              {/* third-column */}
              <div className="col-sm-6 px-0 panel-container panel_container">
                <div className="body-container pt-0">
                  <div className="row body-container">
                    <div className="content-body">
                      <h3 className="h3-bold py-3 mb-0">
                        Room with Breakfast + Lunch/Dinner
                      </h3>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage src={xPng} alt="" />
                      <span className="text-left text_body">
                        Non-Refundable
                      </span>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage
                        className="right-image-icon"
                        src={rightImg}
                        alt=""
                      />
                      <span className="text-left text-body">
                        Free Welcome Drink on Arrival
                      </span>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage
                        className="right-image-icon"
                        src={rightImg}
                        alt=""
                      />
                      <span className="text-left text-body">
                        Free Lunch Or Dinner
                      </span>
                    </div>
                    <div className="image_body d-flex">
                      <LazyImage
                        className="right-image-icon"
                        src={rightImg}
                        alt=""
                      />
                      <span className="text-left text-body">
                        Free Breakfast
                      </span>
                    </div>
                    <div className="image-body">
                      <div className="accordion">
                        <p className="text-left text_body">
                          More details
                          <span>
                            <LazyImage
                              className="active-img"
                              id="image_first"
                              width={20}
                              height={20}
                              src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                              alt="collapse-arrow"
                            />
                          </span>
                        </p>
                      </div>
                      <div className="panel"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 px-0 panel-container panel_section">
                <div className="body-container">
                  <div className="row body-container">
                    <div className="price_section">
                      <div className="price-content-left">
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">Per Night</span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            <del>+₹4,799</del>
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body">
                            +₹518 taxes & fees
                          </span>
                        </div>
                      </div>
                      <div className="price-right">
                        <button className="btn btn-login">Select Room</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      {/* /////// new changes */}

      <section className="res_show" id="res_room_section">
        <div className="row pt-3">
          <h3 className="h3-bold py-2">Select Rooms</h3>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{
                color: "rgb(83 83 83)",
                position: "absolute",
                marginTop: "7px",
                marginRight: "10px",
              }}
            />
            <input
              placeholder="Search rooms"
              style={{
                borderRadius: "8px",
                paddingLeft: "10px",
                border: "2px solid #d3d3d3",
              }}
            />
          </div>
        </div>
        <div className="inline-form">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="same-address1"
            />
            <label className="form-check-label" htmlFor="same-address1">
              Breakfast Included
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="same-address2"
            />
            <label className="form-check-label" htmlFor="same-address2">
              Breakfast & Lunch/Dinner Included
            </label>
          </div>
        </div>
        <div className="res-main-div d-flex gap-3 align-items-center">
          <div className="block-res p0x-cols_border">
            <div className="image-section">
              <LazyImage className="img-fluid" src={roomImage} alt="star" />
            </div>
          </div>

          <div className="block-res body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Luxe Twin Room</h3>
            </div>
            <div className="image-body d-flex">
              <LazyImage src={floorImg} alt="" />
              <span className="text-left text-body">210 sq.ft</span>
            </div>
            <div className="image-body d-flex">
              <LazyImage src={floorImg} alt="" />
              <span className="text-left text-body">City View</span>
            </div>
            <div className="image-body d-flex">
              <LazyImage src={bedImage} alt="" />
              <span className="text-left text-body">
                1 Queen bed or 2 Twin Bed(s)
              </span>
            </div>
            <div className="image-body d-flex">
              <span className="text-left text-body">Work Desk</span>
            </div>
            <div className="image-body">
              <div className="accordion">
                <p
                  className="text-left text_body"
                  id="more-details"
                  onClick={toggleImage}
                >
                  View Rates
                  <span>
                    <LazyImage
                      className="active-img"
                      id="image_first"
                      width={20}
                      height={20}
                      src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                      alt="collapse-arrow"
                    />
                  </span>
                </p>
              </div>
              <div className="panel"></div>
            </div>
          </div>
        </div>
        {showRoom && (
          <div className="px-0">
            <div className="row row_panel">
              <div className="res-card">
                <div className="res-card-body">
                  <div className="d-flex justify-content-evenly align-items-center">
                    <div className="card-bolck">
                      <div className="body-container pt-0">
                        <div className="body-container">
                          <div className=" py-2 d-flex">
                            <button className="room-btn">RECOMMENDED</button>
                          </div>
                          <div className="content-body">
                            <h3 className="h3-bold py-3 mb-0">Room Only</h3>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage src={xPng} alt="" />
                            <span className="text-left text_body">
                              Non-Refundable
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              No meals included
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Welcome Drink on Arrival
                            </span>
                          </div>
                          {!showAll &&
                            roomContentBoard &&
                            roomContentBoard?.length > initialItemsToShow && (
                              <div className="image-body">
                                <div className="accordion">
                                  <p
                                    className="text-left text_body"
                                    onClick={() => setShowAll(true)}
                                  >
                                    More details
                                    <span>
                                      <LazyImage
                                        className="active-img"
                                        id="image_first"
                                        width={20}
                                        height={20}
                                        src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                        alt="collapse-arrow"
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            )}

                          {showAll &&
                            roomContentBoard &&
                            roomContentBoard?.length > initialItemsToShow && (
                              <div className="image-body">
                                <div className="accordion">
                                  <p
                                    className="text-left text_body"
                                    onClick={() => setShowAll(false)}
                                  >
                                    Less
                                    <span>
                                      <LazyImage
                                        className="active-img"
                                        id="image_first"
                                        width={20}
                                        height={20}
                                        src="https://img.icons8.com/sf-regular/48/expand-arrow.png"
                                        alt="expand-arrow"
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="body-container">
                        <div className="row body-container">
                          <div className="price_section">
                            <div className="price-content-left">
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  Per Night
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  <del>+₹4,799</del>
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  +₹518 taxes &amp; fees
                                </span>
                              </div>
                            </div>
                            <div
                              className="price-right"
                              onClick={() => navigate("/hotel-passenger")}
                            >
                              <button className="btn btn-login">
                                Select Room
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="res-card">
                <div className="res-card-body">
                  <div className="d-flex justify-content-evenly align-items-center">
                    <div className="card-bolck">
                      <div className="body-container pt-0">
                        <div className="body-container">
                          <div className="content-body">
                            <h3 className="h3-bold py-3 mb-0">
                              Room with Breakfast
                            </h3>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage src={xPng} alt="" />
                            <span className="text-left text_body">
                              Non-Refundable
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Welcome Drink on Arrival
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Breakfast
                            </span>
                          </div>
                          <div className="image-body">
                            <div className="accordion">
                              <p className="text-left text_body">
                                More details
                                <span>
                                  <LazyImage
                                    className="active-img"
                                    id="image_first"
                                    width={20}
                                    height={20}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                </span>
                              </p>
                            </div>
                            <div className="panel"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-bolck">
                      <div className="body-container">
                        <div className="row body-container">
                          <div className="price_section">
                            <div className="price-content-left">
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  Per Night
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  <del>+₹4,799</del>
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  +₹518 taxes &amp; fees
                                </span>
                              </div>
                            </div>
                            <div
                              className="price-right"
                              onClick={() => navigate("/hotel-passenger")}
                            >
                              <button className="btn btn-login">
                                Select Room
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="res-card">
                <div className="res-card-body">
                  <div className="d-flex justify-content-evenly align-items-center">
                    <div className="card-bolck">
                      <div className="body-container pt-0 px-0">
                        <div className="body-container">
                          <div className="content-body">
                            <h3 className="h3-bold py-3 mb-0">
                              Room with Breakfast + Lunch/Dinner
                            </h3>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage src={xPng} alt="" />
                            <span className="text-left text_body">
                              Non-Refundable
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Welcome Drink on Arrival
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Lunch Or Dinner
                            </span>
                          </div>
                          <div className="image_body d-flex">
                            <LazyImage
                              className="right-image-icon"
                              src={rightImg}
                              alt=""
                            />
                            <span className="text-left text-body">
                              Free Breakfast
                            </span>
                          </div>
                          <div className="image-body">
                            <div className="accordion">
                              <p className="text-left text_body">
                                More details
                                <span>
                                  <LazyImage
                                    className="active-img"
                                    id="image_first"
                                    width={20}
                                    height={20}
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                </span>
                              </p>
                            </div>
                            <div className="panel"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-bolck">
                      <div className="body-container px-0">
                        <div className="row body-container px-0">
                          <div className="price_section">
                            <div className="price-content-left">
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  Per Night
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  <del>+₹4,799</del>
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <h3 className="h3-bold py-0 mb-0">₹4,319</h3>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body">
                                  +₹518 taxes &amp; fees
                                </span>
                              </div>
                            </div>
                            <div
                              className="price-right"
                              onClick={() => navigate("/hotel-passenger")}
                            >
                              <button className="btn btn-login">
                                Select Room
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
