import React from "react";

interface LazyImageProps {
  src: string;
  className?: string;
  alt?: string;
  width?: number;
  height?: number;
  id?: string;
  style?: any;
}

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  className,
  alt,
  width,
  height,
  id,
  style,
}) => {
  return (
    <img
      src={src}
      className={className}
      alt={alt}
      loading="lazy"
      width={width}
      height={height}
      id={id}
      style={style}
    />
  );
};

export default LazyImage;
