import checkCircleImg from "../assets/images/CheckCircle.png";
import KeyImage from "../assets/images/Key.png";
import visaImg from "../assets/images/Visa_Inc_logo-1.svg";
import masterCardImage from "../assets/images/Mastercard-logo-1.svg";
import rupayImage from "../assets/images/logo-rupay.svg";
import amexImage from "../assets/images/logo-amex.svg";
import dinnerImage from "../assets/images/logo-diner.svg";
import gpayImage from "../assets/images/Google_Pay_Logo_1.svg";
import upiImage from "../assets/images/upi-ar21-1.svg";
import LazyImage from "../pages/LazyImage";
import { AppLayout } from "../components/AppLayout";
import { useNavigate } from "react-router-dom";

export const Payment = () => {
  const navigate = useNavigate();
  return (
    <AppLayout>
      <main className="container">
        <div className="tabbing-bar">
          <div className="tab-content" id="nav-tabContent">
            <div className="row">
              <div className="col-sm-12">
                <div className="header_section">
                  <h3 className="h3-bold">Select a payment method</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <section className="cheapest_section" id="cheapest_section">
                  <div className="card card_checkout">
                    <div className="card-body">
                      <h3 className="h3-bold mb-3" id="adult_1">
                        <span>
                          <LazyImage
                            className="img-fluid"
                            src={checkCircleImg}
                            alt=""
                          />
                        </span>
                        Credit/Debit Card
                      </h3>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 pb-0">
                              <div className="card-content">
                                <div className="mb-4 text-left1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Bank card number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                              <div className="card-content">
                                <div className="mb-4 text-left1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Card holder name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                              <div className="card-content">
                                <div className="mb-4 text-left1">
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Expiration date"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                              <div className="card-content">
                                <div className="input-group mb-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="CVV number"
                                    aria-label="CVV number"
                                  />
                                  <span className="input-group-text">
                                    <LazyImage
                                      className="img-fluid"
                                      src={KeyImage}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <ul className="social-media payment-method">
                            <li>
                              <LazyImage src={visaImg} className="visa" alt=""/>
                            </li>
                            <li>
                              <LazyImage src={masterCardImage} alt=""/>
                            </li>
                            <li>
                              <LazyImage src={rupayImage} alt=""/>
                            </li>
                            <li>
                              <LazyImage src={amexImage} alt="" />
                            </li>
                            <li>
                              <LazyImage src={dinnerImage} alt=""/>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card_checkout">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 payment_col">
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="same-address4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="same-address4"
                            >
                              Electronic Payment
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <ul className="social-media payment-method">
                            <li className="payment-list">
                              <LazyImage src={gpayImage} alt=""/>
                            </li>
                            <li className="payment-list">
                              <LazyImage src={upiImage} alt=""/>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-text payment_btn">
                    <button
                      className="btn btn-login"
                      type="button"
                      onClick={() => navigate("/booking-confirmation")}
                    >
                      Pay ₹ 47,298
                    </button>
                  </div>
                </section>
              </div>
              <div className="col-sm-4">
                <div className="left-side">
                  <div className="block block_right">
                    <ul>
                      <li className="py-1">
                        <div className="form-check px-0">
                          <div className="d-flex">
                            <span className="heading_check">
                              New Delhi to Paris
                            </span>
                          </div>
                        </div>
                        <span className="heading_check">Fri, 4 Aug 23</span>
                      </li>
                    </ul>
                    <div className="main_content">
                      <div className="">
                        <div className="payment_container payment_div">
                          <div className="card-content">
                            <h3 className="h3-bold">21:50</h3>
                          </div>
                          <div className="card-content">
                            <h3 className="h3-bold">New Delhi</h3>
                            <p className="card-text mb-0">
                              Indira Gandhi International Airport, Terminal 3
                            </p>
                            <p className="card-text">03 h 35 m</p>
                          </div>
                        </div>
                        <div className="payment_container">
                          <div className="card-content">
                            <h3 className="h3-bold">21:50</h3>
                          </div>
                          <div className="card-content">
                            <h3 className="h3-bold">
                              Dubai <span>Dubai International, Terminal 3</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="block mb-0">
                    <ul>
                      <li className="py-1">
                        <div className="form-check px-0">
                          <div className="d-flex">
                            <span className="hading_check">Total: </span>
                          </div>
                        </div>
                        <span className="hading_check">₹ 47,298</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </AppLayout>
  );
};
