import React, { useEffect, useMemo, useRef, useState } from "react";

import mapPin from "../../../assets/images/MapPin.svg";

interface AutoCompleteHotelProps {
  label: string;
  value: any;
  filteredDestinations: any;
  cityName: string;
  setCityName: (value: string) => void;
  setHotelDestinationPlace: (value: string) => void;
  focusNextInput: () => void;
  forwardedRef: React.RefObject<HTMLInputElement>;
  setHotelDestinationCode: any;
}

const AutoCompleteHotel: React.FC<AutoCompleteHotelProps> = ({
  label,
  value,
  filteredDestinations,
  cityName,
  setHotelDestinationPlace,
  setCityName,
  forwardedRef,
  focusNextInput = () => {},
  setHotelDestinationCode,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const airportListRef = useRef<HTMLUListElement | null>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

  const handleDocumentClick = (e: { target: any }) => {
    if (
      showDropdown &&
      airportListRef.current &&
      !airportListRef.current.contains(e.target)
    ) {
      setCityName("");
      setHotelDestinationPlace("");
      setHotelDestinationCode("");
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  const handleListItemKeyDown = (e: any) => {
    if (!showDropdown) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (
        highlightedIndex === -1 ||
        highlightedIndex === filteredDestinations.length - 1
      ) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex(highlightedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlightedIndex === -1 || highlightedIndex === 0) {
        setHighlightedIndex(filteredDestinations.length - 1);
      } else {
        setHighlightedIndex(highlightedIndex - 1);
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedCity = filteredDestinations[highlightedIndex];
        setCityName(selectedCity?.node.destinationData.texts[0].text);
        setHotelDestinationPlace(
          selectedCity?.node.destinationData.texts[0].text
        );
        setHotelDestinationCode(
          selectedCity?.node.destinationData.texts[0].language
        );
        // onChange({
        //   cityName: selectedAirport.CityName,
        //   code: selectedAirport.Code,
        //   airportName: selectedAirport.Name,
        // });

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedCity = filteredDestinations[highlightedIndex];
        setCityName(selectedCity?.node.destinationData.texts[0].text);
        setHotelDestinationPlace(
          selectedCity?.node.destinationData.texts[0].text
        );
        setHotelDestinationCode(
          selectedCity?.node.destinationData.texts[0].language
        );
        // onChange({
        //   cityName: selectedAirport.CityName,
        //   code: selectedAirport.Code,
        //   airportName: selectedAirport.Name,
        // });

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
      focusNextInput && focusNextInput();
    }
  };

  return (
    <>
      <div className="inline-text">
        <div className="border px-2 py-2 rounded-2 border-dark">
          <div className="d-flex align-items-center gap-2">
            <img loading="lazy" className="multi-icon" src={mapPin} alt="" />
            <span className="fw-bold fs-6">{label}</span>
          </div>
          <div className="input_heading">
            <input
              //  type="text"
              type="search"
              aria-label="Search"
              ref={forwardedRef}
              className="fw-bold border-0 fs-4 w-100"
              placeholder={"Destination"}
              value={cityName}
              onChange={(e) => {
                setCityName(e.target.value);
                setHotelDestinationPlace(e.target.value);
                setShowDropdown(true);
              }}
              onKeyDown={handleListItemKeyDown}
            />
          </div>
          <div className="code_heading">
            <span className="code-des d-inline-block text-truncate w-100">
              {cityName}
            </span>
          </div>
        </div>
        {showDropdown && cityName !== "" && (
          <ul
            ref={airportListRef}
            style={{
              margin: "2px",
              border: "1px solid #ccc",
              padding: "6px",
              position: "absolute",
              backgroundColor: "white",
              width: "100%",
              borderRadius: "5px",
              zIndex: 999,
              maxHeight: "20rem",
              overflowY: "auto",
            }}
          >
            {filteredDestinations.map((item: any, idx: number) => {
              const cityName = item.node.destinationData.texts[0].text;
              const cityCode = item.node.destinationData.texts[0].language;
              const code = item.node.destinationData.code;

              return (
                <li
                  key={code}
                  style={{
                    listStyleType: "none",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    cursor: "pointer",
                    backgroundColor:
                      idx === highlightedIndex ? "#ffebeb" : "white",
                    borderRadius: idx === highlightedIndex ? "10px" : "",
                    paddingLeft: "7px",
                  }}
                  onClick={() => {
                    setCityName(cityName);
                    setHotelDestinationPlace(cityName);
                    setHotelDestinationCode(cityCode);
                    setShowDropdown(false);
                    if (focusNextInput) {
                      focusNextInput();
                    }
                  }}
                  tabIndex={0}
                >
                  <span>{cityName}</span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default AutoCompleteHotel;
