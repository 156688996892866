function usePassengersLabel(passengers: {
  adults: number;
  children: number;
  infants?: number;
}) {
  const passengersLabelParts = [];
  if (passengers.adults > 0) {
    passengersLabelParts.push(
      `${passengers.adults} Adult${passengers.adults !== 1 ? "s" : ""}`
    );
  }
  if (passengers.children > 0) {
    passengersLabelParts.push(
      `${passengers.children} Child${passengers.children !== 1 ? "ren" : ""}`
    );
  }
  if (passengers.infants && passengers.infants > 0) {
    passengersLabelParts.push(
      `${passengers.infants} Infant${passengers.infants !== 1 ? "s" : ""}`
    );
  }

  const passengersLabel =
    passengersLabelParts.length > 0 ? `${passengersLabelParts.join(", ")}` : "";

  return passengersLabel;
}

export default usePassengersLabel;
