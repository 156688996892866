import admin_1 from "../../../assets/images/admin_1.png";
import PencilSimple from "../../../assets/images/PencilSimple.png";
import LazyImage from "../../../pages/LazyImage";
import { updateHotelDeals } from "../../../api/AdminAPI";
import { useState } from "react";
import { toast } from "react-toastify";
import AWS from "aws-sdk";

interface AddEditProps {
  setToggleAdmin: (value: string) => void;
}

export const AddEdit = ({ setToggleAdmin }: AddEditProps) => {
  const getSelectedDealJSON = localStorage.getItem("hotel-deal");
  const initialSelectedDeal = JSON.parse(String(getSelectedDealJSON));

  const [userImage, setUserImage] = useState<any | null>(null);

  const [selectedDeal, setSelectedDeal] = useState(initialSelectedDeal);

  function getFileExtension(filename: string) {
    const extension = filename.split(".").pop();
    return extension ? "." + extension : "";
  }

  const submitDealsHandler = () => {
    if (userImage !== null) {
      const profilePic =
        userImage !== null
          ? "cdn/images/hotel-deals/" +
            selectedDeal.city.replace(/\s/g, "") +
            getFileExtension(userImage.name)
          : "";

      updateHotelDeals({
        _id: selectedDeal._id,
        city: selectedDeal.city,
        details: selectedDeal.details,
        hotelImage: profilePic,
      })
        .then((res) => {
          setToggleAdmin("promotions");
          toast.success("Successfully Updated Hotel Deal", {
            toastId: "success-admin",
          });
        })
        .catch((err) =>
          toast.error("Error in updating Hotel Deal", {
            toastId: "error-admin",
          })
        );

      localStorage.setItem("hotelImage", profilePic);
    } else if (selectedDeal.details !== "") {
      updateHotelDeals({
        _id: selectedDeal._id,
        city: selectedDeal.city,
        details: selectedDeal.details,
        hotelImage: selectedDeal.hotelImage,
      })
        .then((res) => {
          setToggleAdmin("promotions");
          toast.success("Successfully Updated Hotel Deal", {
            toastId: "success-admin",
          });
        })
        .catch((err) =>
          toast.error("Error in updating Hotel Deal", {
            toastId: "error-admin",
          })
        );
    } else {
      toast.error("Please upload deal image before proceeding", {
        toastId: "mandatory-image",
      });
    }

    if (!userImage) {
      return;
    } else {
      uploadImageToS3();
    }
  };

  async function uploadImageToS3() {
    if (userImage !== null) {
      try {
        AWS.config.update({
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_PUBLIC_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_PUBLIC_SECRET_ACCESS_KEY,
        });

        const S3_BUCKET = "maashree-public-s3";
        const REGION = process.env.REACT_APP_AWS_REGION;
        const imagePath = "cdn/images/hotel-deals/";

        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });

        const params = {
          Bucket: S3_BUCKET,
          Key:
            imagePath +
            selectedDeal.city.replace(/\s/g, "") +
            getFileExtension(userImage.name),
          Body: userImage,
          ContentType: userImage.type,
        };

        await s3.upload(params).promise();
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleCityChange = (event: { target: { value: any } }) => {
    const newCity = event.target.value;
    setSelectedDeal({ ...selectedDeal, city: newCity });
  };

  const handleDetailsChange = (event: { target: { value: any } }) => {
    const newDetails = event.target.value;
    setSelectedDeal({ ...selectedDeal, details: newDetails });
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return; // No file selected, exit the function
    }

    const maxSizeBytes = 5 * 1024 * 1024; // 5 MB
    if (selectedFile.size > maxSizeBytes) {
      toast.error("File size exceeds the limit of 5 MB");
      setUserImage("");
      return;
    }

    // Check file extension (lowercased) against allowed types
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only PNG, JPG, and JPEG files are allowed");
      setUserImage("");
      return;
    }

    setUserImage(selectedFile);
  };

  return (
    <>
      {/* <!-- add and edit -page --> */}
      <section className="mt-3" id="admin-profile">
        {getSelectedDealJSON && (
          <>
            <div className="admin-dashboard">
              <div className="user-heading py-3">
                <h3 className="h3-bold">{initialSelectedDeal.city}</h3>
              </div>

              <div className="profile-section d-flex gap-3">
                <div className="left-profile-section">
                  <h3 className="block_heading">Upload and manage images:</h3>
                </div>
                <div className="right-profile-section">
                  <div
                    className="profile-image"
                    style={{
                      textTransform: "capitalize",
                      width: "100px",
                      height: "100px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <>
                      {userImage ? (
                        <LazyImage
                          src={URL.createObjectURL(userImage)}
                          alt="User"
                          className="userProfilePicture"
                        />
                      ) : initialSelectedDeal.hotelImage !== "" ? (
                        <LazyImage
                          className="userProfilePicture"
                          src={`https://d3c46yygxatk0.cloudfront.net/${initialSelectedDeal.hotelImage}`}
                          alt="profile"
                        />
                      ) : (
                        <LazyImage
                          className="userProfilePicture"
                          src={admin_1}
                          alt="profile"
                        />
                      )}
                    </>
                  </div>
                  <div className="action-icon d-flex justify-content-between align-items-center py-2">
                    <div className="link-box">
                      <div className="file-box">
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          id="inputGroupFile01"
                          value=""
                          onChange={(event) => handleFileChange(event)}
                        />
                        <LazyImage src={PencilSimple} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="profile-section d-flex gap-3">
                <div className="left-profile-section">
                  <h3 className="block_heading">City</h3>
                </div>
                <div className="right-profile-section">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="cityInput"
                      placeholder="Enter"
                      value={selectedDeal.city}
                      onChange={handleCityChange}
                    />
                  </div>
                </div>
              </div>

              <div className="profile-section d-flex gap-3">
                <div className="left-profile-section">
                  <h3 className="block_heading">Offers details</h3>
                </div>
                <div className="right-profile-section">
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="detailsInput"
                      placeholder="Enter"
                      value={selectedDeal.details}
                      onChange={handleDetailsChange}
                    />
                  </div>
                </div>
              </div>

              <div className="py-4 text-center">
                <button
                  className="btn btn-login"
                  type="button"
                  onClick={submitDealsHandler}
                >
                  {" "}
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};
