import { toast } from "react-toastify";
import {
  OTPResendEmail,
  OTPResendMobile,
  OTPVerifyEmail,
  OTPVerifyMobile,
} from "../../../api/LoginAndSignInAPIs";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { updateProfile } from "../../../api/UserProfile";
import { emailRegex } from "../../../utils/validation";

interface OTConfirmationProps {
  setOnUpdate: Dispatch<SetStateAction<boolean>>;
}

export const OTConfirmation = ({ setOnUpdate }: OTConfirmationProps) => {
  const [validOTP, setValidOTP] = useState(true);
  const [otpValue, setOtpValue] = useState("");

  // const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!validOTP && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [validOTP]);

  const submitOtpHandler = () => {
    const getUserId = localStorage.getItem("loggedInUser");
    const countryCode = localStorage.getItem("country-code");
    const newVerifyId = localStorage.getItem("newVerifyId");

    if (!emailRegex.test(getUserId as string)) {
      const email = newVerifyId;
      OTPVerifyEmail(false, String(getUserId), otpValue, String(email))
        .then((response) => {
          setValidOTP(false);
          toast.success(response.message, {
            toastId: "OTPVerifySuccessEmailToast",
          });
          const mobileNumber = String(getUserId);
          const code = String(countryCode);

          updateProfile({
            countryCode: code,
            mobileNumber,
          })
            .then((res) => console.log("updated user response", res))
            .catch((error) => {
              console.log(error);
              toast.error(error.response.data.message, {
                toastId: "updateLoginDetails",
              });
            });
          setOnUpdate(true);
        })
        .catch((error) => {
          setValidOTP(true);
          toast.error(error.response.data.message, {
            toastId: "OTPVerifyErrorEmailToast",
          });
        });
    } else {
      const mobileNumber = newVerifyId;
      OTPVerifyMobile(
        false,
        String(getUserId),
        otpValue,
        String(countryCode),
        String(mobileNumber)
      )
        .then((response) => {
          const email = String(getUserId);
          updateProfile({
            email,
          }).catch((error) => {
            toast.error(error.response.data.message, {
              toastId: "updateLoginDetails",
            });
          });

          setOnUpdate(true);
          setValidOTP(false);
          toast.success(response.message, {
            toastId: "OTPVerifySuccessMobileToast",
          });
        })
        .catch((error) => {
          setValidOTP(true);
          toast.error(error.response.data.message, {
            toastId: "OTPVerifyErrorMobileToast",
          });
        });
    }
  };

  const resendOtpHandler = () => {
    const getUserId = localStorage.getItem("loggedInUser");
    const countryCode = localStorage.getItem("country-code");

    if (emailRegex.test(getUserId as string)) {
      OTPResendEmail(String(getUserId))
        .then((response) => {
          console.log(response);
          toast.info(response.message, {
            toastId: "OTPResentMobileToast",
          });
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            toastId: "OTPResentMobileToast",
          })
        );
    } else {
      OTPResendMobile(String(getUserId), countryCode as string)
        .then((response) => {
          console.log(response);
          toast.info(response.message, {
            toastId: "OTPResentMobileToast",
          });
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            toastId: "OTPResentMobileToast",
          })
        );
    }
  };

  return (
    <div className="modal-dialog modal-lg modal-login-details modal-dialog-centered">
      <div className="modal-content site-modal personal-modal">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div className="row modal-row1">
            <div className="col-sm-12">
              <div className="modal-left">
                <h2 className="modal-head">Login Details</h2>

                <div className="mb-4 text-left1">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label2 mb-3"
                  >
                    OTP has been sent to email or mobile no.
                  </label>
                  <span className="otp">
                    {" "}
                    <Link to="/profile" onClick={resendOtpHandler}>
                      Resend OTP
                    </Link>{" "}
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter OPT"
                      value={otpValue}
                      onChange={(e) => setOtpValue(e.target.value)}
                    />
                  </span>
                </div>

                <div className="mb-0 text-left">
                  {validOTP ? (
                    <button
                      className="btn lg-btn modal-btn"
                      onClick={() => submitOtpHandler()}
                      disabled={otpValue === ""}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      ref={buttonRef}
                      className="btn lg-btn modal-btn"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
