import mapImage from "../../../assets/images/map-image.png";
import LazyImage from "../../../pages/LazyImage";
export const MapDetails = () => {
  return (
    <section id="map_section" style={{ display: "block" }}>
      <div className="heading_text">
        <h3 className="h3-bold mb-0">Location</h3>
      </div>
      <div className="block block-section mb-0 px-3 py-2">
        <span className="text-left text-body">
          The Location of Ginger Goa, Panjim has been rated 4.3 by 4747 guests
        </span>
      </div>
      <div className="row py-4">
        <div className="col-sm-5 col-md-5">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Key Landmarks
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="block mb-0">
                    <ul>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Immaculate Conception Church
                          </label>
                          <div className="distance">
                            <span>1.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Bambolim Beach
                          </label>
                          <div className="distance">
                            <span>5.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Basilica Of Bom Jesus
                          </label>
                          <div className="distance">
                            <span>10.9 KM</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Tourist Attractions
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="block mb-0">
                    <ul>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Immaculate Conception Church
                          </label>
                          <div className="distance">
                            <span>1.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Bambolim Beach
                          </label>
                          <div className="distance">
                            <span>5.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Basilica Of Bom Jesus
                          </label>
                          <div className="distance">
                            <span>10.9 KM</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Airport
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="block mb-0">
                    <ul>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Immaculate Conception Church
                          </label>
                          <div className="distance">
                            <span>1.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Bambolim Beach
                          </label>
                          <div className="distance">
                            <span>5.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Basilica Of Bom Jesus
                          </label>
                          <div className="distance">
                            <span>10.9 KM</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Restaurants
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="block mb-0">
                    <ul>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Immaculate Conception Church
                          </label>
                          <div className="distance">
                            <span>1.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Bambolim Beach
                          </label>
                          <div className="distance">
                            <span>5.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Basilica Of Bom Jesus
                          </label>
                          <div className="distance">
                            <span>10.9 KM</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Other Landmarks
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="block mb-0">
                    <ul>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Immaculate Conception Church
                          </label>
                          <div className="distance">
                            <span>1.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Bambolim Beach
                          </label>
                          <div className="distance">
                            <span>5.6 KM</span>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="same-address4"
                          >
                            Basilica Of Bom Jesus
                          </label>
                          <div className="distance">
                            <span>10.9 KM</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-7 col-md-7">
          <div className="image-section">
            <LazyImage className="img-fluid" src={mapImage} alt="star" />
          </div>
        </div>
      </div>
    </section>
  );
};
