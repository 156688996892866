import LazyImage from "../pages/LazyImage";
export const TalkToAgents = () => {
  return (
    <div className="talk-to-agents">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Talk to our agent</h3>
            <p>Your Personal Travel Experts at Your Service!</p>
            <div className="footer-contact mb-3 d-flex justify-content-center align-items-center gap-3">
              <a className="tel-contact" href="tel:+44-2039129888">
                <span>
                  <LazyImage
                    width={35}
                    height={35}
                    src="https://img.icons8.com/color/48/great-britain.png"
                    alt="great-britain"
                  />
                </span>
                +44 2039 129 888
              </a>

              <a className="tel-contact" href="tel:+91-9028129888">
                <span>
                  <LazyImage
                    width={35}
                    height={35}
                    src="https://img.icons8.com/color/48/india.png"
                    alt="india"
                  />
                </span>
                +91 9028 129 888
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
