import axios from "axios";
import { adminDashboard } from "./endpoints";

export async function getAdminProfile() {
  const tokenAdmin = localStorage.getItem("admin_token");

  const headers = {
    Authorization: `Bearer ${tokenAdmin}`,
  };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: adminDashboard,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error profile:", error);
    throw error;
  }
}
