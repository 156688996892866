import { useState } from "react";
import { Modal } from "react-bootstrap";
import ComingSoon from "../../../assets/images/coming-soon.png";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../../pages/LazyImage";
export const TimeRemaining = () => {
  const [showModal, setShowModal] = useState(true);

  const navigate = useNavigate();

  //   useEffect(() => {
  //     let countdownInterval: any;

  //     if (showModal && timeRemaining > 0) {
  //       countdownInterval = setInterval(() => {
  //         setTimeRemaining(timeRemaining - 1);
  //       }, 1000);
  //     }

  //     return () => {
  //       clearInterval(countdownInterval);
  //     };
  //   }, [showModal, timeRemaining]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <strong>Hurry up!</strong> Your session will expire soon!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-cente mb-3">
          <LazyImage className="m-auto" src={ComingSoon} alt="ComingSoon" />
          </div>
          {/* <p className="text-center">
            Your session will expire in : {Math.floor(timeRemaining / 60)}{" "}
            minutes and {timeRemaining % 60} seconds
          </p> */}
          <p className="text-center">
            Your session will expire soon! Please click on "More time" to
            Continue booking.
          </p>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              className="btn btn-login"
              style={{ backgroundColor: "#ff8a65", marginRight: "3%" }}
              onClick={() => navigate("/search-flights")}
            >
              Back to search
            </button>
            <button className="btn btn-login" onClick={handleClose}>
              More time
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
