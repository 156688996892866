import { useState, useRef, useEffect } from "react";
import { Calendar } from "react-date-range";
import calendarSVG from "../../assets/images/calender.svg";
import { AutoCompleteMultiCity } from "./shared/AutoCompleteMultiCity";
import moment from "moment";
import { getCurrentLocationAirports } from "../../api/FlightAPI";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

interface City {
  departure: string;
  destination: string;
  departureCode: string;
  destinationCode: string;
  departureAirportName: string;
  destinationAirportName: string;
  departureDate: string;
}

interface MultiCityProps {
  flightPassengers: {
    adults: number;
    infants: number;
    children: number;
  };
  additionalFlightInfo: {
    directFlight: boolean;
    studentFare: boolean;
    nearby: boolean;
    seniorCitizen: boolean;
  };
  flightClass: string;
}

export const MultiCityForm = ({
  flightPassengers,
  additionalFlightInfo,
  flightClass,
}: MultiCityProps) => {
  const [cities, setCities] = useState([
    {
      departure: "",
      departureCode: "",
      destinationCode: "",
      destination: "",
      departureAirportName: "",
      destinationAirportName: "",
      departureDate: moment(new Date()).format("DD MMM YYYY"),
    },
    {
      departure: "",
      departureCode: "",
      destinationCode: "",
      destination: "",
      departureAirportName: "",
      destinationAirportName: "",
      departureDate: "",
    },
  ]);

  const [showCalenderMultiCity, setShowCalenderMultiCity] = useState(false);
  const [editingCityIndex, setEditingCityIndex] = useState(-1);
  const [indexCity, setIndexCity] = useState(0);

  const [dateMultiCity, setDateMultiCity] = useState(new Date());
  const calendarRef = useRef<HTMLSpanElement | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  window.onpopstate = () => {
    navigate("/");
  };

  useEffect(() => {
    const today = moment();
    const updatedCities = cities?.map((city) => {
      const cityDepartureDate = moment(city.departureDate, "DD MMM YYYY");
      if (cityDepartureDate.isBefore(today, "day")) {
        return {
          ...city,
          departureDate: "",
        };
      }
      return city;
    });

    setCities(updatedCities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalenderMultiCity(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const triggerCurrentLocation = async (index: number) => {
    await getCurrentLocationAirports()
      .then((response) => {
        const airports = response.data.Airports?.map(
          ({ CityName, CityCode }: { CityName: string; CityCode: string }) => {
            handleCityChange(CityName, index, "departure");
            setCode(index, "departureCode", CityCode);
            return { cityName: CityName, code: CityCode };
          }
        );
        localStorage.setItem("currentCity", JSON.stringify(airports));
      })
      .catch(() =>
        toast.error("Error in getting your current location.", {
          toastId: "forGeoLocation",
        })
      );
  };

  useEffect(() => {
    const multiCityJSON = localStorage.getItem("userMultiFlightSearchData");
    const multiCity = JSON.parse(String(multiCityJSON));

    if (multiCity) {
      setCities([...multiCity.cities]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCityChange(value: string, index: number, field: keyof City) {
    const newCities = [...cities];
    newCities[index][field] = value;

    setCities(newCities);
  }

  const handleEditCity = (index: any) => {
    setEditingCityIndex(index);
  };

  function setCode(index: number, codeType: keyof City, code: string) {
    const newCities = [...cities];
    newCities[index][`${codeType}` as keyof City] = code;
    setCities(newCities);
  }

  function setAirportName(
    index: number,
    portType: keyof City,
    airportNameVal: string
  ) {
    const newCities = [...cities];
    newCities[index][`${portType}` as keyof City] = airportNameVal;
    setCities(newCities);
  }

  useEffect(() => {
    for (const city of cities) {
      if (
        city.departureCode !== "" &&
        city.destinationCode !== "" &&
        city.departureCode === city.destinationCode
      ) {
        toast.error("Destination and Departure place cannot be same.", {
          toastId: "sameFlightDestination32",
        });
      }
    }
  }, [cities]);

  const addCity = () => {
    let validationPassed = true;

    for (const city of cities) {
      if (
        city.departure === "" ||
        city.departureCode === "" ||
        city.destinationCode === "" ||
        city.destination === "" ||
        city.departureDate === ""
      ) {
        validationPassed = false;
        toast.error("Please fill required flight details.", {
          toastId: "fillRequired",
        });
        break;
      }
      if (city.departureCode === city.destinationCode) {
        validationPassed = false;
        toast.error("Destination and Departure place cannot be same.", {
          toastId: "sameFlightDestinationCode",
        });
      }

      if (
        flightPassengers.adults +
          flightPassengers.infants +
          flightPassengers.children >
        9
      ) {
        validationPassed = false;
        toast.error("Please select less than 9 Passengers", {
          toastId: "flightPassengers",
        });
      }
    }

    if (validationPassed) {
      setCities([
        ...cities,
        {
          departure: "",
          departureCode: "",
          destinationCode: "",
          destination: "",
          departureAirportName: "",
          destinationAirportName: "",
          departureDate: "",
        },
      ]);
    }
  };

  const handleDepartureDateChange = (
    index: number,
    item: moment.MomentInput
  ) => {
    const updatedCities = cities?.map((city, i) => {
      if (i === index) {
        return {
          ...city,
          departureDate: moment(item).format("DD MMM YYYY"),
        };
      }
      return city;
    });

    setCities(updatedCities);
  };

  const submitMultiCity = () => {
    let validationPassed = true;

    for (const city of cities) {
      if (
        city.departure === "" ||
        city.departureCode === "" ||
        city.destinationCode === "" ||
        city.destination === "" ||
        city.departureDate === ""
      ) {
        validationPassed = false;
        toast.error("Please fill required flight details.", {
          toastId: "fillRequired",
        });
        break;
      }
      if (city.departureCode === city.destinationCode) {
        validationPassed = false;
        toast.error("Destination and Departure place cannot be same.", {
          toastId: "sameFlightDestinationCode",
        });
        break;
      }
      if (
        flightPassengers.adults === 0 &&
        flightPassengers.children === 0 &&
        flightPassengers.infants === 0
      ) {
        validationPassed = false;
        toast.error("Please select less than 9 Passengers", {
          toastId: "flightPassengers",
        });
      }
    }

    if (validationPassed) {
      if (location.pathname === "/") {
        navigate("/search-multi-city");
      }
      if (location.pathname !== "/") {
        window.location.replace("/search-multi-city");
      } else {
        navigate(0);
      }

      localStorage.setItem(
        "userMultiFlightSearchData",
        JSON.stringify({
          ADT: flightPassengers.adults,
          CHD: flightPassengers.children,
          INF: flightPassengers.infants,
          Cabin: flightClass,
          ReturnDate: "",
          OnwardDate: moment(new Date()).format("YYYY-MM-DD"),
          IsDirect: additionalFlightInfo.directFlight,
          IsStudentFare: additionalFlightInfo.studentFare,
          IsNearbyAirport: additionalFlightInfo.nearby,
          IsSeniorCitizen: additionalFlightInfo.seniorCitizen,
          flightWay: "multi city",
          cities: cities,
        })
      );
    }
  };

  const handleRemoveCity = (index: any) => {
    // Create a new array without the city at the specified index
    const updatedCities = [...cities];
    updatedCities.splice(index, 1);
    setCities(updatedCities);
  };

  const getMinDate = (currentIndex: number | undefined) => {
    const selectedDates = cities
      .slice(0, currentIndex) // Get previous rows' selected dates
      ?.map((city) => new Date(city.departureDate).getTime());

    const maxPreviousDate =
      selectedDates.length > 0 ? new Date(Math.max(...selectedDates)) : today;

    return new Date(
      maxPreviousDate.getFullYear(),
      maxPreviousDate.getMonth(),
      maxPreviousDate.getDate() + 1
    );
  };

  const today = new Date();

  // Calculate the max date (current date + 1 year)
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  const inputDepartureRef = cities?.map(() =>
    React.createRef<HTMLInputElement>()
  );
  const datePickerRef = useRef<HTMLInputElement>(null);

  const inputRefs = cities?.map(() => React.createRef<HTMLInputElement>());

  const focusNextInput = (currentIndex: number) => {
    if (currentIndex < inputRefs.length) {
      inputRefs[currentIndex].current?.focus();
    }
  };

  return (
    <div>
      <div className="add-city-div">
        {cities &&
          cities?.map((city, index) => (
            <div id="add-another-city" key={index}>
              <div className="inline-form2 mt-2">
                <div
                  className="inline-text"
                  onClick={() => handleEditCity(index)}
                >
                  <AutoCompleteMultiCity
                    placeholder="Departure city"
                    target="departure"
                    cities={cities}
                    city={city.departure}
                    airportName={city.departureAirportName}
                    codeVal={city.departureCode}
                    index={index}
                    editingCityIndex={editingCityIndex}
                    code={setCode}
                    setAirportName={setAirportName}
                    handleCityChange={handleCityChange as any}
                    triggerCurrentLocation={() => triggerCurrentLocation(index)}
                    locationIcon={true}
                    forwardedRef={inputRefs[index]}
                    newRef={inputDepartureRef[index]}
                    focusNextInput={() => focusNextInput(indexCity)}
                  />
                </div>
                <div
                  className="inline-text"
                  onClick={() => handleEditCity(index)}
                >
                  <AutoCompleteMultiCity
                    placeholder="Destination city"
                    target="destination"
                    cities={cities}
                    city={city.destination}
                    airportName={city.destinationAirportName}
                    codeVal={city.destinationCode}
                    index={index}
                    editingCityIndex={editingCityIndex}
                    code={setCode}
                    setAirportName={setAirportName}
                    handleCityChange={handleCityChange as any}
                    forwardedRef={inputRefs[index]}
                    focusNextInput={() => focusNextInput(indexCity)}
                    setShowCalenderMultiCity={setShowCalenderMultiCity}
                  />
                </div>
                <div
                  className="inline-text"
                  onClick={() => handleEditCity(index)}
                >
                  <div className="border px-2 py-2 rounded-2 border-dark">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        loading="lazy"
                        src={calendarSVG}
                        className="multi-icon"
                        alt=""
                        onClick={() =>
                          setShowCalenderMultiCity(!showCalenderMultiCity)
                        }
                      />

                      <span className="fw-bold fs-6">Depart</span>
                    </div>

                    <div
                      className="input_heading"
                      onClick={() =>
                        setShowCalenderMultiCity(!showCalenderMultiCity)
                      }
                    >
                      <input
                        type="text"
                        ref={datePickerRef}
                        className="fw-bold border-0 fs-4"
                        value={
                          city.departureDate !== ""
                            ? moment(city.departureDate).format("DD")
                            : "-"
                        }
                        onChange={() => {}}
                      />
                      <span className="date_formate">
                        {city.departureDate !== ""
                          ? moment(city.departureDate).format("MMM'YY")
                          : "select date"}
                      </span>
                    </div>

                    <div
                      className="code_heading"
                      onClick={() =>
                        setShowCalenderMultiCity(!showCalenderMultiCity)
                      }
                    >
                      <span className="code-des d-inline-block text-truncate w-100">
                        {" "}
                        {city.departureDate !== ""
                          ? moment(city.departureDate).format("dddd")
                          : "-"}{" "}
                      </span>
                    </div>
                    {showCalenderMultiCity && index === editingCityIndex && (
                      <span
                        ref={calendarRef}
                        style={{ position: "absolute", zIndex: 9999 }}
                      >
                        <Calendar
                          minDate={getMinDate(index)}
                          maxDate={maxDate}
                          onChange={(item) => {
                            setDateMultiCity(item);
                            handleDepartureDateChange(index, item);
                            setShowCalenderMultiCity(!showCalenderMultiCity);

                            setIndexCity(index + 1);

                            if (index < inputRefs.length - 1) {
                              handleEditCity(index + 1);
                              inputDepartureRef[index + 1]?.current?.focus();
                            }
                          }}
                          date={dateMultiCity}
                        />
                      </span>
                    )}
                  </div>
                </div>

                {/* Cross icon to remove multicity */}
                {cities.length > 2 && (
                  <span onClick={() => handleRemoveCity(index)}>
                    <span
                      className="flight-cross-icon"
                      style={{
                        fontSize: "21px",
                        color: "red",
                        fontWeight: "600",
                      }}
                    >
                      X
                    </span>

                    <span
                      className="remove-flight"
                      style={{
                        fontSize: "21px",
                        color: "red",
                        fontWeight: "600",
                      }}
                    >
                      Remove Flight
                    </span>
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="inline-form2 my-3" id="city_col">
        <div className="inline-left">
          <button
            className="btn outline-link outline-link-lg city-btn"
            type="button"
            disabled={cities.length === 6}
            onClick={addCity}
          >
            Add another city
          </button>
        </div>
        <div className="inline-right">
          <button
            className="btn btn-login"
            type="button"
            disabled={cities.length === 1}
            onClick={submitMultiCity}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
