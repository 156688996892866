import { useEffect, useRef, useState } from "react";
import calendarSVG from "../../../assets/images/calender.svg";
import { Calendar, DateRange } from "react-date-range";
import moment from "moment";
import usePassengersLabel from "../shared/usePassengersLabel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAllAirports,
  getCurrentLocationAirports,
  getGeneratedSignedToken,
} from "../../../api/FlightAPI";
import AutoCompleteCity from "../shared/AutoCompleteCity";
import { toast } from "react-toastify";
import { MultiCityForm } from "../MulticityForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useFlightLoader } from "../shared/useFlightLoader";
import AirplaneLoader from "../../loaders/AirplaneLoader";

export const SearchFlight = () => {
  const [flightOneWay, setFlightOneWay] = useState("one way");
  const [flightClass, setFlightClass] = useState("economy");

  const [flightPassengers, setFlightPassengers] = useState({
    adults: 1,
    infants: 0,
    children: 0,
  });

  const [additionalFlightInfo, setAdditionalFlightInfo] = useState({
    directFlight: false,
    studentFare: false,
    nearby: false,
    seniorCitizen: false,
  });
  const [flightDeparturePlace, setFlightDeparturePlace] = useState({
    cityName: "",
    code: "",
    airportName: "",
  });
  const [flightDestinationPlace, setFlightDestinationPlace] = useState({
    cityName: "",
    code: "",
    airportName: "",
  });
  const [checkOnDateChange, setCheckOnDateChange] = useState({
    startDate: false,
    endDate: false,
  });

  const [selectedDepartureDate, setSelectedDepartureDate] = useState(
    moment(String(new Date())).format("YYYY-MM-DD")
  );

  const [dateRangeStartDate, setDateRangeStartDate] = useState(new Date());
  const [selectedReturnDate, setSelectedReturnDate] = useState("");

  const [showCalenderDeparture, setShowCalenderDeparture] = useState(false);
  const [showPassengers, setShowPassengers] = useState(false);
  const [sameSelectedCityAlert, setSameSelectedCityAlert] = useState(false);

  const [date, setDate] = useState<Date>();

  const calendarRef = useRef<HTMLSpanElement | null>(null);
  const passengersRef = useRef<HTMLUListElement | null>(null);

  const today = new Date();

  const passengersLabel = usePassengersLabel(flightPassengers);
  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  const navigate = useNavigate();
  const location = useLocation();

  const [stateRoundTrip, setStateRoundTrip] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const flightSearchData = localStorage.getItem("tabSearchData");

  const checkPassengers =
    flightPassengers.adults + flightPassengers.children > 8;

  useEffect(() => {
    if (location.pathname === "/") {
      localStorage.setItem("tabSearchData", "flight");
    }
  }, [location]);

  const triggerOnToggleLocation = async () => {
    await getCurrentLocationAirports()
      .then((response) => {
        const airports = response.data.Airports.map(
          ({
            CityName,
            CityCode,
            Name,
          }: {
            CityName: string;
            CityCode: string;
            Name: string;
          }) => {
            setFlightDeparturePlace({
              cityName: CityName,
              code: CityCode,
              airportName: Name,
            });
            return { cityName: CityName, code: CityCode };
          }
        );
        localStorage.setItem("currentCity", JSON.stringify(airports));
      })
      .catch(() =>
        toast.error("Error in getting your current location.", {
          toastId: "forGeoLocation",
        })
      );
  };

  useEffect(() => {
    const storeAllAirports = async () => {
      await getAllAirports()
        .then((response) => {
          localStorage.setItem(
            "allAirports",
            JSON.stringify(response.data.Airports)
          );
        })
        .catch((err) => console.log(err));
    };

    if (location.pathname === "/") {
      const storeToken = async () => {
        await getGeneratedSignedToken()
          .then((response) => {
            const flightToken = {
              TUI: response.data.TUI,
              signedToken: response.data.SignToken,
            };
            localStorage.setItem("flightToken", JSON.stringify(flightToken));
            storeAllAirports();
          })
          .catch((err) =>
            console.log(err, "cannot store signed token into local storage")
          );
      };

      storeToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/search-multi-city") {
      const flightMultiCityJSON = localStorage.getItem(
        "userMultiFlightSearchData"
      );
      if (flightMultiCityJSON) {
        const flightMultiCity = JSON.parse(flightMultiCityJSON);

        setFlightPassengers({
          adults: flightMultiCity.ADT,
          children: flightMultiCity.CHD,
          infants: flightMultiCity.INF,
        });

        setFlightClass(flightMultiCity.Cabin);

        setAdditionalFlightInfo({
          directFlight: flightMultiCity.IsDirect,
          studentFare: flightMultiCity.IsStudentFare,
          nearby: flightMultiCity.IsNearbyAirport,
          seniorCitizen: flightMultiCity.IsSeniorCitizen,
        });

        if (location.pathname === "/search-multi-city") {
          setFlightOneWay("multi city");
        } else {
          setFlightOneWay(flightMultiCity.flightWay);
        }
      }
    } else {
      const flightDataJSON = localStorage.getItem("userFlightSearchData");

      if (flightDataJSON) {
        const flightData = JSON.parse(flightDataJSON);

        if (location.pathname !== "/") {
          setFlightPassengers({
            adults: flightData.ADT,
            children: flightData.CHD,
            infants: flightData.INF,
          });

          setSelectedDepartureDate(flightData.OnwardDate);
          setSelectedReturnDate(flightData.ReturnDate);

          setStateRoundTrip([
            {
              ...stateRoundTrip[0],
              startDate: moment(flightData.OnwardDate).toDate(),
              endDate:
                flightData.ReturnDate === ""
                  ? moment(flightData.ReturnDate).add(5, "days").toDate()
                  : moment(flightData.ReturnDate).toDate(),
            },
          ]);
          setDate(moment(flightData.OnwardDate).toDate());
          if (location.pathname === "/search-multi-city") {
            setFlightOneWay("multi city");
          } else {
            setFlightOneWay(flightData.flightWay);
          }
        }

        setAdditionalFlightInfo({
          directFlight: flightData.IsDirect,
          studentFare: flightData.IsStudentFare,
          nearby: flightData.IsNearbyAirport,
          seniorCitizen: flightData.IsSeniorCitizen,
        });
        setFlightDeparturePlace({
          cityName: flightData?.fromPlace,
          code: flightData.FromCode,
          airportName: flightData.airNameDeparture,
        });
        setFlightDestinationPlace({
          cityName: flightData.toPlace,
          code: flightData.ToCode,
          airportName: flightData.airportNameDestination,
        });
        setFlightClass(flightData.Cabin);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      flightDeparturePlace.code !== "" &&
      flightDeparturePlace.code !== "" &&
      flightDeparturePlace.code === flightDestinationPlace.code
    ) {
      setSameSelectedCityAlert(true);
    } else {
      setSameSelectedCityAlert(false);
    }
  }, [flightDeparturePlace.code, flightDestinationPlace.code]);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalenderDeparture(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const startDate = moment(dateRangeStartDate).toDate();
    const returnDate = moment(selectedReturnDate, "DD MMM YYYY").toDate();

    if (startDate > returnDate) {
      setSelectedReturnDate(moment(startDate).format("DD MMM YYYY"));
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate,
          endDate: startDate,
        },
      ]);
    }
    if (checkOnDateChange.endDate) {
      setDate(moment(dateRangeStartDate).toDate());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeStartDate]);

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    const startDate = moment(dateRangeStartDate).toDate();
    if (flightData) {
      setSelectedReturnDate(
        moment(flightData.ReturnDate).format("DD MMM YYYY")
      );
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate: moment(flightData.OnwardDate).toDate(),
          endDate: moment(flightData.ReturnDate).toDate(),
        },
      ]);
    } else {
      setSelectedReturnDate(moment(startDate).format("DD MMM YYYY"));
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate: moment(startDate).toDate(),
          endDate: moment(startDate).toDate(),
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // passengers dropdown toggle
  const handleDocumentClick = (e: { target: any }) => {
    if (
      showPassengers &&
      passengersRef.current &&
      !passengersRef.current.contains(e.target)
    ) {
      setShowPassengers(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassengers]);

  const handleDropdownToggle = () => {
    setShowPassengers(!showPassengers);
  };

  const handleMenuClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  const searchFlightHandler = () => {
    if (
      flightOneWay === "" ||
      flightClass === "" ||
      flightDeparturePlace.cityName === "" ||
      flightDestinationPlace.cityName === "" ||
      (flightPassengers.adults === 0 &&
        flightPassengers.children === 0 &&
        flightPassengers.infants === 0)
    ) {
      toast.error("Please fill required flight details.", {
        toastId: "flightFillDetailError",
      });
    } else if (flightDeparturePlace.code === flightDestinationPlace.code) {
      setSameSelectedCityAlert(true);
    } else {
      setIsFlightLoading(true);

      localStorage.removeItem("multicitySearch");
      localStorage.setItem(
        "userFlightSearchData",
        JSON.stringify({
          ADT: flightPassengers.adults,
          CHD: flightPassengers.children,
          INF: flightPassengers.infants,
          Cabin: flightClass,
          FromCode: flightDeparturePlace.code,
          fromPlace: flightDeparturePlace?.cityName,
          ToCode: flightDestinationPlace.code,
          toPlace: flightDestinationPlace.cityName,
          ReturnDate: selectedReturnDate !== "" ? selectedReturnDate : "",
          OnwardDate: selectedDepartureDate !== "" ? selectedDepartureDate : "",
          IsDirect: additionalFlightInfo.directFlight,
          IsStudentFare: additionalFlightInfo.studentFare,
          IsNearbyAirport: additionalFlightInfo.nearby,
          IsSeniorCitizen: additionalFlightInfo.seniorCitizen,
          flightWay: flightOneWay,
          airNameDeparture: flightDeparturePlace.airportName,
          airportNameDestination: flightDestinationPlace.airportName,
        })
      );
      setIsFlightLoading(false);
      if (location.pathname === "/" && flightOneWay !== "one way") {
        navigate("/search-roundtrip");
      } else if (location.pathname === "/" && flightOneWay === "one way") {
        navigate("/search-flights");
      } else if (location.pathname !== "/" && flightOneWay === "one way") {
        window.location.replace("/search-flights");
      } else if (location.pathname !== "/" && flightOneWay !== "one way") {
        window.location.replace("/search-roundtrip");
      } else if (location.pathname !== "/" && flightOneWay === "multi city") {
        window.location.replace("/search-multi-city");
      } else {
        navigate(0);
      }
    }
  };

  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  // auto cursor move  input boxes
  const cityInput1Ref = useRef<HTMLInputElement>(null);
  const cityInput2Ref = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLInputElement>(null);
  const roundDatePickerRef = useRef<HTMLInputElement>(null);

  const focusNextInput = (
    currentInputRef: React.RefObject<HTMLInputElement>
  ) => {
    if (currentInputRef === cityInput1Ref) {
      cityInput2Ref.current?.focus();
    } else if (currentInputRef === cityInput2Ref) {
      datePickerRef.current?.focus();
      datePickerRef.current?.click();
    }
  };
  return (
    <>
      <div className="inline-form" style={{ alignItems: "end" }}>
        <div className="form-check">
          <input
            id="credit"
            name="flightWayOne"
            type="radio"
            className="form-check-input"
            checked={flightOneWay === "one way"}
            onChange={() => {
              setFlightOneWay("one way");
            }}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: flightOneWay === "one way" ? "#ED3237" : "",
            }}
          />
          <label className="form-check-label" htmlFor="credit">
            One way
          </label>
        </div>

        <div className="form-check">
          <input
            id="debit"
            name="flightWayRound"
            type="radio"
            className="form-check-input"
            checked={flightOneWay === "round trip"}
            onChange={() => setFlightOneWay("round trip")}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: flightOneWay === "round trip" ? "#ED3237" : "",
            }}
          />
          <label className="form-check-label" htmlFor="debit">
            Round trip
          </label>
        </div>

        {flightSearchData !== "hotelFlight" && (
          <div className="form-check">
            <input
              id="debit2"
              name="flightWayMulti"
              type="radio"
              className="form-check-input"
              checked={flightOneWay === "multi city"}
              onChange={() => {
                setFlightOneWay("multi city");
              }}
              style={{
                border: "0.5px solid #505050",
                backgroundColor: flightOneWay === "multi city" ? "#ED3237" : "",
              }}
            />
            <label className="form-check-label" htmlFor="debit2">
              Multi city
            </label>
          </div>
        )}

        <div
          className="dropdown select-dropdown form-check px-0"
          style={{ textTransform: "capitalize" }}
        >
          <a
            className="dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {flightClass}
          </a>
          <ul className="dropdown-menu" style={{ color: "black" }}>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "economy" ? "bolder" : "",
                }}
              >
                <input
                  id="economyClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "economy" ? true : false}
                  onChange={(e) => setFlightClass("economy")}
                />
                <label className="form-check-label" htmlFor="economyClass">
                  Economy
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "premium economy" ? "bolder" : "",
                }}
              >
                <input
                  id="premiumEconomyClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "premium economy" ? true : false}
                  onChange={(e) => setFlightClass("premium economy")}
                />
                <label
                  className="form-check-label"
                  htmlFor="premiumEconomyClass"
                >
                  Premium economy
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "business" ? "bolder" : "",
                }}
              >
                <input
                  id="businessClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "business" ? true : false}
                  onChange={() => setFlightClass("business")}
                />
                <label className="form-check-label" htmlFor="businessClass">
                  Business
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "first class" ? "bolder" : "",
                }}
              >
                <input
                  id="firstClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  onChange={(e) =>
                    e.target.value === "on" && setFlightClass("first class")
                  }
                />
                <label className="form-check-label" htmlFor="firstClass">
                  First class
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div className="dropdown select-dropdown form-check px-0">
          <Link
            className="dropdown-toggle"
            to="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={handleDropdownToggle}
          >
            {passengersLabel}
          </Link>
          <ul
            ref={passengersRef}
            style={{ color: "black" }}
            className="dropdown-menu passengers-menu"
          >
            {showPassengers && (
              <span onClick={handleMenuClick}>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Adults
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (flightPassengers.adults > 1) {
                            setFlightPassengers({
                              ...flightPassengers,
                              adults: flightPassengers.adults - 1,
                              infants:
                                flightPassengers.infants >=
                                flightPassengers.adults
                                  ? flightPassengers.infants - 1
                                  : flightPassengers.infants,
                            });
                          }
                        }}
                      />{" "}
                      {flightPassengers.adults}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color: checkPassengers ? "grey" : "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!checkPassengers) {
                            setFlightPassengers({
                              ...flightPassengers,
                              adults: flightPassengers.adults + 1,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  Ages (12 + or above)
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Child
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (flightPassengers.children > 0) {
                            setFlightPassengers({
                              ...flightPassengers,
                              children: flightPassengers.children - 1,
                            });
                          }
                        }}
                      />{" "}
                      {flightPassengers.children}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color: checkPassengers ? "grey" : "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!checkPassengers) {
                            setFlightPassengers({
                              ...flightPassengers,
                              children: flightPassengers.children + 1,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  Ages (2-12)
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Infant
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (flightPassengers.infants > 0) {
                            setFlightPassengers({
                              ...flightPassengers,
                              infants: flightPassengers.infants - 1,
                            });
                          }
                        }}
                      />{" "}
                      {flightPassengers.infants}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color:
                            flightPassengers.infants === flightPassengers.adults
                              ? "grey"
                              : "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (
                            flightPassengers.infants <=
                            flightPassengers.adults - 1
                          ) {
                            setFlightPassengers({
                              ...flightPassengers,
                              infants: flightPassengers.infants + 1,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  Ages (below 2 years)
                </li>
              </span>
            )}
          </ul>
        </div>
      </div>

      {flightOneWay !== "multi city" && (
        <>
          <div
            className={
              flightOneWay === "round trip"
                ? " inline-form2 mt-2 rounded-form"
                : "inline-form2 mt-2 oneWay-form"
            }
          >
            <div className="inline-text">
              <AutoCompleteCity
                label="Departure city"
                value={flightDeparturePlace}
                onChange={setFlightDeparturePlace}
                triggerOnToggleLocation={triggerOnToggleLocation}
                locationIcon={true}
                type="From"
                forwardedRef={cityInput1Ref}
                focusNextInput={() => focusNextInput(cityInput1Ref)}
              />
            </div>

            <div className="inline-text">
              <AutoCompleteCity
                label="Destination city"
                value={flightDestinationPlace}
                onChange={setFlightDestinationPlace}
                type="To"
                forwardedRef={cityInput2Ref}
                focusNextInput={() => focusNextInput(cityInput2Ref)}
              />
              {sameSelectedCityAlert && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#fd8d8d",
                    padding: "4px",
                    borderRadius: "7px",
                    fontSize: "13px",
                  }}
                >
                  Destination and Departure place cannot be same.
                </div>
              )}
            </div>

            <div className="inline-text">
              <div className="border px-2 py-2 rounded-2 border-dark">
                <div className="d-flex align-items-center gap-2">
                  <img
                    loading="lazy"
                    className="multi-icon"
                    src={calendarSVG}
                    alt=""
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  />

                  <span className="fw-bold fs-6">Depart</span>
                </div>

                <div
                  className="input_heading"
                  onClick={() => {
                    setCheckOnDateChange({
                      ...checkOnDateChange,
                      startDate: true,
                    });
                    setShowCalenderDeparture(!showCalenderDeparture);
                  }}
                >
                  <input
                    type="text"
                    ref={datePickerRef}
                    className="fw-bold border-0 fs-4 w-100"
                    style={{ width: "100%" }}
                    value={moment(selectedDepartureDate).format("DD")}
                    onChange={() => {}}
                  />
                  <span className="date_formate">
                    {moment(selectedDepartureDate).format("MMM'YY")}
                  </span>
                </div>

                <div
                  className="code_heading"
                  onClick={() => {
                    setCheckOnDateChange({
                      ...checkOnDateChange,
                      startDate: true,
                    });
                    setShowCalenderDeparture(!showCalenderDeparture);
                  }}
                >
                  <span className="code-des d-inline-block text-truncate w-100">
                    {" "}
                    {moment(selectedDepartureDate).format("dddd")}{" "}
                  </span>
                </div>
                {showCalenderDeparture && flightOneWay !== "round trip" && (
                  <span
                    ref={calendarRef}
                    style={{ position: "absolute", zIndex: 9999 }}
                  >
                    <Calendar
                      minDate={
                        new Date(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate()
                        )
                      }
                      maxDate={maxDate}
                      onChange={(item) => {
                        setDate(item);
                        setSelectedDepartureDate(
                          moment(item).format("DD MMM YYYY")
                        );

                        const startDate = moment(item).toDate();

                        if (startDate > moment(selectedReturnDate).toDate()) {
                          setStateRoundTrip([
                            {
                              ...stateRoundTrip[0],
                              startDate: startDate,
                              endDate: moment(startDate).toDate(),
                            },
                          ]);

                          setSelectedReturnDate(
                            moment(startDate).format("DD MMM YYYY")
                          );
                        }
                        setShowCalenderDeparture(!showCalenderDeparture);
                      }}
                      date={date}
                    />
                  </span>
                )}
              </div>
            </div>

            {flightOneWay === "round trip" && (
              <div className="inline-text">
                <div className="border px-2 py-2 rounded-2 border-dark">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      loading="lazy"
                      className="multi-icon"
                      src={calendarSVG}
                      alt=""
                      onClick={() =>
                        setShowCalenderDeparture(!showCalenderDeparture)
                      }
                    />

                    <span className="fw-bold fs-6">Return</span>
                  </div>

                  <div
                    className="input_heading"
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: false,
                        endDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  >
                    <input
                      type="text"
                      ref={roundDatePickerRef}
                      className="fw-bold border-0 fs-4"
                      style={{ width: "100%" }}
                      value={moment(selectedReturnDate).format("DD")}
                      onChange={() => {}}
                    />
                    <span className="date_formate">
                      {moment(selectedReturnDate).format("MMM'YY")}
                    </span>
                  </div>

                  <div
                    className="code_heading"
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: false,
                        endDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  >
                    <span className="code-des d-inline-block text-truncate w-100">
                      {" "}
                      {selectedReturnDate !== ""
                        ? moment(selectedReturnDate).format("dddd")
                        : ""}{" "}
                    </span>
                  </div>
                  {showCalenderDeparture && (
                    <span
                      ref={calendarRef}
                      style={{ position: "absolute", zIndex: 9999 }}
                    >
                      <DateRange
                        minDate={
                          selectedDepartureDate !== "" &&
                          !checkOnDateChange.startDate
                            ? moment(selectedDepartureDate).toDate()
                            : checkOnDateChange.startDate
                            ? new Date(
                                today.getFullYear(),
                                today.getMonth(),
                                today.getDate()
                              )
                            : moment(date).toDate()
                        }
                        maxDate={maxDate}
                        onChange={(item) => {
                          if (checkOnDateChange.startDate) {
                            const startDate = moment(
                              item.selection.startDate
                            ).toDate();
                            setStateRoundTrip([
                              { ...stateRoundTrip[0], startDate },
                            ]);
                            setDateRangeStartDate(startDate);
                            setSelectedDepartureDate(
                              moment(startDate).format("DD MMM YYYY")
                            );
                          } else {
                            const endDate = moment(
                              item.selection.endDate
                            ).toDate();
                            setStateRoundTrip([
                              { ...stateRoundTrip[0], endDate },
                            ]);
                            setSelectedReturnDate(
                              moment(endDate).format("DD MMM YYYY")
                            );
                          }
                          if (checkOnDateChange.endDate) {
                            setShowCalenderDeparture(false);
                          } else {
                            setTimeout(() => {
                              if (roundDatePickerRef) {
                                roundDatePickerRef?.current?.focus();
                                roundDatePickerRef?.current?.click();
                              }
                            }, 200);
                          }

                          setShowCalenderDeparture(false);
                          setCheckOnDateChange({
                            startDate: false,
                            endDate: false,
                          });
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={stateRoundTrip}
                      />
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="inline-text">
              <button
                className="btn btn-login search_record"
                type="button"
                onClick={searchFlightHandler}
              >
                Search
              </button>
            </div>
          </div>
        </>
      )}

      {/* Multi City form  */}

      {flightOneWay === "multi city" && (
        <MultiCityForm
          flightPassengers={flightPassengers}
          additionalFlightInfo={additionalFlightInfo}
          flightClass={flightClass}
        />
      )}

      <div className="inline-form mt-3">
        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              directFlight: !additionalFlightInfo.directFlight,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.directFlight}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.directFlight
                ? "#ED3237"
                : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label">Direct Flights</label>
        </div>

        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              studentFare: !additionalFlightInfo.studentFare,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.studentFare}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.studentFare
                ? "#ED3237"
                : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label">Student Fare</label>
        </div>

        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              nearby: !additionalFlightInfo.nearby,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.nearby}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.nearby ? "#ED3237" : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label">Nearby Airports</label>
        </div>

        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              seniorCitizen: !additionalFlightInfo.seniorCitizen,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.seniorCitizen}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.seniorCitizen
                ? "#ED3237"
                : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label">Sr. Citizen Fare</label>
        </div>
      </div>

      {isFlightLoading && location.pathname !== "/" && <AirplaneLoader />}
    </>
  );
};
