import axios from "axios";
import {
  canceledTripsURL,
  completedTripsURL,
  deleteUserProfileURL,
  getUserProfilePicURL,
  getUserProfileURL,
  hotelDealsListURL,
  upcomingTripsURL,
  updateProfileURL,
} from "./endpoints";

interface UserTypes {
  fullName?: string;
  gender?: string;
  dob?: string;
  gst?: string;
  address?: {
    street: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
  };
  email?: string;
  countryCode?: string;
  mobileNumber?: string;
  profilePic?: string;
}

interface deleteUserProps {
  _id: string;
}

export async function getUserProfile() {
  const tokenUser = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${tokenUser}`,
  };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: getUserProfileURL,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error profile:", error);
    throw error;
  }
}

export async function getUserProfilePic() {
  const tokenUser = localStorage.getItem("token");
  const tokenProfile = String(localStorage.getItem("userImageKey"));

  const headers = {
    Authorization: `Bearer ${tokenUser}`,
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${getUserProfilePicURL}key=${tokenProfile}`,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error profile:", error);
    throw error;
  }
}

export async function updateProfile(user: UserTypes) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload: UserTypes = { ...user };

  try {
    const response = await axios.post(updateProfileURL, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
}

export async function hotelDealsListHome() {
  const tokenUser = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${tokenUser}`,
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: hotelDealsListURL,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error profile:", error);
    throw error;
  }
}

export async function deleteUserProfile(userId: deleteUserProps) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload: deleteUserProps = { ...userId };

  try {
    const response = await axios.post(deleteUserProfileURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
}

//My travels

export async function upComingTrips() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(upcomingTripsURL, { headers });
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
}

export async function completedTrips() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(completedTripsURL, { headers });
    return response.data;
  } catch (error) {
    console.error("Error in completed trips list:", error);
    throw error;
  }
}

export async function canceledTripsList() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(canceledTripsURL, { headers });
    return response.data;
  } catch (error) {
    console.error("Error In cancel trips list:", error);
    throw error;
  }
}

export async function getHotelDealsPic(tokenPicName: any) {
  const tokenAdmin = localStorage.getItem("admin_token");
  const headers = {
    Authorization: `Bearer ${tokenAdmin}`,
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${getUserProfilePicURL}key=${tokenPicName}`,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error In Deals Pic:", error);
    throw error;
  }
}

export async function getHotelDealsPicHomePage(tokenPicName: any) {
  const headers = {
    Authorization: `Bearer token`,
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${getUserProfilePicURL}key=${tokenPicName}`,
    headers,
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error In Deals Pic:", error);
    throw error;
  }
}

// export async function canceledTripsList() {
//   const token = localStorage.getItem("token");
//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };

//   try {
//     const response = await axios.get(canceledTripsURL, { headers });
//     return response.data;
//   } catch (error) {
//     console.error("Error In cancel trips list:", error);
//     throw error;
//   }
// }
