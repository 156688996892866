import cycleImg from "../../../assets/images/bicycle.png";
import simpleBike from "../../../assets/images/PersonSimpleBike.png";
import groupsImg from "../../../assets/images/groups.png";
import wineImg from "../../../assets/images/wine.png";
import carImage from "../../../assets/images/Car.png";
import rightImg from "../../../assets/images/right.png";
import LazyImage from "../../../pages/LazyImage";

interface AmenitiesDetailsProps {
  roomData: any;
}

export const AmenitiesDetails = ({ roomData }: AmenitiesDetailsProps) => {
  return (
    <>
      <div className="heading_section">
        <h3 className="h3-bold">
          Amenities at {roomData.hotelData.hotelName},{" "}
          {roomData.hotelData.location.city}
        </h3>
      </div>
      <div className="main_content icon-content">
        <div className="block block-section" style={{ alignItems: "center" }}>
          <LazyImage className="game-image-icon" src={cycleImg} alt="" />
          <span className="text-left text-body">Kids Play Area</span>
        </div>
        <div className="block block-section" style={{ alignItems: "center" }}>
          <LazyImage className="game-image-icon" src={simpleBike} alt="" />
          <span className="text-left text-body">Indoor Games</span>
        </div>
        <div className="block block-section" style={{ alignItems: "center" }}>
          <LazyImage className="game-image-icon" src={groupsImg} alt="" />
          <span className="text-left text-body">Restaurant</span>
        </div>
        <div className="block block-section" style={{ alignItems: "center" }}>
          <LazyImage className="game-image-icon" src={wineImg} alt="" />
          <span className="text-left text-body">Bar</span>
        </div>
        <div className="block block-section" style={{ alignItems: "center" }}>
          <LazyImage className="game-image-icon" src={carImage} alt="" />
          <span className="text-left text-body">Vehicle Rentals</span>
        </div>
      </div>

      <div className="row_content">
        <div className="block">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Highlighted Amenities</h3>
            </div>
            {roomData.hotelData.allAmenities.edges
              .slice(0, 6)
              .map((amenities: any) => (
                <div className="image_body d-flex">
                  <LazyImage className="right-icon" src={rightImg} alt="" />
                  <span className="text-left text-body">
                    {amenities.node.amenityData.amenityCode}
                  </span>
                </div>
              ))}

            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Indoor Games</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Restaurant</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Bar</span>
            </div> */}
          </div>
        </div>

        <div className="block">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Basic Facilities</h3>
            </div>
            {roomData.hotelData.allAmenities.edges
              .slice(6, 12)
              .map((amenities: any) => (
                <div className="image_body d-flex">
                  <LazyImage className="right-icon" src={rightImg} alt="" />
                  <span className="text-left text-body">
                    {amenities.node.amenityData.amenityCode}
                  </span>
                </div>
              ))}

            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Parking</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Intercom</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Power Backup</span>
            </div> */}
          </div>
        </div>

        <div className="block">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Transfers</h3>
            </div>
            {roomData.hotelData.allAmenities.edges
              .slice(12, 18)
              .map((amenities: any) => (
                <div className="image_body d-flex">
                  <LazyImage className="right-icon" src={rightImg} alt="" />
                  <span className="text-left text-body">
                    {amenities.node.amenityData.amenityCode}
                  </span>
                </div>
              ))}

            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Paid Pickup/Drop</span>
            </div> */}
            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                Paid Airport Transfers
              </span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                Paid Bus Station Transfers
              </span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                Paid Railway Station Transfers
              </span>
            </div> */}
          </div>
        </div>

        <div className="block">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Family and kids</h3>
            </div>
            {roomData.hotelData.allAmenities.edges
              .slice(18, 24)
              .map((amenities: any) => (
                <div className="image_body d-flex">
                  <LazyImage className="right-icon" src={rightImg} alt="" />
                  <span className="text-left text-body">
                    {amenities.node.amenityData.amenityCode}
                  </span>
                </div>
              ))}
            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Kids Play Area</span>
            </div> */}
          </div>
        </div>

        <div className="block">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Food and Drinks</h3>
            </div>
            {roomData.hotelData.allAmenities.edges
              .slice(24, 30)
              .map((amenities: any) => (
                <div className="image_body d-flex">
                  <LazyImage className="right-icon" src={rightImg} alt="" />
                  <span className="text-left text-body">
                    {amenities.node.amenityData.amenityCode}
                  </span>
                </div>
              ))}
            {/* <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Restaurant</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Bar</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Kids' Meals</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">Dining Area</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
