import okImage from "../../assets/images/img-ok.svg";
import calenderSVG from "../../assets/images/calender.svg";
import airportImage from "../../assets/images/airport.jpg";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { updateProfile } from "../../api/UserProfile";
import { Calendar } from "react-date-range";
import moment from "moment";
import { toast } from "react-toastify";
import { useRefreshUser } from "../context/RefreshUserContext";
import { useAuth } from "../context/AuthenticationContext";
import LazyImage from "../../pages/LazyImage";
export const SuccessfulSignIn = () => {
  const [fullNameValue, setFullNameValue] = useState("");
  const [dobValue, setDobValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [showCalender, setShowCalender] = useState(false);
  const [date, setDate] = useState<Date>();

  const { setRefreshUser } = useRefreshUser();
  const { updateAuthStatus } = useAuth();

  const today = new Date();

  const fullNameRegex = /^[a-zA-Z\s]+$/;

  const calendarRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalender(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onContinueUserHandler = () => {
    updateProfile({
      fullName: fullNameValue,
      gender: genderValue,
      dob: dobValue,
    })
      .then((response) => {
        // updating auth status to access private routes without refresh
        updateAuthStatus(true);

        console.log("User status:", response);
        toast.success(response.message, {
          toastId: "successToastRegister",
        });
        setRefreshUser(true);
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error(error.response.data.message, {
          toastId: "errorToastRegister",
        });
      });
  };

  const skipUserHandler = () => {
    localStorage.setItem("defaultName", "backpacker");
    window.location.reload();
    setRefreshUser(true);
    updateAuthStatus(true);
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal3"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content site-modal">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => localStorage.clear()}
              ></button>
              <div className="row modal-row1">
                <div className="col-sm-6">
                  <div className="modal-left">
                    <h2 className="modal-head modal-head2">
                      <LazyImage src={okImage} alt="" />{" "}
                      <span>Congratulations! Account created successfully</span>
                    </h2>
                    <div className="mb-3 text-left1">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label2"
                      >
                        Full name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter name"
                        value={fullNameValue}
                        onChange={(e) => setFullNameValue(e.target.value)}
                      />
                    </div>

                    <div className="row mb-4">
                      <div className="col-sm-6">
                        <div className="text-left1 mb-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label2"
                          >
                            Gender
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={genderValue}
                            onChange={(e) => setGenderValue(e.target.value)}
                          >
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-left1">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label2"
                          >
                            DOB
                          </label>
                          <span className="date">
                            {" "}
                            <img loading="lazy"
                              src={calenderSVG}
                              alt=""
                              onClick={() => setShowCalender(!showCalender)}
                            />{" "}
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="30/06/2023"
                              value={dobValue}
                              autoComplete="off"
                              onChange={() => {}}
                              onClick={() => setShowCalender(!showCalender)}
                            />
                            {showCalender && (
                              <span
                                ref={calendarRef}
                                style={{ position: "absolute", zIndex: "999" }}
                              >
                                <Calendar
                                  maxDate={
                                    new Date(
                                      today.getFullYear(),
                                      today.getMonth(),
                                      today.getDate()
                                    )
                                  }
                                  onChange={(item) => {
                                    setDate(item);
                                    setDobValue(
                                      moment(item).format("DD/MM/YYYY")
                                    );
                                    setShowCalender(false);
                                  }}
                                  date={date}
                                />
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 text-left">
                      <button
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          onContinueUserHandler();
                        }}
                        className="btn lg-btn mb-3"
                        disabled={
                          fullNameValue === "" ||
                          dobValue === "" ||
                          genderValue === "" ||
                          !fullNameRegex.test(fullNameValue)
                        }
                      >
                        Save & continue
                      </button>
                      <Link
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          skipUserHandler();
                        }}
                        to={"/home"}
                        className="link-block"
                      >
                        Skip
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="modal-right">
                    <LazyImage src={airportImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
