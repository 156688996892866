import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";

export const TermsOfUse = () => {
  return (
    <AppLayout>
      <div className="container pt-5 px-3" style={{ marginTop: "5rem" }}>
        <h2 className="mb-4" style={{ textAlign: "center" }}>
          Terms of Use
        </h2>
        <p>
          Welcome to <a href="/">Maashree Tours And Travels World</a>. By
          accessing and using our website, you agree to comply with and be bound
          by the following Terms of Use. Please read these terms carefully
          before using our services. If you do not agree with these terms,
          please refrain from using our website.
        </p>

        <h4 className="mt-4">1. Use of the Website:</h4>
        <ul className="m-4">
          <li>
            You are granted a limited, non-transferable, and revocable license
            to access and use our website for personal, non-commercial purposes.
          </li>
          <li>
            You agree not to use our website for any unlawful purpose or in any
            way that may damage, disable, or impair its functionality.
          </li>
        </ul>

        <h4 className="mt-4">2. User Content:</h4>
        <ul className="m-4">
          <li>
            Any content you contribute to our website, including reviews,
            comments, and suggestions, is your responsibility. By submitting
            content, you grant us a non-exclusive, royalty-free, perpetual, and
            worldwide license to use, modify, and display such content.
          </li>
        </ul>

        <h4 className="mt-4">3. Intellectual Property:</h4>
        <ul className="m-4">
          <li>
            All content on our website, including text, graphics, logos, images,
            videos, and software, is protected by intellectual property rights
            and is the property of Maashree or its licensors.
          </li>
          <li>
            You may not use, reproduce, distribute, modify, or create derivative
            works based on our content without our explicit permission.
          </li>
        </ul>

        <h4 className="mt-4">4. Links to Third-Party Websites:</h4>
        <ul className="m-4">
          <li>
            Our website may contain links to third-party websites for your
            convenience. These links do not imply endorsement or responsibility
            for the content on those sites.
          </li>
          <li>
            We recommend reviewing the terms and privacy policies of third-party
            websites before interacting with them.
          </li>
        </ul>

        <h4 className="mt-4">5. Limitation of Liability:</h4>
        <ul className="m-4">
          <li>
            We strive to provide accurate and up-to-date information, but we do
            not guarantee the completeness, accuracy, or reliability of the
            content on our website.
          </li>
          <li>
            Maashree will not be held liable for any direct, indirect,
            incidental, consequential, or punitive damages arising from your use
            of our website.
          </li>
        </ul>

        <h4 className="mt-4">6. Indemnification:</h4>
        <ul className="m-4">
          <li>
            You agree to indemnify and hold Maashree and its affiliates harmless
            from any claims, damages, losses, liabilities, and expenses arising
            from your use of our website or violation of these terms.
          </li>
        </ul>

        <h4 className="mt-4">7. Changes to Terms:</h4>
        <ul className="m-4">
          <li>
            We reserve the right to modify these Terms of Use at any time.
            Changes will be effective upon posting on our website.
          </li>
          <li>
            It is your responsibility to review these terms regularly to stay
            informed of any updates.
          </li>
        </ul>

        <h4 className="mt-4">8. Governing Law:</h4>
        <p>
          These Terms of Use are governed by and construed in accordance with
          the laws, without regard to its conflict of law principles.
        </p>

        <p className="mt-4">
          Thank you for using <a href="/">Maashree</a>. We are committed to
          providing you with a seamless and enjoyable experience while
          maintaining a fair and respectful online environment. If you have any
          questions or concerns about these terms, please{" "}
          <a href="/support" target="_blank">
            contact us
          </a>
          .
        </p>

        <h4 className="mt-4">9. Refund timelines, policies & processes:</h4>
        <p>
          <b>How to track your refund</b>
          <br />
          Have a refund coming? We've made it easy for you to track when it'll
          arrive. Click Chat now and our Virtual Agent (whatsapp chat or mail us
          on enquiry@maashree.in) will guide you to your refund status.
        </p>

        <h3 className="mt-4">How it works</h3>
        <ul className="m-4">
          <li>
            It may take up to 24 hours to process your refund. Once that has
            happened, your bank or payment service (such as CC, UPI, etc.) will
            take care of the rest. Just know that it may take them up to 7 days
            to post the credit to your account, and up to 2 billing cycles to
            show the credit on your statement.
          </li>
          <li>
           We'll send you an email alert about your refund amount, and how and when you'll get it.
          </li>
          <li>
            If you've been charged a deposit or partial payment, you'll be
            refunded for that amount, minus any fees.
          </li>
        </ul>

        <h3 className="mt-4">The basics on cancellation fees</h3>
        <ul className="m-4">
          <li>
            Any Flight Cancellation is levied charges of INR 800/- or (USD 10)
            Per Person. (Plus Airline Charges if any)
          </li>
          <li>
            Any Flight Changes/Reschedule is levied charges of INR 800/- or (USD
            10) per sector, Per Person. (Plus Airline Charges if any)
          </li>
          <li>
            If you used points and a credit card to pay for your booking, any
            cancellation fees will come out of the points portion of your refund
            first, followed by the credit card portion, if necessary. If you
            used all your 2,000 points to pay for your trip, then used your
            credit card to cover the remaining cost, the cancel fee will come
            out of your points balance first.
          </li>
        </ul>

        <h3 className="mt-4">Flights</h3>
        <ul className="m-4">
          <li>
           Some flight bookings are eligible for a full refund. Our Charges INR
            800/- or (USD 10) Per Person (ex. totally depends on the airline
            policies)
          </li>
          <li>
           
           Sometimes instead of getting your refund back, you may instead get a flight credit to use for a future
            booking. INR 800/- or (USD 10) Per Person (ex. totally depends on
            the airline policies)
          </li>
        </ul>
      </div>
      <hr style={{ margin: "4%" }} />
      <Footer />
    </AppLayout>
  );
};
