import { useState } from "react";
import React from "react";

import "./DestinationFaq.component.css";


export const DestinationFaq = () => {
    return (
      <>
        <h2 className="heading">Frequently Asked Questions</h2>
        <div className="destination-list">
          <div className="question">
            <p>
              <span>Q: </span> What are the best tours in Paris?
            </p>
          </div>
          <div className="answer">
            <p>
              <span>A: </span>The best tours in Paris according to Viator
              travelers are:
            </p>
            <ul>
              <li>
                <a href="#">
                  <p className="links">
                    Eiffel Tower Second Floor Tour and Summit Option by Elevator
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p className="links">
                    Bateaux Parisiens Seine River Gourmet Dinner & Sightseeing
                    Cruise
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p className="links">
                    Eiffel Tower Second Floor Tour and Summit Option by Elevator
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p className="links">
                    Bateaux Parisiens Seine River Gourmet Dinner & Sightseeing
                    Cruise
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="more-links">
          <div className="more-heading">
            <h4>
              <a href="#">More Tours in Paris</a>
            </h4>
            <div className="link-list">
              <ul>
                <li>
                  <a href="#" className="link">
                    <p>Art & Culture</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="link">
                    <p>Audio Guides</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="link">
                    <p>Classes & Workshops</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="more-links">
          <div className="more-heading">
            <h4>
              <a href="#">Attractions in Paris</a>
            </h4>
            <div className="link-list">
              <ul>
                <li>
                  <a href="#" className="link">
                    <p>Art & Culture</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="link">
                    <p>Audio Guides</p>
                  </a>
                </li>
                <li>
                  <a href="#" className="link">
                    <p>Classes & Workshops</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
}