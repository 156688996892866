import { useState, useEffect } from "react";
import { AppLayout } from "../components/AppLayout";
import { OffersPromotions } from "../components/forms/AdminDashboard/OffersPromotions";
import { BookingManagement } from "../components/forms/AdminDashboard/BookingManagement";
import { HotelManagement } from "../components/forms/AdminDashboard/HotelManagement";
import { AddEdit } from "../components/forms/AdminDashboard/AddEdit";
import filter from "../assets/images/filter.png";
import LazyImage from "../pages/LazyImage";
import { getAdminProfile } from "../api/AdminProfile";
import { valuesAndMeanings } from "../utils/helpers";
import { CommissionsSlabs } from "../components/forms/AdminDashboard/CommissionSlabs";
import { HotelCommissionsSlabs } from "../components/forms/AdminDashboard/HotelCommissionSlabs";

export const AdminDashboard = () => {
  const [toggleAdmin, setToggleAdmin] = useState("promotions");
  const [storedFlightList, setStoredFlightList] = useState<any[]>([]);
  const [originalFlightArray, setOriginalFlightArray] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState("");

  const [reCallAPI, setReCallAPI] = useState(false);

  const handleFilterChange = (filter: any) => {
    setSelectedFilter((prevFilter) => (prevFilter === filter ? "" : filter));
  };

  useEffect(() => {
    getAdminProfile()
      .then((res) => {
        if (res.data) {
          setStoredFlightList(res.data.flightBookings);
          setOriginalFlightArray(res.data.flightBookings);
        }
      })
      .catch((err) => console.log(err));
  }, [reCallAPI]);

  const applySelectedFilter = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectedFilter === "upcoming") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray?.filter(
          (flight: {
            flightBookingResponseData: { OnwardDate: string | number | Date };
          }) => {
            const onwardDates = new Date(
              flight?.flightBookingResponseData?.OnwardDate
            );
            onwardDates?.setHours(0, 0, 0, 0);

            return today < onwardDates;
          }
        );
        setStoredFlightList(filteredFlights);
      }
    }
    if (selectedFilter === "complete") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray?.filter(
          (flight: {
            flightBookingResponseData: { OnwardDate: string | number | Date };
          }) => {
            const onwardDates = new Date(
              flight?.flightBookingResponseData?.OnwardDate
            );
            onwardDates.setHours(0, 0, 0, 0);

            return today > onwardDates;
          }
        );
        setStoredFlightList(filteredFlights);
      }
    }

    if (selectedFilter === "success") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray.filter((flight) => {
          const statusMeaning = valuesAndMeanings.find((item) =>
            item.code
              .split(",")
              .some((code) => flight?.flightStatus?.includes(code))
          );
          return statusMeaning && statusMeaning.text.includes("Success");
        });
        setStoredFlightList(filteredFlights);
      }
    }

    if (selectedFilter === "pending") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray.filter((flight) => {
          const statusMeaning = valuesAndMeanings.find((item) =>
            item.code
              .split(",")
              .some((code) => flight?.flightStatus?.includes(code))
          );
          return statusMeaning && statusMeaning.text.includes("Hold");
        });
        setStoredFlightList(filteredFlights);
      }
    }

    if (selectedFilter === "failed") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray.filter((flight) => {
          const statusMeaning = valuesAndMeanings.find((item) =>
            item.code
              .split(",")
              .some((code) => flight?.flightStatus?.includes(code))
          );
          return statusMeaning && statusMeaning.text.includes("Failed");
        });
        setStoredFlightList(filteredFlights);
      }
    }

    if (selectedFilter === "cancelled") {
      if (originalFlightArray) {
        const filteredFlights = originalFlightArray.filter((flight) => {
          flight?.flightStatus?.includes("CR");

          return flight?.flightStatus?.includes("CR");
        });
        setStoredFlightList(filteredFlights);
      }
    }

    if (selectedFilter === "") {
      setStoredFlightList(originalFlightArray);
    }
  };

  return (
    <AppLayout>
      {/* You Admin Layout goes here  */}
      <main className="container mb-5 pt-5">
        <div className="d-flex align-items-center justify-content-between res-admin">
          <div className="left-div desktop-view">
            <h1 className="h1-inner">Admin</h1>
          </div>
          {toggleAdmin !== "promotions" &&
            toggleAdmin !== "slabs" &&
            toggleAdmin !== "slabsHotel" && (
              <div className="left-div res-admin">
                <h1
                  className="h1-inner"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                >
                  Filter
                </h1>
                <div
                  className="res-fliter-image"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                >
                  <LazyImage src={filter} alt="filter" />
                </div>
              </div>
            )}

          <div className="filter-add d-flex gap-3">
            {toggleAdmin !== "promotions" &&
              toggleAdmin !== "slabs" &&
              toggleAdmin !== "slabsHotel" &&
              toggleAdmin !== "edit" && (
                <div className="filter-admin">
                  <h3
                    className="h3-bold mb-0"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                  >
                    <span className="px-1">
                      <LazyImage src={filter} alt="filter" />
                    </span>
                    Filter
                  </h3>
                </div>
              )}

            {/* <div
              className="d-flex gap-2 align-items-center justify-content-end px-0 add_icon"
              onClick={() => setToggleAdmin("edit")}
            >
              <LazyImage src={Plus} alt="" />
              <span className="action-text action-edit">Add</span>
            </div> */}
          </div>
          <div className="res-desktop">
            <div className="right-div d-flex position-absolute">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={inputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue(e.target.value)
                }
              />
              <button
                className="btn btn-login"
                type="submit"
                onClick={() => setSearchTerm(inputValue)}
                // onClick={() => setSearchTerm(searchTerm)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "35px" }}
          className="select-tab-container position-relative gap-0 res-seclect-tab"
        >
          <div
            className="select-tab"
            onClick={() => setToggleAdmin("promotions")}
          >
            <h3
              className={
                toggleAdmin === "promotions"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="promotions"
            >
              Hotel Deals
            </h3>
          </div>
          <div className="select-tab" onClick={() => setToggleAdmin("booking")}>
            <h3
              className={
                toggleAdmin === "booking"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="booking"
            >
              Flight Management
            </h3>
          </div>
          <div className="select-tab" onClick={() => setToggleAdmin("hotel")}>
            <h3
              className={
                toggleAdmin === "hotel"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="booking"
            >
              Hotel Management
            </h3>
          </div>

          <div className="select-tab" onClick={() => setToggleAdmin("slabs")}>
            <h3
              className={
                toggleAdmin === "slabs"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="booking"
            >
              Flight Commissions
            </h3>
          </div>

          <div
            className="select-tab"
            onClick={() => setToggleAdmin("slabsHotel")}
          >
            <h3
              className={
                toggleAdmin === "slabsHotel"
                  ? " tab-bold mb-0 tab-active"
                  : "tab-bold mb-0"
              }
              id="booking"
            >
              Hotel Commissions
            </h3>
          </div>
          <div className="search-desktop">
            <div className="right-div d-flex position-absolute">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={inputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue(e.target.value)
                }
              />
              <button
                className="btn btn-login"
                type="submit"
                onClick={() => setSearchTerm(inputValue)}
                //  onClick={() => setSearchTerm(searchTerm)}
              >
                Search
              </button>
            </div>
          </div>

          {/* <div className="res_add m-auto">
            <div
              className="d-flex gap-2 align-items-center justify-content-end px-0 add_icon"
              onClick={() => setToggleAdmin("edit")}
            >
              <LazyImage src={Plus} alt="" />
              <span className="action-text action-edit" id="edit">
                Add
              </span>
            </div>
          </div> */}
        </div>
        {/* filter  */}
        <div
          className="offcanvas offcanvas-bottom"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">
              Filter
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body small">
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="upcoming"
                id="upcoming"
                onChange={() => handleFilterChange("upcoming")}
                checked={selectedFilter === "upcoming"}
              />
              <label
                className="form-check-label admin-label"
                htmlFor="upcoming"
              >
                Upcoming Travel
              </label>
            </div>
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="complete"
                id="complete"
                onChange={() => handleFilterChange("complete")}
                checked={selectedFilter === "complete"}
              />
              <label
                className="form-check-label admin-label"
                htmlFor="complete"
              >
                Complete Travel
              </label>
            </div>
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="success"
                id="success"
                onChange={() => handleFilterChange("success")}
                checked={selectedFilter === "success"}
              />
              <label className="form-check-label admin-label" htmlFor="success">
                Success
              </label>
            </div>
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="pending"
                id="pending"
                onChange={() => handleFilterChange("pending")}
                checked={selectedFilter === "pending"}
              />
              <label className="form-check-label admin-label" htmlFor="pending">
                Pending
              </label>
            </div>
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="failed"
                id="failed"
                onChange={() => handleFilterChange("failed")}
                checked={selectedFilter === "failed"}
              />
              <label className="form-check-label admin-label" htmlFor="failed">
                Failed
              </label>
            </div>
            <div className="form-check admin-dash">
              <input
                className="form-check-input"
                type="checkbox"
                name="cancelled"
                id="cancelled"
                onChange={() => handleFilterChange("cancelled")}
                checked={selectedFilter === "cancelled"}
              />
              <label
                className="form-check-label admin-label"
                htmlFor="cancelled"
              >
                Cancelled
              </label>
            </div>
            <div className="modal-footer d-flex gap-3 admin-filter-btn">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Close
              </button>
              <button
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                type="button"
                className="btn btn-login search_record"
                onClick={applySelectedFilter}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        {toggleAdmin === "promotions" && (
          <OffersPromotions setToggleAdmin={setToggleAdmin} />
        )}
        {toggleAdmin === "booking" && (
          <BookingManagement
            storedFlightList={storedFlightList}
            searchTerm={searchTerm}
            setReCallAPI={setReCallAPI}
          />
        )}
        {toggleAdmin === "hotel" && <HotelManagement />}
        {toggleAdmin === "slabs" && <CommissionsSlabs />}
        {toggleAdmin === "slabsHotel" && <HotelCommissionsSlabs />}
        {toggleAdmin === "edit" && <AddEdit setToggleAdmin={setToggleAdmin} />}
      </main>
      {/* ------   */}
      {/* <TalkToAgents />
            <Footer /> */}
    </AppLayout>
  );
};
