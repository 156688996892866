import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchFlight } from "./forms/flight/SearchFlight";
import { HotelPlusFlight } from "./forms/hotel-and-flight/HotelPlusFlight";
import { SearchHotel } from "./forms/hotel/SearchHotel";
import { SearchActivity } from "./forms/activity/SearchActivity";
import { faHotel, faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ActivityPage } from "./forms/activity/ActivityPage";

export const HeroSection = () => {
  const [activeTab, setActiveTab] = useState("flight");

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="hero-section">
      <div className="container">
        <div className="hero-section-top">
          <div className="hero-section-left">
            {" "}
            <h1>"Best Flight Rates Worldwide"</h1>{" "}
          </div>
        </div>
        {/* Heading done above */}

        <div className="tabbing-bar">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active responsivetab"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => {
                  localStorage.setItem("tabSearchData", "flight");
                  handleTabClick("flight");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlaneUp}
                  style={{
                    color: activeTab === "flight" ? "#ffffff" : "#a8a8a8",
                    marginRight: "6px",
                  }}
                />
                Flight
              </button>

              {/* <button
                className="nav-link responsivetab"
                id="nav-activity-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-activity"
                type="button"
                role="tab"
                aria-controls="nav-activity"
                aria-selected="false"
                onClick={() => {
                  localStorage.setItem("tabSearchData", "hotel");
                  handleTabClick("hotel");
                }}
              >
                <FontAwesomeIcon
                  icon={faHotel}
                  style={{
                    color: activeTab === "activity" ? "#ffffff" : "#a8a8a8",
                    marginRight: "6px",
                  }}
                />
                Activity
              </button> */}
              {/* <button
                className="nav-link responsivetab"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => {
                  localStorage.setItem("tabSearchData", "hotel");
                  handleTabClick("hotel");
                }}
              >
                <FontAwesomeIcon
                  icon={faHotel}
                  style={{
                    color: activeTab === "hotel" ? "#ffffff" : "#a8a8a8",
                    marginRight: "6px",
                  }}
                />
                Hotel
              </button> */}
              {/* <button
                className="nav-link responsivetab"
                id="nav-profile-tab2"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile2"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => {
                  localStorage.setItem("tabSearchData", "hotelFlight");
                  handleTabClick("hotelFlight");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlaneUp}
                  style={{
                    color: activeTab === "hotelFlight" ? "#ffffff" : "#a8a8a8",
                    marginRight: "6px",
                  }}
                />
                <FontAwesomeIcon
                  icon={faHotel}
                  style={{
                    color: activeTab === "hotelFlight" ? "#ffffff" : "#a8a8a8",
                    marginRight: "6px",
                  }}
                />
                Flight + Hotel
              </button> */}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
              tabIndex={0}
            >
              <div className="" id="one-way">
                <SearchFlight />
              </div>
            </div>

            {/* <div
              className="tab-pane fade"
              id="nav-activity"
              role="tabpanel"
              aria-labelledby="nav-activity-tab"
              tabIndex={0}
            >
              <SearchActivity />
            </div>

            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
              tabIndex={0}
            >
              <SearchHotel activeTab={activeTab} />
            </div> */}

            {/* <div
              className="tab-pane fade"
              id="nav-profile2"
              role="tabpanel"
              aria-labelledby="nav-profile-tab2"
              tabIndex={0}
            >
              <HotelPlusFlight />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
