import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import calenderImage from "../../../assets/images/calender.svg";
import pencilSimpleIcon from "../../../assets/images/PencilSimple.svg";
import moment from "moment";
import { getUserProfile, updateProfile } from "../../../api/UserProfile";
import axios from "axios";
import { Calendar } from "react-date-range";
import AWS from "aws-sdk";
import { toast } from "react-toastify";
import {
  cityRegex,
  fullNameRegex,
  gstRegex,
  pinCodeRegex,
  stateRegex,
  streetRegex,
} from "../../../utils/validation";
import { useRefreshUser } from "../../context/RefreshUserContext";
import LazyImage from "../../../pages/LazyImage";
const initialDataObject = {
  fullName: "",
  dob: "",
  gender: "",
  gst: "",
  address: { pincode: "", city: "", country: "", state: "", street: "" },
};

interface BasicDetailsProps {
  setOnUpdate: Dispatch<SetStateAction<boolean>>;
  openBasicDetailsModal: boolean;
  setOpenBasicDetailsModal: (val: boolean) => void;
  userProfilePicture: string;
}

export const BasicDetails = ({
  setOnUpdate,
  openBasicDetailsModal,
  setOpenBasicDetailsModal,
  userProfilePicture,
}: BasicDetailsProps) => {
  const [countries, setCountries] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userImage, setUserImage] = useState<any | null>(null);
  const [date, setDate] = useState<Date>();
  const [formData, setFormData] = useState({ ...initialDataObject });
  const [dobValue, setDobValue] = useState("");
  const [userId, setUserId] = useState("");

  const { setRefreshUser } = useRefreshUser();

  const tokenProfile = String(localStorage.getItem("userImageKey"));

  const [formDataInitial, setFormDataInitial] = useState({
    ...initialDataObject,
  });

  const today = new Date();

  const calendarRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalender(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (openBasicDetailsModal) {
      getUserProfile()
        .then((response) => {
          setFormData({
            ...formData,
            ...response.data,
          });
          setFormDataInitial({
            ...formData,
            ...response.data,
            ...response.data.address,
          });
          setUserId(response.data._id);
        })
        .catch((err) => console.log(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBasicDetailsModal]);

  useEffect(() => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (openBasicDetailsModal) {
      const getResponseCountryCode = () => {
        axios
          .get("https://www.makemytrip.com/api/countryList")
          .then((response) => {
            setCountries(response.data);
          });
      };
      getResponseCountryCode();
    }
  }, [openBasicDetailsModal]);

  useEffect(() => {
    const handleValidation = () => {
      const isFormValid =
        (formData.address.pincode &&
          !pinCodeRegex.test(formData.address.pincode)) ||
        (formData.address.state !== "" &&
          !stateRegex.test(formData.address.state)) ||
        (formData.address.city !== "" &&
          !cityRegex.test(formData.address.city)) ||
        (formData.address.street !== "" &&
          !streetRegex.test(formData.address.street));

      setIsFormValid(isFormValid);
    };

    handleValidation();
  }, [formData]);

  function getFileExtension(filename: string) {
    const extension = filename.split(".").pop();
    return extension ? "." + extension : "";
  }

  async function uploadImageToS3() {
    if (userImage !== null) {
      try {
        AWS.config.update({
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        });

        const S3_BUCKET = "maashree-private";
        const REGION = process.env.REACT_APP_AWS_REGION;
        const imagePath = "users/images/";

        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });

        const params = {
          Bucket: S3_BUCKET,
          Key: imagePath + userId + getFileExtension(userImage.name),
          Body: userImage,
          ContentType: userImage.type,
        };

        await s3.upload(params).promise();
      } catch (err) {
        console.log(err);
      }
    }
  }

  const onSaveHandler = async () => {
    const {
      fullName,
      dob,
      gender,
      gst,
      address: { city, country, state, street, pincode },
    } = formData;

    const address = {
      street,
      city,
      state,
      country,
      pincode,
    };

    setRefreshUser(true);
    setOpenBasicDetailsModal(false);

    const profilePic =
      userImage !== null
        ? "users/images/" + userId + getFileExtension(userImage.name)
        : "";

    localStorage.setItem("userImageKey", profilePic);

    updateProfile({ fullName, gender, dob, gst, address, profilePic })
      .then((response) => {
        toast.success(response.message);
        setOnUpdate(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });

    if (!userImage) {
      return;
    } else {
      uploadImageToS3();
    }
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    if (name.startsWith("address.")) {
      const addressField = name.substring("address.".length);
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return; // No file selected, exit the function
    }

    const maxSizeBytes = 5 * 1024 * 1024; // 5 MB
    if (selectedFile.size > maxSizeBytes) {
      toast.error("File size exceeds the limit of 5 MB");
      setUserImage("");
      return;
    }

    // Check file extension (lowercased) against allowed types
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only PNG, JPG, and JPEG files are allowed");
      setUserImage("");
      return;
    }

    setUserImage(selectedFile);
  };

  const handleDateChange = (selectedDate: any) => {
    setDate(selectedDate);
    setShowCalender(false);

    const inputDate = new Date(selectedDate);
    const formattedDate = `${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDate.getFullYear()}`;

    setDobValue(formattedDate);

    setFormData({
      ...formData,
      dob: formattedDate,
    });
  };

  const resetForm = () => {
    setFormData({ ...initialDataObject, ...formDataInitial });
    setOpenBasicDetailsModal(false);
  };

  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content site-modal personal-modal">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={resetForm}
          ></button>

          <div className="row modal-row1">
            <div className="col-sm-12">
              <div className="modal-left">
                <h2 className="modal-head">Personal information</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="mb-4 text-left1">
                      <div
                        className="box-img"
                        style={{
                          textTransform: "capitalize",
                          width: "100px",
                          height: "100px",
                          border: "1px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        <>
                          {tokenProfile !== "undefined" && userImage ? (
                            <LazyImage
                              src={URL.createObjectURL(userImage)}
                              alt="User"
                              className="userProfilePicture"
                              style={{ width: "100px", height: "100px" }}
                            />
                          ) : tokenProfile !== "undefined" &&
                            userProfilePicture ? (
                            <LazyImage
                              src={userProfilePicture}
                              alt="User"
                              className="userProfilePicture"
                              style={{ width: "100px", height: "100px" }}
                            />
                          ) : formData?.fullName ? (
                            formData.fullName[0]
                          ) : (
                            "T"
                          )}
                        </>
                      </div>
                      <div className="link-box">
                        <div className="file-box">
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            id="inputGroupFile01"
                            value=""
                            onChange={(event) => handleFileChange(event)}
                          />
                          <LazyImage src={pencilSimpleIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-4 text-left1">
                          <label htmlFor="fullName" className="form-label">
                            Full name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            placeholder="Full Name"
                            name="fullName"
                            value={
                              formData.fullName === "" ||
                              formData.fullName === null
                                ? ""
                                : formData.fullName
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="dob" className="form-label">
                            Date of Birth
                          </label>
                          <div className="text-left1 inline-text3">
                            <LazyImage src={calenderImage} alt="" />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="DOB"
                              id="dob"
                              name="dob"
                              value={
                                formData.dob === null || formData.dob === ""
                                  ? ""
                                  : dobValue === ""
                                  ? moment(
                                      formData.dob,
                                      "YYYY-MM-DD hh:mm:ss A Z"
                                    ).format("DD-MM-YYYY")
                                  : dobValue
                              }
                              autoComplete="off"
                              onChange={() => {}}
                              onClick={() => setShowCalender(!showCalender)}
                            />
                            {showCalender && (
                              <span
                                ref={calendarRef}
                                style={{ position: "absolute" }}
                              >
                                <Calendar
                                  maxDate={
                                    new Date(
                                      today.getFullYear(),
                                      today.getMonth(),
                                      today.getDate()
                                    )
                                  }
                                  onChange={handleDateChange}
                                  date={date}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="gender" className="form-label">
                            Gender
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                          >
                            <option value="">Select</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          <b>Address</b>
                        </label>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-4 text-left1">
                          <label htmlFor="street" className="form-label">
                            Street
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="street"
                            id="street"
                            name="address.street"
                            value={formData.address.street}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="city" className="form-label">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="city"
                            id="city"
                            name="address.city"
                            value={formData.address.city}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="state" className="form-label">
                            State
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="state"
                            id="state"
                            name="address.state"
                            value={formData.address.state}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            id="country"
                            name="address.country"
                            value={formData.address.country}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Country</option>
                            {countries.map(
                              ({ defaultName, phoneCode }, idx) => (
                                <option
                                  value={defaultName}
                                  key={idx + phoneCode}
                                >
                                  {defaultName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-4 text-left1">
                          <label htmlFor="pincode" className="form-label">
                            Pin code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="pin code"
                            id="pincode"
                            name="address.pincode"
                            value={
                              formData.address.pincode === null
                                ? ""
                                : formData.address.pincode
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-4 text-left1">
                          <label htmlFor="gstInfo" className="form-label">
                            GST info (optional):
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="GST Info"
                            id="gstInfo"
                            name="gst"
                            value={formData.gst}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="text-center">
                            <button
                              className="btn btn-login modal-btn"
                              data-bs-dismiss="modal"
                              onClick={onSaveHandler}
                              disabled={
                                isFormValid ||
                                (formData.gst !== "" &&
                                  !gstRegex.test(formData.gst)) ||
                                (formData.fullName !== "" &&
                                  !fullNameRegex.test(formData.fullName))
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
