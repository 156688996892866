import { Box } from "@mui/material";
import { ReactNode } from "react";
import { Navbar } from "./Navbar";
import { AdminNavbar } from "./AdminNavbar"
import { RefreshUserProvider } from "./context/RefreshUserContext";

interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const admin_token = localStorage.getItem("admin_token");
  return (
    <RefreshUserProvider>
      {admin_token ? (
        <AdminNavbar />
      ) : (
        <Navbar />
      )}

      <Box>
        <Box>{children}</Box>
      </Box>
    </RefreshUserProvider>
  );
};
