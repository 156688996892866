import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { TalkToAgents } from "../components/TalkToAgent";

export const About = () => {
  return (
    <AppLayout>
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-10 m-auto mt-5 about-section">
            <div className="heading-div">
              <h2 className="about-heading">About Us</h2>
            </div>
            <div className="content-div">
              <p className="text-left">
                Maashree a Global OTA (Online Travel Agency) service provider,
                we have exceptional commitment to customer success. Since 2005,
                with the aim of offering best fit travel services and technology
                solutions. We are trusted by 10,000+ Clients worldwide, with a
                one-stop platform to provide more than 1600,000+ Hotels and 500+
                Airlines at 20,000 Destinations, 18,000+ Activities & Tickets,
                with 500+ Car Rental suppliers Worldwide.
              </p>
            </div>
            <div className="heading-div">
              <h2 className="about-heading">Why Us</h2>
            </div>
            <div className="content-div">
              <p className="text-left">
                With more than 1 million hotels in 195 destination Countries and
                regions, we've built an extensive hotel network to give our
                customers a wonderful choice of accommodation. Our far-reaching
                flight network has over 2 million individual flight routes
                connecting more than 5,000 cities around the globe. Payment
                accepts 27+ different Currencies globally, when you combine this
                with our 24/7 ‘English Speaking’ customer service in (UK & APEC)
                and various other travel products; you can trust us to take care
                of your next Journey.
              </p>
            </div>
            <div className="heading-div">
              <h2 className="about-heading">Secure Payment</h2>
            </div>
            <div className="content-div">
              <p className="text-left">
                Ensure safe & secure transactions Compliant with global security
                standards and powered by real-time fraud detection.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ margin: "1%" }} />
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
