import OwlCarousel from "react-owl-carousel";
import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import leftRoomImg from "../../assets/images/left_room_image.png";
import starImage from "../../assets/images/star.png";
import roomGridImg from "../../assets/images/Mask group.png";
import userImg from "../../assets/images/Users.png";
import rightImg from "../../assets/images/right.png";
import roomGal from "../../assets/images/image76.png";
import roomGal2 from "../../assets/images/image77.png";
import maskRoomImage from "../../assets/images/Mask group.png";
import image76 from "../../assets/images/image76.png";
import { useEffect, useState } from "react";
import { RoomDetails } from "../../components/forms/hotel/RoomDetails";
import { AmenitiesDetails } from "../../components/forms/hotel/AmenitiesDetails";
import { PoliciesDetail } from "../../components/forms/hotel/PoliciesDetail";
import { PhotoDetails } from "../../components/forms/hotel/PhotosDetails";
import { MapDetails } from "../../components/forms/hotel/MapDetails";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../pages/LazyImage";
import { contentBoardsRoom, hotelContentsList } from "../../api/HotelAPI";
import { toast } from "react-toastify";
import HotelLoader from "../../components/loaders/HotelLoader";
import { SearchHotel } from "../../components/forms/hotel/SearchHotel";

export const HotelRoom = () => {
  const [selectedTab, setSelectedTab] = useState("rooms");
  const [hotelRoomData, setHotelRoomData] = useState<any[]>();
  const [roomContentBoard, setRoomContentBoard] = useState<any[]>();

  const [hotelLoader, setHotelLoader] = useState(true);

  const navigate = useNavigate();

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1199: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    const hotelAdminJSON = localStorage.getItem("optionRef-hotel");
    const hotelAdmin = JSON.parse(String(hotelAdminJSON));

    hotelContentsList({
      access: hotelAdmin.adminCode,
      hotelCodes: hotelAdmin.selectedHotelCode,
      destinationCodes: ["ES"],
    })
      .then((res) => {
        if (res.data.data.hotelX.hotels.edges.length > 0) {
          setHotelRoomData(res.data.data.hotelX.hotels.edges);
        } else {
          setHotelRoomData([]);
        }
        setHotelLoader(false);
      })
      .catch((error) =>
        toast.error(error.response.data.message, {
          toastId: "hotel-search-list-toast",
        })
      );

    contentBoardsRoom({ access: hotelAdmin.adminCode })
      .then((res) => {
        if (res.data.hotelX.boards) {
          setRoomContentBoard(res.data.hotelX.boards.edges);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <AppLayout>
      <main className="container mt-3">
        <div className="tabbing-bar res_tabbing">
          <div className="tab-content">
            <SearchHotel activeTab="hotel" />
          </div>
        </div>
        {hotelRoomData && hotelRoomData.length > 0 ? (
          <>
            <div className="row">
              <div className="col-sm-5 res_widht">
                <div className="carousel-owl">
                  <OwlCarousel
                    className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                    {...options}
                  >
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={leftRoomImg}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={leftRoomImg}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName},{" "}
                                {hotelRoomData[0]?.node.hotelData.location.city}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={leftRoomImg}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName},{" "}
                                {hotelRoomData[0]?.node.hotelData.location.city}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={leftRoomImg}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName},{" "}
                                {hotelRoomData[0]?.node.hotelData.location.city}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={roomGridImg}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName},{" "}
                                {hotelRoomData[0]?.node.hotelData.location.city}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="offer-card">
                        <div className="offer-col">
                          <div className="col-left">
                            <LazyImage
                              className="left_image"
                              src={roomGal}
                              alt=""
                            />
                          </div>
                          <div className="col-right px-2">
                            <div className="block-content mb-2">
                              <h3 className="h3-bold mb-0">
                                {hotelRoomData[0]?.node.hotelData.hotelName},{" "}
                                {hotelRoomData[0]?.node.hotelData.location.city}
                              </h3>
                              <span>
                                <LazyImage
                                  className="img-fluid"
                                  src={starImage}
                                  alt="star"
                                />{" "}
                              </span>
                            </div>
                            <div className="block-content mb-2">
                              <span className="content-text-light">
                                {" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location.city
                                }{" "}
                              </span>
                              <span className="content-text">
                                {" "}
                                |{" "}
                                {
                                  hotelRoomData[0]?.node.hotelData.location
                                    .address
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>

              <div className="card-body res_show res_widht">
                <div className="res-left">
                  <div className="col sm-12 content-body">
                    <h3 className="h3-bold py-2 mb-0">Luxe Twin Room</h3>
                  </div>
                  <div className="row book-row justify-content-between align-items-center flex-wrap">
                    <div className="block res_block mb-0">
                      <div className="image_body d-flex">
                        <LazyImage src={userImg} alt="" />
                        <span className="text-left text-body price_text">
                          For 2 Adults
                        </span>
                      </div>
                      <div className="image_body d-flex">
                        <LazyImage
                          className="right-image-icon"
                          src={rightImg}
                          alt=""
                        />
                        <span className="text-left text-body price_text">
                          Non-Refundable
                        </span>
                      </div>
                      <div className="image_body d-flex">
                        <LazyImage
                          className="right-image-icon"
                          src={rightImg}
                          alt=""
                        />
                        <span className="text-left text-body price_text">
                          Room Only
                        </span>
                      </div>
                      <div className="view-rooms">
                        <span className="text-left text-body price_text">
                          View other rooms
                        </span>
                      </div>
                    </div>
                    <div className="block res_block mb-0">
                      <div className="price-content-left">
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body price_text">
                            Per Night
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body price_text">
                            <del>+₹4,799</del>
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <h3 className="h3-bold py-0 mb-0 price_text">
                            ₹4,319
                          </h3>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text-body price_text">
                            +₹518 taxes &amp; fees
                          </span>
                        </div>
                        <div className="image_body d-flex pb-1">
                          <span className="text-left text_body price_text">
                            Saving ₹ 480
                          </span>
                        </div>
                      </div>
                      <div
                        className="price-right"
                        onClick={() => navigate("/hotel-passenger")}
                      >
                        <button className="btn btn-login price_text">
                          Book now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="row room-gallery">
                  <div className="col-sm-6 py-3 res_hide">
                    <div className="image-gallery">
                      <LazyImage
                        className="img-fluid img-gallery"
                        src={maskRoomImage}
                        alt="room-gallery"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 py-3 res_hide">
                    <div className="card">
                      <div className="card-body">
                        <div className="content-body">
                          <h3 className="h3-bold py-2 mb-0">Luxe Twin Room</h3>
                        </div>
                        <div className="row book-row">
                          <div className="col-sm-6">
                            <div className="image_body d-flex">
                              <LazyImage src={userImg} alt="" />
                              <span className="text-left text-body price_text">
                                For 2 Adults
                              </span>
                            </div>
                            <div className="image_body d-flex">
                              <LazyImage
                                className="right-image-icon"
                                src={rightImg}
                                alt=""
                              />
                              <span className="text-left text-body price_text">
                                Non-Refundable
                              </span>
                            </div>
                            <div className="image_body d-flex">
                              <LazyImage
                                className="right-image-icon"
                                src={rightImg}
                                alt=""
                              />
                              <span className="text-left text-body price_text">
                                Room Only
                              </span>
                            </div>
                            <br />
                            <br />
                            <div className="view-rooms">
                              <span className="text-left text-body price_text">
                                View other rooms
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="price-content-left">
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body price_text">
                                  Per Night
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body price_text">
                                  <del>+₹4,799</del>
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <h3 className="h3-bold py-0 mb-0 price_text">
                                  ₹4,319
                                </h3>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text-body price_text">
                                  +₹518 taxes & fees
                                </span>
                              </div>
                              <div className="image_body d-flex pb-1">
                                <span className="text-left text_body price_text">
                                  Saving ₹ 480
                                </span>
                              </div>
                            </div>
                            <div
                              className="price-right"
                              onClick={() => navigate("/hotel-passenger")}
                            >
                              <button className="btn btn-login price_text">
                                Book now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 py-3 res_hide">
                    <div className="image-gallery">
                      <LazyImage
                        className="img-fluid img-gallery"
                        src={image76}
                        alt="room-gallery"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 py-3 res_hide">
                    <div className="image-gallery">
                      <LazyImage
                        className="img-fluid img-gallery"
                        src={roomGal2}
                        alt="room-gallery"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* // Listing with tabs  */}

            <div className="select-tab-container gap-0">
              <div className="select-tab " style={{ marginLeft: "auto" }}>
                <h3
                  className={
                    selectedTab === "rooms"
                      ? "tab-bold tab-active mb-0"
                      : "tab-bold mb-0"
                  }
                  id="rooms"
                  onClick={() => setSelectedTab("rooms")}
                  style={{ color: selectedTab === "rooms" ? "white" : "" }}
                >
                  Rooms
                </h3>
              </div>

              <div
                className="select-tab"
                onClick={() => setSelectedTab("amenities")}
              >
                <h3
                  className={
                    selectedTab === "amenities"
                      ? "tab-bold tab-active mb-0"
                      : "tab-bold mb-0"
                  }
                  id="amenities"
                  style={{ color: selectedTab === "amenities" ? "white" : "" }}
                >
                  Amenities
                </h3>
              </div>
              <div
                className="select-tab"
                onClick={() => setSelectedTab("hotel")}
              >
                <h3
                  className={
                    selectedTab === "hotel"
                      ? "tab-bold tab-active mb-0"
                      : "tab-bold mb-0"
                  }
                  id="hotel_policies"
                  style={{ color: selectedTab === "hotel" ? "white" : "" }}
                >
                  Hotel Policies
                </h3>
              </div>
              <div
                className="select-tab"
                onClick={() => setSelectedTab("photos")}
              >
                <h3
                  className={
                    selectedTab === "photos"
                      ? "tab-bold tab-active mb-0"
                      : "tab-bold mb-0"
                  }
                  style={{ color: selectedTab === "photos" ? "white" : "" }}
                  id="photos"
                >
                  Photos
                </h3>
              </div>
              <div className="select-tab" onClick={() => setSelectedTab("map")}>
                <h3
                  style={{ color: selectedTab === "map" ? "white" : "" }}
                  className={
                    selectedTab === "map"
                      ? "tab-bold tab-active mb-0"
                      : "tab-bold mb-0"
                  }
                  id="map"
                >
                  Map
                </h3>
              </div>
            </div>

            {selectedTab === "rooms" && (
              <RoomDetails roomContentBoard={roomContentBoard} />
            )}

            <section id="amenities_section" style={{ display: "block" }}>
              {selectedTab === "amenities" && hotelRoomData && (
                <AmenitiesDetails roomData={hotelRoomData[0].node} />
              )}
            </section>

            {selectedTab === "hotel" && hotelRoomData && (
              <PoliciesDetail roomData={hotelRoomData[0].node} />
            )}

            {selectedTab === "photos" && <PhotoDetails />}

            {selectedTab === "map" && <MapDetails />}
          </>
        ) : (
          <h5 style={{ textAlign: "center", fontSize: "x-large" }}>
            No Data Found
          </h5>
        )}
      </main>
      <TalkToAgents />
      <Footer />
      {hotelLoader && <HotelLoader />}
    </AppLayout>
  );
};
