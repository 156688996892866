import {useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { AppLayout } from "../../AppLayout";
import Footer from "../../Footer";
import "./DestinationPage.component.css";
import {DestinationFilter} from "./DestinationFilter";
import {DestinationResult} from "./DestinationResult";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export const DestinationPage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

    return (
      <AppLayout>
        <section className="destination-page">
          <div className="container">
            <div className="destination-heading">
              <ul>
                <li>
                  <Link to="/">
                    <p>Home</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="des-carousel">
          <div className="container">
            <h2>Paris Tours</h2>
            <Carousel responsive={responsive}>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
              <div className="carousel-con">
                <a href="#">
                  <p>Item 1</p>
                </a>
              </div>
            </Carousel>
          </div>
          
        </section>

        <section className="des-container">
          <div className="container">
            <div className="des-con-component">
              <DestinationFilter />
              <DestinationResult />
            </div>
          </div>
        </section>

        <Footer />
      </AppLayout>
    );
}