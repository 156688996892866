import calendarSVG from "../../../assets/images/calender.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { Calendar, DateRange } from "react-date-range";
import moment from "moment";
import usePassengersLabel from "../shared/usePassengersLabel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AutoCompleteCity from "../shared/AutoCompleteCity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getCurrentLocationAirports } from "../../../api/FlightAPI";
import { useFlightLoader } from "../shared/useFlightLoader";
import AirplaneLoader from "../../loaders/AirplaneLoader";
import LazyImage from "../../../pages/LazyImage";
export const HotelPlusFlight = () => {
  const today = new Date();
  const [oneWay, setOneWay] = useState("one way");
  const [flightClass, setFlightClass] = useState("economy");
  const [selectedHotelRoom, setSelectedHotelRoom] = useState("1 room");
  const [sameSelectedCityAlert, setSameSelectedCityAlert] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dateRangeStartDate, setDateRangeStartDate] = useState(new Date());
  const [nightStay, setNightStay] = useState("1 night");
  const [nationalityValue, setNationalityValue] = useState({
    country: "",
    code: "",
  });
  const [passengers, setPassengers] = useState({
    adults: 1,
    infants: 0,
    children: 0,
  });
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(
    moment(String(new Date())).format("YYYY-MM-DD")
  );
  const [additionalFlightInfo, setAdditionalFlightInfo] = useState({
    directFlight: false,
    studentFare: false,
    nearby: false,
  });
  const [flightDeparturePlace, setFlightDeparturePlace] = useState({
    cityName: "",
    code: "",
    airportName: "",
  });
  const [flightDestinationPlace, setFlightDestinationPlace] = useState({
    cityName: "",
    code: "",
    airportName: "",
  });
  const [checkOnDateChange, setCheckOnDateChange] = useState({
    startDate: false,
    endDate: false,
  });

  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  const [selectedReturnDate, setSelectedReturnDate] = useState("");
  const [showCalenderDeparture, setShowCalenderDeparture] = useState(false);
  const [showPassengers, setShowPassengers] = useState(false);
  const [date, setDate] = useState<Date>();
  const [stateRoundTrip, setStateRoundTrip] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const getAirPortsJSON = localStorage.getItem("allAirports");

  const navigate = useNavigate();
  const location = useLocation();

  const calendarRef = useRef<HTMLSpanElement | null>(null);
  const passengersRef = useRef<HTMLUListElement | null>(null);

  const passengersLabel = usePassengersLabel(passengers);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalenderDeparture(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // passengers dropdown toggle
  const handleDocumentClick = (e: { target: any }) => {
    if (
      showPassengers &&
      passengersRef.current &&
      !passengersRef.current.contains(e.target)
    ) {
      setShowPassengers(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPassengers]);

  const handleDropdownToggle = () => {
    setShowPassengers(!showPassengers);
  };

  const triggerOnToggleLocation = async () => {
    await getCurrentLocationAirports()
      .then((response) => {
        const airports = response.data.Airports.map(
          ({
            CityName,
            CityCode,
            Name,
          }: {
            CityName: string;
            CityCode: string;
            Name: string;
          }) => {
            setFlightDeparturePlace({
              cityName: CityName,
              code: CityCode,
              airportName: Name,
            });
            return { cityName: CityName, code: CityCode };
          }
        );
        localStorage.setItem("currentCity", JSON.stringify(airports));
      })
      .catch(() =>
        toast.error("Error in getting your current location.", {
          toastId: "forGeoLocation",
        })
      );
  };

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userFlightSearchData");

    if (flightDataJSON) {
      const flightData = JSON.parse(flightDataJSON);

      if (location.pathname !== "/") {
        setPassengers({
          adults: flightData.ADT,
          children: flightData.CHD,
          infants: flightData.INF,
        });

        setSelectedDepartureDate(flightData.OnwardDate);
        setSelectedReturnDate(flightData.ReturnDate);

        setStateRoundTrip([
          {
            ...stateRoundTrip[0],
            startDate: moment(flightData.OnwardDate).toDate(),
            endDate:
              flightData.ReturnDate === ""
                ? moment(flightData.ReturnDate).add(5, "days").toDate()
                : moment(flightData.ReturnDate).toDate(),
          },
        ]);
        setDate(moment(flightData.OnwardDate).toDate());
        setOneWay(flightData.flightWay);
      }

      setAdditionalFlightInfo({
        directFlight: flightData.IsDirect,
        studentFare: flightData.IsStudentFare,
        nearby: flightData.IsNearbyAirport,
      });
      setFlightDeparturePlace({
        cityName: flightData?.fromPlace,
        code: flightData?.FromCode,
        airportName: flightData.airNameDeparture,
      });
      setFlightDestinationPlace({
        cityName: flightData.toPlace,
        code: flightData.ToCode,
        airportName: flightData.airportNameDestination,
      });

      setFlightClass(flightData.Cabin);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      flightDeparturePlace.code !== "" &&
      flightDeparturePlace.code !== "" &&
      flightDeparturePlace.code === flightDestinationPlace.code
    ) {
      setSameSelectedCityAlert(true);
    } else {
      setSameSelectedCityAlert(false);
    }
  }, [flightDeparturePlace.code, flightDestinationPlace.code]);

  useEffect(() => {
    const startDate = moment(dateRangeStartDate).toDate();
    const returnDate = moment(selectedReturnDate, "DD MMM YYYY").toDate();

    if (startDate > returnDate) {
      setSelectedReturnDate(moment(startDate).format("DD MMM YYYY"));
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate,
          endDate: startDate,
        },
      ]);
    }

    setDate(moment(dateRangeStartDate).toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeStartDate]);

  useEffect(() => {
    const flightDataJSON = localStorage.getItem("userFlightSearchData");
    const flightData = JSON.parse(String(flightDataJSON));

    const startDate = moment(dateRangeStartDate).toDate();
    if (flightData) {
      setSelectedReturnDate(
        moment(flightData.ReturnDate).format("DD MMM YYYY")
      );
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate: moment(flightData.OnwardDate).toDate(),
          endDate: moment(flightData.ReturnDate).toDate(),
        },
      ]);

      setNationalityValue({
        country: flightData.country,
        code: flightData.code,
      });

      setSelectedHotelRoom(flightData.room ? flightData.room : "1 room");

      setNightStay(flightData.nightStay ? flightData.nightStay : "1 night");
    } else {
      setSelectedReturnDate(moment(startDate).format("DD MMM YYYY"));
      setStateRoundTrip([
        {
          ...stateRoundTrip[0],
          startDate: moment(startDate).toDate(),
          endDate: moment(startDate).toDate(),
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchFlightHandler = () => {
    if (
      oneWay === "" ||
      flightClass === "" ||
      flightDeparturePlace.cityName === "" ||
      nationalityValue.country === "" ||
      flightDestinationPlace.cityName === "" ||
      (passengers.adults === 0 &&
        passengers.children === 0 &&
        passengers.infants === 0)
    ) {
      toast.error("Please fill required flight details.", {
        toastId: "flightFillDetailError",
      });
    } else if (flightDeparturePlace.code === flightDestinationPlace.code) {
      setSameSelectedCityAlert(true);
    } else {
      setIsFlightLoading(true);

      localStorage.removeItem("multicitySearch");
      localStorage.setItem(
        "userFlightSearchData",
        JSON.stringify({
          ADT: passengers.adults,
          CHD: passengers.children,
          INF: passengers.infants,
          Cabin: flightClass,
          FromCode: flightDeparturePlace.code,
          fromPlace: flightDeparturePlace?.cityName,
          ToCode: flightDestinationPlace.code,
          toPlace: flightDestinationPlace.cityName,
          ReturnDate: selectedReturnDate !== "" ? selectedReturnDate : "",
          OnwardDate: selectedDepartureDate !== "" ? selectedDepartureDate : "",
          IsDirect: additionalFlightInfo.directFlight,
          IsStudentFare: additionalFlightInfo.studentFare,
          IsNearbyAirport: additionalFlightInfo.nearby,
          flightWay: oneWay,
          airNameDeparture: flightDeparturePlace.airportName,
          airportNameDestination: flightDestinationPlace.airportName,
          country: nationalityValue.country,
          code: nationalityValue.code,
          nights: nightStay,
          room: selectedHotelRoom,
        })
      );

      localStorage.setItem(
        "hotel-search",
        JSON.stringify({
          destinationName: flightDestinationPlace.cityName,
          checkInDate:
            selectedDepartureDate !== "" ? selectedDepartureDate : "",
          numberOfNights: Number(nightStay.replace(/\D/g, "")),
          nationality: nationalityValue.country,
          nationalityCode: nationalityValue.code,
          checkOutDate: selectedReturnDate !== "" ? selectedReturnDate : "",
          rooms: [
            {
              id: 1,
              adults: passengers.adults,
              children: passengers.children,
              childAges: [],
            },
          ],
        })
      );

      setIsFlightLoading(false);
      if (location.pathname === "/" && oneWay !== "one way") {
        navigate("/search-roundtrip");
      } else if (location.pathname === "/" && oneWay === "one way") {
        navigate("/search-flights");
      } else if (location.pathname !== "/" && oneWay === "one way") {
        window.location.replace("/search-flights");
      } else if (location.pathname !== "/" && oneWay !== "one way") {
        window.location.replace("/search-roundtrip");
      } else {
        navigate(0);
      }
    }
  };

  const handleMenuClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  // auto cursor move  input boxes
  const cityInput1Ref = useRef<HTMLInputElement>(null);
  const cityInput2Ref = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLInputElement>(null);
  const nextInputRef = useRef<HTMLInputElement>(null);

  const focusNextInput = (
    currentInputRef: React.RefObject<HTMLInputElement>
  ) => {
    if (currentInputRef === cityInput1Ref) {
      cityInput2Ref.current?.focus();
    } else if (currentInputRef === cityInput2Ref) {
      datePickerRef.current?.focus();
      datePickerRef.current?.click();
    }
  };

  const removeDuplicateCountries = (data: any) => {
    const uniqueCountries = new Set();
    const filteredData: any[] = [];
    if (data) {
      for (const item of data) {
        const countryName = item.Country.toLowerCase();
        if (!uniqueCountries.has(countryName)) {
          uniqueCountries.add(countryName);
          filteredData.push(item);
        }
      }
    }

    return filteredData;
  };

  const filteredCountries = useMemo(() => {
    const uniqueCountriesData = removeDuplicateCountries(
      JSON.parse(String(getAirPortsJSON))
    );

    return uniqueCountriesData.filter(({ Country, Code }: any) =>
      `${Country} ${Code}`
        .toLowerCase()
        .includes(nationalityValue.country?.toLowerCase())
    );
  }, [getAirPortsJSON, nationalityValue.country]);

  return (
    <>
      <div className="inline-form">
        <div className="form-check">
          <input
            id="oneWayFlight"
            name="flightWay"
            type="radio"
            className="form-check-input"
            checked={oneWay === "one way" ? true : false}
            onChange={() => setOneWay("one way")}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: oneWay === "one way" ? "#ED3237" : "",
            }}
          />
          <label className="form-check-label" htmlFor="oneWayFlight">
            One way
          </label>
        </div>
        <div className="form-check">
          <input
            id="roundTripFlight"
            name="flightWay"
            type="radio"
            className="form-check-input"
            checked={oneWay === "round trip" ? true : false}
            onChange={() => setOneWay("round trip")}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: oneWay === "round trip" ? "#ED3237" : "",
            }}
          />
          <label className="form-check-label" htmlFor="roundTripFlight">
            Round trip
          </label>
        </div>

        <div
          className="dropdown select-dropdown"
          style={{ textTransform: "capitalize" }}
        >
          <a
            className=" dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedHotelRoom}
          </a>

          <ul className="dropdown-menu" style={{ color: "black" }}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((roomCount) => (
              <React.Fragment key={roomCount}>
                <li>
                  <div
                    className="form-check"
                    onClick={() => setSelectedHotelRoom(`${roomCount} room`)}
                    style={{
                      fontWeight:
                        selectedHotelRoom === `${roomCount} room`
                          ? "bolder"
                          : "",
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor={String(roomCount)}
                    >
                      {roomCount} Room
                    </label>

                    <input
                      id={String(roomCount)}
                      name="room"
                      type="radio"
                      className="form-check-input"
                      required
                      defaultChecked={selectedHotelRoom === `${roomCount} room`}
                      onChange={() => setSelectedHotelRoom(`${roomCount} room`)}
                    />
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>

        <div className="dropdown select-dropdown">
          <Link
            className=" dropdown-toggle"
            to="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={handleDropdownToggle}
          >
            {passengersLabel}
          </Link>

          <ul
            ref={passengersRef}
            className="dropdown-menu passengers-menu"
            style={{ color: "black" }}
          >
            {showPassengers && (
              <span onClick={handleMenuClick}>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Adults
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (passengers.adults > 1) {
                            setPassengers({
                              ...passengers,
                              adults: passengers.adults - 1,
                            });
                          }
                        }}
                      />{" "}
                      {passengers.adults}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPassengers({
                            ...passengers,
                            adults: passengers.adults + 1,
                          });
                        }}
                      />
                    </div>
                  </div>
                  Ages (12 + or above)
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Children
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (passengers.children > 0) {
                            setPassengers({
                              ...passengers,
                              children: passengers.children - 1,
                            });
                          }
                        }}
                      />{" "}
                      {passengers.children}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPassengers({
                            ...passengers,
                            children: passengers.children + 1,
                          });
                        }}
                      />
                    </div>{" "}
                  </div>
                  Ages (2-12)
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div className="top-row" style={{ color: "black" }}>
                    Infant
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginRight: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (passengers.infants > 0) {
                            setPassengers({
                              ...passengers,
                              infants: passengers.infants - 1,
                            });
                          }
                        }}
                      />{" "}
                      {passengers.infants}{" "}
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                          color: "#ed3237",
                          fontSize: "19px",
                          margin: "-2px",
                          marginLeft: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPassengers({
                            ...passengers,
                            infants: passengers.infants + 1,
                          });
                        }}
                      />
                    </div>
                  </div>
                  Ages (below 2 years)
                </li>
              </span>
            )}
          </ul>
        </div>

        <div
          className="dropdown select-dropdown form-check px-0"
          style={{ textTransform: "capitalize" }}
        >
          <a
            className="dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {flightClass}
          </a>
          <ul className="dropdown-menu" style={{ color: "black" }}>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "economy" ? "bolder" : "",
                }}
              >
                <input
                  id="economyClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "economy" ? true : false}
                  onChange={(e) => setFlightClass("economy")}
                />
                <label className="form-check-label" htmlFor="economyClass">
                  Economy
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "premium economy" ? "bolder" : "",
                }}
              >
                <input
                  id="premiumEconomyClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "premium economy" ? true : false}
                  onChange={(e) => setFlightClass("premium economy")}
                />
                <label
                  className="form-check-label"
                  htmlFor="premiumEconomyClass"
                >
                  Premium economy
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "business" ? "bolder" : "",
                }}
              >
                <input
                  id="businessClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  checked={flightClass === "business" ? true : false}
                  onChange={() => setFlightClass("business")}
                />
                <label className="form-check-label" htmlFor="businessClass">
                  Business
                </label>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <div
                className="form-check"
                style={{
                  fontWeight: flightClass === "first class" ? "bolder" : "",
                }}
              >
                <input
                  id="firstClass"
                  name="flightClass"
                  type="radio"
                  className="form-check-input"
                  onChange={(e) =>
                    e.target.value === "on" && setFlightClass("first class")
                  }
                />
                <label className="form-check-label" htmlFor="firstClass">
                  First class
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div
          className="dropdown select-dropdown"
          style={{ textTransform: "capitalize" }}
        >
          <a
            className=" dropdown-toggle"
            href="/"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {nightStay}
          </a>

          <ul className="dropdown-menu " style={{ color: "black" }}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((nightCount) => (
              <React.Fragment key={nightCount}>
                <li>
                  <div
                    className="form-check"
                    key={nightCount}
                    onClick={() => setNightStay(`${nightCount} night`)}
                    style={{
                      fontWeight:
                        nightStay === `${nightCount} night` ? "bolder" : "",
                    }}
                  >
                    <label className="form-check-label" htmlFor="debit">
                      {nightCount} night
                    </label>
                    <input
                      id="debit"
                      name="nights"
                      type="radio"
                      className="form-check-input"
                      required
                      checked={nightStay === `${nightCount} night`}
                      onChange={() => setNightStay(`${nightCount} night`)}
                    />
                  </div>

                  <hr className="dropdown-divider" />
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <>
          <div
            className={
              oneWay === "round trip"
                ? " inline-form2 mt-2 rounded-form"
                : "inline-form2 mt-2 oneWay-form"
            }
          >
            <div className="inline-text">
              <AutoCompleteCity
                label="Departure city"
                value={flightDeparturePlace}
                onChange={setFlightDeparturePlace}
                triggerOnToggleLocation={triggerOnToggleLocation}
                locationIcon={true}
                type="From"
                forwardedRef={cityInput1Ref}
                focusNextInput={() => focusNextInput(cityInput1Ref)}
              />
            </div>

            <div className="inline-text">
              <AutoCompleteCity
                label="Destination city"
                value={flightDestinationPlace}
                onChange={setFlightDestinationPlace}
                type="To"
                forwardedRef={cityInput2Ref}
                focusNextInput={() => focusNextInput(cityInput2Ref)}
              />
              {sameSelectedCityAlert && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#fd8d8d",
                    padding: "4px",
                    borderRadius: "7px",
                    fontSize: "13px",
                  }}
                >
                  Destination and Departure place cannot be same.
                </div>
              )}
            </div>

            <div className="inline-text">
              <div className="border px-2 py-2 rounded-2 border-dark">
                <div className="d-flex align-items-center gap-2">
                  <img
                    loading="lazy"
                    className="multi-icon"
                    src={calendarSVG}
                    alt=""
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  />

                  <span className="fw-bold fs-6">Depart</span>
                </div>

                <div
                  className="input_heading"
                  onClick={() => {
                    setCheckOnDateChange({
                      ...checkOnDateChange,
                      startDate: true,
                    });
                    setShowCalenderDeparture(!showCalenderDeparture);
                  }}
                >
                  <input
                    type="text"
                    //  type="search"
                    //  aria-label="Search"
                    ref={datePickerRef}
                    className="fw-bold border-0 fs-4 w-100"
                    style={{ width: "100%" }}
                    value={moment(selectedDepartureDate).format("DD")}
                    onChange={() => {}}
                  />
                  <span className="date_formate">
                    {moment(selectedDepartureDate).format("MMM'YY")}
                  </span>
                </div>

                <div
                  className="code_heading"
                  onClick={() => {
                    setCheckOnDateChange({
                      ...checkOnDateChange,
                      startDate: true,
                    });
                    setShowCalenderDeparture(!showCalenderDeparture);
                  }}
                >
                  <span className="code-des d-inline-block text-truncate w-100">
                    {" "}
                    {moment(selectedDepartureDate).format("dddd")}{" "}
                  </span>
                </div>
                {showCalenderDeparture && oneWay !== "round trip" && (
                  <span
                    ref={calendarRef}
                    style={{ position: "absolute", zIndex: 9999 }}
                  >
                    <Calendar
                      minDate={
                        new Date(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate()
                        )
                      }
                      maxDate={maxDate}
                      onChange={(item) => {
                        setDate(item);
                        setSelectedDepartureDate(
                          moment(item).format("DD MMM YYYY")
                        );

                        const startDate = moment(item).toDate();

                        if (startDate > moment(selectedReturnDate).toDate()) {
                          setStateRoundTrip([
                            {
                              ...stateRoundTrip[0],
                              startDate: startDate,
                              endDate: moment(startDate).toDate(),
                            },
                          ]);

                          setSelectedReturnDate(
                            moment(startDate).format("DD MMM YYYY")
                          );
                        }

                        setTimeout(() => {
                          if (nextInputRef.current && oneWay !== "round trip") {
                            nextInputRef.current.focus();
                            nextInputRef.current.click();
                          }
                        }, 2000);

                        setShowCalenderDeparture(!showCalenderDeparture);
                      }}
                      date={date}
                    />
                  </span>
                )}
              </div>
            </div>

            {oneWay === "round trip" && (
              <div className="inline-text">
                <div className="border px-2 py-2 rounded-2 border-dark">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      loading="lazy"
                      className="multi-icon"
                      src={calendarSVG}
                      alt=""
                      onClick={() =>
                        setShowCalenderDeparture(!showCalenderDeparture)
                      }
                    />

                    <span className="fw-bold fs-6">Return</span>
                  </div>

                  <div
                    className="input_heading"
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: false,
                        endDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  >
                    <input
                      type="text"
                      // type="search"
                      aria-label="Search"
                      className="fw-bold border-0 fs-4"
                      style={{ width: "100%" }}
                      value={moment(selectedReturnDate).format("DD")}
                      onChange={() => {}}
                    />
                    <span className="date_formate">
                      {moment(selectedReturnDate).format("MMM'YY")}
                    </span>
                  </div>

                  <div
                    className="code_heading"
                    onClick={() => {
                      setCheckOnDateChange({
                        ...checkOnDateChange,
                        startDate: false,
                        endDate: true,
                      });
                      setShowCalenderDeparture(!showCalenderDeparture);
                    }}
                  >
                    <span className="code-des d-inline-block text-truncate w-100">
                      {" "}
                      {selectedReturnDate !== ""
                        ? moment(selectedReturnDate).format("dddd")
                        : ""}{" "}
                    </span>
                  </div>
                  {showCalenderDeparture && (
                    <span
                      ref={calendarRef}
                      style={{ position: "absolute", zIndex: 9999 }}
                    >
                      <DateRange
                        minDate={
                          selectedDepartureDate !== "" &&
                          !checkOnDateChange.startDate
                            ? moment(selectedDepartureDate).toDate()
                            : checkOnDateChange.startDate
                            ? new Date(
                                today.getFullYear(),
                                today.getMonth(),
                                today.getDate()
                              )
                            : moment(date).toDate()
                        }
                        maxDate={maxDate}
                        onChange={(item) => {
                          if (checkOnDateChange.startDate) {
                            const startDate = moment(
                              item.selection.startDate
                            ).toDate();
                            setStateRoundTrip([
                              { ...stateRoundTrip[0], startDate },
                            ]);
                            setDateRangeStartDate(startDate);
                            setSelectedDepartureDate(
                              moment(startDate).format("DD MMM YYYY")
                            );
                          } else {
                            const endDate = moment(
                              item.selection.endDate
                            ).toDate();
                            setStateRoundTrip([
                              { ...stateRoundTrip[0], endDate },
                            ]);
                            setSelectedReturnDate(
                              moment(endDate).format("DD MMM YYYY")
                            );
                          }

                          setTimeout(() => {
                            if (nextInputRef.current) {
                              nextInputRef.current.focus();
                              nextInputRef.current.click();
                            }
                          }, 2000);

                          setShowCalenderDeparture(!showCalenderDeparture);
                          setCheckOnDateChange({
                            startDate: false,
                            endDate: false,
                          });
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={stateRoundTrip}
                      />
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="inline-text">
              <div className="border px-2 py-2 rounded-2 border-dark">
                <div className="d-flex align-items-center gap-2">
                  <span className="fw-bold fs-6">Nationality</span>
                </div>
                <div className="input_heading" title={nationalityValue.country}>
                  <input
                    ref={nextInputRef}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    type="search"
                    aria-label="Search"
                    className="fw-bold border-0 fs-4 w-100"
                    placeholder={"Nationality"}
                    value={nationalityValue.country}
                    onChange={(e) => {
                      setNationalityValue({
                        country: e.target.value,
                        code: nationalityValue.code,
                      });
                      setShowDropdown(true);
                    }}
                  />
                </div>
                <div className="code_heading">
                  <span
                    className="code-des d-inline-block text-truncate w-100"
                    style={{ textTransform: "uppercase" }}
                  >
                    {nationalityValue.code}
                  </span>
                </div>
              </div>
              {showDropdown && (
                <ul
                  style={{
                    margin: "2px",
                    border: "1px solid #ccc",
                    padding: "6px",
                    position: "absolute",
                    backgroundColor: "white",
                    width: "100%",
                    borderRadius: "5px",
                    zIndex: 999,
                    maxHeight: "20rem",
                    overflowY: "auto",
                  }}
                >
                  {filteredCountries.map(
                    ({ Country, Code, LogoPath }: any, idx: number) => {
                      return (
                        <li
                          key={Code}
                          style={{
                            listStyleType: "none",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            paddingTop: "5px",
                            cursor: "pointer",
                            // backgroundColor:
                            //   idx === highlightedIndex ? "#ffebeb" : "white",
                            // borderRadius: idx === highlightedIndex ? "10px" : "",
                            paddingLeft: "7px",
                          }}
                          onClick={() => {
                            setNationalityValue({
                              country: Country,
                              code: LogoPath,
                            });
                            setShowDropdown(false);
                          }}
                          tabIndex={0}
                        >
                          <span>{Country}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>

            <div className="inline-text">
              <button
                className="btn btn-login search_record"
                type="button"
                onClick={searchFlightHandler}
              >
                Search
              </button>
            </div>
          </div>
        </>
      </div>

      <div className="inline-form mt-3">
        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              directFlight: !additionalFlightInfo.directFlight,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.directFlight}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.directFlight
                ? "#ED3237"
                : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label" htmlFor="same-address">
            Direct Flights
          </label>
        </div>

        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              studentFare: !additionalFlightInfo.studentFare,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.studentFare}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.studentFare
                ? "#ED3237"
                : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label" htmlFor="same-address3">
            Student Fare
          </label>
        </div>

        <div
          className="form-check"
          onClick={() =>
            setAdditionalFlightInfo({
              ...additionalFlightInfo,
              nearby: !additionalFlightInfo.nearby,
            })
          }
        >
          <input
            type="checkbox"
            className="form-check-input"
            checked={additionalFlightInfo.nearby}
            style={{
              border: "0.5px solid #505050",
              backgroundColor: additionalFlightInfo.nearby ? "#ED3237" : "",
            }}
            onChange={() => {}}
          />
          <label className="form-check-label" htmlFor="same-address">
            Nearby Airports
          </label>
        </div>
      </div>

      {isFlightLoading && <AirplaneLoader />}
    </>
  );
};
