import { Key } from "react";
import { fullNameRegex, panCardRegex } from "../../utils/validation";

interface HotelPassengerDetailsFormProps {
  countries: any;
  passenger: any;
  roomIndex: number;
  submittedForm: boolean;
  handleInputChange: any;
}

export const HotelPassengerDetailsForm = ({
  countries,
  passenger,
  roomIndex,
  submittedForm,
  handleInputChange,
}: HotelPassengerDetailsFormProps) => {
  const getUserSearchedData = localStorage.getItem("hotel-search");
  const parsedSearchedData = JSON.parse(String(getUserSearchedData));
  return (
    <div className="card card_checkout">
      <div className="card-body">
        <div id="">
          <div className="row">
            <div className="col-sm-6 col-md-6 pb-4 checkout-col">
              <div className="card-content">
                <h5>Room {roomIndex + 1}</h5>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 pb-4 checkout-col">
              <div className="card-content">
                {/* <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Double_bed6"
                    />
                    <label
                      className="form-check-label label_text"
                      htmlFor="Double_bed6"
                    >
                      Enter the data for all passengers
                    </label>
                  </div> */}
              </div>
            </div>
            {passenger?.map((passengerValue: any, passengerIndex: number) => (
              <>
                <h5 style={{ fontWeight: "600" }}>
                  {passengerValue.age === 20 ? "Adult" : "Child"}
                </h5>
                <div className="col-sm-6 col-md-6 pb-4 checkout-col">
                  <div className="card-content">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label label_text"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      style={{ textTransform: "uppercase" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="First Name"
                      value={passengerValue.firstName}
                      onChange={(event) =>
                        handleInputChange(
                          event,
                          roomIndex,
                          passengerIndex,
                          "firstName"
                        )
                      }
                    />
                    {submittedForm && passengerValue.firstName === "" && (
                      <div
                        className="error-text"
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "8px",
                          paddingTop: "3px",
                        }}
                      >
                        Please Enter First Name
                      </div>
                    )}
                    {submittedForm &&
                      passengerValue.firstName !== "" &&
                      !fullNameRegex.test(passengerValue.firstName) && (
                        <div
                          className="error-text"
                          style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "8px",
                            paddingTop: "3px",
                          }}
                        >
                          Please Enter Valid First Name
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 pb-4 checkout-col">
                  <div className="card-content">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label label_text"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      style={{ textTransform: "uppercase" }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Last Name"
                      value={passengerValue.lastName}
                      onChange={(event) =>
                        handleInputChange(
                          event,
                          roomIndex,
                          passengerIndex,
                          "lastName"
                        )
                      }
                    />
                    {submittedForm && passengerValue.lastName === "" && (
                      <div
                        className="error-text"
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "8px",
                          paddingTop: "3px",
                        }}
                      >
                        Please Enter Last Name
                      </div>
                    )}
                    {submittedForm &&
                      passengerValue.lastName !== "" &&
                      !fullNameRegex.test(passengerValue.lastName) && (
                        <div
                          className="error-text"
                          style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "8px",
                            paddingTop: "3px",
                          }}
                        >
                          Please Enter Valid Last Name
                        </div>
                      )}
                  </div>
                </div>

                {parsedSearchedData.nationality === "India" && (
                  <div className="col-sm-6 col-md-6 pb-4 checkout-col">
                    <div className="card-content">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label label_text tooltip"
                      >
                        Pan Card
                        <span
                          style={{
                            padding: "0 8px",
                            margin: "10px",
                            border: "1px solid #e53d30",
                            borderRadius: "50%",
                            fontWeight: "700",
                            color: "#e53d30",
                            fontSize: "14px",
                          }}
                        >
                          i
                        </span>
                        <span className="tooltiptext">
                          RBI mandates collection of PAN for bookings covered
                          under the LRS which includes expenditure on foreign
                          travel. PAN will be fetched from your user profile,
                          previous bookings or external partners. T&C
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Pan Card"
                        value={passengerValue.panCard}
                        onChange={(event) =>
                          handleInputChange(
                            event,
                            roomIndex,
                            passengerIndex,
                            "panCard"
                          )
                        }
                      />
                      {submittedForm && passengerValue.panCard === "" && (
                        <div
                          className="error-text"
                          style={{
                            color: "red",
                            fontSize: "small",
                            marginLeft: "8px",
                            paddingTop: "3px",
                          }}
                        >
                          Please Enter Pan No.
                        </div>
                      )}
                      <span style={{ fontSize: "small", padding: "4px" }}>
                        You can claim TCS in IT filing
                      </span>
                      {submittedForm &&
                        passengerValue.panCard !== "" &&
                        !panCardRegex.test(passengerValue.panCard) && (
                          <div
                            className="error-text"
                            style={{
                              color: "red",
                              fontSize: "small",
                              marginLeft: "8px",
                              paddingTop: "3px",
                            }}
                          >
                            Please Enter Valid Pan No.
                          </div>
                        )}
                    </div>
                  </div>
                )}

                <div className="col-sm-6 col-md-6 pb-4">
                  {/* <div className="card-content text-left1">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Nationality
                    </label>
                    <select
                      value={passengerValue.nationality}
                      placeholder="Select nationality"
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(event) =>
                        handleInputChange(
                          event,
                          roomIndex,
                          passengerIndex,
                          "nationality"
                        )
                      }
                    >
                      <option value="">Select</option>
                      {countries.map(
                        (
                          country: { defaultName: string },
                          index: Key | null | undefined
                        ) => (
                          <option key={index} value={country.defaultName}>
                            {country.defaultName}
                          </option>
                        )
                      )}
                    </select>
                    {submittedForm && passengerValue.nationality === "" && (
                      <div
                        className="error-text"
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "8px",
                          paddingTop: "3px",
                        }}
                      >
                        Please Select Nationality
                      </div>
                    )}
                  </div> */}
                </div>

                {passengerIndex + 1 !== passengerValue.length - 1 && <hr />}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
