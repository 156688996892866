import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import starImg from "../../assets/images/star.png";
import EnvelopeSimple from "../../assets/images/EnvelopeSimple.png";
import CaretDown from "../../assets/images/CaretDown.png";
import MagnifyingGlass from "../../assets/images/MagnifyingGlass.svg";
import buildingImg from "../../assets/images/building.png";
import xImg from "../../assets/images/X.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import LazyImage from "../../pages/LazyImage";
import Accordion from "../../components/Accordion/Accordion";
import {
  emailRegex,
  fullNameRegex,
  mobileRegex,
  panCardRegex,
} from "../../utils/validation";
import {
  hotelBooking,
  upToDateBooking,
  validatePanCard,
} from "../../api/HotelAPI";
import { HotelPassengerDetailsForm } from "./HotelPassengerDetailsForm";
import { toast } from "react-toastify";
import HotelLoader from "../../components/loaders/HotelLoader";
import moment from "moment";

const initialPassengerObj = {
  firstName: "",
  lastName: "",
  nationality: "",
  panCard: "",
};

export const PassengerHotel = () => {
  const hotelJSON = localStorage.getItem("hotel-search");
  const hotelStorage = hotelJSON ? JSON.parse(hotelJSON) : null;

  const [submittedForm, setSubmitted] = useState(false);
  const [requestAccommodation, setRequestAccommodation] = useState(false);

  const [countries, setCountries] = useState([]);
  const [accordionData, setAccordionData] = useState<any[]>([]);
  const [checkoutData, setCheckoutData] = useState<any>();
  const [optionRefID, setOptionRefID] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [phoneCodeValue, setPhoneCodeValue] = useState("91");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [searchText, setSearchText] = useState("");
  const [passengersArray, setPassengersArray] = useState([initialPassengerObj]);

  const [hotelLoader, setHotelLoader] = useState(true);
  const [leadPAssenger, setleadPAssenger] = useState(true);

  const getUserSearchedData = localStorage.getItem("hotel-search");
  const parsedSearchedData = JSON.parse(String(getUserSearchedData));

  const navigate = useNavigate();

  useEffect(() => {
    const getResponseCountryCode = () => {
      axios
        .get("https://www.makemytrip.com/api/countryList")
        .then((response) => {
          setCountries(response.data);
        })
        .catch((err) => console.log(err));
    };
    getResponseCountryCode();
  }, []);

  useEffect(() => {
    const hotelAdminJSON = localStorage.getItem("optionRef-hotel");
    const hotelAdmin = hotelJSON ? JSON.parse(String(hotelAdminJSON)) : null;
    if (hotelAdmin) {
      upToDateBooking({
        optionRefId: String(hotelAdmin?.selectedOptionRef),
        client: hotelAdmin.clientData.name,
        auditTransactions: true,
        context: String(hotelAdmin?.supplierData?.code),
        testMode: false,
        timeout: 5000,
      })
        .then((res) => {
          if (res.data.hotelX.quote.optionQuote) {
            setOptionRefID(res.data.hotelX.quote.optionQuote.optionRefId);
            setCheckoutData(res.data.hotelX.quote.optionQuote);
          } else {
            setOptionRefID("");
          }
          setHotelLoader(false);
        })
        .catch((error) =>
          toast.error(error.response.data.message, {
            toastId: "hotel-checkout-up-date-booking",
          })
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrencySymbol = (currencyData: any) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyData?.currency,
    });
    const parts = formatter.formatToParts(currencyData.gross);

    for (const part of parts) {
      if (part.type === "currency") {
        return part.value;
      }
    }

    return null;
  };

  useEffect(() => {
    if (checkoutData) {
      const updatedAccordionData = [
        {
          title: "Hotel Taxes",
          subtitle: `${getCurrencySymbol(checkoutData.price)} ${
            checkoutData.price.gross
          }`,
          content: "Hotel Taxes",
          contentsecond: `${getCurrencySymbol(checkoutData.price)} ${
            checkoutData.price.gross
          }`,
        },
        {
          title: "Total Amount",
          subtitle: `${getCurrencySymbol(checkoutData.price)} ${
            checkoutData.price.gross
          }`,
        },
      ];

      setAccordionData(updatedAccordionData);
    }
  }, [checkoutData]);

  useEffect(() => {
    const updatedPassengersArray = hotelStorage.rooms.map(
      (room: any, index: any) => {
        const paxes = [];

        if (leadPAssenger && index === 0) {
          paxes.push({
            ...initialPassengerObj,
            age: 20,
          });
        } else {
          for (let i = 0; i < room.adults; i++) {
            paxes.push({
              ...initialPassengerObj,
              age: 20,
            });
          }

          for (let i = 0; i < room.children; i++) {
            paxes.push({
              ...initialPassengerObj,
              age: 3,
            });
          }
        }

        return {
          occupancyRefId: index + 1,
          paxes: paxes,
        };
      }
    );

    setPassengersArray(updatedPassengersArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadPAssenger]);

  const handleInputChange = (
    event: { target: { value: any } },
    roomIndex: string | number,
    passengerIndex: string | number,
    field: string | number
  ) => {
    const updatedPassengersArray: any = [...passengersArray];

    updatedPassengersArray[roomIndex].paxes[passengerIndex][field] =
      event.target.value;

    setPassengersArray(updatedPassengersArray);
  };

  const totalAdults = hotelStorage?.rooms.reduce(
    (total: any, room: { adults: any }) => total + room.adults,
    0
  );
  const totalChildren = hotelStorage?.rooms.reduce(
    (total: any, room: { children: any }) => total + room.children,
    0
  );

  const summaryGuestText = `${totalAdults} Adult${
    totalAdults > 1 ? "s" : ""
  }, ${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setSubmitted(true);

    let hasErrors = false;

    if (mobileNumber.trim() === "") {
      setMobileNumberError("Mobile No. is required");
      hasErrors = true;
    } else if (!mobileRegex.test(mobileNumber)) {
      setMobileNumberError("Please enter valid mobile No.");
      hasErrors = true;
    } else {
      setMobileNumberError("");
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      hasErrors = true;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter valid Email");
      hasErrors = true;
    } else {
      setEmailError("");
    }

    passengersArray.forEach((rooms: any) =>
      rooms.paxes?.forEach(
        (passengerValue: {
          firstName: string;
          lastName: string;
          nationality: string;
          panCard: string;
        }) => {
          if (
            passengerValue.firstName.trim() === "" ||
            !fullNameRegex.test(passengerValue.firstName) ||
            passengerValue.lastName === "" ||
            !fullNameRegex.test(passengerValue.lastName) ||
            parsedSearchedData.nationality === "" ||
            (parsedSearchedData.nationality === "India" &&
              passengerValue.panCard === "") ||
            (parsedSearchedData.nationality === "India" &&
              !panCardRegex.test(passengerValue.panCard))
          ) {
            hasErrors = true;
          }
        }
      )
    );
    let promises: any[] = [];

    passengersArray.forEach((rooms: any) =>
      rooms.paxes?.forEach(
        (passengerValue: {
          firstName: string;
          lastName: string;
          nationality: string;
          panCard: string;
        }) => {
          const promise = validatePanCard({
            PanNumber: passengerValue.panCard,
            FirstName: passengerValue.firstName,
            LastName: passengerValue.lastName,
          })
            .then((response) => {
              if (
                passengerValue.panCard !== "" &&
                response.data.Code !== "200"
              ) {
                hasErrors = true;
                toast.error(response.data.Msg[0], {
                  toastId: "hotel-11",
                });
              }
            })
            .catch((err) => {
              hasErrors = true;
              console.log(err);
            });

          promises.push(promise);
        }
      )
    );

    Promise.all(promises)
      .then(() => {
        if (!hasErrors) {
          handleBooking();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleBooking = async () => {
    const getFirstObjectPassenger = passengersArray.map((room: any) => ({
      paxes: {
        name: room.paxes[0].firstName,
        surname: room.paxes[0].lastName,
      },
    }));

    await hotelBooking({
      optionRefId: optionRefID,
      deltaPriceAmount: 10,
      deltaPricePercent: 10,
      holderName: getFirstObjectPassenger[0].paxes.name,
      holderSurname: getFirstObjectPassenger[0].paxes.surname,
      remarks:
        "In this valuation, the option price has changed to check the correct treatment of the price changes between the different & calls.",
      rooms: passengersArray.map((room: any) => ({
        occupancyRefId: room.occupancyRefId,
        paxes: room.paxes.map((passenger: any) => ({
          name: passenger.firstName,
          surname: passenger.lastName,
          age: passenger.age,
        })),
      })),
    })
      .then((res) => {
        localStorage.setItem(
          "booking-hotel",
          JSON.stringify(res.data.hotelX.book.booking.reference.bookingID)
        );
        navigate("/hotel-confirmation");
      })
      .catch((error) =>
        toast.error(error.response.data.message, {
          toastId: "hotel-checkout-book-hotel",
        })
      );
  };

  return (
    <AppLayout>
      <main className="container">
        <div className="tabbing-bar hotel-bar">
          <div className="tab-content" id="nav-tabContent">
            <div className="row">
              <div className="col-sm-8">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="header_passenger px-2">
                      <div className="left-passenger">
                        <h3 className="h3-bold">
                          Ginger Goa, {hotelStorage.destinationName}
                        </h3>
                        <span className="">
                          <LazyImage
                            className="img-fluid"
                            src={starImg}
                            alt=""
                          />
                          {/* <button className="passenger-btn">
                            {" "}
                            Couple Friendly{" "}
                          </button> */}
                        </span>
                        <div className="content-passenger pt-2">
                          <span className="">
                            Plot No. 37, 38, Near Passport Office, SGO Complex,
                            EDC, Pato, Goa, India
                          </span>
                        </div>
                      </div>
                      <div className="right-passenger">
                        <LazyImage
                          className="img-fluid"
                          src={buildingImg}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <section className="cheapest_section" id="cheapest_section">
                  {/* First card */}
                  <div className="card card_checkout">
                    <div className="card-body">
                      <div className="main_content res_main_block">
                        <div className="card_block res_block">
                          <div className="card-content">
                            <p className="card-text mb-2 fw-bold">CHECK IN</p>
                            <div className="passenger-text">
                              <h3 className="block_heading">
                                {moment(hotelStorage.checkInDate).format(
                                  "Do MMM YYYY"
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="card_block res_block">
                          <div className="card-content">
                            <p className="card-text mb-2 fw-bold">CHECK OUT</p>
                            <div className="passenger-text">
                              <h3 className="block_heading">
                                {" "}
                                {moment(hotelStorage.checkOutDate).format(
                                  "Do MMM YYYY"
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="card_block res_block">
                          <div className="card-content">
                            <p className="card-text mb-2 fw-bold">NIGHTS</p>
                            <div className="passenger-text">
                              <h3 className="block_heading">
                                {hotelStorage.numberOfNights} Night
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="card_block res_block">
                          <div className="card-content">
                            <p className="card-text mb-2 fw-bold">ROOMS</p>
                            <div className="passenger-text">
                              <h3 className="block_heading">
                                {" "}
                                {hotelStorage.rooms.length} Room
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="card_block res_block">
                          <div className="card-content">
                            <p className="card-text mb-2 fw-bold">GUESTS</p>
                            <div className="passenger-text">
                              <h3 className="block_heading">
                                {summaryGuestText}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row px-0">
                    <div className="col-sm-12">
                      <div className="py-3 mb-2">
                        <div className="card-content">
                          <h3 className="h3-bold">Luxe Twin Room</h3>
                        </div>
                        <ul className="px-3 mt-2">
                          <li className="passenger_text"> Room Only</li>
                          <li className="passenger_text">
                            {" "}
                            No meals included{" "}
                          </li>
                          <li className="passenger_text">
                            Free Welcome Drink on Arrival{" "}
                          </li>
                        </ul>
                        <div className="header_section res_hide">
                          <div
                            className="passenger-text image_body d-flex pb-0"
                            style={{ marginLeft: "0px" }}
                          >
                            <LazyImage src={xImg} alt="" />
                            <span className="text-left text_body">
                              {" "}
                              Non-Refundable
                            </span>
                          </div>
                          <div className="passenger-text">
                            <p className="card-text mb-2">|</p>
                            <p className="card-text mb-0 passenger_text">
                              On Cancellation, You will not get any refund
                            </p>
                          </div>
                          <b className="passenger_text">
                            {" "}
                            Cancellation policy details{" "}
                          </b>
                        </div>
                        <div className="res_show pt-3">
                          <b className="passenger_text">
                            {" "}
                            Cancellation policy details{" "}
                          </b>
                          <div className="image_body d-flex pb-0">
                            <LazyImage src={xImg} alt="" />
                            <span className="text-left text_body">
                              {" "}
                              Non-Refundable
                            </span>
                          </div>
                          <div className="passenger_text">
                            <p className="card-text mb-2 passenger_text">
                              On Cancellation, You will not get any refund
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row px-0">
                    <div className="col-sm-12">
                      <div className="py-3 mb-2">
                        <div className="card-content">
                          <h3 className="h3-bold fw-bold">
                            Remarks (Hotel remarks if any)
                          </h3>
                          <ul className="confirmation-content px-3">
                            <li className="">
                              Check-in hour from 14:00 to 12:00.{" "}
                            </li>
                            <li className="">Car park Yes with charges. </li>
                            <li className="">Deposit on arrival. </li>
                            <li className="">
                              Car park YES (with additional debit notes).{" "}
                            </li>
                            <li className="">Check-in hour 14:00 - 12:00. </li>
                            <li className="">Deposit on arrival. </li>
                            <li className="">
                              Identification card at arrival.{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Accommodation  */}
                  <div
                    style={{
                      display: "flex",
                      gap: "9px",
                      fontSize: "16px",
                      marginBottom: "24px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="request-accommodation"
                      style={{ height: "20px", width: "15px" }}
                      onChange={() =>
                        setRequestAccommodation(!requestAccommodation)
                      }
                    />
                    <label
                      htmlFor="request-accommodation"
                      style={{ fontWeight: "600" }}
                    >
                      Add Request for the accommodation and comments
                    </label>
                  </div>

                  {requestAccommodation && (
                    <>
                      <h3 className="h3-bold">
                        Requests for the accommodation
                      </h3>
                      {/* Adult-1 */}
                      <div className="card-row">
                        <div className="card-body px-0 pt-0">
                          <p
                            className="card-passenger mb-3 passenger_text"
                            id="adult_1"
                          >
                            For reference only, Maashree cannot guarantee them
                          </p>
                          <div className="row_hide">
                            <div className="row">
                              <div className="col-sm-3 col-md-3 pb-4 checkout-col">
                                <div className="card-content">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Double_bed_1"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Double_bed_1"
                                    >
                                      Double bed
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Two_beds_1"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Two_beds_1"
                                    >
                                      Smoking room
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3 col-md-3 pb-4 checkout-col">
                                <div className="card-content">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Double_bed_2"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Double_bed_2"
                                    >
                                      Non-smoking room
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Two_beds_2"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Two_beds_2"
                                    >
                                      Adjacent rooms
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3 col-md-3 pb-4 checkout-col">
                                <div className="card-content">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Double_bed_3"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Double_bed_3"
                                    >
                                      Two beds
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Two_beds_3"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Two_beds_3"
                                    >
                                      Late Arrival
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="client_without_voucher_3"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="client_without_voucher_3"
                                    >
                                      Ground floor
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3 col-md-3 pb-4 checkout-col">
                                <div className="card-content">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Double_bed_4"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Double_bed_4"
                                    >
                                      Except ground floor
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="Two_beds_4"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="Two_beds_4"
                                    >
                                      Honeymoon
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="client_without_voucher_4"
                                    />
                                    <label
                                      className="form-check-label check_label"
                                      htmlFor="client_without_voucher_4"
                                    >
                                      Cot
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <h3 className="h3-bold">Comments</h3>
                          <span
                            style={{
                              fontSize: "small",
                              color: "#d04000",
                            }}
                          >
                            (You can only add upto 1000 characters)
                          </span>
                          <div className="form-check mb-3 px-0">
                            <textarea
                              id=""
                              name=""
                              maxLength={1000}
                              rows={5}
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Passenger */}
                  <h3 className="h3-bold">Passenger Details</h3>
                  <div
                    className="form-check"
                    style={{
                      position: "relative",
                      zIndex: "99999",
                      width: "100%",
                      textAlign: "end",
                      display: "flex",
                      justifyContent: "end",
                      gap: "8px",
                      padding: "14px",
                      fontSize: "17px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="pass-check"
                      defaultChecked={true}
                      onChange={() => setleadPAssenger(!leadPAssenger)}
                    />
                    <label
                      className="form-check-label label_text"
                      htmlFor="pass-check"
                    >
                      Enter the lead passenger data only
                    </label>
                  </div>
                  {passengersArray?.map((room: any, roomIndex: number) => (
                    <HotelPassengerDetailsForm
                      countries={countries}
                      roomIndex={roomIndex}
                      passenger={room.paxes}
                      submittedForm={submittedForm}
                      handleInputChange={handleInputChange}
                    />
                  ))}
                </section>
                <div className="form-group">
                  <h3 className="h3-bold">Contact Information </h3>
                  <label className="email_text">
                    <span>
                      <LazyImage
                        className="img-fluid"
                        src={EnvelopeSimple}
                        alt=""
                      />
                    </span>{" "}
                    Your booking information will be sent here..
                  </label>
                </div>
                <div className="row pb-3">
                  <div className="col-sm-6 my-2 mobile-listing">
                    <div className="mobile-select">
                      <div className="mobile-data-list">
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            href="/"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="country-code">
                              +{phoneCodeValue}
                              <LazyImage
                                src={CaretDown}
                                className="caret"
                                alt=""
                              />{" "}
                            </span>
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <div className="country-code-box">
                                <LazyImage
                                  src={MagnifyingGlass}
                                  className="search-icon"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Country or Code"
                                  value={searchText}
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                />
                              </div>
                            </li>
                            {countries
                              .filter(({ name, phoneCode, nameCode }) =>
                                `${name} ${phoneCode} ${nameCode}`
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              )
                              .map(({ name, phoneCode, nameCode }, idx) => (
                                <li
                                  key={`phone-code-${phoneCode + idx}`}
                                  onClick={() => setPhoneCodeValue(phoneCode)}
                                >
                                  <span className="dropdown-item">
                                    <span>
                                      {" "}
                                      {name} (+{phoneCode})
                                    </span>{" "}
                                    <span
                                      className="code-country"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      {nameCode}{" "}
                                    </span>
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Add your mobile no."
                        value={mobileNumber}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                        }}
                      />
                    </div>
                    {mobileNumberError !== "" && (
                      <div
                        className="error-text"
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "8px",
                          paddingTop: "3px",
                        }}
                      >
                        {mobileNumberError}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 my-2">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && (
                      <div
                        className="error-text"
                        style={{
                          color: "red",
                          fontSize: "small",
                          marginLeft: "8px",
                          paddingTop: "3px",
                        }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Side bar section right -------------------------------------*/}
              <div className="col-sm-4  sticky-top">
                <div className="confirmation-box">
                  <div className="confirm-box">
                    <h3 className="h3-bold">Price Break-up</h3>
                    {checkoutData !== undefined &&
                      accordionData.map(
                        ({ title, subtitle, content, contentsecond }) => (
                          <Accordion
                            key={`acc-${title}`}
                            title={title}
                            subtitle={subtitle}
                            content={content}
                            contentsecond={contentsecond}
                          />
                        )
                      )}
                  </div>
                </div>
                {/* <div className="left-side left-side_1 mt-4">
                  <h3 className="h3-bold">Coupon Codes</h3>
                  <div className="block block_right">
                    <p className="passenger_text py-2 mb-0">
                      No coupon codes applicable for this property.{" "}
                    </p>
                    <ul>
                      <li className="py-1">
                        <div className="input-group mb-0">
                          <input
                            type="text"
                            className="form-control backg-code"
                            aria-label="Enter Promo code"
                            value="MMT Gift Cards can be applied at payment step"
                            onChange={() => {}}
                          />
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="input-group mb-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Promo code"
                            aria-label="Enter Promo code"
                          />
                          <span className="input-group-text input_group">
                            <LazyImage className="img-fluid" src={checkImg} alt="" />
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className="inline-text payment_btn">
                <button
                  className="btn btn-login"
                  type="button"
                  onClick={(e) => handleSubmit(e)}
                >
                  Continue to payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <TalkToAgents />
      <Footer />
      {hotelLoader && <HotelLoader />}
    </AppLayout>
  );
};
