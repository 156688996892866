import status from "../../../assets/images/status.png";
import Trash from "../../../assets/images/Trash.png";
import LazyImage from "../../../pages/LazyImage";
import { useEffect, useState } from "react";
import {
  createSlabs,
  deleteSlabs,
  slabsList,
  updateSlabsInAdmin,
} from "../../../api/AdminAPI";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface SlabsArray {
  flightSlabs: {
    minAmount: number;
    maxAmount: number;
    commissionRate: number;
    addonRate: number;
    _id: string;
  }[];
  hotelSlabs: {
    minAmount: number;
    maxAmount: number;
    commissionRate: number;
    addonRate: number;
    _id: string;
  }[];
}

export const HotelCommissionsSlabs = () => {
  const [editSlabs, setEditSlabs] = useState<any>();
  const [isUpdated, setIsUpdated] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [slabError, setSlabError] = useState({
    error: true,
    message: "",
  });

  const [slabsData, setSlabsData] = useState({
    minAmount: 0,
    maxAmount: 0,
    commissionRate: 0,
    addonRate: 0,
    _id: "",
  });
  const [initialSlabsData, setInitialSlabsData] = useState({
    minAmount: 0,
    maxAmount: 0,
    commissionRate: 0,
    addonRate: 0,
  });

  const [adminSlabsList, setAdminSlabsList] = useState<
    SlabsArray | undefined
  >();

  useEffect(() => {
    slabsList()
      .then((res) => {
        setAdminSlabsList(res.data);
        setParentId(res.data._id);
      })
      .catch((err) => console.log(err));

    setIsUpdated(false);
  }, [isUpdated]);

  useEffect(() => {
    if (!editSlabs) {
      setSlabsData({
        minAmount: 0,
        maxAmount: 0,
        commissionRate: 0,
        addonRate: 0,
        _id: "",
      });
    }
  }, [editSlabs]);

  const addSlabsData = () => {
    const slabsOriginalList = {
      minAmount: initialSlabsData.minAmount,
      maxAmount: initialSlabsData.maxAmount,
      commissionRate: initialSlabsData.commissionRate,
      addonRate: initialSlabsData.addonRate,
      slabType: 2,
      document_id: String(parentId),
    };

    createSlabs(slabsOriginalList)
      .then((res) => {
        setIsUpdated(true);
        toast.success("successfully updated Data", {
          toastId: "slab-success",
        });
      })
      .catch((err) =>
        toast.error("Error in updating Commission Data", {
          toastId: "slab-data",
        })
      );

    setInitialSlabsData({
      minAmount: 0,
      maxAmount: 0,
      commissionRate: 0,
      addonRate: 0,
    });
  };

  const deleteSlabData = () => {
    if (adminSlabsList && adminSlabsList && adminSlabsList?.hotelSlabs) {
      deleteSlabs({
        slabType: 2,
        document_id: String(parentId),
        _id: editSlabs._id,
      })
        .then((res) => {
          setIsUpdated(true);
          toast.success("successfully Deleted Commission Slab Data", {
            toastId: "slab-success",
          });
        })
        .catch((err) =>
          toast.error("Error in Deleting Commission Slab Data", {
            toastId: "slab-data",
          })
        );
    }
  };

  const saveSlabsData = () => {
    const slabsOriginalList = {
      minAmount: slabsData.minAmount,
      maxAmount: slabsData.maxAmount,
      commissionRate: slabsData.commissionRate,
      addonRate: slabsData.addonRate,
      _id: slabsData._id,
      slabType: 2,
    };

    updateSlabsInAdmin(slabsOriginalList)
      .then((res) => {
        setIsUpdated(true);
        toast.success("successfully updated Data", {
          toastId: "slab-success",
        });
      })
      .catch((err) =>
        toast.error("Error in updating Commission Data", {
          toastId: "slab-data",
        })
      );
  };

  const setMaxLimitHandler = () => {
    const latObj =
      adminSlabsList?.hotelSlabs[adminSlabsList?.hotelSlabs.length - 1];

    if (latObj) {
      setMaxAmount(latObj.maxAmount);
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Commissions Slabs</h2>
          <button
            style={{
              backgroundColor: "#ed3237",
              color: "white",
              border: "none",
              borderRadius: "11px",
              height: "36px",
              padding: "7px",
            }}
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#addSlab"
            onClick={() => setMaxLimitHandler()}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ color: "#ffffff", marginRight: "8px" }}
            />
            Add Commission
          </button>
        </div>

        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Min Amount</th>
              <th>Max Amount</th>
              <th>Commission Rate</th>
              <th>Addon Rate</th>
              <th style={{ width: "18%" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {adminSlabsList?.hotelSlabs.map((item: any) => (
              <tr key={item._id}>
                <td>{item.minAmount}</td>
                <td>{item.maxAmount === null ? "No Limit" : item.maxAmount}</td>
                <td>{item.commissionRate}</td>
                <td>{item.addonRate}</td>
                <td
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="card-content">
                    <button
                      className="btn btn-refund-status"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#payment"
                      onClick={() => {
                        setEditSlabs(item);
                        setSlabsData(item);
                      }}
                    >
                      <span className="mx-2">
                        <LazyImage
                          className="cross_imatge"
                          src={status}
                          alt=""
                        />
                      </span>
                      Edit
                    </button>
                  </div>
                  <div className="card-content">
                    {" "}
                    <button
                      className="btn btn-refund-status"
                      style={{ border: "1px solid red" }}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteSlab"
                      onClick={() => {
                        setEditSlabs(item);
                        setSlabsData(item);
                      }}
                    >
                      <span className="mx-2">
                        <LazyImage src={Trash} alt="" />
                      </span>
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="payment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="paymentLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="paymentLabel">
                Edit Slab
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  setSlabsData({
                    minAmount: 0,
                    maxAmount: 0,
                    commissionRate: 0,
                    addonRate: 0,
                    _id: "",
                  })
                }
              ></button>
            </div>
            {editSlabs && (
              <div className="modal-body">
                <div className="block">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                      Minimum Amount :
                    </h3>
                    <h3 className="block_heading mb-0">
                      <input
                        type="string"
                        pattern="[0-9]*"
                        value={slabsData.minAmount}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const isValidInput = /^[0-9]*$/.test(inputVal);

                          if (isValidInput) {
                            setSlabsData({
                              ...slabsData,
                              minAmount: Number(e.target.value),
                            });
                          }
                        }}
                      />
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                      Maximum Amount :
                    </h3>
                    <h3 className="block_heading mb-0">
                      <input
                        type="string"
                        pattern="[0-9]*"
                        value={slabsData.maxAmount}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const isValidInput = /^[0-9]*$/.test(inputVal);

                          if (isValidInput) {
                            setSlabsData({
                              ...slabsData,
                              maxAmount: Number(e.target.value),
                            });
                          }
                        }}
                      />
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                      Commission Rate :
                    </h3>
                    <h3 className="block_heading mb-0">
                      <input
                        type="string"
                        pattern="[0-9]*"
                        value={slabsData.commissionRate}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const isValidInput = /^[0-9]*$/.test(inputVal);

                          if (isValidInput) {
                            setSlabsData({
                              ...slabsData,
                              commissionRate: Number(e.target.value),
                            });
                          }
                        }}
                      />
                    </h3>
                  </div>

                  <div className="d-flex align-items-center gap-2 mb-2">
                    <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                      Addon Rate :
                    </h3>

                    <h3 className="block_heading mb-0">
                      <input
                        type="string"
                        pattern="[0-9]*"
                        value={slabsData.addonRate}
                        onChange={(e) => {
                          const inputVal = e.target.value;
                          const isValidInput = /^[0-9]*$/.test(inputVal);

                          if (isValidInput) {
                            setSlabsData({
                              ...slabsData,
                              addonRate: Number(inputVal),
                            });
                          }
                        }}
                      />
                    </h3>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
                onClick={() => saveSlabsData()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* // Add slabs  */}
      <div
        className="modal fade"
        id="addSlab"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="addSlab"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="addSlab">
                Add Slab
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setInitialSlabsData({
                    minAmount: 0,
                    maxAmount: 0,
                    commissionRate: 0,
                    addonRate: 0,
                  });

                  setSlabError({
                    error: false,
                    message: "",
                  });
                }}
              ></button>
            </div>

            <div className="modal-body">
              <div className="block">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                    Minimum Amount :
                  </h3>
                  <h3 className="block_heading mb-0">
                    <input
                      type="string"
                      pattern="[0-9]*"
                      value={initialSlabsData.minAmount}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputVal);

                        if (isValidInput) {
                          if (Number(e.target.value) < maxAmount) {
                            setSlabError({
                              error: true,
                              message: `Minimum Amount Cannot be smaller than ${maxAmount}`,
                            });
                          } else {
                            setSlabError({
                              error: false,
                              message: "",
                            });
                          }

                          setInitialSlabsData({
                            ...initialSlabsData,
                            minAmount: Number(e.target.value),
                          });
                        }
                      }}
                    />
                  </h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                    Maximum Amount :
                  </h3>
                  <h3 className="block_heading mb-0">
                    <input
                      type="string"
                      pattern="[0-9]*"
                      value={initialSlabsData.maxAmount}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputVal);

                        if (isValidInput) {
                          if (
                            Number(e.target.value) < initialSlabsData.minAmount
                          ) {
                            setSlabError({
                              error: true,
                              message: `Maximum Amount Cannot be smaller than ${initialSlabsData.minAmount}`,
                            });
                          } else {
                            setSlabError({
                              error: false,
                              message: "",
                            });
                          }

                          setInitialSlabsData({
                            ...initialSlabsData,
                            maxAmount: Number(e.target.value),
                          });
                        }
                      }}
                    />
                  </h3>
                </div>

                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                    Commission Rate :
                  </h3>
                  <h3 className="block_heading mb-0">
                    <input
                      type="string"
                      pattern="[0-9]*"
                      value={initialSlabsData.commissionRate}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputVal);

                        if (isValidInput) {
                          setInitialSlabsData({
                            ...initialSlabsData,
                            commissionRate: Number(e.target.value),
                          });
                        }
                      }}
                    />
                  </h3>
                </div>

                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0" style={{ width: "45%" }}>
                    Addon Rate :
                  </h3>

                  <h3 className="block_heading mb-0">
                    <input
                      type="string"
                      pattern="[0-9]*"
                      value={initialSlabsData.addonRate}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        const isValidInput = /^[0-9]*$/.test(inputVal);

                        if (isValidInput) {
                          setInitialSlabsData({
                            ...initialSlabsData,
                            addonRate: Number(inputVal),
                          });
                        }
                      }}
                    />
                  </h3>
                </div>
              </div>
            </div>

            <span style={{ color: "red" }}>{slabError.message}</span>

            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
                onClick={() => addSlabsData()}
                disabled={slabError.error}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Prompt Modal  */}
      <div
        className="modal fade"
        id="deleteSlab"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="deleteSlab"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteSlab">
                Delete Slab
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="block">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3
                    className="block_heading mb-0"
                    style={{ fontSize: "larger" }}
                  >
                    Are you sure you want to delete this commission slab ?
                  </h3>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn search_record"
                data-bs-dismiss="modal"
                style={{ border: "1px solid" }}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
                onClick={() => deleteSlabData()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
