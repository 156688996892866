import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import arrowDown from "../../assets/images/FileArrowDown.png";
import hotelImage from "../../assets/images/image 73.png";
import starImg from "../../assets/images/star.png";
import Accordion from "../../components/Accordion/Accordion";
import LazyImage from "../../pages/LazyImage";
import { useEffect, useState } from "react";
import { bookingDetailsConfirmation } from "../../api/HotelAPI";
import { accordionData } from "../../components/Accordion/accordionData";
import moment from "moment";

export const HotelConfirmation = () => {
  const [bookingDetails, setBookingDetails] = useState<any>();

  const hotelJSON = localStorage.getItem("hotel-search");
  const hotelStorage = hotelJSON ? JSON.parse(hotelJSON) : null;

  const currentDate = new Date();

  useEffect(() => {
    const getBooking = localStorage.getItem("booking-hotel");
    const getBookingDetailID = JSON.parse(String(getBooking));

    bookingDetailsConfirmation({
      bookingID: getBookingDetailID,
      client: "maashree",
      auditTransactions: true,
      context: "HOTELTEST",
      testMode: true,
      timeout: 18000,
    })
      .then((res) => {
        setBookingDetails(res.data.hotelX.booking.bookings[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  const totalAdults = hotelStorage?.rooms.reduce(
    (total: any, room: { adults: any }) => total + room.adults,
    0
  );
  const totalChildren = hotelStorage?.rooms.reduce(
    (total: any, room: { children: any }) => total + room.children,
    0
  );

  const summaryGuestText = `${totalAdults} Adult${
    totalAdults > 1 ? "s" : ""
  }, ${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`;

  return (
    <AppLayout>
      <main>
        {bookingDetails && (
          <>
            <div className="md-stepper-horizontal orange">
              <div className="d-flex justify-content-evenly align-content-center flex-wrap">
                <div className="d-flex align-items-center gap-3">
                  <div className="md-step active done">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="75"
                      height="75"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                    </svg>
                  </div>
                  <div className="md-step active editable">
                    <div className="md-step-title">
                      <h2 className="h1-inner mb-0">Thank You!</h2>
                      <p>Your Payment has been Received</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center res_padding">
                  <div className="md-step active res_padding">
                    <div className="md-step-circle">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-success"
                          width="75"
                          height="75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          style={{
                            width: "33px",
                            marginTop: "-22px",
                            marginLeft: "-2px",
                            fill: "white",
                          }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                        </svg>
                      </span>
                    </div>
                    <div className="md-step-title">
                      <h3 className="payment_heading">Payment Received</h3>
                      <p className="payment-sub">
                        ₹ 4,137 received via Deposit Account
                      </p>
                    </div>
                    <div className="md-step-bar-right"></div>
                  </div>
                  <div className="md-step">
                    <div className="md-step-circle">
                      <span>
                        <div
                          className="spinner-grow "
                          role="status"
                          style={{
                            color:
                              bookingDetails?.status &&
                              bookingDetails?.status === "OK"
                                ? "#198754"
                                : "#ffcb4a",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </span>
                    </div>
                    <div className="md-step-title">
                      <h3 className="payment_heading">
                        {bookingDetails?.status &&
                        bookingDetails?.status === "OK"
                          ? " Booking Success"
                          : " Booking In Progress"}
                        {/* <span
                        style={{
                          background: "#ed3237",
                          padding: "7px",
                          borderRadius: "50%",
                          position: "absolute",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => setRefreshBooking(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                          style={{
                            fill: "#fff",
                            width: "20px",
                            height: "20px",
                            marginBottom: "2px",
                          }}
                        >
                          <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0-32-14.3-32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"></path>
                        </svg>
                      </span> */}
                      </h3>
                      <p className="payment-sub">For Hotel</p>
                    </div>
                    <div className="md-step-bar-left"></div>
                    <div className="md-step-bar-right"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container pt-2">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="h1-inner mb-0">Booking confirmation</h1>
                  <div className="sub-heading-confirmation">
                    <span>
                      Booking no.: {bookingDetails?.reference.supplier} |
                      john.smith@gmail.com | Booked on{" "}
                      {moment(currentDate).format("Do MMM YYYY")}
                    </span>
                    <span className="card-right first_card">
                      <span>
                        <LazyImage
                          className="img-fluid"
                          src={arrowDown}
                          alt="view-icon"
                        />
                      </span>
                      Download
                    </span>
                  </div>
                </div>
              </div>

              <div className="tabbing-bar mt-0" style={{ top: "0px" }}>
                <div className="tab-content" id="nav-tabContent">
                  {/* Divide section */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="header_passenger px-2">
                        <div className="left-passenger">
                          <h3 className="h3-bold">
                            {bookingDetails?.hotel.hotelName},{" "}
                            {hotelStorage.destinationName}
                          </h3>
                          <span className="">
                            <LazyImage
                              className="img-fluid"
                              src={starImg}
                              alt=""
                            />
                            <button className="passenger-btn">
                              {" "}
                              Couple Friendly{" "}
                            </button>
                          </span>
                          <div className="content-passenger pt-2">
                            <span className="">
                              Plot No. 37, 38, Near Passport Office, SGO
                              Complex, EDC, Pato, Goa, India
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card_confirmation mb-3">
                    <div className="card-body">
                      <div className="main_content res_main_block align-self-start">
                        <div className="card_block res_block px-2">
                          <div className="card-img">
                            <LazyImage
                              className="img-fluid"
                              src={hotelImage}
                              alt="Emirates"
                            />
                          </div>
                        </div>
                        <div className="card_block res_block px-2">
                          <div className="card-content">
                            <p className="card-text fw-bold mb-1"> ROOMS </p>
                            {bookingDetails?.hotel.rooms?.map((room: any) => (
                              <h3 className="block_heading">
                                {room.description}
                              </h3>
                            ))}

                            <p className="card-text">Room Only</p>
                          </div>
                        </div>
                        <div className="card_block res_block px-2">
                          <div className="card-content">
                            <p className="card-text fw-bold mb-1">CHECK IN</p>
                            <h3 className="block_heading">
                              {" "}
                              {moment(hotelStorage.checkInDate).format(
                                "Do MMM YYYY"
                              )}
                            </h3>
                          </div>
                        </div>
                        <div className="card_block res_block px-2">
                          <div className="card-content">
                            <p className="card-text fw-bold mb-1">CHECK OUT</p>
                            <h3 className="block_heading">
                              {" "}
                              {moment(hotelStorage.checkOutDate).format(
                                "Do MMM YYYY"
                              )}
                            </h3>
                            {/* <p className="card-text">12:00 PM</p> */}
                          </div>
                        </div>
                        <div className="card_block res_block px-2">
                          <div className="card-content">
                            <p className="card-text fw-bold mb-1">GUESTS</p>
                            <h3 className="block_heading">
                              {" "}
                              {summaryGuestText}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-table pb-3">
                  Passenger information (4 adults)
                </h3>
                <table className="table mb-5">
                  <thead style={{ backgroundColor: "#CFE4F9" }}>
                    <tr>
                      <th style={{ backgroundColor: "#cfe4f9" }} scope="col">
                        Sr No.
                      </th>
                      <th style={{ backgroundColor: "#cfe4f9" }} scope="col">
                        Full name
                      </th>
                      <th style={{ backgroundColor: "#cfe4f9" }} scope="col">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" data-label="Sr No.">
                        1
                      </th>
                      <td data-label="Full name">James Smith</td>
                      <td className="status-co" data-label="Status">
                        {bookingDetails?.status === "OK"
                          ? "Confirmed"
                          : "Pending"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" data-label="Sr No.">
                        2
                      </th>
                      <td data-label="Full name">Richard Conover</td>
                      <td className="status-co" data-label="Status">
                        {bookingDetails?.status === "OK"
                          ? "Confirmed"
                          : "Pending"}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" data-label="Sr No.">
                        3
                      </th>
                      <td data-label="Full name">Michael Dawkins</td>
                      <td className="status-co" data-label="Status">
                        {bookingDetails?.status === "OK"
                          ? "Confirmed"
                          : "Pending"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row mt-5">
                <div className="col-sm-7 col-md-7 mb-3 res-padding">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button fw-bold"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Cancellation policy and Terms & conditions
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul
                            className="confirmation-content"
                            style={{ padding: "10px" }}
                          >
                            <li>
                              All Guests, including children and infants, must
                              present valid identification at check-in.
                            </li>
                            <li>
                              Check-in begins 2 hours prior to the flight for
                              seat assignment and closes 60 minutes prior to the
                              scheduled departure.
                            </li>
                            <li>
                              Carriage and other services provided by the
                              carrier are subject to conditions of carriage,
                              which are hereby incorporated by reference. These
                              conditions may be obtained from the issuing
                              carrier.
                            </li>
                            <li>
                              Indian allows its Guests to check-in up to 15 kg
                              of luggage per paying passenger. Baggage in excess
                              of 15 kg is subject to a fee to be paid at the
                              airport at check-in.
                            </li>
                            <li>
                              Cancellation Charges shall be as per airline
                              rules.
                            </li>
                            <li>
                              For any queries please contact Maashree at
                              18001803838/9910383838 for all MTNL/BSNL phones.
                            </li>
                            <li>
                              Please check the figures / timings as they may
                              change time to time without any notice to the
                              passenger.
                            </li>
                            <li>
                              For Delhi- Mumbai Travel Sectors / Customer Needs
                              to recheck the departure and arrival terminals
                              again with the airlines prior 24 hours off light
                              scheduled timings.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Important Information for Checkin
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ul
                            className="confirmation-content"
                            style={{ padding: "10px" }}
                          >
                            <li>
                              All Guests, including children and infants, must
                              present valid identification at check-in.
                            </li>
                            <li>
                              Check-in begins 2 hours prior to the flight for
                              seat assignment and closes 60 minutes prior to the
                              scheduled departure.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5 col-ms-5 box-ind-main">
                  <div className="w-100 px-3">
                    <div className="confirm-box">
                      <h3 className="h3-bold">Fare Summary</h3>
                      {accordionData.map(
                        ({ title, subtitle, content, contentsecond }) => (
                          <Accordion
                            key={`confirmation-hotel-${title}`}
                            title={title}
                            subtitle={subtitle}
                            content={content}
                            contentsecond={contentsecond}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 m-auto res-padding">
                  <div className="card card-contact mt-2">
                    <div className="card-body">
                      <h3 className="text-table">Primary Contact</h3>
                      <p className="box-heading">
                        We will contact you on this number. You can email or
                        WhatsApp the ticket to other contacts.
                      </p>
                      <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                        <div className="contact-block">
                          <p className="box-heading mb-0">
                            <span>
                              <LazyImage src="" alt="" />
                            </span>{" "}
                            {bookingDetails?.holder.name +
                              " " +
                              bookingDetails?.holder.surname}
                          </p>
                        </div>
                        <div className="contact-block">
                          <p className="box-heading mb-0">
                            <span>
                              <LazyImage src="" alt="" />
                            </span>{" "}
                            testuser@yopmail.com
                          </p>
                        </div>
                        <div className="contact-block">
                          <p className="box-heading mb-0">
                            <span>
                              <LazyImage src="" alt="" />
                            </span>{" "}
                            +91 8965742310
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
