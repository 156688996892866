import { useEffect, useState } from "react";
import pencilSimpleIcon from "../assets/images/PencilSimple.svg";
import okImage from "../assets/images/img-ok.svg";
import { BasicDetails } from "./modal/user-profile/BasicDetails";
import { LoginDetails } from "./modal/user-profile/LoginDetails";
import { OTConfirmation } from "./modal/user-profile/OTPconfirmation";
import {
  deleteUserProfile,
  getUserProfile,
  getUserProfilePic,
} from "../api/UserProfile";
import LazyImage from "../pages/LazyImage";
import moment from "moment";
import { toast } from "react-toastify";
export const ProfilePage = () => {
  const [onUpdate, setOnUpdate] = useState(false);
  const [openBasicDetailsModal, setOpenBasicDetailsModal] = useState(false);
  const [openLoginDetailsModal, setOpenLoginDetailsModal] = useState(false);
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [userProfileId, setUserProfileId] = useState("");

  const [showModal, setShowModal] = useState(false);

  const tokenProfile = String(localStorage.getItem("userImageKey"));

  const [currentUser, setCurrentUser] = useState<{
    fullName: string;
    email: string;
    gender: string;
    dob: string;
    countryCode: string;
    mobileNumber: string;
    gst: string;
    address: {
      street: string;
      city: string;
      state: string;
      country: string;
      pincode: string;
    };
  }>({
    fullName: "",
    email: "",
    gender: "",
    dob: "",
    countryCode: "",
    mobileNumber: "",
    gst: "",
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        setCurrentUser({
          ...currentUser,
          ...response.data,
        });
        setUserProfileId(response.data._id);
        localStorage.setItem("userImageKey", response.data.profilePic);
      })
      .catch((err) => console.log(err));
    return () => setOnUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        setCurrentUser({
          ...currentUser,
          ...response.data,
        });
        localStorage.setItem("userImageKey", response.data.profilePic);
      })
      .catch((err) => console.log(err));
    return () => setOnUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdate]);

  useEffect(() => {
    const storedProfilePic = localStorage.getItem("userImageKey");

    storedProfilePic &&
      tokenProfile !== "undefined" &&
      getUserProfilePic()
        .then((response) => {
          setUserProfilePicture(response.data);
        })
        .catch((error) => console.log(error));
  }, [onUpdate, tokenProfile]);

  const storedProfilePic = localStorage.getItem("userImageKey");

  useEffect(() => {
    storedProfilePic &&
      tokenProfile !== "undefined" &&
      getUserProfilePic()
        .then((response) => {
          setUserProfilePicture(response.data);
        })
        .catch((error) => console.log(error));
  }, [storedProfilePic, tokenProfile]);

  const deleteAccountHandler = () => {
    deleteUserProfile({ _id: userProfileId })
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("loginId");
        localStorage.removeItem("country-code");
        localStorage.removeItem("defaultName");
        localStorage.removeItem("newVerifyId");
        localStorage.removeItem("loggedInUser");
        localStorage.removeItem("userImageKey");
        window.location.reload();
      })
      .catch((err) =>
        toast.error("Error Occurred In Deleting Account", {
          toastId: "logoutToast",
        })
      );
  };

  return (
    <>
      <main className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="profile-card">
              <h3 className="profile-head">
                Personal Information{" "}
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalForUserDetails"
                  onClick={() => setOpenBasicDetailsModal(true)}
                >
                  <LazyImage src={pencilSimpleIcon} alt="" />
                </span>
              </h3>

              <div className="row">
                <div className="col-md-2 mb-4">
                  <div
                    className="box-img"
                    style={{
                      textTransform: "capitalize",
                      width: "100px",
                      height: "100px",
                      border: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    {tokenProfile !== "undefined" && userProfilePicture && (
                      <LazyImage
                        src={userProfilePicture}
                        alt="User"
                        className="userProfilePicture"
                        style={{
                          width: "145px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {currentUser.fullName && currentUser.fullName[0]}
                    {currentUser.fullName === "" && !userProfilePicture && "T"}
                  </div>
                </div>

                <div className="col-md-10">
                  <div className="row mb-3">
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">Full name</label>
                      <p
                        className="label2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {currentUser.fullName === null ||
                        currentUser.fullName === ""
                          ? "Full name"
                          : currentUser.fullName}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">Date of Birth</label>
                      <p className="label2">
                        {currentUser.dob === null || currentUser.dob === ""
                          ? "DOB"
                          : moment(currentUser.dob).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile">
                      <label className="label1">Gender</label>
                      <p className="label2">
                        {currentUser.gender === "F"
                          ? "Female"
                          : currentUser.gender === "M"
                          ? "Male"
                          : currentUser.gender === "O"
                          ? "other"
                          : "Add Gender"}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className="label1"
                      >
                        Address
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">Street</label>
                      <p
                        className="label2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {currentUser.address?.street === ""
                          ? "Your street"
                          : currentUser.address?.street}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">City</label>
                      <p
                        className="label2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {currentUser.address?.city === ""
                          ? "Your City"
                          : currentUser.address?.city}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">State</label>
                      <p
                        className="label2"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {currentUser.address?.state === ""
                          ? "Your State"
                          : currentUser.address?.state}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">County</label>
                      <p className="label2">
                        {currentUser.address?.country === ""
                          ? "Your Country"
                          : currentUser.address?.country}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-4">
                      <label className="label1">Pin code</label>
                      <p className="label2">
                        {currentUser?.address.pincode === "" ||
                        currentUser?.address.pincode === null ||
                        currentUser?.address.pincode === undefined
                          ? "Your Pin code"
                          : currentUser?.address.pincode}
                      </p>
                    </div>
                    <div className="col-sm-4 col-profile mb-0">
                      <label className="label1">GST info</label>
                      <p className="label2">
                        {currentUser.gst === ""
                          ? "Add GST Info"
                          : currentUser.gst}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="profile-card">
              <h3 className="profile-head">
                Login Details{" "}
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalLoginDetails"
                  onClick={() => setOpenLoginDetailsModal(true)}
                >
                  <LazyImage src={pencilSimpleIcon} alt="" />
                </span>
              </h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <label className="label1">
                        Mobile Number{" "}
                        {currentUser.mobileNumber !== "" && (
                          <span className="verified">
                            <LazyImage src={okImage} alt="" width={10} />{" "}
                            Verified
                          </span>
                        )}
                      </label>
                      <p>
                        {currentUser.mobileNumber === ""
                          ? "Add your mobile no."
                          : currentUser.mobileNumber}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <label className="label1">
                        Email Address
                        {currentUser.email !== "" && (
                          <span className="verified">
                            <LazyImage src={okImage} alt="" width={10} />{" "}
                            Verified
                          </span>
                        )}
                      </label>
                      <p>
                        {currentUser.email === ""
                          ? "Add Your Email"
                          : currentUser.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex">
              <button
                onClick={() => openModal()}
                className="btn btn-login search_record mt-4"
                type="button"
                style={{ width: "unset" }}
              >
                Delete My Account
              </button>
            </div>
          </div>

          <div
            className={`modal ${showModal ? "show" : ""}`}
            tabIndex={-1}
            role="dialog"
            style={{ display: showModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" style={{ border: "1px solid" }}>
                <div className="modal-header">
                  <h5 className="modal-title" style={{ fontWeight: "600" }}>
                    Delete Account
                  </h5>
                  <button type="button" className="close" onClick={closeModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Are you sure you want to delete your Account?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={deleteAccountHandler}
                    style={{ backgroundColor: "red" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Basic Modal */}

      <div
        className="modal fade"
        id="exampleModalForUserDetails"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <BasicDetails
          setOnUpdate={setOnUpdate}
          openBasicDetailsModal={openBasicDetailsModal}
          setOpenBasicDetailsModal={setOpenBasicDetailsModal}
          userProfilePicture={userProfilePicture}
        />
      </div>

      {/* Login Modal */}
      <div
        className="modal fade"
        id="exampleModalLoginDetails"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <LoginDetails
          openLoginDetailsModal={openLoginDetailsModal}
          setOpenLoginDetailsModal={setOpenLoginDetailsModal}
        />
      </div>

      {/* OTP modal */}

      <div
        className="modal fade"
        id="exampleModal5"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <OTConfirmation setOnUpdate={setOnUpdate} />
      </div>
    </>
  );
};
