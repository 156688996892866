import PencilSimple from "../../../assets/images/PencilSimple.png";
import LazyImage from "../../../pages/LazyImage";
import admin_1 from "../../../assets/images/admin_1.png";
import { useEffect, useState } from "react";
import { hotelDealsList } from "../../../api/AdminAPI";

interface OffersPromotionProps {
  setToggleAdmin: (val: string) => void;
}

export const OffersPromotions = ({ setToggleAdmin }: OffersPromotionProps) => {
  const [offersList, setOffersList] = useState<any[]>([]);

  useEffect(() => {
    hotelDealsList()
      .then((res) => {
        const updatedOffers = res.data.map((offer: { hotelImage: string }) => {
          return offer;
        });

        Promise.all(updatedOffers)
          .then((updatedOfferArray) => {
            setOffersList(updatedOfferArray);
          })
          .catch((err) => {
            console.error("Error in fetching hotel pics", err);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {/* <!-- OffersPromotions --> */}
      <section className="upcoming_section mt-4" id="Promotions_section">
        {offersList.length > 0 && (
          <>
            {offersList.map((deals) => (
              <div className="card card_upcmoing">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-2 col-md-2">
                      <div className="block mb-0">
                        <div className="card-img">
                          {deals.hotelImage !== "" ? (
                            <LazyImage
                              className="img-fluid admin-images"
                              src={`https://d3c46yygxatk0.cloudfront.net/${deals.hotelImage}`}
                              alt="profile"
                            />
                          ) : (
                            <LazyImage
                              className="img-fluid admin-images"
                              src={admin_1}
                              alt="profile"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-10 col-md-10 m-auto">
                      <div className="d-flex justify-content-between align-items-center admin_dashboard">
                        <div className="block mb-0">
                          <div className="card-content">
                            <p className="card-text mb-2 d-flex gap-2 align-items-center">
                              <span className="image_bookin">
                                <LazyImage
                                  src="assets/images/business.png"
                                  alt=""
                                />
                              </span>
                              Hotel
                            </p>
                            <h3 className="block_heading">{deals.city}</h3>
                            <p className="card-text">{deals.details}</p>

                            {/* <div className="d-flex gap-2 align-items-center">
                              <button className="btn btn-cancel" type="button">
                                T&amp;C's Apply
                              </button>
                            </div> */}
                          </div>
                        </div>

                        <div
                          className="d-flex align-items-center gap-4"
                          onClick={() => {
                            localStorage.setItem(
                              "hotel-deal",
                              JSON.stringify(deals)
                            );
                            setToggleAdmin("edit");
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <LazyImage src={PencilSimple} alt="" />

                            <span className="action-text action-edit">
                              Edit
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </section>
    </>
  );
};
