import axios from "axios";
import {
  createSlabsURL,
  deleteSlabsURL,
  getPaymentStatusURL,
  hotelDealsListURL,
  slabsListURL,
  updateHotelDealsURL,
  updatePaymentStatusURL,
  updateSlabsInAdminURL,
} from "./endpoints";

interface updateDealsPayloadProps {
  _id: string;
  city: string;
  details: string;
  hotelImage: string;
}

interface paymentPayloadProps {
  txnId: string;
}

interface updatePaymentStatusPayloadProps {
  txnId: string;
  refundStatus: number;
}

interface updateSlabsPayloadProps {
  slabType: number;
  _id?: string;
  minAmount: number;
  maxAmount: number;
  commissionRate: number;
  addonRate: number;
}

interface deleteSlabsPayloadProps {
  _id: string;
  slabType: number;
  document_id: string;
}

interface createSlabsPayloadProps {
  slabType: number;
  minAmount: number;
  maxAmount: number;
  commissionRate: number;
  addonRate: number;
  document_id: string;
}

export async function hotelDealsList() {
  const tokenAdmin = localStorage.getItem("admin_token");

  if (tokenAdmin) {
    try {
      const response = await axios.get(hotelDealsListURL, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in getting Hotel deals:", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function slabsList() {
  try {
    const response = await axios.get(slabsListURL);
    return response.data;
  } catch (error) {
    console.error("Error in getting Admin slabs:", error);
    throw error;
  }
}

export async function updateHotelDeals(
  updateDealsPayload: updateDealsPayloadProps
) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: updateDealsPayloadProps = { ...updateDealsPayload };

  if (tokenAdmin) {
    try {
      const response = await axios.post(updateHotelDealsURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in getting Hotel deals:", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function getPaymentStatus(paymentPayload: paymentPayloadProps) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: paymentPayloadProps = { ...paymentPayload };

  if (tokenAdmin) {
    try {
      const response = await axios.post(getPaymentStatusURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in Payment details", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function updatePaymentStatus(
  updatePaymentStatusPayload: updatePaymentStatusPayloadProps
) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: updatePaymentStatusPayloadProps = {
    ...updatePaymentStatusPayload,
  };

  if (tokenAdmin) {
    try {
      const response = await axios.post(updatePaymentStatusURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in Payment details", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function updateSlabsInAdmin(
  updateSlabsPayload: updateSlabsPayloadProps
) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: updateSlabsPayloadProps = {
    ...updateSlabsPayload,
  };

  if (tokenAdmin) {
    try {
      const response = await axios.post(updateSlabsInAdminURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in updating slabs", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function deleteSlabs(deleteSlabsPayload: deleteSlabsPayloadProps) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: deleteSlabsPayloadProps = {
    ...deleteSlabsPayload,
  };

  if (tokenAdmin) {
    try {
      const response = await axios.post(deleteSlabsURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in deleting slabs", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}

export async function createSlabs(createSlabsPayload: createSlabsPayloadProps) {
  const tokenAdmin = localStorage.getItem("admin_token");

  const payload: createSlabsPayloadProps = {
    ...createSlabsPayload,
  };

  if (tokenAdmin) {
    try {
      const response = await axios.post(createSlabsURL, payload, {
        headers: {
          Authorization: `Bearer ${tokenAdmin}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in creating slab", error);
      throw error;
    }
  } else {
    console.log("No signed token found.");
    return null;
  }
}
