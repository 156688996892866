import {useState} from "react";
import React from "react";
import "./DestinationResult.component.css";
import {FilterCards} from "./FilterCards";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export const DestinationResult = () =>{

    const [age, setAge] = React.useState<string>("");

    const handleChange = (event: SelectChangeEvent<string>) => {
      setAge(event.target.value as string);
    };

    return (
      <>
        <div className="des-res-component">
          <div className="des-res-summary">
            <p>
              Packed with landmarks, pretty as a picture, and undeniably
              romantic, the French capital knows how to woo visitors. Whether
              you've already fallen head-over-heels or just getting acquainted,
              booking Paris tours will help you get to know this charmant city.
              Admire the UNESCO-listed riverbanks of the Seine aboard a cruise,
              head to the summit of the Eiffel Tower for unbeatable views, and
              make eyes at the Mona Lisa on a Louvre excursion. Then, explore
              further, whether you opt to explore neighborhoods like Le Marais
              and Montmartre, get gourmet with a wine tasting, or head to
              Versailles for the day.
            </p>
          </div>

          <div className="des-res-filters">
            <div className="filter-sec-one">
              <p>3,932 results</p>
            </div>
            <div className="filter-sec-two">
              <div className="filter-dropdown">
                <Box sx={{ minWidth: 180 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Featured
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="Featured"
                      onChange={handleChange}
                      aria-selected
                    >
                      <MenuItem value={10}>Traveler Rating</MenuItem>
                      <MenuItem value={20}>Price (Low to High)</MenuItem>
                      <MenuItem value={30}>Price (High to Low)</MenuItem>
                      <MenuItem value={30}>Duration (Short to Long)</MenuItem>
                      <MenuItem value={30}>Duration (Long to Short)</MenuItem>
                      <MenuItem value={30}>New on Maashree</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>

          <div className="des-res-cards">
            <FilterCards />
          </div>
        </div>
      </>
    );
}