import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { ProfilePage } from "../components/ProfilePage";
import { TalkToAgents } from "../components/TalkToAgent";

export const Profile = () => {
  return (
    <AppLayout>
      <ProfilePage />
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
