import { createContext, ReactNode, useContext, useState } from "react";

interface LoaderContextType {
  isFlightLoading: boolean;
  setIsFlightLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ContextFlightLoaderProps {
  children: ReactNode;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const FlightLoaderProvider: React.FC<ContextFlightLoaderProps> = ({
  children,
}) => {
  const [isFlightLoading, setIsFlightLoading] = useState<boolean>(false);

  return (
    <LoaderContext.Provider value={{ isFlightLoading, setIsFlightLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useFlightLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error(
      "useFlightLoader must be used within a FlightLoaderProvider"
    );
  }
  return context;
};
