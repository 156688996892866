import React, { useEffect, useMemo, useRef, useState } from "react";
import LazyImage from "../../../pages/LazyImage";
import airplaneSVG from "../../../assets/images/AirplaneTilt.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";

interface MultiCityEntryProps {
  placeholder: string;
  target: string;
  city: any;
  codeVal: string;
  cities: any;
  airportName: string;
  editingCityIndex: number;
  index: number;
  code: (index: number, codeType: any, code: string) => void;
  setAirportName: any;
  handleCityChange: (value: string, index: number, field: keyof any) => void;
  triggerCurrentLocation?: () => void;
  locationIcon?: boolean;
  focusNextInput: () => void;
  forwardedRef: React.RefObject<HTMLInputElement>;
  newRef?: any;
  setShowCalenderMultiCity?: any;
}

export const AutoCompleteMultiCity: React.FC<MultiCityEntryProps> = ({
  placeholder,
  target,
  city,
  codeVal,
  cities,
  airportName,
  editingCityIndex,
  index,
  code,
  setAirportName,
  handleCityChange,
  triggerCurrentLocation,
  locationIcon,
  forwardedRef,
  newRef,
  focusNextInput = () => {},
  setShowCalenderMultiCity,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const getAirPortsJSON = localStorage.getItem("allAirports");
  const airportListRef = useRef<HTMLUListElement | null>(null);

  const handleDocumentClick = (e: { target: any }) => {
    if (
      showDropdown &&
      airportListRef.current &&
      !airportListRef.current.contains(e.target)
    ) {
      handleCityChange("", index, target);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  const handleListItemKeyDown = (e: any) => {
    if (!showDropdown) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (
        highlightedIndex === -1 ||
        highlightedIndex === filteredAirports.length - 1
      ) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex(highlightedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlightedIndex === -1 || highlightedIndex === 0) {
        setHighlightedIndex(filteredAirports.length - 1);
      } else {
        setHighlightedIndex(highlightedIndex - 1);
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedAirport = filteredAirports[highlightedIndex];
        handleCityChange(selectedAirport.CityName, index, target);
        code(index, target + "Code", selectedAirport.Code);
        setAirportName(index, target + "AirportName", selectedAirport.Name);

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedAirport = filteredAirports[highlightedIndex];
        handleCityChange(selectedAirport.CityName, index, target);
        code(index, target + "Code", selectedAirport.Code);
        setAirportName(index, target + "AirportName", selectedAirport.Name);

        if (target === "destination") {
          setShowCalenderMultiCity(true);
        }

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
      focusNextInput && focusNextInput();
    }
  };

  const filteredAirports = useMemo(() => {
    return JSON.parse(String(getAirPortsJSON))?.filter(
      ({ CityName, Code }: any) =>
        `${CityName} ${Code}`.toLowerCase().includes(city?.toLowerCase())
    );
  }, [getAirPortsJSON, city]);

  return (
    <>
      <div className="border px-2 py-2 rounded-2 border-dark">
        <div className="d-flex align-items-center gap-2">
          <LazyImage src={airplaneSVG} alt="" className="autoCompleted" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 256 256"
            data-testid="originIcon"
          ></svg>
          <span className="fw-bold fs-6">
            {target === "departure" ? "From" : "To"}
          </span>
        </div>

        <div className="input_heading">
          <input
            type="search"
            aria-label="Search"
            className="fw-bold border-0 fs-4"
            id={placeholder}
            placeholder={placeholder}
            ref={
              index !== 0 && target !== "destination" ? newRef : forwardedRef
            }
            value={city}
            onChange={(e) => {
              handleCityChange(e.target.value, index, target);
              setShowDropdown(true);

              console.log(index, forwardedRef, "----");
            }}
            autoComplete="off"
            onKeyDown={handleListItemKeyDown}
          />
        </div>

        {locationIcon && (
          <FontAwesomeIcon
            icon={faLocationCrosshairs}
            style={{
              color: "#b1b1b1",
              position: "absolute",
              top: "22px",
              right: "16px",
              zIndex: "999",
            }}
            onClick={triggerCurrentLocation}
          />
        )}

        <div className="code_heading">
          <span className="code-des d-inline-block text-truncate w-100">
            {codeVal !== undefined &&
              `${codeVal === "" ? "-" : `[${codeVal}]`} ${
                airportName !== undefined ? airportName : ""
              }`}
          </span>
        </div>
      </div>

      {showDropdown && city !== "" && index === editingCityIndex && (
        <ul
          ref={airportListRef}
          style={{
            margin: "2px",
            border: "1px solid #ccc",
            padding: "6px",
            position: "absolute",
            backgroundColor: "white",
            width: "100%",
            borderRadius: "5px",
            zIndex: 999,
            maxHeight: "20rem",
            overflowY: "auto",
          }}
        >
          {filteredAirports &&
            filteredAirports?.map(
              ({ CityName, Code, Name, Country }: any, idx: number) => (
                <li
                  key={idx}
                  style={{
                    listStyleType: "none",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    cursor: "pointer",
                    backgroundColor:
                      idx === highlightedIndex ? "#ffebeb" : "white",
                    borderRadius: idx === highlightedIndex ? "10px" : "",
                    paddingLeft: "7px",
                  }}
                  onClick={() => {
                    handleCityChange(CityName, index, target);
                    code(index, target + "Code", Code);
                    setAirportName(index, target + "AirportName", Name);
                    setShowDropdown(false);
                    if (focusNextInput) {
                      focusNextInput();
                    }
                    if (target === "destination") {
                      setShowCalenderMultiCity(true);
                    }
                  }}
                  tabIndex={0}
                >
                  <span
                    style={{
                      fontSize: "11px",
                      border: "1px solid",
                      padding: "2px",
                      marginRight: "10px",
                    }}
                  >
                    {Code}
                  </span>{" "}
                  <span>{CityName}</span>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "13px",
                        marginTop: "7px",
                        color: "#717171",
                      }}
                    >
                      {Name}
                    </span>
                    <span
                      style={{
                        marginLeft: "auto",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#757575",
                        textTransform: "uppercase",
                        width: "45%",
                        marginTop: "5px",
                        textAlign: "end",
                      }}
                    >
                      {Country}
                    </span>
                  </div>
                </li>
              )
            )}
        </ul>
      )}
    </>
  );
};
