import { useState, ChangeEvent } from "react";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import { PiWarningCircle } from "react-icons/pi";
import "./ActivityCheckout.component.css";

export const ActivityCheckout = () => {
  const [selectedValue, setSelectedValue] = useState("a");
  const [showMore, setShowMore] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleToggle = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const text =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.";

  const truncateText = (text: string, wordLimit: number): string => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const displayText = showMore ? text : truncateText(text, 50);

  return (
    <div className="activity-checkout">
      {/* Form 1 */}
      <div className="check-form">
        <div className="radio-btn">
          <Radio
            checked={selectedValue === "a"}
            onChange={handleChange}
            value="a"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
        </div>
        {selectedValue === "a" ? (
          <>
            <div className="content-block">
              <h4>Option 1</h4>
              <p>
                {displayText}
                <button onClick={handleToggle} className="toggle-button">
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </p>
              <div className="time-block">
                <div className="block active">
                  <Button>Primary</Button>
                </div>
                <div className="block">
                  <Button>Primary</Button>
                </div>
                <div className="block disable">
                  <Button disabled>Disabled</Button>
                </div>
              </div>
              <div className="warning-block">
                <PiWarningCircle />
                <p className="link">Non Refundable</p>
                <p className="normal">
                  - You will not receive a refund if you cancel.
                </p>
              </div>
            </div>
            <div className="btn-block">
              <div className="total-cost">
                <p className="prize">₹13,784.64</p>
                <p className="info">2 Travelers x ₹6,892.32</p>
              </div>
              <div className="book-now">
                <p>Book Now</p>
              </div>
            </div>
          </>
        ) : (
          <div className="total-cost">
            <p className="prize">₹13,784.64</p>
            <p className="info">2 Travelers x ₹6,892.32</p>
          </div>
        )}
      </div>

      {/* Form 2 */}
      <div className="check-form">
        <div className="radio-btn">
          <Radio
            checked={selectedValue === "b"}
            onChange={handleChange}
            value="b"
            name="radio-buttons"
            inputProps={{ "aria-label": "B" }}
          />
        </div>
        {selectedValue === "b" ? (
          <>
            <div className="content-block">
              <h4>Option 2</h4>
              <p>
                {displayText}
                <button onClick={handleToggle} className="toggle-button">
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </p>
              <div className="time-block">
                <div className="block active">
                  <Button>Primary</Button>
                </div>
                <div className="block">
                  <Button>Primary</Button>
                </div>
                <div className="block disable">
                  <Button disabled>Disabled</Button>
                </div>
              </div>
              <div className="warning-block">
                <PiWarningCircle />
                <p className="link">Non Refundable</p>
                <p className="normal">
                  - You will not receive a refund if you cancel.
                </p>
              </div>
            </div>
            <div className="btn-block">
              <div className="total-cost">
                <p className="prize">₹15,000.00</p>
                <p className="info">3 Travelers x ₹5,000.00</p>
              </div>
              <div className="book-now">
                <p>Book Now</p>
              </div>
            </div>
          </>
        ) : (
          <div className="total-cost">
            <p className="prize">₹15,000.00</p>
            <p className="info">3 Travelers x ₹5,000.00</p>
          </div>
        )}
      </div>

      {/* Additional forms can be added similarly */}
    </div>
  );
};
