import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import React from "react";
import { Home } from "./pages/Home";
import { Profile } from "./pages/UserProfile";
import { useAuth } from "./components/context/AuthenticationContext";
import { PrivacyPolicy } from "./pages/PrivacyAndPolicy";
import { TermsOfUse } from "./pages/TermsOfUse";
import { FilterFlights } from "./pages/flight/FilterFlights";
import { CheckoutFlight } from "./pages/flight/CheckoutFlight";
import { Payment } from "./pages/Payment";
import { BookingConfirmation } from "./pages/flight/BookingConfirmation";
import { CookiePolicy } from "./pages/CookiePolicy";
import { MulticityFlightSearch } from "./pages/flight/MulticityFlightFilter";
import { CustomerSupport } from "./pages/CustomerSupport";
import { MyTravels } from "./pages/MyTravels";
import { RoundTripFlightSearch } from "./pages/flight/RoundTripFilter";
import { FilterHotels } from "./pages/hotel/FilterHotel";
import { HotelRoom } from "./pages/hotel/HotelRoom";
import { PassengerHotel } from "./pages/hotel/PassengerHotel";
import { HotelConfirmation } from "./pages/hotel/ConfirmationHotel";
import { CheckoutRoundtripFlight } from "./pages/flight/CheckoutRoundTrip";
import { AdminDashboard } from "./pages/AdminDashboard";
import { PaymentSuccess } from "./pages/payment/PaymentSuccess";
import { PaymentFailed } from "./pages/payment/PaymentFailed";
import { About } from "./pages/About";
import { NotFound } from "./pages/NotFound";
import { PaymentBuffer } from "./pages/payment/PaymentBuffer";
import { CheckoutMultiCity } from "./pages/flight/CheckoutMultiCity";
import { AdminLogin } from "./pages/AdminLogin";
import { ActivityPage } from "./components/forms/activity/ActivityPage";
import { DestinationPage } from "./components/forms/destination/DestinationPage";

export const PageRoutes = () => {
  const { isAuthenticated, isAdminAuthenticated } = useAuth();
  const admin_token = localStorage.getItem("admin_token");

  const isDashboardDomain = window.location.hostname === "admin.maashree.com";
  const isWebsiteDomain = window.location.hostname === "maashree.com";
  // const isDashboardDomain = window.location.hostname === "admin.localhost";
  // const isWebsiteDomain = window.location.hostname === "localhost";
  console.log("host", window.location.hostname);

  if (isWebsiteDomain) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          element={<PrivateRouteWrapper isAuthenticated={isAuthenticated} />}
        >
          <Route path="/profile" element={<Profile />} />
          <Route path="/my-travels" element={<MyTravels />} />
        </Route>
        <Route path="/flight-checkout" element={<CheckoutFlight />} />
        <Route path="/search-flights" element={<FilterFlights />} />
        <Route path="/search-multi-city" element={<MulticityFlightSearch />} />
        <Route path="/search-roundtrip" element={<RoundTripFlightSearch />} />
        <Route
          path="/checkout-round-trip"
          element={<CheckoutRoundtripFlight />}
        />
        <Route path="/checkout-multi-city" element={<CheckoutMultiCity />} />
        {/* Hotel  */}
        <Route path="/search-hotels" element={<FilterHotels />} />
        <Route path="/hotel-room" element={<HotelRoom />} />
        <Route path="/hotel-passenger" element={<PassengerHotel />} />
        <Route path="/hotel-confirmation" element={<HotelConfirmation />} />
        {/* Hotel end */}
        {/* Activity */}
        <Route path="/activity-page" element={<ActivityPage />} />
        {/* Activity end */}
        {/* Destination */}
        <Route path="/destination-page" element={<DestinationPage />} />
        {/* Destination ends */}

        <Route path="/payment" element={<Payment />} />
        <Route path="/booking-confirmation" element={<BookingConfirmation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/support" element={<CustomerSupport />} />
        <Route path="/about" element={<About />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        {/* Payment status */}
        <Route path="/redirect?" element={<PaymentBuffer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
    //};
  }
  if (isDashboardDomain) {
    return (
      <Routes>
        <Route
          path="/"
          element={isAdminAuthenticated ? <AdminDashboard /> : <AdminLogin />}
        />
        <Route
          path="/admin"
          element={
            isAdminAuthenticated ? <AdminDashboard /> : <Navigate to="/" />
          }
        />
        <Route
          path="/"
          element={!admin_token ? <AdminLogin /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/admin" />} />
      </Routes>
    );
  }
  return null; // fallback in case neither domain matches
};

const PrivateRouteWrapper: React.FC<{ isAuthenticated: boolean }> = ({
  isAuthenticated,
}) => {
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
};
