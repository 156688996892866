import LazyImage from "../../../pages/LazyImage";
import { useEffect, useRef, useState } from "react";
import caretDown from "../../../assets/images/CaretDown.png";
import magnifyGlass from "../../../assets/images/MagnifyingGlass.svg";
import axios from "axios";
import {
  sendOTPVerifyEmail,
  sendOTPVerifyMobile,
} from "../../../api/LoginAndSignInAPIs";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../api/UserProfile";
import { emailRegex, mobileRegex } from "../../../utils/validation";

const initialDataObject = {
  countryCode: "",
  mobileNumber: "",
  email: "",
};

interface LoginDetailsProps {
  openLoginDetailsModal: boolean;
  setOpenLoginDetailsModal: (val: boolean) => void;
}

export const LoginDetails = ({
  openLoginDetailsModal,
  setOpenLoginDetailsModal,
}: LoginDetailsProps) => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [formData, setFormData] = useState({ ...initialDataObject });
  const [validUser, setValidUser] = useState(true);
  const [storedUserData, setStoredUserData] = useState({
    ...initialDataObject,
  });
  const [userAPIResponse, setUserAPIResponse] = useState<{
    email?: string;
    mobileNumber?: string;
  }>({});

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!validUser && buttonRef.current) {
      buttonRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validUser]);

  useEffect(() => {
    if (openLoginDetailsModal) {
      getUserProfile()
        .then((response) => {
          setFormData({
            ...formData,
            ...response.data,
          });
          setStoredUserData({ ...response.data });
          setUserAPIResponse({ ...response.data });
        })
        .catch((err) => console.log(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLoginDetailsModal]);

  useEffect(() => {
    if (openLoginDetailsModal) {
      const getResponseCountryCode = () => {
        axios
          .get(
            "https://www.makemytrip.com/api/countryList?region=in&language=eng&currency=inr"
          )
          .then((response) => {
            setCountryCodes(response.data);
          });
      };
      getResponseCountryCode();
    }
  }, [openLoginDetailsModal]);

  const handleInputChangeEmail = (e: { target: { value: any } }) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handleInputChangeMobile = (e: { target: { value: any } }) => {
    setFormData({ ...formData, mobileNumber: e.target.value });
  };

  const onSubmitAdd = () => {
    setOpenLoginDetailsModal(false);
    if (storedUserData.email === "" || storedUserData.email === undefined) {
      const email = formData.email;
      sendOTPVerifyEmail(email, formData.countryCode)
        .then((response) => {
          localStorage.setItem("loggedInUser", storedUserData.mobileNumber);
          localStorage.setItem("country-code", storedUserData.countryCode);
          localStorage.setItem("newVerifyId", formData.email);
          console.log("User registration status:", response);
          toast.success(response.message, {
            toastId: "OTPSentSuccessToast",
          });
          setValidUser(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setValidUser(true);
          toast.error(`${error.response.data.message}`, {
            toastId: "OTPSentErrorToast",
          });
        });
    } else {
      localStorage.setItem("country-code", formData.countryCode);
      const mobileNumber = formData.mobileNumber;
      sendOTPVerifyMobile(mobileNumber, formData.countryCode)
        .then((response) => {
          localStorage.setItem("loggedInUser", storedUserData.email);
          localStorage.setItem("newVerifyId", formData.mobileNumber);
          console.log("User registration status:", response);
          toast.success(response.message, {
            toastId: "OTPSentSuccessMobileToast",
          });
          setValidUser(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setValidUser(true);
          toast.error(`${error.response.data.message}`, {
            toastId: "OTPSentErrorMobileToast",
          });
        });
    }
  };

  const resetForm = () => {
    setOpenLoginDetailsModal(false);
    setFormData({ ...initialDataObject });
  };

  return (
    <div className="modal-dialog modal-lg modal-login-details modal-dialog-centered">
      <div className="modal-content site-modal personal-modal">
        <div className="modal-body">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={resetForm}
          ></button>

          <div className="row modal-row1">
            <div className="col-sm-12">
              <div className="modal-left">
                <h2 className="modal-head">Login Details</h2>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="mb-4 text-left1 mobile-listing">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Mobile Number
                      </label>
                      <div className="mobile-select">
                        <div className="mobile-data-list">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle"
                              href="/"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="country-code">
                                {" "}
                                {storedUserData.countryCode === "" ||
                                storedUserData.countryCode === undefined
                                  ? `+${formData.countryCode}`
                                  : `+${storedUserData.countryCode}`}
                                <LazyImage
                                  src={caretDown}
                                  className="caret"
                                  alt=""
                                />{" "}
                              </span>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <div className="country-code-box">
                                  <LazyImage
                                    src={magnifyGlass}
                                    className="search-icon"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Country or Code"
                                    value={searchText}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                  />
                                </div>
                              </li>
                              {countryCodes
                                .filter(({ name, phoneCode, nameCode }) =>
                                  `${name} ${phoneCode} ${nameCode}`
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                )
                                .map(({ name, phoneCode, nameCode }, idx) => (
                                  <li
                                    key={phoneCode + idx}
                                    onClick={() =>
                                      setFormData({
                                        ...formData,
                                        countryCode: phoneCode,
                                      })
                                    }
                                  >
                                    <span className="dropdown-item">
                                      <span>
                                        {" "}
                                        {name} (+{phoneCode})
                                      </span>{" "}
                                      <span
                                        className="code-country"
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {nameCode}{" "}
                                      </span>
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Add your mobile no."
                          value={
                            storedUserData.mobileNumber === "" ||
                            storedUserData.mobileNumber === undefined
                              ? formData.mobileNumber
                              : storedUserData.mobileNumber
                          }
                          onChange={handleInputChangeMobile}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="mb-4 text-left1">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email"
                        id="email"
                        name="email"
                        value={
                          storedUserData.email === "" ||
                          storedUserData.email === undefined
                            ? formData.email
                            : storedUserData.email
                        }
                        onChange={handleInputChangeEmail}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="text-center">
                      {validUser ? (
                        <button
                          className="btn lg-btn modal-btn"
                          onClick={onSubmitAdd}
                          disabled={
                            storedUserData.email === "" ||
                            (storedUserData.email === undefined &&
                              !emailRegex.test(formData.email)) ||
                            storedUserData.mobileNumber === "" ||
                            (storedUserData.mobileNumber === undefined &&
                              !mobileRegex.test(formData.mobileNumber)) ||
                            formData.countryCode === "" ||
                            (userAPIResponse.email !== undefined &&
                              userAPIResponse.mobileNumber !== undefined)
                          }
                        >
                          Continue
                        </button>
                      ) : (
                        <button
                          ref={buttonRef}
                          className="btn lg-btn modal-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal5"
                          data-bs-dismiss="modal"
                        >
                          Continue
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
