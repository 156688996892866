import { AppLayout } from "../../components/AppLayout";
import warning from "../../assets/images/warning_icon.png";
import LazyImage from "../../pages/LazyImage";

export const PaymentFailed = () => {
  return (
    <AppLayout>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="text-center row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="payment_failed">Rs. 5000</h1>
                <span className="payment_transaction">
                  Transaction ID: B-Agra170705162220
                </span>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="text-center pb-2">
                <LazyImage src={warning} alt="warning-icon"/>
              </div>
              <p className="fs-4">
                <span className="text-danger">
                  We are sorry but the transaction failed.
                </span>
              </p>
              <p className="fs-6 mb-1 text-dark">Unknown Error</p>
              <hr className="my-2" />
              <p className="fs-6 mb-1 text-dark">Unable to be determined</p>
              <hr className="my-2" />
              <p className="fs-6 mb-1 text-dark">
                You will be redirected to PayU Test Account in{" "}
                <span className="text-secondary">few</span> seconds
              </p>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
