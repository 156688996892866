import { useState, useRef, useEffect } from "react";
import plus from "../../../assets/images/PlusCircle.png";
import baggage from "../../../assets/images/baggage.png";
import meals from "../../../assets/images/icons_meal.png";
import seats from "../../../assets/images/icons-seats.png";
import checking from "../../../assets/images/icons-checking.png";
import sports from "../../../assets/images/icons-sports.png";
import available_seat from "../../../assets/images/available_seat.svg";
import FleetBlocked from "../../../assets/images/FleetBlocked.svg";
import unable from "../../../assets/images/unable.svg";
import cross from "../../../assets/images/cross-mark.png";
import airport_seat from "../../../assets/images/airport_seat.svg";
import selected_seat from "../../../assets/images/selected_seat.svg";
import right_arrow from "../../../assets/images/right-arrow.png";
import cross_icon from "../../../assets/images/X.png";
import "../../../assets/css/addons.css";
import LazyImage from "../../../pages/LazyImage";
import OwlCarousel from "react-owl-carousel";
import baggae_image from "../../../assets/images/pricing-traveller-baggage (1).jpg";
import meal_image from "../../../assets/images/pricing-traveller-meals.jpg";
import right from "../../../assets/images/right.png";
import sport_image from "../../../assets/images/pricing-traveller-sports.jpg";
import checking_image from "../../../assets/images/priority-check-in-baggage.png";
import { SidebarCheckout } from "../flight/SidebarCheckout";
import { useAddons } from "./addonccontext";
import { FaCheck, FaTimes } from "react-icons/fa";

interface AddonsSectionProps {
  paidSSRList: any;
  seatpaidSSRList: any;
  adultsArray: any[];
  childrenArray: any[];
  infantArray: any[];
  userData: any;
  setAddonDetails: any;
  setMealAddonDetails: any;
  setSeatAddonDetails: any;
  seatAddonDetails: any;
  mealAddonDetails: any;
  addonDetails: any;
  sportBaggageDetails: any;
  setSportBaggageDetails: any;
  priorityBaggageDetails: any;
  setPriorityBaggageDetails: any;
  onClick: () => void;
  onHide: () => void;
  checkConnectingFlights: any;
  setAddonDetailsChild: any;
  addonDetailsChild: any;
  setAddonDetailsInfant: any;
  addonDetailsInfant: any;
  setMealAddonDetailsChild: any;
  mealAddonDetailsChild: any;
  setMealAddonDetailsInfant: any;
  mealAddonDetailsInfant: any;
  setSeatAddonDetailsChild: any;
  seatAddonDetailsChild: any;
  setSeatAddonDetailsInfant: any;
  seatAddonDetailsInfant: any;
  sportBaggageDetailsChild: any;
  setSportBaggageDetailsChild: any;
  sportBaggageDetailsInfant: any;
  setSportBaggageDetailsInfant: any;
  priorityBaggageDetailsChild: any;
  setPriorityBaggageDetailsChild: any;
  priorityBaggageDetailsInfant: any;
  setPriorityBaggageDetailsInfant: any;
}

export const AddonsSection = ({
  paidSSRList,
  seatpaidSSRList,
  adultsArray,
  childrenArray,
  infantArray,
  userData: userDataValue,
  setAddonDetails,
  setMealAddonDetails,
  setSeatAddonDetails,
  seatAddonDetails,
  mealAddonDetails,
  addonDetails,
  onClick,
  onHide,
  sportBaggageDetails,
  setSportBaggageDetails,
  priorityBaggageDetails,
  setPriorityBaggageDetails,
  checkConnectingFlights,
  setAddonDetailsChild,
  addonDetailsChild,
  setAddonDetailsInfant,
  addonDetailsInfant,
  setMealAddonDetailsChild,
  mealAddonDetailsChild,
  setMealAddonDetailsInfant,
  mealAddonDetailsInfant,
  setSeatAddonDetailsChild,
  seatAddonDetailsChild,
  setSeatAddonDetailsInfant,
  seatAddonDetailsInfant,
  setSportBaggageDetailsChild,
  sportBaggageDetailsChild,
  setSportBaggageDetailsInfant,
  sportBaggageDetailsInfant,
  priorityBaggageDetailsChild,
  setPriorityBaggageDetailsChild,
  priorityBaggageDetailsInfant,
  setPriorityBaggageDetailsInfant,
}: AddonsSectionProps) => {
  const owlRef = useRef(null);
  const [baggageUserType, setBaggageUserType] = useState({
    type: "",
    index: 0,
    fuid: 1,
  });

  const [seatUserType, setSeatUserType] = useState({
    type: "",
    index: 0,
    FlightNo: "",
  });
  const [sportUserType, setSportUserType] = useState({
    type: "",
    index: 0,
    fuid: 1,
  });
  const [priorityUserType, setPriorityUserType] = useState({
    type: "",
    index: 0,
    fuid: 1,
  });
  const [baggageVisible, setBaggageVisible] = useState(true);
  const [mealsVisible, setMealsVisible] = useState(false);
  const [seatsVisible, setSeatsVisible] = useState(false);
  const [sportBaggageVisible, setSportBaggageVisible] = useState(false);
  const [priorityBaggageVisible, setPriorityBaggageVisible] = useState(false);

  const [clickedFlightIndex, setClickedFlightIndex] = useState<number | null>(
    null
  );
  const [selectedAdultIndexes, setSelectedAdultIndexes] = useState<
    (number | undefined)[]
  >([]);

  const [cardAddonsVisible, setCardAddonsVisible] = useState(false);
  const [ssrValuesText, setSsrValuesText] = useState<string[][]>([]);
  const [ssrValuesChildText, setSsrValuesChildText] = useState<Array<string>>(
    Array(childrenArray?.length).fill("")
  );
  const [ssrValuesTextChild, setSsrValuesTextChild] = useState<string[][]>([]);
  const [ssrValuesTextInfant, setSsrValuesTextInfant] = useState<string[][]>(
    []
  );
  const [ssrMealValuesText, setMealSsrValuesText] = useState<string[][]>([]);
  const [ssrMealValuesChildText, setMealSsrValuesChildText] = useState<
    string[][]
  >([]);
  const [ssrMealValuesInfantText, setMealSsrValuesInfantText] = useState<
    string[][]
  >([]);
  const [ssrSeatValuesInfantText, setSeatSsrValuesInfantText] = useState<
    string[][]
  >([]);
  const [seatSsrValuesText, setSeatSsrValuesText] = useState<string[][]>([]);
  const [seatSsrValuesChildText, setSeatSsrValuesChildText] = useState<
    string[][]
  >([]);
  const [sportBaggageChildText, setSportBaggageChildText] = useState<
    string[][]
  >([]);
  const [sportBaggageInfantText, setSportBaggageInfantText] = useState<
    string[][]
  >([]);

  const [sportBaggageValuesText, setSportBaggageValuesText] = useState<
    string[][]
  >([]);

  const [priorityBaggageValuesText, setPriorityBaggageValuesText] = useState<
    string[][]
  >([]);
  const [priorityBaggageValuesChildText, setPriorityBaggageValuesChildText] =
    useState<string[][]>([]);

  const [priorityBaggageValuesInfantText, setPriorityBaggageValuesInfantText] =
    useState<string[][]>([]);

  const [ssrSeatNumber, SetSsrSeatNumber] = useState("");
  const [ssrChildSeatNumber, SetSsrChildSeatNumber] = useState("");
  const [sportBaggageInfantValue, SetSportBaggageInfantValue] = useState("");

  const [sportBaggageChildValue, SetSportBaggageChildValue] = useState("");
  const [ssrBaggageText, SetSsrBaggageText] = useState("");
  const [ssrBaggageChildText, SetSsrBaggageChildText] = useState("");
  const [ssrBaggageInfantText, SetSsrBaggageInfantText] = useState("");
  const [ssrMealText, SetSsrMealText] = useState("");
  const [ssrMealChildText, SetSsrMealChildText] = useState("");
  const [ssrMealInfantText, SetSsrMealInfantText] = useState("");
  const [ssrSeatInfantText, SetSsrSeatInfantText] = useState("");
  const [ssrSportBaggageText, SetSsrSportBaggageText] = useState("");
  const [ssrPriorityBaggageText, SetSsrPriorityBaggageText] = useState("");
  const [ssrPriorityBaggageChildText, SetSsrPriorityBaggageChildText] =
    useState("");
  const [ssrPriorityBaggageInfantText, SetSsrPriorityBaggageInfantText] =
    useState("");
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const [forNameData, setForNameData] = useState<string | null>(null);
  const [toData, setToData] = useState<string | null>(null);
  const [netFareValues, setNetFareValues] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredJourney, setHoveredJourney] = useState<string | null>(null);
  const [showCross, setShowCross] = useState(false);
  // baggage
  const [totalCharge, setTotalCharge] = useState(0);
  const [totalChargeChild, setTotalChargeChild] = useState(0);
  const [totalChargeInfant, setTotalChargeInfant] = useState(0);
  // meal
  const [mealtotalCharge, setMealTotalCharge] = useState(0);
  const [mealtotalChargeChild, setMealTotalChargeChild] = useState(0);
  const [mealtotalChargeInfant, setMealTotalChargeInfant] = useState(0);
  // seat
  const [seattotalCharge, setSeatTotalCharge] = useState(0);
  const [seattotalChargeChild, setSeatTotalChargeChild] = useState(0);
  const [seattotalChargeInfant, setSeatTotalChargeInfant] = useState(0);
  // sport
  const [sportTotalCharge, setsportTotalCharge] = useState(0);
  const [sportTotalChargeChild, setSportTotalChargeChild] = useState(0);
  const [sportTotalChargeInfant, setSportTotalChargeInfant] = useState(0);
  // Priority
  const [priorityTotalCharge, setPriorityTotalCharge] = useState(0);
  const [priorityTotalChargeChild, setPriorityTotalChargeChild] = useState(0);
  const [priorityTotalChargeInfant, setPriorityTotalChargeInfant] = useState(0);

  const {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalMealValue,
    setTotalMealValue,
    totalSeatValue,
    setTotalSeatValue,
    totalSportBaggageValue,
    setTotalSportBaggageValue,
    totalPriorityBaggageValue,
    setTotalPriorityBaggageValue,
  } = useAddons();

  const amount = finalAmount;
  ////console.log("finalAmount", finalAmount);

  const options = {
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1199: {
        items: 4,
      },
    },
  };

  const showBaggageContent = () => {
    setBaggageVisible(true);
    setMealsVisible(false);
    setSeatsVisible(false);
    setCardAddonsVisible(false);
    setSportBaggageVisible(false);
    setPriorityBaggageVisible(false);
  };

  const showMealsContent = () => {
    setBaggageVisible(false);
    setMealsVisible(true);
    setSeatsVisible(false);
    setCardAddonsVisible(false);
    setSportBaggageVisible(false);
    setPriorityBaggageVisible(false);
  };

  const showSeatsContent = () => {
    setBaggageVisible(false);
    setMealsVisible(false);
    setSeatsVisible(true);
    setCardAddonsVisible(false);
    setSportBaggageVisible(false);
    setPriorityBaggageVisible(false);
  };

  const sport_content = () => {
    setMealsVisible(false);
    setBaggageVisible(false);
    setSeatsVisible(false);
    setCardAddonsVisible(false);
    setPriorityBaggageVisible(false);
    setSportBaggageVisible(true);
  };

  const priority_content = () => {
    setMealsVisible(false);
    setBaggageVisible(false);
    setSeatsVisible(false);
    setCardAddonsVisible(false);
    setPriorityBaggageVisible(true);
    setSportBaggageVisible(false);
  };

  const showCardAddons = (type: string, indexValue: number, fuid: number) => {
    setCardAddonsVisible(true);
    setShowCross(true);
    setBaggageUserType({ type, index: indexValue, fuid: fuid });
    setSeatUserType({ type, index: indexValue, FlightNo: flights });
    setSportUserType({ type, index: indexValue, fuid: fuid });
    setPriorityUserType({ type, index: indexValue, fuid: fuid });
  };
  const hideCardAddons = () => {
    setCardAddonsVisible(false);
  };

  // updateBaggageAddon
  const updateBaggageAddons = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = addonDetails.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...addonDetails];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setAddonDetails(updatedAddonDetails);
      ////console.log(updated value setAddonDetails", updatedAddonDetails);
    } else {
      const newAddonDetails = [...addonDetails];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setAddonDetails(newAddonDetails);
      ////console.log(updated value setAddonDetails", newAddonDetails);
    }
    SetSsrBaggageText(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  useEffect(() => {
    let totalChargeaddons: number = 0;
    addonDetails.forEach((flight: any) => {
      flight.forEach((user: any) => {
        totalChargeaddons += user.charge || 0;
      });
    });
    setTotalCharge(totalChargeaddons);
    //console.log("setTotalCharge",totalCharge);
  }, [totalCharge, ssrValuesText, addonDetails]);

  const removeBaggagePrice = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setAddonDetails((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let totalChargeAddons: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          totalChargeAddons += user.charge || 0;
        });
      });
      setTotalCharge(totalChargeAddons);
      //console.log("setTotalCharge", totalCharge);
      //console.log("Total charge:", totalChargeAddons); // Log total charge
      return newValues;
    });
    ////console.log(setAddonDetails", addonDetails);

    setClickedJourney(null);
  };

  // updateBaggageAddons
  const updateBaggageAddonsChild = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = addonDetailsChild.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);

    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...addonDetailsChild];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setAddonDetailsChild(updatedAddonDetails);
      ////console.log(updated value setAddonDetailsChild", updatedAddonDetails);
    } else {
      const newAddonDetails = [...addonDetailsChild];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }

      setAddonDetailsChild(newAddonDetails);
      ////console.log(updated value setAddonDetailsChild", newAddonDetails);
    }

    setSsrValuesTextChild((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      const PaxID = ssrValues.PaxID - 1;
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      newValuesChild[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesChild;
    });
    SetSsrBaggageChildText(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  const updateBaggageAddonsInfant = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = addonDetailsInfant.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);

    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...addonDetailsInfant];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setAddonDetailsInfant(updatedAddonDetails);
      ////console.log(updated value setAddonDetailsInfant", updatedAddonDetails);
    } else {
      const newAddonDetails = [...addonDetailsInfant];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }

      setAddonDetailsInfant(newAddonDetails);
      ////console.log(updated value setAddonDetailsInfant", newAddonDetails);
    }
    setSsrValuesTextInfant((prevValuesInfant) => {
      const newValuesInfant = [...prevValuesInfant];
      if (!newValuesInfant[flightIndex]) {
        newValuesInfant[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesInfant[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesInfant;
    });

    SetSsrBaggageInfantText(`${ssrValues.Description}`);
    setIsClicked(true);
  };
  useEffect(() => {
    let totalChargeChilds: number = 0;
    addonDetailsChild.forEach((flight: any) => {
      flight.forEach((user: any) => {
        totalChargeChilds += user.charge || 0;
      });
    });
    setTotalChargeChild(totalChargeChilds);
    //console.log("setTotalChargeChild", totalChargeChilds);

    let totalChargeInfannt: number = 0;
    addonDetailsInfant.forEach((flight: any) => {
      flight.forEach((user: any) => {
        totalChargeInfannt += user.charge || 0;
      });
    });
    setTotalChargeInfant(totalChargeInfannt);
    //console.log("setTotalChargeInfant", totalChargeInfant);
  }, [totalCharge, ssrValuesText, ssrValuesTextChild, ssrValuesTextInfant]);

  useEffect(() => {
    const ssrTolatAmount = totalCharge + totalChargeChild + totalChargeInfant;
    //console.log("ssrTolatAmount", ssrTolatAmount);
    setTotalValue([ssrTolatAmount]);
    //console.log("totalValue", totalValue);
  }, [
    totalCharge,
    totalChargeChild,
    totalChargeInfant,
    ssrValuesText,
    ssrValuesTextChild,
    ssrValuesTextInfant,
  ]);

  const removeBaggagePriceChild = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);

    setSsrValuesTextChild((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });

    setAddonDetailsChild((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let totalChargechilds: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          totalChargechilds += user.charge || 0;
        });
      });
      setTotalChargeChild(totalChargechilds);
      //console.log("setTotalChargeChild", totalChargeChild);

      return newValues;
    });

    setClickedJourney(null);
  };

  const removeBaggagePriceInfant = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setSsrValuesTextInfant((prevValues) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      return newValues;
    });

    setAddonDetailsInfant((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let totalChargeInfants: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          totalChargeInfants += user.charge || 0;
        });
      });
      setTotalChargeInfant(totalChargeInfants);
      //console.log("setTotalChargeInfant", totalChargeInfant);

      return newValues;
    });

    setClickedJourney(null);
  };
  // updateMealsAddons
  const updateMealsAddons = (
    ssrMealValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = mealAddonDetails.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrMealValues.PaxID &&
        item.FUID === ssrMealValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrMealValues.PaxID);

    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...mealAddonDetails];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrMealValues,
        flightIndex,
        adultId,
      };
      setMealAddonDetails(updatedAddonDetails);
      ////console.log(updated value setMealAddonDetails", updatedAddonDetails);
    } else {
      const newAddonDetails = [...mealAddonDetails];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrMealValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrMealValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrMealValues,
          flightIndex,
          adultId,
        });
      }

      setMealAddonDetails(newAddonDetails);
      ////console.log(updated value setAddonDetails", newAddonDetails);
    }

    setMealSsrValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (!newValues[flightIndex]) {
        newValues[flightIndex] = [];
      }
      const PaxID = ssrMealValues.PaxID - 1;
      newValues[flightIndex][PaxID] = `${ssrMealValues.charge}`;
      return newValues;
    });
    SetSsrMealText(`${ssrMealValues.Description}`);
    setIsClicked(true);
  };
  const updateMealsAddonsChild = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = mealAddonDetailsChild.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...mealAddonDetailsChild];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setMealAddonDetailsChild(updatedAddonDetails);
      ////console.log(updated value setAddonDetails", updatedAddonDetails);
    } else {
      const newAddonDetails = [...mealAddonDetailsChild];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }

      setMealAddonDetailsChild(newAddonDetails);
      ////console.log(updated value setAddonDetails", newAddonDetails);
    }

    setMealSsrValuesChildText((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesChild[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesChild;
    });
    SetSsrMealChildText(`${ssrValues.Description}`);

    setIsClicked(true);
  };

  const updateMealsAddonsInfant = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = mealAddonDetailsInfant.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);

    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...mealAddonDetailsInfant];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setMealAddonDetailsInfant(updatedAddonDetails);
      ////console.log(updated value setMealAddonDetailsInfant", updatedAddonDetails);
    } else {
      const newAddonDetails = [...mealAddonDetailsInfant];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }

      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }

      setMealAddonDetailsInfant(newAddonDetails);
      ////console.log(updated value setAddonDetails", newAddonDetails);
    }

    setMealSsrValuesInfantText((prevValuesInfant) => {
      const newValuesInfant = [...prevValuesInfant];
      if (!newValuesInfant[flightIndex]) {
        newValuesInfant[flightIndex] = [];
      }

      const PaxID = ssrValues.PaxID - 1;
      newValuesInfant[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesInfant;
    });
    SetSsrMealInfantText(`${ssrValues.Description}`);
    setIsClicked(true);
  };
  useEffect(() => {
    let mealTotalChargeaddons: number = 0;
    mealAddonDetails.forEach((flight: any) => {
      flight.forEach((user: any) => {
        mealTotalChargeaddons += user.charge || 0;
      });
    });
    setMealTotalCharge(mealTotalChargeaddons);
    //console.log("setMealTotalCharge", mealtotalCharge);

    let mealTotalChargeChilds: number = 0;
    mealAddonDetailsChild.forEach((flight: any) => {
      flight.forEach((user: any) => {
        mealTotalChargeChilds += user.charge || 0;
      });
    });
    setMealTotalChargeChild(mealTotalChargeChilds);
    //console.log("setMealTotalChargeChild", mealTotalChargeChilds);

    let mealTotalChargeInfants: number = 0;
    mealAddonDetailsInfant.forEach((flight: any) => {
      flight.forEach((user: any) => {
        mealTotalChargeInfants += user.charge || 0;
      });
    });
    setMealTotalChargeInfant(mealTotalChargeInfants);
    //console.log("setMealTotalChargeInfant", mealTotalChargeInfants);
  }, [
    mealtotalCharge,
    mealtotalChargeChild,
    mealtotalChargeInfant,
    ssrMealValuesText,
    ssrMealValuesChildText,
    ssrMealValuesInfantText,
  ]);

  useEffect(() => {
    const ssrTolatAmount =
      mealtotalCharge + mealtotalChargeChild + mealtotalChargeInfant;
    //console.log("total add", ssrTolatAmount);
    setTotalMealValue([ssrTolatAmount]);
    //console.log("total setTotalMealValue", totalMealValue);
  }, [
    mealtotalCharge,
    mealtotalChargeChild,
    mealtotalChargeInfant,
    ssrMealValuesText,
    ssrMealValuesChildText,
    ssrMealValuesInfantText,
  ]);

  const removeMealPrice = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);

    setMealAddonDetails((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );

      let mealTotalChargeAddons: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          mealTotalChargeAddons += user.charge || 0;
        });
      });
      setMealTotalCharge(mealTotalChargeAddons);
      //console.log("setMealTotalCharge", mealtotalCharge);
      //console.log("Total charge:", mealTotalChargeAddons);

      return newValues;
    });
    setClickedJourney(null);
  };
  const removeMealPriceChild = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    //console.log("Removing item with userIndexToRemove:", userIndexToRemove);
    setCardAddonsVisible(false);

    setMealSsrValuesChildText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });

    setMealAddonDetailsChild((prevValues: any) => {
      const newValues = [...prevValues];

      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );

      let mealtotalChargeChilds: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          mealtotalChargeChilds += user.charge || 0;
        });
      });
      setMealTotalChargeChild(mealtotalChargeChilds);
      //console.log("setMealTotalChargeChild", mealtotalChargeChild);
      //console.log("Total charge:", mealtotalChargeChilds);

      return newValues;
    });
    setClickedJourney(null);
  };

  const removeMealPriceInfant = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setMealSsrValuesInfantText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });

    setMealAddonDetailsInfant((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );

      let mealtotalChargeInfants: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          mealtotalChargeInfants += user.charge || 0;
        });
      });
      setMealTotalChargeInfant(mealtotalChargeInfants);
      //console.log("setMealTotalChargeInfant", mealtotalChargeInfant);
      //console.log("Total charge:", mealtotalChargeInfants);

      return newValues;
    });

    setClickedJourney(null);
  };

  // updateSeatAddons
  const updateSeatAddons = (
    ssrSeatValues: {
      FUID: number;
      Fare: number;
      SSID: number;
      PaxID: number;
      Description: any;
      SeatNumber: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };
    const isDuplicateIndex = seatAddonDetails.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrSeatValues.PaxID &&
        item.FUID === ssrSeatValues.FUID
    );
    const adultId = generatedAdultID(flightIndex, ssrSeatValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...seatAddonDetails];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrSeatValues,
        flightIndex,
        adultId,
      };
      setSeatAddonDetails(updatedAddonDetails);
      ////console.log(updated value setSeatAddonDetails", updatedAddonDetails);
    } else {
      const newAddonDetails = [...seatAddonDetails];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrSeatValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrSeatValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrSeatValues,
          flightIndex,
          adultId,
        });
      }
      setSeatAddonDetails(newAddonDetails);
      ////console.log(updated value setSeatAddonDetails", newAddonDetails);
    }

    setSeatSsrValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (!newValues[flightIndex]) {
        newValues[flightIndex] = [];
      }
      const PaxID = ssrSeatValues.PaxID - 1;
      newValues[flightIndex][PaxID] = `${ssrSeatValues.Fare}`;
      return newValues;
    });
    //console.log("setSeatSsrValuesText", seatSsrValuesText);
    SetSsrSeatNumber(`${ssrSeatValues.SeatNumber}`);
    setCardAddonsVisible(false);
    setIsClicked(true);
  };

  const updateSeatAddonsChild = (
    ssrValues: {
      FUID: number;
      Fare: number;
      SSID: number;
      PaxID: number;
      Description: any;
      SeatNumber: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = seatAddonDetailsChild.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...seatAddonDetailsChild];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setSeatAddonDetailsChild(updatedAddonDetails);
      ////console.log(updated value setSeatAddonDetailsChild", updatedAddonDetails);
    } else {
      // setSeatAddonDetailsChild([...seatAddonDetailsChild, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...seatAddonDetailsChild];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setSeatAddonDetailsChild(newAddonDetails);
      ////console.log(updated value setSeatAddonDetailsChild", newAddonDetails);
    }

    setSeatSsrValuesChildText((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesChild[flightIndex][PaxID] = `${ssrValues.Fare}`;
      return newValuesChild;
    });
    SetSsrChildSeatNumber(`${ssrValues.Description}`);
    setCardAddonsVisible(false);
    setIsClicked(true);
  };

  const updateSeatAddonsInfant = (
    ssrValues: {
      FUID: number;
      Fare: number;
      SSID: number;
      PaxID: number;
      Description: any;
      SeatNumber: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = seatAddonDetailsInfant.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...seatAddonDetailsInfant];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setSeatAddonDetailsInfant(updatedAddonDetails);
      ////console.log(updated value setSeatAddonDetailsInfant", updatedAddonDetails);
    } else {
      //   setSeatAddonDetailsInfant([...seatAddonDetailsInfant, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...seatAddonDetailsInfant];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setSeatAddonDetailsInfant(newAddonDetails);
      ////console.log(updated value setSeatAddonDetailsInfant", newAddonDetails);
    }

    setSeatSsrValuesInfantText((prevValuesInfant) => {
      const newValuesInfant = [...prevValuesInfant];
      if (!newValuesInfant[flightIndex]) {
        newValuesInfant[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesInfant[flightIndex][PaxID] = `${ssrValues.Fare}`;
      return newValuesInfant;
    });
    SetSsrSeatInfantText(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  useEffect(() => {
    let seatTotalChargeaddons: number = 0;
    seatAddonDetails.forEach((flight: any) => {
      flight.forEach((user: any) => {
        // seatTotalChargeaddons += user.Fare || 0;
        const fareAsNumber = parseFloat(user.Fare) || 0;
        seatTotalChargeaddons += fareAsNumber;
      });
    });
    setSeatTotalCharge(seatTotalChargeaddons);
    //console.log("setSeatTotalCharge", seattotalCharge);

    let seatTotalChargechilds: number = 0;
    seatAddonDetailsChild.forEach((flight: any) => {
      flight.forEach((user: any) => {
        //  seatTotalChargechilds += user.Fare || 0;
        const fareAsNumber = parseFloat(user.Fare) || 0;
        seatTotalChargechilds += fareAsNumber;
      });
    });
    setSeatTotalChargeChild(seatTotalChargechilds);
    //console.log("setSeatTotalChargeChild", seattotalChargeChild);

    let seatTotalChargeinfants: number = 0;
    seatAddonDetailsInfant.forEach((flight: any) => {
      flight.forEach((user: any) => {
        // seatTotalChargeinfants += user.Fare || 0;
        const fareAsNumber = parseFloat(user.Fare) || 0;
        seatTotalChargeinfants += fareAsNumber;
      });
    });
    setSeatTotalChargeInfant(seatTotalChargeinfants);
    //console.log("setSeatTotalChargeInfant", seattotalChargeInfant);
  }, [
    seattotalCharge,
    seattotalChargeChild,
    seattotalChargeInfant,
    seatSsrValuesText,
    seatSsrValuesChildText,
    ssrSeatValuesInfantText,
  ]);

  useEffect(() => {
    const ssrTolatAmount =
      seattotalCharge + seattotalChargeChild + seattotalChargeInfant;
    //console.log("ssrTolatAmount", ssrTolatAmount);
    setTotalSeatValue([ssrTolatAmount]);
    //console.log("setTotalSeatValue", totalSeatValue);
    // localStorage.setItem('ssrTolatAmount', ssrTolatAmount.toString());
  }, [
    seattotalCharge,
    seattotalChargeChild,
    seattotalChargeInfant,
    seatSsrValuesText,
    seatSsrValuesChildText,
    ssrSeatValuesInfantText,
  ]);

  const removeSeatPrice = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);

    setSeatSsrValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setSeatSsrValuesText", seatSsrValuesText);

    setSeatAddonDetails((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let seatTotalChargeAddons: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          //  seatTotalChargeAddons += user.Fare || 0;
          const fareAsNumber = parseFloat(user.Fare) || 0;
          seatTotalChargeAddons += fareAsNumber;
        });
      });
      setSeatTotalCharge(seatTotalChargeAddons);
      //console.log("setSeatTotalCharge", seattotalCharge);

      return newValues;
    });

    setClickedJourney(null);
    setShowCross(false);
  };
  const removeSeatPriceChild = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setSeatSsrValuesChildText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });

    setSeatAddonDetailsChild((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let seatTotalChargechilds: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          //  seatTotalChargechilds += user.Fare || 0;
          const fareAsNumber = parseFloat(user.Fare) || 0;
          seatTotalChargechilds += fareAsNumber;
        });
      });
      setSeatTotalChargeChild(seatTotalChargechilds);
      //console.log("setSeatTotalChargeChild", seattotalChargeChild);

      return newValues;
    });
    ////console.log(setSeatAddonDetailsChild", seatAddonDetailsChild);
    setClickedJourney(null);
    setShowCross(false);
  };

  const removeSeatPriceInfant = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setSeatSsrValuesInfantText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setSeatSsrValuesInfantText", ssrSeatValuesInfantText);

    setSeatAddonDetailsInfant((prevValues: any) => {
      const newValues = [...prevValues];

      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let seatTotalChargeinfants: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          //seatTotalChargeinfants += user.Fare || 0;
          const fareAsNumber = parseFloat(user.Fare) || 0;
          seatTotalChargeinfants += fareAsNumber;
        });
      });
      setSeatTotalChargeInfant(seatTotalChargeinfants);
      //console.log("setSeatTotalChargeInfant", seattotalChargeInfant);

      return newValues;
    });
    ////console.log(seatAddonDetailsInfant", seatAddonDetailsInfant);
    setClickedJourney(null);
    setShowCross(false);
  };

  const updateSportBaggageAddons = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };
    const isDuplicateIndex = sportBaggageDetails.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...sportBaggageDetails];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setSportBaggageDetails(updatedAddonDetails);
      ////console.log(updated value setSportBaggageDetails", updatedAddonDetails);
    } else {
      //  setSportBaggageDetails([...sportBaggageDetails, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...sportBaggageDetails];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setSportBaggageDetails(newAddonDetails);
      ////console.log(updated value setAddonDetails", newAddonDetails);
    }

    setSportBaggageValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (!newValues[flightIndex]) {
        newValues[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValues[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValues;
    });
    setIsClicked(true);
  };

  const updateSportBaggageAddonsChild = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = sportBaggageDetailsChild.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...sportBaggageDetailsChild];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setSportBaggageDetailsChild(updatedAddonDetails);
      ////console.log(updated value setSportBaggageDetailsChild", updatedAddonDetails);
    } else {
      //  setSportBaggageDetailsChild([...sportBaggageDetailsChild, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...sportBaggageDetailsChild];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setSportBaggageDetailsChild(newAddonDetails);
      ////console.log(updated value setSportBaggageDetailsChild", newAddonDetails);
    }
    setSportBaggageChildText((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesChild[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesChild;
    });
    //console.log("setSportBaggageChildText", sportBaggageChildText);
    SetSportBaggageChildValue(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  const updateSportBaggageAddonsInfant = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = sportBaggageDetailsInfant.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );
    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...sportBaggageDetailsInfant];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setSportBaggageDetailsInfant(updatedAddonDetails);
      ////console.log(updated value setSportBaggageDetailsInfant", updatedAddonDetails);
    } else {
      //   setSportBaggageDetailsInfant([...sportBaggageDetailsInfant, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...sportBaggageDetailsInfant];

      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );

      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setSportBaggageDetailsInfant(newAddonDetails);
      ////console.log(updated value setSportBaggageDetailsInfant", newAddonDetails);
    }

    setSportBaggageInfantText((prevValuesInfant) => {
      const newValuesInfant = [...prevValuesInfant];
      if (!newValuesInfant[flightIndex]) {
        newValuesInfant[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesInfant[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesInfant;
    });
    //console.log("setSportBaggageInfantText", sportBaggageInfantText);
    SetSportBaggageInfantValue(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  useEffect(() => {
    let sportTotalChargeaddons: number = 0;
    sportBaggageDetails.forEach((flight: any) => {
      flight.forEach((user: any) => {
        sportTotalChargeaddons += user.charge || 0;
      });
    });
    setsportTotalCharge(sportTotalChargeaddons);
    //console.log("setsportTotalCharge", sportTotalCharge);

    let sportTotalChargeChilds: number = 0;
    sportBaggageDetailsChild.forEach((flight: any) => {
      flight.forEach((user: any) => {
        sportTotalChargeChilds += user.charge || 0;
      });
    });
    setSportTotalChargeChild(sportTotalChargeChilds);
    //console.log("setSportTotalChargeChild", sportTotalChargeChild);

    let sportTotalChargeInfants: number = 0;
    sportBaggageDetailsInfant.forEach((flight: any) => {
      flight.forEach((user: any) => {
        sportTotalChargeInfants += user.charge || 0;
      });
    });
    setSportTotalChargeInfant(sportTotalChargeInfants);
    //console.log("setSportTotalChargeInfant", sportTotalChargeInfant);
  }, [
    sportTotalCharge,
    sportTotalChargeChild,
    sportTotalChargeInfant,
    sportBaggageValuesText,
    sportBaggageChildText,
    sportBaggageInfantText,
  ]);

  useEffect(() => {
    const ssrTolatAmount =
      sportTotalCharge + sportTotalChargeChild + sportTotalChargeInfant;
    setTotalSportBaggageValue([ssrTolatAmount]);
    //console.log("total setTotalSportBaggageValue", totalSportBaggageValue);
  }, [
    sportTotalCharge,
    sportTotalChargeChild,
    sportTotalChargeInfant,
    sportBaggageValuesText,
    sportBaggageChildText,
    sportBaggageInfantText,
  ]);

  const removeSportBaggagePrice = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setSportBaggageValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setSportBaggageValuesText", sportBaggageValuesText);

    setSportBaggageDetails((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let sportTotalChargeaddons: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          sportTotalChargeaddons += user.charge || 0;
        });
      });
      setsportTotalCharge(sportTotalChargeaddons);
      //console.log("setsportTotalCharge", sportTotalCharge);

      return newValues;
    });
    ////console.log(setSportBaggageDetails", sportBaggageDetails);
    setClickedJourney(null);
  };

  const removeSportBaggagePriceChild = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);

    setSportBaggageChildText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setSportBaggageChildText", sportBaggageChildText);

    setSportBaggageDetailsChild((prevValues: any) => {
      const newValues = [...prevValues];

      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let sportTotalChargeChilds: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          sportTotalChargeChilds += user.charge || 0;
        });
      });
      setSportTotalChargeChild(sportTotalChargeChilds);
      //console.log("setSportTotalChargeChild", sportTotalChargeChild);

      return newValues;
    });
    ////console.log(setSportBaggageDetailsChild", sportBaggageDetailsChild);
    setClickedJourney(null);
  };

  const removeSportBaggagePriceInfant = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);

    setSportBaggageInfantText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setSportBaggageInfantText", sportBaggageInfantText);

    setSportBaggageDetailsInfant((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let sportTotalChargeInfants: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          sportTotalChargeInfants += user.charge || 0;
        });
      });
      setSportTotalChargeInfant(sportTotalChargeInfants);
      //console.log("setSportTotalChargeInfant", sportTotalChargeInfant);

      return newValues;
    });
    ////console.log(setSportBaggageDetailsInfant", sportBaggageDetailsInfant);
    setClickedJourney(null);
  };
  // updatePriorityBaggageAddons
  const updatePriorityBaggageAddons = (
    ssrValuesData: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = priorityBaggageDetails.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValuesData.PaxID &&
        item.FUID === ssrValuesData.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValuesData.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...priorityBaggageDetails];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValuesData,
        flightIndex,
        adultId,
      };
      setPriorityBaggageDetails(updatedAddonDetails);
      ////console.log(updated value setPriorityBaggageDetails", priorityBaggageDetails);
    } else {
      // setPriorityBaggageDetails([...priorityBaggageDetails, { ...ssrValuesData, flightIndex, adultId }]);
      const newAddonDetails = [...priorityBaggageDetails];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValuesData.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValuesData,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValuesData,
          flightIndex,
          adultId,
        });
      }
      setPriorityBaggageDetails(newAddonDetails);
      ////console.log(updated value setPriorityBaggageDetails", newAddonDetails);
    }
    setPriorityBaggageValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (!newValues[flightIndex]) {
        newValues[flightIndex] = [];
      }
      const PaxID = ssrValuesData.PaxID - 1;
      newValues[flightIndex][PaxID] = `${ssrValuesData.charge}`;
      return newValues;
    });
    //console.log("setPriorityBaggageValuesText", priorityBaggageValuesText);
    SetSsrPriorityBaggageText(`${ssrValuesData.Description}`);
    setIsClicked(true);
  };

  const updatePriorityBaggageAddonsChild = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = priorityBaggageDetailsChild.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...priorityBaggageDetailsChild];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setPriorityBaggageDetailsChild(updatedAddonDetails);
      ////console.log(updated value setPriorityBaggageDetailsChild", updatedAddonDetails);
    } else {
      // setPriorityBaggageDetailsChild([...priorityBaggageDetailsChild, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...priorityBaggageDetailsChild];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setPriorityBaggageDetailsChild(newAddonDetails);
      ////console.log(updated value setPriorityBaggageDetailsChild", newAddonDetails);
    }
    setPriorityBaggageValuesChildText((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesChild[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesChild;
    });
    //console.log("setPriorityBaggageValuesChildText", priorityBaggageValuesChildText);
    SetSsrPriorityBaggageChildText(`${ssrValues.Description}`);
    setIsClicked(true);
  };

  const updatePriorityBaggageAddonsInfant = (
    ssrValues: {
      FUID: number;
      charge: number;
      SSID: number;
      PaxID: number;
      Description: any;
    },
    flightIndex: number,
    passengerIndex: number
  ) => {
    const generatedAdultID = (flightIndex: any, PaxID: any) => {
      return `${flightIndex}_${PaxID}`;
    };

    const isDuplicateIndex = priorityBaggageDetailsInfant.findIndex(
      (item: any) =>
        item.flightIndex === flightIndex &&
        item.PaxID === ssrValues.PaxID &&
        item.FUID === ssrValues.FUID
    );

    const adultId = generatedAdultID(flightIndex, ssrValues.PaxID);
    if (isDuplicateIndex !== -1) {
      const updatedAddonDetails = [...priorityBaggageDetailsInfant];
      updatedAddonDetails[isDuplicateIndex] = {
        ...ssrValues,
        flightIndex,
        adultId,
      };
      setPriorityBaggageDetailsInfant(updatedAddonDetails);
      ////console.log(updated value setPriorityBaggageDetailsInfant", updatedAddonDetails);
    } else {
      // setPriorityBaggageDetailsInfant([...priorityBaggageDetailsInfant, { ...ssrValues, flightIndex, adultId }]);
      const newAddonDetails = [...priorityBaggageDetailsInfant];
      if (!newAddonDetails[flightIndex]) {
        newAddonDetails[flightIndex] = [];
      }
      const existingValueIndex = newAddonDetails[flightIndex].findIndex(
        (item: any) => item.PaxID === ssrValues.PaxID
      );
      if (existingValueIndex !== -1) {
        newAddonDetails[flightIndex][existingValueIndex] = {
          ...ssrValues,
          flightIndex,
          adultId,
        };
      } else {
        newAddonDetails[flightIndex].push({
          ...ssrValues,
          flightIndex,
          adultId,
        });
      }
      setPriorityBaggageDetailsInfant(newAddonDetails);
      // ////console.log(updated value setPriorityBaggageDetailsInfant", newAddonDetails);
    }

    setPriorityBaggageValuesInfantText((prevValuesChild) => {
      const newValuesChild = [...prevValuesChild];
      if (!newValuesChild[flightIndex]) {
        newValuesChild[flightIndex] = [];
      }
      const PaxID = ssrValues.PaxID - 1;
      newValuesChild[flightIndex][PaxID] = `${ssrValues.charge}`;
      return newValuesChild;
    });
    // //console.log("setPriorityBaggageValuesInfantText", priorityBaggageValuesInfantText);
    SetSsrPriorityBaggageInfantText(`${ssrValues.Description}`);
    setIsClicked(true);
  };
  useEffect(() => {
    let priorityTotalChargeaddons: number = 0;
    priorityBaggageDetails.forEach((flight: any) => {
      flight.forEach((user: any) => {
        priorityTotalChargeaddons += user.charge || 0;
      });
    });
    setPriorityTotalCharge(priorityTotalChargeaddons);
    // //console.log("setPriorityTotalCharge", priorityTotalCharge);

    let priorityTotalChargeChilds: number = 0;
    priorityBaggageDetailsChild.forEach((flight: any) => {
      flight.forEach((user: any) => {
        priorityTotalChargeChilds += user.charge || 0;
      });
    });
    setPriorityTotalChargeChild(priorityTotalChargeChilds);
    // //console.log("setPriorityTotalChargeChild", priorityTotalChargeChild);

    let priorityTotalChargeInfants: number = 0;
    priorityBaggageDetailsInfant.forEach((flight: any) => {
      flight.forEach((user: any) => {
        priorityTotalChargeInfants += user.charge || 0;
      });
    });
    setPriorityTotalChargeInfant(priorityTotalChargeInfants);
    // //console.log("setPriorityTotalChargeInfant", priorityTotalChargeInfant);
  }, [
    priorityTotalCharge,
    priorityTotalChargeChild,
    priorityTotalChargeInfant,
    priorityBaggageValuesText,
    priorityBaggageValuesChildText,
    priorityBaggageValuesInfantText,
  ]);

  useEffect(() => {
    const ssrTolatAmount =
      priorityTotalCharge +
      priorityTotalChargeChild +
      priorityTotalChargeInfant;
    setTotalPriorityBaggageValue([ssrTolatAmount]);
    //console.log("total setTotalPriorityBaggageValue", totalPriorityBaggageValue);
  }, [
    priorityTotalCharge,
    priorityTotalChargeChild,
    priorityTotalChargeInfant,
    priorityBaggageValuesText,
    priorityBaggageValuesChildText,
    priorityBaggageValuesInfantText,
  ]);

  const removePriorityBaggagePrice = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setPriorityBaggageValuesText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setPriorityBaggageValuesText", priorityBaggageValuesText);
    setPriorityBaggageDetails((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let priorityTotalChargeaddons: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          priorityTotalChargeaddons += user.charge || 0;
        });
      });
      setPriorityTotalCharge(priorityTotalChargeaddons);
      //console.log("setPriorityTotalCharge", priorityTotalCharge);

      return newValues;
    });
    ////console.log(setPriorityBaggageDetails", priorityBaggageDetails);
    setClickedJourney(null);
  };

  const removePriorityBaggagePriceChild = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    setCardAddonsVisible(false);
    setPriorityBaggageValuesChildText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setPriorityBaggageValuesChildText", priorityBaggageValuesChildText);

    setPriorityBaggageDetailsChild((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let priorityTotalChargeChilds: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          priorityTotalChargeChilds += user.charge || 0;
        });
      });
      setPriorityTotalChargeChild(priorityTotalChargeChilds);
      //console.log("setPriorityTotalChargeChild", priorityTotalChargeChild);

      return newValues;
    });
    ////console.log(setPriorityBaggageDetailsChild", priorityBaggageDetailsChild);
    setClickedJourney(null);
  };

  const removePriorityBaggagePriceInfant = (
    userIndexToRemove: number,
    flightIndexToRemove: number,
    fuid: number
  ) => {
    //console.log("Removing item with userIndexToRemove:", userIndexToRemove);
    setCardAddonsVisible(false);

    setPriorityBaggageValuesInfantText((prevValues) => {
      const newValues = [...prevValues];
      if (newValues[userIndexToRemove]) {
        newValues[flightIndexToRemove].splice(userIndexToRemove, 1);
      }
      return newValues;
    });
    ////console.log(setPriorityBaggageValuesInfantText", priorityBaggageValuesInfantText);

    setPriorityBaggageDetailsInfant((prevValues: any) => {
      const newValues = [...prevValues];
      newValues[flightIndexToRemove] = newValues[flightIndexToRemove].filter(
        (item: any) => item.PaxID !== userIndexToRemove + 1
      );
      let priorityTotalChargeInfants: number = 0;
      newValues.forEach((flight) => {
        flight.forEach((user: any) => {
          priorityTotalChargeInfants += user.charge || 0;
        });
      });
      setPriorityTotalChargeInfant(priorityTotalChargeInfants);
      //console.log("setPriorityTotalChargeInfant", priorityTotalChargeInfant);

      return newValues;
    });
    ////console.log(setPriorityBaggageDetailsInfant", priorityBaggageDetailsInfant);
    setClickedJourney(null);
  };

  const getCustomSerialNumber = (seatNumber: string) => {
    const numericPart = seatNumber.replace(/[^0-9]/g, ""); // Extract numeric part
    return numericPart;
  };
  useEffect(() => {
    const flightJourneyJSON = localStorage.getItem("flightJourney");

    if (flightJourneyJSON) {
      const flightJourney = JSON.parse(flightJourneyJSON);

      if (flightJourney.trips && flightJourney?.trips?.length > 0) {
        const extractedForNameData =
          flightJourney.trips[0].FromName?.split("|")[1];
        //console.log('extractedForNameData', extractedForNameData);

        const extractedToData = flightJourney.trips[0].ToName?.split("|")[1];
        //console.log('extractedToData', extractedToData);

        if (extractedForNameData) {
          setForNameData(extractedForNameData);
          setToData(extractedToData);
        } else {
          console.error(
            "FromName property does not exist in the first trip object"
          );
        }
      } else {
        console.error("The 'trips' array is empty or undefined");
      }
    } else {
      console.error("flightJourneyJSON is null or undefined");
    }
  }, []);

  const handleTotalAmount = (amount: number) => {
    setTotalAmount(totalAmount);
    //console.log("totalAmount", totalAmount);
  };

  const [clickedJourney, setClickedJourney] = useState<string | null>(null);

  const handleClick = (journeyID: string) => {
    setClickedJourney(journeyID);
  };

  const handleMouseEnter = (journeyID: string) => {
    setHoveredJourney(journeyID);
  };

  const handleMouseLeave = () => {};

  const handleCrossClick = () => {
    setShowCross(false);
  };

  useEffect(() => {
    const userFlightSearchDataFlight = localStorage.getItem(
      "userFlightSearchData"
    );

    if (userFlightSearchDataFlight) {
      const flightJourneyFlight = JSON.parse(userFlightSearchDataFlight);
      //console.log("flightJourneyFlight", flightJourneyFlight.fromPlace)
    } else {
      console.error("flightJourneyJSON is null or undefined");
    }
  }, []);

  const firstBaggage = paidSSRList?.find((tripData: any) =>
    tripData.Journey[0].Segments?.some((segment: any) =>
      segment.SSR?.some(
        (journey: any) =>
          journey.Type.includes("2") && journey?.Type?.length > 0
      )
    )
  );

  const firstMeal = paidSSRList?.find((tripData: any) =>
    tripData.Journey[0].Segments?.some((segment: any) =>
      segment.SSR?.some(
        (journey: any) =>
          journey.Type.includes("1") && journey?.Type?.length > 0
      )
    )
  );

  const firstSport = paidSSRList?.find((tripData: any) =>
    tripData.Journey[0].Segments?.some((segment: any) =>
      segment.SSR?.some(
        (journey: any) =>
          journey.Type.includes("3") && journey?.Type?.length > 0
      )
    )
  );

  const firstPriority = paidSSRList?.find((tripData: any) =>
    tripData.Journey[0].Segments?.some((segment: any) =>
      segment.SSR?.some(
        (journey: any) =>
          journey.Type.includes("8") && journey?.Type?.length > 0
      )
    )
  );

  const firstSeat =
    seatpaidSSRList?.length > 0 &&
    seatpaidSSRList?.find((tripData: any) =>
      tripData.Journey[0].Segments?.some(
        (segment: any) => segment?.Seats?.length > 0
      )
    );

  const FlightNameData = localStorage.getItem("FlightName");
  const flights =
    typeof FlightNameData === "string"
      ? JSON.parse(FlightNameData)
      : FlightNameData;
  // //console.log("flights", flights[0]?.Flight?.FlightNo);

  // const flightNumbers = flights.map((flightData: any) => flightData.Flight.FlightNo);
  // //console.log("flightNumbers", flightNumbers);

  const newFlightDatas = seatpaidSSRList;
  const dataconst =
    newFlightDatas?.length > 0 &&
    newFlightDatas[0]?.Journey[0]?.Segments.map(
      (item: any, index: any) => item
    );

  return (
    <>
      <div className="container px-0">
        <div className="row m-auto">
          <div className="col-md-12 col-sm-12 px-0">
            <h5 className="text-left">Addons (Optional) </h5>
            <div className="card">
              <div className="card-body px-0">
                <div className="container px-0">
                  <div className="row m-auto">
                    <div className="d-flex justify-content-start box-row align-items-center flex-wrap px-2 gap-2">
                      {firstBaggage && (
                        <div className="box-col" key="baggageSection">
                          <div className="sec-add">
                            <div
                              className="add-body"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              onClick={onClick}
                            >
                              <div
                                className="body-content"
                                onClick={showBaggageContent}
                              >
                                <LazyImage
                                  className="add-on-image"
                                  src={baggage}
                                  alt="baggage"
                                />
                              </div>
                              <h2 className="heading-add">
                                <span>
                                  <LazyImage src={plus} alt="plus" />{" "}
                                </span>
                                Baggage
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}

                      {firstMeal && (
                        <div className="box-col" key="mealSection">
                          <div className="sec-add">
                            <div
                              className="add-body"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              onClick={onClick}
                            >
                              <div
                                className="body-content"
                                onClick={showMealsContent}
                              >
                                <LazyImage
                                  className="add-on-image"
                                  src={meals}
                                  alt="meals"
                                />
                              </div>
                              <h2 className="heading-add">
                                <span>
                                  <LazyImage src={plus} alt="plus" />{" "}
                                </span>
                                Meals
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}

                      {firstSeat && (
                        <div className="box-col" key="seatSection">
                          <div className="sec-add">
                            <div
                              className="add-body"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              onClick={onClick}
                            >
                              <div
                                className="body-content"
                                onClick={showSeatsContent}
                              >
                                <LazyImage
                                  className="add-on-image"
                                  src={seats}
                                  alt="seats"
                                />
                              </div>
                              <h2 className="heading-add">
                                <span>
                                  <LazyImage src={plus} alt="plus" />{" "}
                                </span>
                                Seats
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}

                      {firstSport && (
                        <div className="box-col" key="sportSection">
                          <div className="sec-add">
                            <div
                              className="add-body"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              onClick={onClick}
                            >
                              <div
                                className="body-content"
                                onClick={sport_content}
                              >
                                <LazyImage
                                  className="add-on-image"
                                  src={sports}
                                  alt="sports"
                                />
                              </div>
                              <h2 className="heading-add">
                                <span>
                                  <LazyImage src={plus} alt="plus" />{" "}
                                </span>
                                Sports Equip
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}

                      {firstPriority && (
                        <div className="box-col" key="prioritySection">
                          <div className="sec-add">
                            <div
                              className="add-body"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                              onClick={onClick}
                            >
                              <div
                                className="body-content"
                                onClick={priority_content}
                              >
                                <LazyImage
                                  className="add-on-image"
                                  src={checking}
                                  alt="checking"
                                />
                              </div>
                              <h2 className="heading-add">
                                <span>
                                  <LazyImage src={plus} alt="plus" />{" "}
                                </span>
                                Priority Checking
                              </h2>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-end addons-offcanvas"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Addons</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={onHide}
          ></button>
        </div>
        <div className="offcanvas-body px-0">
          <div className="add-sub-header">
            <div className="addons-header px-3">
              <div className="d-flex justify-content-between flex-wrap addons-header-gapping">
                {firstBaggage && (
                  <div
                    key="baggageSection"
                    className="d-flex gap-2 align-items-center"
                    id="baggage"
                    onClick={showBaggageContent}
                  >
                    <LazyImage
                      className="add-section"
                      src={baggage}
                      alt="baggage"
                    />
                    <p className="add-sec mb-0"> Baggage </p>
                    <hr
                      id="addons_baggage"
                      className={`addon ${baggageVisible ? "active" : ""}`}
                    />
                  </div>
                )}

                {firstMeal && (
                  <div
                    key="mealSection"
                    className="d-flex gap-2 addons"
                    id="meals"
                    onClick={showMealsContent}
                  >
                    <LazyImage
                      className="add-section"
                      src={meals}
                      alt="meals"
                    />
                    <p className="add-sec mb-0">Meals</p>
                    <hr
                      id="addons_meals"
                      className={`addon ${mealsVisible ? "active" : ""}`}
                    />
                  </div>
                )}

                {firstSeat && (
                  <div
                    key="seatSection"
                    className="d-flex gap-2 addons"
                    id="seats"
                    onClick={showSeatsContent}
                  >
                    <LazyImage
                      className="add-section"
                      src={seats}
                      alt="seats"
                    />
                    <p className="add-sec mb-0">Seats </p>
                    <hr
                      id="seats_seats"
                      className={`addon ${seatsVisible ? "active" : ""}`}
                    />
                  </div>
                )}

                {firstSport && (
                  <div
                    key="sportSection"
                    className="d-flex gap-2 addons"
                    id="seats"
                    onClick={sport_content}
                  >
                    <LazyImage
                      className="add-section"
                      src={sports}
                      alt="sports"
                    />
                    <p className="add-sec mb-0">Sports Equip </p>
                    <hr
                      id="seats_seats"
                      className={`addon ${sportBaggageVisible ? "active" : ""}`}
                    />
                  </div>
                )}

                {firstPriority && (
                  <div
                    key="prioritySection"
                    className="d-flex gap-2 addons"
                    id="seats"
                    onClick={priority_content}
                  >
                    <LazyImage
                      className="add-section"
                      src={checking}
                      alt="checking"
                    />
                    <p className="add-sec mb-0">Priority Checking </p>
                    <hr
                      id="seats_seats"
                      className={`addon ${
                        priorityBaggageVisible ? "active" : ""
                      }`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="main-add d-flex">
            {baggageVisible && (
              <div className="add-baggage-section w-100" id="baggage_content">
                {paidSSRList?.map((trip: any, tripIndex: any) => (
                  <>
                    <div
                      key={tripIndex}
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item">
                        {flights &&
                          flights?.length > 0 &&
                          flights?.map((flight: any, flightIndex: any) => (
                            <>
                              <h2
                                key={flightIndex}
                                className="accordion-header"
                                id={`panelsStayOpen-heading-${flightIndex}`}
                              >
                                <button
                                  className="accordion-button addons"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#panelsStayOpen-collapse-${flightIndex}`}
                                  aria-expanded="true"
                                  aria-controls={`panelsStayOpen-collapse-${flightIndex}`}
                                >
                                  <div className="addons-div">
                                    <p className="addons-heading mb-0">
                                      {" "}
                                      DEPART{" "}
                                    </p>
                                    <p className="addons-heading mb-0">
                                      {" "}
                                      {
                                        flight.Flight.DepAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      <span>
                                        <LazyImage
                                          src={right_arrow}
                                          alt="arrow"
                                        />{" "}
                                      </span>
                                      {
                                        flight.Flight.ArrAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                    </p>
                                  </div>
                                </button>
                              </h2>

                              <div
                                id={`panelsStayOpen-collapse-${flightIndex}`}
                                className={`accordion-collapse collapse ${
                                  flightIndex === clickedFlightIndex
                                    ? "show"
                                    : ""
                                }`}
                                aria-labelledby={`panelsStayOpen-heading-${flightIndex}`}
                              >
                                <div className="second-div-heading py-3 text-center">
                                  <div className="hr-link">
                                    <p className="addons-heading mb-0">
                                      {" "}
                                      {
                                        flight.Flight.DepAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      <span>
                                        <LazyImage
                                          src={right_arrow}
                                          alt="arrow"
                                        />{" "}
                                      </span>
                                      {
                                        flight.Flight.ArrAirportName.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                    </p>
                                    <hr
                                      id={`addons_baggage_${flightIndex}`}
                                      className={`addons ${
                                        baggageVisible ? "active" : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                                <div className="accordion-body">
                                  {adultsArray?.map((adt, index) => (
                                    <>
                                      <div
                                        key={`${flightIndex}_adult_${index}`}
                                        className="d-flex justify-content-between px-2 gap-3"
                                        id={`${adt.id}_${flightIndex}`}
                                      >
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Adult {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!addonDetails[flightIndex]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                addonDetails[flightIndex][
                                                  userIndex
                                                ] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "adult",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add{" "}
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}
                                          {addonDetails[flightIndex]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removeBaggagePrice(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹ {passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      {cardAddonsVisible &&
                                        baggageUserType.type === "adult" &&
                                        baggageUserType.index === index &&
                                        baggageUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {/* {journey.Type.includes("2") && journey.Charge > 0 &&  segment.FUID == "1" && ( */}
                                                                  {journey.Type.includes(
                                                                    "2"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div
                                                                        key={
                                                                          journey.ID
                                                                        }
                                                                        className="card"
                                                                        id={
                                                                          journey.Type
                                                                        }
                                                                      >
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image"
                                                                            src={
                                                                              baggae_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>
                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updateBaggageAddons(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    baggageUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              // })
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}

                                  {userDataValue.CHD > 0 &&
                                    childrenArray?.map((chd, index) => (
                                      <>
                                        <div
                                          key={chd.id}
                                          className="d-flex justify-content-between px-2 gap-3"
                                        >
                                          <div className="left-adults">
                                            <p className="text-left">
                                              Child {index + 1}{" "}
                                            </p>
                                          </div>
                                          <div className="right-adults">
                                            {!addonDetailsChild[
                                              flightIndex
                                            ]?.some(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => {
                                                return (
                                                  addonDetailsChild[
                                                    flightIndex
                                                  ][userIndex] &&
                                                  passenger.PaxID == index + 1
                                                );
                                              }
                                            ) && (
                                              <p
                                                className="text-left add-cards"
                                                onClick={() =>
                                                  showCardAddons(
                                                    "child",
                                                    index,
                                                    flight.Flight.FUID
                                                  )
                                                }
                                              >
                                                Add
                                                <span className="px-2">
                                                  <LazyImage
                                                    src={plus}
                                                    alt="plus"
                                                    style={{ width: "15px" }}
                                                  />
                                                </span>
                                              </p>
                                            )}

                                            {addonDetailsChild[flightIndex]
                                              ?.filter(
                                                (passenger: any) =>
                                                  passenger.PaxID == index + 1
                                              )
                                              ?.map(
                                                (
                                                  passenger: any,
                                                  userIndex: any
                                                ) => (
                                                  <p
                                                    key={userIndex}
                                                    id={passenger.id}
                                                    className="text-left add-cards"
                                                    onClick={() =>
                                                      removeBaggagePriceChild(
                                                        index,
                                                        flightIndex,
                                                        flight.Flight.FUID
                                                      )
                                                    }
                                                  >
                                                    ₹ {passenger.charge}{" "}
                                                    {passenger.Description}
                                                    <span
                                                      className="px-2"
                                                      id={ssrBaggageText}
                                                    >
                                                      <LazyImage
                                                        src={cross}
                                                        alt="plus"
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    </span>
                                                  </p>
                                                )
                                              )}
                                          </div>
                                        </div>

                                        {cardAddonsVisible &&
                                          baggageUserType.type === "child" &&
                                          baggageUserType.index === index && (
                                            <div className="main-carouse-addons card-addons">
                                              <div className="close-btn text-end py-2">
                                                <div
                                                  className="hide-add-cards cross_icon"
                                                  onClick={hideCardAddons}
                                                >
                                                  <LazyImage
                                                    className="hide_icon"
                                                    src={cross_icon}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="carousel-owl">
                                                <OwlCarousel
                                                  className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                  {...options}
                                                >
                                                  {trip.Journey?.map(
                                                    (journey: any) => (
                                                      <>
                                                        {journey.Segments?.map(
                                                          (segment: any) => (
                                                            <>
                                                              {segment.SSR?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Type.includes(
                                                                      "2"
                                                                    ) &&
                                                                      segment.FUID ==
                                                                        flight
                                                                          .Flight
                                                                          .FUID && (
                                                                        <div className="card">
                                                                          <div className="px-2">
                                                                            <LazyImage
                                                                              className="left_image"
                                                                              src={
                                                                                baggae_image
                                                                              }
                                                                              alt=""
                                                                            />
                                                                            <h5 className="add-card charg">
                                                                              ₹
                                                                              {
                                                                                journey.Charge
                                                                              }
                                                                            </h5>

                                                                            <p className="paraText">
                                                                              {
                                                                                journey.Description
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                          <div className="inline-block text-center pb-3">
                                                                            <button
                                                                              className={`btn btn-login ${
                                                                                clickedJourney ===
                                                                                journey.ID
                                                                                  ? "btn-clicked"
                                                                                  : ""
                                                                              }`}
                                                                              onClick={() => {
                                                                                updateBaggageAddonsChild(
                                                                                  {
                                                                                    FUID: segment.FUID,
                                                                                    charge:
                                                                                      journey.Charge,
                                                                                    SSID: journey.ID,
                                                                                    PaxID:
                                                                                      baggageUserType.index +
                                                                                      1,
                                                                                    Description:
                                                                                      journey.Description,
                                                                                  },
                                                                                  flightIndex,
                                                                                  index
                                                                                );
                                                                                handleClick(
                                                                                  journey.ID
                                                                                );
                                                                              }}
                                                                              onMouseEnter={() =>
                                                                                handleMouseEnter(
                                                                                  journey.ID
                                                                                )
                                                                              }
                                                                            >
                                                                              {clickedJourney ===
                                                                              journey.ID ? (
                                                                                <FaCheck />
                                                                              ) : (
                                                                                "Add"
                                                                              )}
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                  </>
                                                                )
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </OwlCarousel>
                                              </div>
                                            </div>
                                          )}
                                      </>
                                    ))}

                                  {userDataValue.INF > 0 &&
                                    infantArray?.map((inf, index) => (
                                      <>
                                        <div className="d-flex justify-content-between px-2 gap-3">
                                          <div className="left-adults">
                                            <p className="text-left">
                                              Infant {index + 1}{" "}
                                            </p>
                                          </div>
                                          <div className="right-adults">
                                            {!addonDetailsInfant[
                                              flightIndex
                                            ]?.some(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => {
                                                return (
                                                  addonDetailsInfant[
                                                    flightIndex
                                                  ][userIndex] &&
                                                  passenger.PaxID == index + 1
                                                );
                                              }
                                            ) && (
                                              <p
                                                className="text-left add-cards"
                                                onClick={() =>
                                                  showCardAddons(
                                                    "infant",
                                                    index,
                                                    flight.Flight.FUID
                                                  )
                                                }
                                              >
                                                Add
                                                <span className="px-2">
                                                  <LazyImage
                                                    src={plus}
                                                    alt="plus"
                                                    style={{ width: "15px" }}
                                                  />
                                                </span>
                                              </p>
                                            )}
                                            {addonDetailsInfant[flightIndex]
                                              ?.filter(
                                                (passenger: any) =>
                                                  passenger.PaxID == index + 1
                                              )
                                              ?.map(
                                                (
                                                  passenger: any,
                                                  userIndex: any
                                                ) => (
                                                  <p
                                                    key={userIndex}
                                                    id={passenger.id}
                                                    className="text-left add-cards"
                                                    onClick={() =>
                                                      removeBaggagePriceInfant(
                                                        userIndex,
                                                        flightIndex,
                                                        flight.Flight.FUID
                                                      )
                                                    }
                                                  >
                                                    ₹{passenger.charge}{" "}
                                                    {passenger.Description}
                                                    <span
                                                      className="px-2"
                                                      id={ssrBaggageText}
                                                    >
                                                      <LazyImage
                                                        src={cross}
                                                        alt="plus"
                                                        style={{
                                                          width: "15px",
                                                        }}
                                                      />
                                                    </span>
                                                  </p>
                                                )
                                              )}
                                          </div>
                                        </div>

                                        {cardAddonsVisible &&
                                          baggageUserType.type === "infant" &&
                                          baggageUserType.index === index && (
                                            <div className="main-carouse-addons card-addons">
                                              <div className="close-btn text-end py-2">
                                                <div
                                                  className="hide-add-cards cross_icon"
                                                  onClick={hideCardAddons}
                                                >
                                                  <LazyImage
                                                    className="hide_icon"
                                                    src={cross_icon}
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="carousel-owl">
                                                <OwlCarousel
                                                  className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                  {...options}
                                                >
                                                  {trip.Journey?.map(
                                                    (journey: any) => (
                                                      <>
                                                        {journey.Segments?.map(
                                                          (segment: any) => (
                                                            <>
                                                              {segment.SSR?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Type.includes(
                                                                      "2"
                                                                    ) &&
                                                                      segment.FUID ==
                                                                        flight
                                                                          .Flight
                                                                          .FUID && (
                                                                        <div className="card">
                                                                          <div className="px-2">
                                                                            <LazyImage
                                                                              className="left_image MealImage"
                                                                              src={
                                                                                baggae_image
                                                                              }
                                                                              alt=""
                                                                            />
                                                                            <h5 className="add-card charg">
                                                                              ₹
                                                                              {
                                                                                journey.Charge
                                                                              }
                                                                            </h5>

                                                                            <p className="paraText">
                                                                              {
                                                                                journey.Description
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                          <div className="inline-block text-center pb-3">
                                                                            <button
                                                                              className={`btn btn-login ${
                                                                                clickedJourney ===
                                                                                journey.ID
                                                                                  ? "btn-clicked"
                                                                                  : ""
                                                                              }`}
                                                                              onClick={() => {
                                                                                updateBaggageAddonsInfant(
                                                                                  {
                                                                                    FUID: segment.FUID,
                                                                                    charge:
                                                                                      journey.Charge,
                                                                                    SSID: journey.ID,
                                                                                    PaxID:
                                                                                      baggageUserType.index +
                                                                                      1,
                                                                                    Description:
                                                                                      journey.Description,
                                                                                  },
                                                                                  flightIndex,
                                                                                  index
                                                                                );
                                                                                handleClick(
                                                                                  journey.ID
                                                                                );
                                                                              }}
                                                                              onMouseEnter={() =>
                                                                                handleMouseEnter(
                                                                                  journey.ID
                                                                                )
                                                                              }
                                                                            >
                                                                              {clickedJourney ===
                                                                              journey.ID ? (
                                                                                <FaCheck />
                                                                              ) : (
                                                                                "Add"
                                                                              )}
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                  </>
                                                                )
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </OwlCarousel>
                                              </div>
                                            </div>
                                          )}
                                      </>
                                    ))}
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}

            {mealsVisible && (
              <div className="add-baggage-section w-100" id="meals_content">
                {paidSSRList?.map((trip: any) => (
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      {flights &&
                        flights?.length > 0 &&
                        flights?.map((flight: any, flightIndex: any) => (
                          <>
                            <h2
                              key={flightIndex}
                              className="accordion-header"
                              id={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <button
                                className="accordion-button addons"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-collapse-${flightIndex}`}
                                aria-expanded="true"
                                aria-controls={`panelsStayOpen-collapse-${flightIndex}`}
                              >
                                <div className="addons-div">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    DEPART{" "}
                                  </p>
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                </div>
                              </button>
                            </h2>

                            <div
                              id={`panelsStayOpen-collapse-${flightIndex}`}
                              className={`accordion-collapse collapse ${
                                flightIndex === clickedFlightIndex ? "show" : ""
                              }`}
                              aria-labelledby={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <div className="second-div-heading py-3 text-center">
                                <div className="hr-link">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                  <hr
                                    id="meals_baggage"
                                    className={`addons ${
                                      mealsVisible ? "active" : ""
                                    }`}
                                  />
                                </div>
                              </div>
                              <div className="accordion-body">
                                {adultsArray?.map((adt, index) => (
                                  <>
                                    <div
                                      className="d-flex justify-content-between px-2 gap-3"
                                      id={adt.id}
                                    >
                                      <div className="left-adults">
                                        <p className="text-left">
                                          Adult {index + 1}{" "}
                                        </p>
                                      </div>
                                      <div className="right-adults">
                                        {!mealAddonDetails[flightIndex]?.some(
                                          (passenger: any, userIndex: any) => {
                                            return (
                                              mealAddonDetails[flightIndex][
                                                userIndex
                                              ] && passenger.PaxID == index + 1
                                            );
                                          }
                                        ) && (
                                          <p
                                            className="text-left add-cards"
                                            onClick={() =>
                                              showCardAddons(
                                                "adult",
                                                index,
                                                flight.Flight.FUID
                                              )
                                            }
                                          >
                                            Add
                                            <span className="px-2">
                                              <LazyImage
                                                src={plus}
                                                alt="plus"
                                                style={{ width: "15px" }}
                                              />
                                            </span>
                                          </p>
                                        )}

                                        {mealAddonDetails[flightIndex]
                                          ?.filter(
                                            (passenger: any) =>
                                              passenger.PaxID == index + 1
                                          )
                                          ?.map(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => (
                                              <p
                                                key={userIndex}
                                                id={passenger.id}
                                                className="text-left add-cards"
                                                onClick={() =>
                                                  removeMealPrice(
                                                    index,
                                                    flightIndex,
                                                    flight.Flight.FUID
                                                  )
                                                }
                                              >
                                                ₹{passenger.charge}{" "}
                                                {passenger.Description}
                                                <span
                                                  className="px-2"
                                                  id={ssrBaggageText}
                                                >
                                                  <LazyImage
                                                    src={cross}
                                                    alt="plus"
                                                    style={{ width: "15px" }}
                                                  />
                                                </span>
                                              </p>
                                            )
                                          )}
                                      </div>
                                    </div>

                                    {cardAddonsVisible &&
                                      baggageUserType.type === "adult" &&
                                      //     baggageUserType.index === index &&
                                      baggageUserType.index === index &&
                                      baggageUserType.fuid ===
                                        flight.Flight.FUID && (
                                        <div className="main-carouse-addons card-addons">
                                          <div className="close-btn text-end py-2">
                                            <div
                                              className="hide-add-cards cross_icon"
                                              onClick={hideCardAddons}
                                            >
                                              <LazyImage
                                                className="hide_icon"
                                                src={cross_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="carousel-owl">
                                            <OwlCarousel
                                              className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                              {...options}
                                            >
                                              {trip.Journey?.map(
                                                (journey: any) => (
                                                  <>
                                                    {journey.Segments?.map(
                                                      (segment: any) => (
                                                        <>
                                                          {segment.SSR?.map(
                                                            (journey: any) => (
                                                              <>
                                                                {journey.Type.includes(
                                                                  "1"
                                                                ) &&
                                                                  segment.FUID ==
                                                                    flight
                                                                      .Flight
                                                                      .FUID && (
                                                                    <div
                                                                      key={
                                                                        journey.ID
                                                                      }
                                                                      className="card"
                                                                      id={
                                                                        journey.Type
                                                                      }
                                                                    >
                                                                      <div className="px-2">
                                                                        <LazyImage
                                                                          className="left_image"
                                                                          src={
                                                                            meal_image
                                                                          }
                                                                          alt=""
                                                                        />
                                                                        <h5 className="add-card charg">
                                                                          ₹
                                                                          {
                                                                            journey.Charge
                                                                          }
                                                                        </h5>

                                                                        <p className="paraText">
                                                                          {
                                                                            journey.Description
                                                                          }
                                                                        </p>
                                                                      </div>
                                                                      <div className="inline-block text-center pb-3">
                                                                        <button
                                                                          className={`btn btn-login ${
                                                                            clickedJourney ===
                                                                            journey.ID
                                                                              ? "btn-clicked"
                                                                              : ""
                                                                          }`}
                                                                          onClick={() => {
                                                                            updateMealsAddons(
                                                                              {
                                                                                FUID: segment.FUID,
                                                                                charge:
                                                                                  journey.Charge,
                                                                                SSID: journey.ID,
                                                                                PaxID:
                                                                                  baggageUserType.index +
                                                                                  1,
                                                                                Description:
                                                                                  journey.Description,
                                                                                // userIndex: index + 1,
                                                                              },
                                                                              flightIndex,
                                                                              index
                                                                            );
                                                                            handleClick(
                                                                              journey.ID
                                                                            );
                                                                          }}
                                                                          onMouseEnter={() =>
                                                                            handleMouseEnter(
                                                                              journey.ID
                                                                            )
                                                                          }
                                                                        >
                                                                          {clickedJourney ===
                                                                          journey.ID ? (
                                                                            <FaCheck />
                                                                          ) : (
                                                                            "Add"
                                                                          )}
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </OwlCarousel>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                ))}

                                {userDataValue.CHD > 0 &&
                                  childrenArray?.map((chd, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Child {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!mealAddonDetailsChild[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                mealAddonDetailsChild[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "child",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}

                                          {mealAddonDetailsChild[flightIndex]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  id={passenger.id}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removeMealPriceChild(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹{passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        baggageUserType.type === "child" &&
                                        baggageUserType.index === index && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "1"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image"
                                                                            src={
                                                                              meal_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updateMealsAddonsChild(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    baggageUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}

                                {userDataValue.INF > 0 &&
                                  infantArray?.map((inf, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Infant {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!mealAddonDetailsInfant[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                mealAddonDetailsInfant[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "infant",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}

                                          {mealAddonDetailsInfant[flightIndex]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  id={passenger.id}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removeMealPriceInfant(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹{passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        baggageUserType.type === "infant" &&
                                        // baggageUserType.index === index && (
                                        baggageUserType.index === index &&
                                        baggageUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "1"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image MealImage"
                                                                            src={
                                                                              meal_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updateMealsAddonsInfant(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    baggageUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {seatsVisible && (
              <div className="add-seats-section w-100" id="seats_content">
                {seatpaidSSRList?.length > 0 &&
                  seatpaidSSRList?.map((trip: any) => (
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item">
                        {flights &&
                          flights?.length > 0 &&
                          flights?.map((flight: any, flightIndex: any) => (
                            <>
                              {dataconst.map((item: any, dataIndex: any) => (
                                <>
                                  {item.FlightNo === flight.Flight.FlightNo && (
                                    <>
                                      <h2
                                        key={flightIndex}
                                        className="accordion-header"
                                        id={`panelsStayOpen-heading-${flightIndex}`}
                                      >
                                        <button
                                          className="accordion-button addons"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#panelsStayOpen-collapse-${flightIndex}`}
                                          aria-expanded="true"
                                          aria-controls={`panelsStayOpen-collapse-${flightIndex}`}
                                        >
                                          <div className="addons-div">
                                            <p className="addons-heading mb-0">
                                              {" "}
                                              DEPART{" "}
                                            </p>
                                            <p className="addons-heading mb-0">
                                              {" "}
                                              {
                                                flight.Flight.DepAirportName.split(
                                                  "|"
                                                )[1]
                                              }{" "}
                                              <span>
                                                <LazyImage
                                                  src={right_arrow}
                                                  alt="arrow"
                                                />{" "}
                                              </span>{" "}
                                              {
                                                flight.Flight.ArrAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        </button>
                                      </h2>

                                      <div
                                        id={`panelsStayOpen-collapse-${flightIndex}`}
                                        className={`accordion-collapse collapse ${
                                          flightIndex === clickedFlightIndex
                                            ? "show"
                                            : ""
                                        }`}
                                        aria-labelledby={`panelsStayOpen-heading-${flightIndex}`}
                                      >
                                        <div className="second-div-heading py-3 text-center">
                                          <div className="hr-link">
                                            <p className="addons-heading mb-0">
                                              {" "}
                                              {
                                                flight.Flight.DepAirportName.split(
                                                  "|"
                                                )[1]
                                              }{" "}
                                              <span>
                                                <LazyImage
                                                  src={right_arrow}
                                                  alt="arrow"
                                                />{" "}
                                              </span>{" "}
                                              {
                                                flight.Flight.ArrAirportName.split(
                                                  "|"
                                                )[1]
                                              }
                                            </p>
                                            <hr
                                              id="seats_baggage"
                                              className={`addons ${
                                                seatsVisible ? "active" : ""
                                              }`}
                                            />
                                          </div>
                                        </div>
                                        <div className="accordion-body">
                                          {adultsArray?.map((adt, index) => (
                                            <>
                                              <div className="d-flex justify-content-between px-2 gap-3">
                                                <div className="left-adults">
                                                  <p className="text-left">
                                                    Adult {index + 1}{" "}
                                                  </p>
                                                </div>
                                                <div className="right-adults">
                                                  {!seatAddonDetails[
                                                    flightIndex
                                                  ]?.some(
                                                    (
                                                      passenger: any,
                                                      userIndex: any
                                                    ) => {
                                                      return (
                                                        seatAddonDetails[
                                                          flightIndex
                                                        ][userIndex] &&
                                                        passenger.PaxID ==
                                                          index + 1
                                                      );
                                                    }
                                                  ) && (
                                                    <p
                                                      className="text-left add-cards"
                                                      onClick={() =>
                                                        showCardAddons(
                                                          "adult",
                                                          index,
                                                          flight.Flight.FUID
                                                        )
                                                      }
                                                    >
                                                      Add{" "}
                                                      <span className="px-2">
                                                        <LazyImage
                                                          src={plus}
                                                          alt="plus"
                                                          style={{
                                                            width: "15px",
                                                          }}
                                                        />
                                                      </span>
                                                    </p>
                                                  )}
                                                  {seatAddonDetails[flightIndex]
                                                    ?.filter(
                                                      (passenger: any) =>
                                                        passenger.PaxID ==
                                                        index + 1
                                                    )
                                                    ?.map(
                                                      (
                                                        passenger: any,
                                                        userIndex: any
                                                      ) => (
                                                        <p
                                                          key={userIndex}
                                                          className="text-left add-cards"
                                                          onClick={() =>
                                                            removeSeatPrice(
                                                              index,
                                                              flightIndex,
                                                              flight.Flight.FUID
                                                            )
                                                          }
                                                        >
                                                          ₹ {passenger.Fare}{" "}
                                                          {passenger.SeatNumber}
                                                          <span
                                                            className="px-2"
                                                            id={ssrBaggageText}
                                                          >
                                                            <LazyImage
                                                              src={cross}
                                                              alt="plus"
                                                              style={{
                                                                width: "15px",
                                                              }}
                                                            />
                                                          </span>
                                                        </p>
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                              {console.log(
                                                `dataconst.FlightNo: ${item.FlightNo}, flight.Flight.FlightNo: ${flight.Flight.FlightNo}`
                                              )}

                                              {cardAddonsVisible &&
                                                seatUserType.type === "adult" &&
                                                seatUserType.index ===
                                                  index && (
                                                  // seatUserType.index === index && dataconst[index].FlightNo == flight.Flight.FlightNo && (
                                                  <div className="main-carouse-addons card-addons">
                                                    <div className="close-btn text-end py-2">
                                                      <div
                                                        className="hide-add-cards cross_icon"
                                                        onClick={hideCardAddons}
                                                      >
                                                        <LazyImage
                                                          className="hide_icon"
                                                          src={cross_icon}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="card">
                                                      <div className="header-card">
                                                        <h5 className="text-center">
                                                          {" "}
                                                          FRONT{" "}
                                                        </h5>
                                                      </div>
                                                      <div className="footer-card">
                                                        <h5 className="text-center">
                                                          {" "}
                                                          BACK{" "}
                                                        </h5>
                                                      </div>
                                                      <div className="seats-section">
                                                        <div className="seats-section-row mx-3">
                                                          <div className="footer-card-seats">
                                                            <div className="d-flex gap-5 py-3 align-items-end">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  F
                                                                </h3>
                                                              </div>

                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "F"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="seats-left-section index-seat py-3">
                                                                                          <h3
                                                                                            className="text-center SeatGroup"
                                                                                            id={
                                                                                              journey.SeatGroup
                                                                                            }
                                                                                          >
                                                                                            {getCustomSerialNumber(
                                                                                              journey.SeatNumber
                                                                                            )}
                                                                                          </h3>
                                                                                        </div>

                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}{" "}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                            <div className="d-flex gap-5 py-3">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  E
                                                                </h3>
                                                              </div>

                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "E"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.AvailStatus ===
                                                                                            false && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus ===
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                            <div className="d-flex gap-5 py-3">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  D
                                                                </h3>
                                                              </div>

                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "D"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.AvailStatus ===
                                                                                            false && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div className="d-flex gap-5 py-3">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  C
                                                                </h3>
                                                              </div>
                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "C"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.AvailStatus ===
                                                                                            false && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                            <div className="d-flex gap-5 py-3">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  B
                                                                </h3>
                                                              </div>
                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "B"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.AvailStatus ===
                                                                                            false && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                            <div className="d-flex gap-5 py-3 align-items-baseline">
                                                              <div className="seats-left-section">
                                                                <h3 className="text-left">
                                                                  {" "}
                                                                  A
                                                                </h3>
                                                              </div>
                                                              {trip.Journey?.map(
                                                                (
                                                                  journey: any
                                                                ) => (
                                                                  <>
                                                                    {journey.Segments?.map(
                                                                      (
                                                                        segment: any
                                                                      ) => (
                                                                        <>
                                                                          {segment.FlightNo ===
                                                                            flight
                                                                              .Flight
                                                                              .FlightNo && (
                                                                            <>
                                                                              {segment.Seats?.map(
                                                                                (
                                                                                  journey: any
                                                                                ) => (
                                                                                  <>
                                                                                    {journey.SeatNumber.includes(
                                                                                      "A"
                                                                                    ) && (
                                                                                      <div
                                                                                        key={
                                                                                          journey.ID
                                                                                        }
                                                                                        className="seats-selection-left"
                                                                                      >
                                                                                        <div className="d-flex justify-content-between gap-3">
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section fleetBlocked">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  FleetBlocked
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "Open" && (
                                                                                            <div
                                                                                              className={`seats-left-section ${
                                                                                                clickedJourney ===
                                                                                                journey.SSID
                                                                                                  ? "btn-clicked"
                                                                                                  : ""
                                                                                              }`}
                                                                                              onClick={() => {
                                                                                                updateSeatAddons(
                                                                                                  {
                                                                                                    FUID: flight
                                                                                                      .Flight
                                                                                                      .FUID,
                                                                                                    Fare: journey.Fare,
                                                                                                    SSID: journey.SSID,
                                                                                                    PaxID:
                                                                                                      seatUserType.index +
                                                                                                      1,
                                                                                                    SeatNumber:
                                                                                                      journey.SeatNumber,
                                                                                                    Description:
                                                                                                      journey.Description,
                                                                                                    // userIndex: index + 1,
                                                                                                  },
                                                                                                  flightIndex,
                                                                                                  index
                                                                                                );
                                                                                                handleClick(
                                                                                                  journey.SSID
                                                                                                );
                                                                                              }}
                                                                                              onMouseEnter={() =>
                                                                                                handleMouseEnter(
                                                                                                  journey.SSID
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              {clickedJourney ===
                                                                                              journey.SSID ? (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              ) : (
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    available_seat
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              )}
                                                                                            </div>
                                                                                          )}

                                                                                          {journey.AvailStatus ===
                                                                                            false && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Restricted" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.AvailStatus ===
                                                                                            true && (
                                                                                            <>
                                                                                              {journey.SeatStatus !==
                                                                                                "FleetBlocked" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Restricted" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "CheckedIn" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Unavailable" ||
                                                                                                journey.SeatStatus !==
                                                                                                  "Reserved" ||
                                                                                                (journey.SeatStatus !==
                                                                                                  "Blocked" && (
                                                                                                  <div className="seats-left-section Restricted">
                                                                                                    <img
                                                                                                      className="images-fluid"
                                                                                                      src={
                                                                                                        unable
                                                                                                      }
                                                                                                      alt={
                                                                                                        journey.Fare
                                                                                                      }
                                                                                                      title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                    />
                                                                                                  </div>
                                                                                                ))}
                                                                                            </>
                                                                                          )}

                                                                                          {journey.SeatStatus ===
                                                                                            "CheckedIn" && (
                                                                                            <div className="seats-left-section Restricted">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Unavailable" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "FleetBlocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Reserved" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                          {journey.SeatStatus ===
                                                                                            "Blocked" && (
                                                                                            <div className="seats-left-section Unavailable">
                                                                                              <img
                                                                                                className="images-fluid"
                                                                                                src={
                                                                                                  unable
                                                                                                }
                                                                                                alt={
                                                                                                  journey.Fare
                                                                                                }
                                                                                                title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                              />
                                                                                            </div>
                                                                                          )}
                                                                                        </div>
                                                                                        <div className="seats-left-section index-seat py-3">
                                                                                          <h3
                                                                                            className="text-left SeatGroup"
                                                                                            id={
                                                                                              journey.XValue
                                                                                            }
                                                                                          >
                                                                                            {getCustomSerialNumber(
                                                                                              journey.SeatNumber
                                                                                            )}
                                                                                          </h3>
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )
                                                                    )}
                                                                  </>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center slod-row px-3 py-3">
                                                      <div className="seats-slot">
                                                        <div className="d-flex align-items-center gap-2">
                                                          <LazyImage
                                                            className="images-fluid"
                                                            src={selected_seat}
                                                            alt=""
                                                          />
                                                          <p className="text-left mb-0">
                                                            {" "}
                                                            Your Seat
                                                          </p>
                                                        </div>
                                                      </div>

                                                      <div className="seats-slot">
                                                        <div className="d-flex align-items-center gap-2">
                                                          <LazyImage
                                                            className="images-fluid"
                                                            src={unable}
                                                            alt=""
                                                          />
                                                          <p className="text-left mb-0">
                                                            Reserved
                                                          </p>
                                                          Others
                                                        </div>
                                                      </div>

                                                      <div className="seats-slot">
                                                        <div className="d-flex align-items-center gap-2">
                                                          <LazyImage
                                                            className="images-fluid"
                                                            src={available_seat}
                                                            alt=""
                                                          />
                                                          <p className="text-left mb-0">
                                                            {" "}
                                                            Others
                                                          </p>
                                                        </div>
                                                      </div>

                                                      <div className="seats-slot">
                                                        <div className="d-flex align-items-center gap-2">
                                                          <LazyImage
                                                            className="images-fluid"
                                                            src={FleetBlocked}
                                                            alt=""
                                                          />
                                                          <p className="text-left mb-0">
                                                            {" "}
                                                            Blocked/Available at
                                                            Airport <br /> Check
                                                            -in/Occupied
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                            </>
                                          ))}

                                          {userDataValue.CHD > 0 &&
                                            childrenArray?.map((chd, index) => (
                                              <>
                                                <div className="d-flex justify-content-between px-2 gap-3">
                                                  <div className="left-adults">
                                                    <p className="text-left">
                                                      Child {index + 1}{" "}
                                                    </p>
                                                  </div>
                                                  <div className="right-adults">
                                                    {!seatAddonDetailsChild[
                                                      flightIndex
                                                    ]?.some(
                                                      (
                                                        passenger: any,
                                                        userIndex: any
                                                      ) => {
                                                        return (
                                                          seatAddonDetailsChild[
                                                            flightIndex
                                                          ][userIndex] &&
                                                          passenger.PaxID ==
                                                            index + 1
                                                        );
                                                      }
                                                    ) && (
                                                      <p
                                                        className="text-left add-cards"
                                                        onClick={() =>
                                                          showCardAddons(
                                                            "child",
                                                            index,
                                                            flight.Flight.FUID
                                                          )
                                                        }
                                                      >
                                                        Add{" "}
                                                        <span className="px-2">
                                                          <LazyImage
                                                            src={plus}
                                                            alt="plus"
                                                            style={{
                                                              width: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </p>
                                                    )}
                                                    {seatAddonDetailsChild[
                                                      flightIndex
                                                    ]
                                                      ?.filter(
                                                        (passenger: any) =>
                                                          passenger.PaxID ==
                                                          index + 1
                                                      )
                                                      ?.map(
                                                        (
                                                          passenger: any,
                                                          userIndex: any
                                                        ) => (
                                                          <p
                                                            key={userIndex}
                                                            className="text-left add-cards"
                                                            onClick={() =>
                                                              removeSeatPriceChild(
                                                                index,
                                                                flightIndex,
                                                                flight.Flight
                                                                  .FUID
                                                              )
                                                            }
                                                          >
                                                            ₹ {passenger.Fare}{" "}
                                                            {
                                                              passenger.SeatNumber
                                                            }
                                                            <span
                                                              className="px-2"
                                                              id={
                                                                ssrBaggageText
                                                              }
                                                            >
                                                              <LazyImage
                                                                src={cross}
                                                                alt="plus"
                                                                style={{
                                                                  width: "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </p>
                                                        )
                                                      )}
                                                  </div>
                                                </div>

                                                {cardAddonsVisible &&
                                                  seatUserType.type ===
                                                    "child" &&
                                                  seatUserType.index ===
                                                    index && (
                                                    // seatUserType.index === index && seatUserType.FlightNo === flight.Flight.FlightNo && (
                                                    <div className="main-carouse-addons card-addons">
                                                      <div className="close-btn text-end py-2">
                                                        <div
                                                          className="hide-add-cards cross_icon"
                                                          onClick={
                                                            hideCardAddons
                                                          }
                                                        >
                                                          <LazyImage
                                                            className="hide_icon"
                                                            src={cross_icon}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="card">
                                                        <div className="header-card">
                                                          <h5 className="text-center">
                                                            {" "}
                                                            FRONT{" "}
                                                          </h5>
                                                        </div>
                                                        <div className="footer-card">
                                                          <h5 className="text-center">
                                                            {" "}
                                                            BACK{" "}
                                                          </h5>
                                                        </div>
                                                        <div className="seats-section">
                                                          <div className="seats-section-row mx-3">
                                                            <div className="footer-card-seats">
                                                              <div className="d-flex gap-5 py-3 align-items-end">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    F
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "F"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="seats-left-section index-seat py-3">
                                                                                            <h3
                                                                                              className="text-center SeatGroup"
                                                                                              id={
                                                                                                journey.SeatGroup
                                                                                              }
                                                                                            >
                                                                                              {getCustomSerialNumber(
                                                                                                journey.SeatNumber
                                                                                              )}
                                                                                            </h3>
                                                                                          </div>

                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    E
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "E"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    D
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "D"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <br />
                                                              <br />
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    C
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "C"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    B
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "B"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3 align-items-baseline">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    {" "}
                                                                    A
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "A"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsChild(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                          <div className="seats-left-section index-seat py-3">
                                                                                            <h3
                                                                                              className="text-left SeatGroup"
                                                                                              id={
                                                                                                journey.XValue
                                                                                              }
                                                                                            >
                                                                                              {getCustomSerialNumber(
                                                                                                journey.SeatNumber
                                                                                              )}
                                                                                            </h3>
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="d-flex justify-content-between align-items-center slod-row px-3 py-3">
                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={
                                                                selected_seat
                                                              }
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Your Seat
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={unable}
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              Reserved
                                                            </p>
                                                            Others
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={
                                                                available_seat
                                                              }
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Others
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={FleetBlocked}
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Blocked/Available
                                                              at Airport <br />{" "}
                                                              Check -in/Occupied
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </>
                                            ))}

                                          {userDataValue.INF > 0 &&
                                            infantArray?.map((inf, index) => (
                                              <>
                                                <div className="d-flex justify-content-between px-2 gap-3">
                                                  <div className="left-adults">
                                                    <p className="text-left">
                                                      Infant {index + 1}{" "}
                                                    </p>
                                                  </div>
                                                  <div className="right-adults">
                                                    {!seatAddonDetailsInfant[
                                                      flightIndex
                                                    ]?.some(
                                                      (
                                                        passenger: any,
                                                        userIndex: any
                                                      ) => {
                                                        return (
                                                          seatAddonDetailsInfant[
                                                            flightIndex
                                                          ][userIndex] &&
                                                          passenger.PaxID ==
                                                            index + 1
                                                        );
                                                      }
                                                    ) && (
                                                      <p
                                                        className="text-left add-cards"
                                                        onClick={() =>
                                                          showCardAddons(
                                                            "infant",
                                                            index,
                                                            flight.Flight.FUID
                                                          )
                                                        }
                                                      >
                                                        Add{" "}
                                                        <span className="px-2">
                                                          <LazyImage
                                                            src={plus}
                                                            alt="plus"
                                                            style={{
                                                              width: "15px",
                                                            }}
                                                          />
                                                        </span>
                                                      </p>
                                                    )}
                                                    {seatAddonDetailsInfant[
                                                      flightIndex
                                                    ]
                                                      ?.filter(
                                                        (passenger: any) =>
                                                          passenger.PaxID ==
                                                          index + 1
                                                      )
                                                      ?.map(
                                                        (
                                                          passenger: any,
                                                          userIndex: any
                                                        ) => (
                                                          <p
                                                            key={userIndex}
                                                            className="text-left add-cards"
                                                            onClick={() =>
                                                              removeSeatPriceInfant(
                                                                index,
                                                                flightIndex,
                                                                flight.Flight
                                                                  .FUID
                                                              )
                                                            }
                                                          >
                                                            ₹ {passenger.Fare}{" "}
                                                            {
                                                              passenger.SeatNumber
                                                            }
                                                            <span
                                                              className="px-2"
                                                              id={
                                                                ssrBaggageText
                                                              }
                                                            >
                                                              <LazyImage
                                                                src={cross}
                                                                alt="plus"
                                                                style={{
                                                                  width: "15px",
                                                                }}
                                                              />
                                                            </span>
                                                          </p>
                                                        )
                                                      )}
                                                  </div>
                                                </div>

                                                {cardAddonsVisible &&
                                                  seatUserType.type ===
                                                    "infant" &&
                                                  seatUserType.index ===
                                                    index && (
                                                    // seatUserType.index === index && seatUserType.FlightNo === flight.Flight.FlightNo && (
                                                    <div className="main-carouse-addons card-addons">
                                                      <div className="close-btn text-end py-2">
                                                        <div
                                                          className="hide-add-cards cross_icon"
                                                          onClick={
                                                            hideCardAddons
                                                          }
                                                        >
                                                          <LazyImage
                                                            className="hide_icon"
                                                            src={cross_icon}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="card">
                                                        <div className="header-card">
                                                          <h5 className="text-center">
                                                            {" "}
                                                            FRONT{" "}
                                                          </h5>
                                                        </div>
                                                        <div className="footer-card">
                                                          <h5 className="text-center">
                                                            {" "}
                                                            BACK{" "}
                                                          </h5>
                                                        </div>
                                                        <div className="seats-section">
                                                          <div className="seats-section-row mx-3">
                                                            <div className="footer-card-seats">
                                                              <div className="d-flex gap-5 py-3 align-items-end">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    F
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "F"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="seats-left-section index-seat py-3">
                                                                                            <h3
                                                                                              className="text-center SeatGroup"
                                                                                              id={
                                                                                                journey.SeatGroup
                                                                                              }
                                                                                            >
                                                                                              {getCustomSerialNumber(
                                                                                                journey.SeatNumber
                                                                                              )}
                                                                                            </h3>
                                                                                          </div>

                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    E
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "E"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    D
                                                                  </h3>
                                                                </div>

                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "D"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <br />
                                                              <br />
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    C
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "C"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                ) : (
                                                                                                  <img
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                    title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.AvailStatus ===
                                                                                              true && (
                                                                                              <>
                                                                                                {journey.SeatStatus !==
                                                                                                  "FleetBlocked" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Restricted" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "CheckedIn" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Unavailable" ||
                                                                                                  journey.SeatStatus !==
                                                                                                    "Reserved" ||
                                                                                                  (journey.SeatStatus !==
                                                                                                    "Blocked" && (
                                                                                                    <div className="seats-left-section Restricted">
                                                                                                      <img
                                                                                                        className="images-fluid"
                                                                                                        src={
                                                                                                          unable
                                                                                                        }
                                                                                                        alt={
                                                                                                          journey.Fare
                                                                                                        }
                                                                                                        title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                      />
                                                                                                    </div>
                                                                                                  ))}
                                                                                              </>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <img
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                  title={`Fare: ${journey.Fare}| Seat Number: ${journey.SeatNumber}| Seat Type: ${journey.SeatType}`}
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    B
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "B"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <LazyImage
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                  />
                                                                                                ) : (
                                                                                                  <LazyImage
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div
                                                                                                className="seats-left-section Unavailable"
                                                                                                onClick={() =>
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                              <div className="d-flex gap-5 py-3 align-items-baseline">
                                                                <div className="seats-left-section">
                                                                  <h3 className="text-left">
                                                                    {" "}
                                                                    A
                                                                  </h3>
                                                                </div>
                                                                {trip.Journey?.map(
                                                                  (
                                                                    journey: any
                                                                  ) => (
                                                                    <>
                                                                      {journey.Segments?.map(
                                                                        (
                                                                          segment: any
                                                                        ) => (
                                                                          <>
                                                                            {segment.FlightNo ===
                                                                              flight
                                                                                .Flight
                                                                                .FlightNo && (
                                                                              <>
                                                                                {segment.Seats?.map(
                                                                                  (
                                                                                    journey: any
                                                                                  ) => (
                                                                                    <>
                                                                                      {journey.SeatNumber.includes(
                                                                                        "A"
                                                                                      ) && (
                                                                                        <div
                                                                                          key={
                                                                                            journey.ID
                                                                                          }
                                                                                          className="seats-selection-left"
                                                                                        >
                                                                                          <div className="d-flex justify-content-between gap-3">
                                                                                            {journey.SeatStatus ===
                                                                                              "FleetBlocked" && (
                                                                                              <div className="seats-left-section fleetBlocked">
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    FleetBlocked
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Open" && (
                                                                                              <div
                                                                                                className={`seats-left-section ${
                                                                                                  clickedJourney ===
                                                                                                  journey.SSID
                                                                                                    ? "btn-clicked"
                                                                                                    : ""
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                  updateSeatAddonsInfant(
                                                                                                    {
                                                                                                      FUID: flight
                                                                                                        .Flight
                                                                                                        .FUID,
                                                                                                      Fare: journey.Fare,
                                                                                                      SSID: journey.SSID,
                                                                                                      PaxID:
                                                                                                        seatUserType.index +
                                                                                                        1,
                                                                                                      SeatNumber:
                                                                                                        journey.SeatNumber,
                                                                                                      Description:
                                                                                                        journey.Description,
                                                                                                      // userIndex: index + 1,
                                                                                                    },
                                                                                                    flightIndex,
                                                                                                    index
                                                                                                  );
                                                                                                  handleClick(
                                                                                                    journey.SSID
                                                                                                  );
                                                                                                }}
                                                                                                onMouseEnter={() =>
                                                                                                  handleMouseEnter(
                                                                                                    journey.SSID
                                                                                                  )
                                                                                                }
                                                                                              >
                                                                                                {clickedJourney ===
                                                                                                journey.SSID ? (
                                                                                                  <LazyImage
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      unable
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                  />
                                                                                                ) : (
                                                                                                  <LazyImage
                                                                                                    className="images-fluid"
                                                                                                    src={
                                                                                                      available_seat
                                                                                                    }
                                                                                                    alt={
                                                                                                      journey.Fare
                                                                                                    }
                                                                                                  />
                                                                                                )}
                                                                                              </div>
                                                                                            )}

                                                                                            {journey.SeatStatus ===
                                                                                              "Restricted" && (
                                                                                              <div className="seats-left-section Restricted">
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                            {journey.SeatStatus ===
                                                                                              "Unavailable" && (
                                                                                              <div className="seats-left-section Unavailable">
                                                                                                <LazyImage
                                                                                                  className="images-fluid"
                                                                                                  src={
                                                                                                    unable
                                                                                                  }
                                                                                                  alt={
                                                                                                    journey.Fare
                                                                                                  }
                                                                                                />
                                                                                              </div>
                                                                                            )}
                                                                                          </div>
                                                                                          <div className="seats-left-section index-seat py-3">
                                                                                            <h3
                                                                                              className="text-left SeatGroup"
                                                                                              id={
                                                                                                journey.XValue
                                                                                              }
                                                                                            >
                                                                                              {getCustomSerialNumber(
                                                                                                journey.SeatNumber
                                                                                              )}
                                                                                            </h3>
                                                                                          </div>
                                                                                        </div>
                                                                                      )}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="d-flex justify-content-between align-items-center slod-row px-3 py-3">
                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={
                                                                selected_seat
                                                              }
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Your Seat
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={unable}
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              Reserved
                                                            </p>
                                                            Others
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={
                                                                available_seat
                                                              }
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Others
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="seats-slot">
                                                          <div className="d-flex align-items-center gap-2">
                                                            <LazyImage
                                                              className="images-fluid"
                                                              src={FleetBlocked}
                                                              alt=""
                                                            />
                                                            <p className="text-left mb-0">
                                                              {" "}
                                                              Blocked/Available
                                                              at Airport <br />{" "}
                                                              Check -in/Occupied
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </>
                                            ))}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {sportBaggageVisible && (
              <div className="add-baggage-section w-100" id="sport_content">
                {paidSSRList?.map((trip: any) => (
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      {flights &&
                        flights?.length > 0 &&
                        flights?.map((flight: any, flightIndex: any) => (
                          <>
                            <h2
                              key={flightIndex}
                              className="accordion-header"
                              id={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <button
                                className="accordion-button addons"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-collapse-${flightIndex}`}
                                aria-expanded="true"
                                aria-controls={`panelsStayOpen-collapse-${flightIndex}`}
                              >
                                <div className="addons-div">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    DEPART{" "}
                                  </p>
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                </div>
                              </button>
                            </h2>

                            <div
                              id={`panelsStayOpen-collapse-${flightIndex}`}
                              className={`accordion-collapse collapse ${
                                flightIndex === clickedFlightIndex ? "show" : ""
                              }`}
                              aria-labelledby={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <div className="second-div-heading py-3 text-center">
                                <div className="hr-link">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                  <hr
                                    id="addons_baggage"
                                    className={`addons ${
                                      sportBaggageVisible ? "active" : ""
                                    }`}
                                  />
                                </div>
                              </div>
                              <div className="accordion-body">
                                {adultsArray?.map((adt, index) => (
                                  <>
                                    <div
                                      className="d-flex justify-content-between px-2 gap-3"
                                      key={adt.id}
                                    >
                                      <div className="left-adults">
                                        <p className="text-left">
                                          Adult {index + 1}{" "}
                                        </p>
                                      </div>
                                      <div className="right-adults">
                                        {!sportBaggageDetails[
                                          flightIndex
                                        ]?.some(
                                          (passenger: any, userIndex: any) => {
                                            return (
                                              sportBaggageDetails[flightIndex][
                                                userIndex
                                              ] && passenger.PaxID == index + 1
                                            );
                                          }
                                        ) && (
                                          <p
                                            className="text-left add-cards"
                                            onClick={() =>
                                              showCardAddons(
                                                "adult",
                                                index,
                                                flight.Flight.FUID
                                              )
                                            }
                                          >
                                            Add{" "}
                                            <span className="px-2">
                                              <LazyImage
                                                src={plus}
                                                alt="plus"
                                                style={{ width: "15px" }}
                                              />
                                            </span>
                                          </p>
                                        )}
                                        {sportBaggageDetails[flightIndex]
                                          ?.filter(
                                            (passenger: any) =>
                                              passenger.PaxID == index + 1
                                          )
                                          ?.map(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => (
                                              <p
                                                key={userIndex}
                                                className="text-left add-cards"
                                                onClick={() =>
                                                  removeSportBaggagePrice(
                                                    index,
                                                    flightIndex,
                                                    flight.Flight.FUID
                                                  )
                                                }
                                              >
                                                ₹ {passenger.charge}{" "}
                                                {passenger.Description}
                                                <span
                                                  className="px-2"
                                                  id={ssrBaggageText}
                                                >
                                                  <LazyImage
                                                    src={cross}
                                                    alt="plus"
                                                    style={{ width: "15px" }}
                                                  />
                                                </span>
                                              </p>
                                            )
                                          )}
                                      </div>
                                    </div>

                                    {cardAddonsVisible &&
                                      sportUserType.type === "adult" &&
                                      // sportUserType.index === index && (
                                      sportUserType.index === index &&
                                      sportUserType.fuid ===
                                        flight.Flight.FUID && (
                                        <div className="main-carouse-addons card-addons">
                                          <div className="close-btn text-end py-2">
                                            <div
                                              className="hide-add-cards cross_icon"
                                              onClick={hideCardAddons}
                                            >
                                              <LazyImage
                                                className="hide_icon"
                                                src={cross_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="carousel-owl">
                                            <OwlCarousel
                                              className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                              {...options}
                                            >
                                              {trip.Journey?.map(
                                                (journey: any) => (
                                                  <>
                                                    {journey.Segments?.map(
                                                      (segment: any) => (
                                                        <>
                                                          {segment.SSR?.map(
                                                            (journey: any) => (
                                                              <>
                                                                {journey.Type.includes(
                                                                  "3"
                                                                ) &&
                                                                  segment.FUID ==
                                                                    flight
                                                                      .Flight
                                                                      .FUID && (
                                                                    <div
                                                                      key={
                                                                        journey.ID
                                                                      }
                                                                      className="card"
                                                                      id={
                                                                        journey.Type
                                                                      }
                                                                    >
                                                                      <div className="px-2">
                                                                        <LazyImage
                                                                          className="left_image"
                                                                          src={
                                                                            sport_image
                                                                          }
                                                                          alt=""
                                                                        />
                                                                        <h5 className="add-card charg">
                                                                          ₹
                                                                          {
                                                                            journey.Charge
                                                                          }
                                                                        </h5>

                                                                        <p className="paraText">
                                                                          {
                                                                            journey.Description
                                                                          }
                                                                        </p>
                                                                      </div>
                                                                      <div className="inline-block text-center pb-3">
                                                                        <button
                                                                          className={`btn btn-login ${
                                                                            clickedJourney ===
                                                                            journey.ID
                                                                              ? "btn-clicked"
                                                                              : ""
                                                                          }`}
                                                                          onClick={() => {
                                                                            updateSportBaggageAddons(
                                                                              {
                                                                                FUID: segment.FUID,
                                                                                charge:
                                                                                  journey.Charge,
                                                                                SSID: journey.ID,
                                                                                PaxID:
                                                                                  sportUserType.index +
                                                                                  1,
                                                                                Description:
                                                                                  journey.Description,
                                                                                // userIndex: index + 1,
                                                                              },
                                                                              flightIndex,
                                                                              index
                                                                            );
                                                                            handleClick(
                                                                              journey.ID
                                                                            );
                                                                          }}
                                                                          onMouseEnter={() =>
                                                                            handleMouseEnter(
                                                                              journey.ID
                                                                            )
                                                                          }
                                                                        >
                                                                          {clickedJourney ===
                                                                          journey.ID ? (
                                                                            <FaCheck />
                                                                          ) : (
                                                                            "Add"
                                                                          )}
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </OwlCarousel>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                ))}

                                {userDataValue.CHD > 0 &&
                                  childrenArray?.map((chd, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Child {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!sportBaggageDetailsChild[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                sportBaggageDetailsChild[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "child",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add{" "}
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}
                                          {sportBaggageDetailsChild[flightIndex]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removeSportBaggagePriceChild(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹ {passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        sportUserType.type === "child" &&
                                        // sportUserType.index === index && (
                                        sportUserType.index === index &&
                                        sportUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "3"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image"
                                                                            src={
                                                                              sport_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updateSportBaggageAddonsChild(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    sportUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}

                                {userDataValue.INF > 0 &&
                                  infantArray?.map((inf, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Infant {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!sportBaggageDetailsInfant[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                sportBaggageDetailsInfant[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "infant",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add{" "}
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}
                                          {sportBaggageDetailsInfant[
                                            flightIndex
                                          ]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removeSportBaggagePriceInfant(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹ {passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        sportUserType.type === "infant" &&
                                        sportUserType.index === index &&
                                        sportUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "3"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image MealImage"
                                                                            src={
                                                                              sport_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updateSportBaggageAddonsInfant(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    sportUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {priorityBaggageVisible && (
              <div className="add-baggage-section w-100" id="priority_content">
                {paidSSRList?.map((trip: any) => (
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      {flights &&
                        flights?.length > 0 &&
                        flights?.map((flight: any, flightIndex: any) => (
                          <>
                            <h2
                              key={flightIndex}
                              className="accordion-header"
                              id={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <button
                                className="accordion-button addons"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#panelsStayOpen-collapse-${flightIndex}`}
                                aria-expanded="true"
                                aria-controls={`panelsStayOpen-collapse-${flightIndex}`}
                              >
                                <div className="addons-div">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    DEPART{" "}
                                  </p>
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                </div>
                              </button>
                            </h2>

                            <div
                              id={`panelsStayOpen-collapse-${flightIndex}`}
                              className={`accordion-collapse collapse ${
                                flightIndex === clickedFlightIndex ? "show" : ""
                              }`}
                              aria-labelledby={`panelsStayOpen-heading-${flightIndex}`}
                            >
                              <div className="second-div-heading py-3 text-center">
                                <div className="hr-link">
                                  <p className="addons-heading mb-0">
                                    {" "}
                                    {
                                      flight.Flight.DepAirportName.split("|")[1]
                                    }{" "}
                                    <span>
                                      <LazyImage
                                        src={right_arrow}
                                        alt="arrow"
                                      />{" "}
                                    </span>
                                    {flight.Flight.ArrAirportName.split("|")[1]}{" "}
                                  </p>
                                  <hr
                                    id="addons_baggage"
                                    className={`addons ${
                                      priorityBaggageVisible ? "active" : ""
                                    }`}
                                  />
                                </div>
                              </div>
                              <div className="accordion-body">
                                {adultsArray?.map((adt, index) => (
                                  <>
                                    <div
                                      className="d-flex justify-content-between px-2 gap-3"
                                      key={adt.id}
                                    >
                                      <div className="left-adults">
                                        <p className="text-left">
                                          Adult {index + 1}{" "}
                                        </p>
                                      </div>
                                      <div className="right-adults">
                                        {!priorityBaggageDetails[
                                          flightIndex
                                        ]?.some(
                                          (passenger: any, userIndex: any) => {
                                            return (
                                              priorityBaggageDetails[
                                                flightIndex
                                              ][userIndex] &&
                                              passenger.PaxID == index + 1
                                            );
                                          }
                                        ) && (
                                          <p
                                            className="text-left add-cards"
                                            onClick={() =>
                                              showCardAddons(
                                                "adult",
                                                index,
                                                flight.Flight.FUID
                                              )
                                            }
                                          >
                                            Add{" "}
                                            <span className="px-2">
                                              <LazyImage
                                                src={plus}
                                                alt="plus"
                                                style={{ width: "15px" }}
                                              />
                                            </span>
                                          </p>
                                        )}
                                        {priorityBaggageDetails[flightIndex]
                                          ?.filter(
                                            (passenger: any) =>
                                              passenger.PaxID == index + 1
                                          )
                                          ?.map(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => (
                                              <p
                                                key={userIndex}
                                                className="text-left add-cards"
                                                onClick={() =>
                                                  removePriorityBaggagePrice(
                                                    index,
                                                    flightIndex,
                                                    flight.Flight.FUID
                                                  )
                                                }
                                              >
                                                ₹ {passenger.charge}{" "}
                                                {passenger.Description}
                                                <span
                                                  className="px-2"
                                                  id={ssrBaggageText}
                                                >
                                                  <LazyImage
                                                    src={cross}
                                                    alt="plus"
                                                    style={{ width: "15px" }}
                                                  />
                                                </span>
                                              </p>
                                            )
                                          )}
                                      </div>
                                    </div>

                                    {cardAddonsVisible &&
                                      priorityUserType.type === "adult" &&
                                      // priorityUserType.index === index && (
                                      priorityUserType.index === index &&
                                      priorityUserType.fuid ===
                                        flight.Flight.FUID && (
                                        <div className="main-carouse-addons card-addons">
                                          <div className="close-btn text-end py-2">
                                            <div
                                              className="hide-add-cards cross_icon"
                                              onClick={hideCardAddons}
                                            >
                                              <LazyImage
                                                className="hide_icon"
                                                src={cross_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div className="carousel-owl">
                                            <OwlCarousel
                                              className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                              {...options}
                                            >
                                              {trip.Journey?.map(
                                                (journey: any) => (
                                                  <>
                                                    {journey.Segments?.map(
                                                      (segment: any) => (
                                                        <>
                                                          {segment.SSR?.map(
                                                            (journey: any) => (
                                                              <>
                                                                {journey.Type.includes(
                                                                  "8"
                                                                ) &&
                                                                  segment.FUID ==
                                                                    flight
                                                                      .Flight
                                                                      .FUID && (
                                                                    <div
                                                                      key={
                                                                        journey.ID
                                                                      }
                                                                      className="card"
                                                                      id={
                                                                        journey.Type
                                                                      }
                                                                    >
                                                                      <div className="px-2">
                                                                        <LazyImage
                                                                          className="left_image"
                                                                          src={
                                                                            checking_image
                                                                          }
                                                                          alt=""
                                                                        />
                                                                        <h5 className="add-card charg">
                                                                          ₹
                                                                          {
                                                                            journey.Charge
                                                                          }
                                                                        </h5>

                                                                        <p className="paraText">
                                                                          {
                                                                            journey.Description
                                                                          }
                                                                        </p>
                                                                      </div>
                                                                      <div className="inline-block text-center pb-3">
                                                                        <button
                                                                          className={`btn btn-login ${
                                                                            clickedJourney ===
                                                                            journey.ID
                                                                              ? "btn-clicked"
                                                                              : ""
                                                                          }`}
                                                                          onClick={() => {
                                                                            updatePriorityBaggageAddons(
                                                                              {
                                                                                FUID: segment.FUID,
                                                                                charge:
                                                                                  journey.Charge,
                                                                                SSID: journey.ID,
                                                                                PaxID:
                                                                                  sportUserType.index +
                                                                                  1,
                                                                                Description:
                                                                                  journey.Description,
                                                                                // userIndex: index + 1,
                                                                              },
                                                                              flightIndex,
                                                                              index
                                                                            );
                                                                            handleClick(
                                                                              journey.ID
                                                                            );
                                                                          }}
                                                                          onMouseEnter={() =>
                                                                            handleMouseEnter(
                                                                              journey.ID
                                                                            )
                                                                          }
                                                                        >
                                                                          {clickedJourney ===
                                                                          journey.ID ? (
                                                                            <FaCheck />
                                                                          ) : (
                                                                            "Add"
                                                                          )}
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                              </>
                                                            )
                                                          )}
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </OwlCarousel>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                ))}

                                {userDataValue.CHD > 0 &&
                                  childrenArray?.map((chd, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Child {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!priorityBaggageDetailsChild[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                priorityBaggageDetailsChild[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "child",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add{" "}
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}
                                          {priorityBaggageDetailsChild[
                                            flightIndex
                                          ]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removePriorityBaggagePriceChild(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹ {passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        priorityUserType.type === "child" &&
                                        // priorityUserType.index === index && (
                                        sportUserType.index === index &&
                                        sportUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "8"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image"
                                                                            src={
                                                                              checking_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updatePriorityBaggageAddonsChild(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    sportUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}

                                {userDataValue.INF > 0 &&
                                  infantArray?.map((inf, index) => (
                                    <>
                                      <div className="d-flex justify-content-between px-2 gap-3">
                                        <div className="left-adults">
                                          <p className="text-left">
                                            Infant {index + 1}{" "}
                                          </p>
                                        </div>
                                        <div className="right-adults">
                                          {!priorityBaggageDetailsInfant[
                                            flightIndex
                                          ]?.some(
                                            (
                                              passenger: any,
                                              userIndex: any
                                            ) => {
                                              return (
                                                priorityBaggageDetailsInfant[
                                                  flightIndex
                                                ][userIndex] &&
                                                passenger.PaxID == index + 1
                                              );
                                            }
                                          ) && (
                                            <p
                                              className="text-left add-cards"
                                              onClick={() =>
                                                showCardAddons(
                                                  "infant",
                                                  index,
                                                  flight.Flight.FUID
                                                )
                                              }
                                            >
                                              Add{" "}
                                              <span className="px-2">
                                                <LazyImage
                                                  src={plus}
                                                  alt="plus"
                                                  style={{ width: "15px" }}
                                                />
                                              </span>
                                            </p>
                                          )}
                                          {priorityBaggageDetailsInfant[
                                            flightIndex
                                          ]
                                            ?.filter(
                                              (passenger: any) =>
                                                passenger.PaxID == index + 1
                                            )
                                            ?.map(
                                              (
                                                passenger: any,
                                                userIndex: any
                                              ) => (
                                                <p
                                                  key={userIndex}
                                                  className="text-left add-cards"
                                                  onClick={() =>
                                                    removePriorityBaggagePriceInfant(
                                                      index,
                                                      flightIndex,
                                                      flight.Flight.FUID
                                                    )
                                                  }
                                                >
                                                  ₹ {passenger.charge}{" "}
                                                  {passenger.Description}
                                                  <span
                                                    className="px-2"
                                                    id={ssrBaggageText}
                                                  >
                                                    <LazyImage
                                                      src={cross}
                                                      alt="plus"
                                                      style={{ width: "15px" }}
                                                    />
                                                  </span>
                                                </p>
                                              )
                                            )}
                                        </div>
                                      </div>

                                      {cardAddonsVisible &&
                                        priorityUserType.type === "infant" &&
                                        // priorityUserType.index === index && (
                                        priorityUserType.index === index &&
                                        priorityUserType.fuid ===
                                          flight.Flight.FUID && (
                                          <div className="main-carouse-addons card-addons">
                                            <div className="close-btn text-end py-2">
                                              <div
                                                className="hide-add-cards cross_icon"
                                                onClick={hideCardAddons}
                                              >
                                                <LazyImage
                                                  className="hide_icon"
                                                  src={cross_icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="carousel-owl">
                                              <OwlCarousel
                                                className="owl-carousel owl-theme hotel-carousel owl-hotel-room"
                                                {...options}
                                              >
                                                {trip.Journey?.map(
                                                  (journey: any) => (
                                                    <>
                                                      {journey.Segments?.map(
                                                        (segment: any) => (
                                                          <>
                                                            {segment.SSR?.map(
                                                              (
                                                                journey: any
                                                              ) => (
                                                                <>
                                                                  {journey.Type.includes(
                                                                    "8"
                                                                  ) &&
                                                                    segment.FUID ==
                                                                      flight
                                                                        .Flight
                                                                        .FUID && (
                                                                      <div className="card">
                                                                        <div className="px-2">
                                                                          <LazyImage
                                                                            className="left_image MealImage"
                                                                            src={
                                                                              checking_image
                                                                            }
                                                                            alt=""
                                                                          />
                                                                          <h5 className="add-card charg">
                                                                            ₹
                                                                            {
                                                                              journey.Charge
                                                                            }
                                                                          </h5>

                                                                          <p className="paraText">
                                                                            {
                                                                              journey.Description
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <div className="inline-block text-center pb-3">
                                                                          <button
                                                                            className={`btn btn-login ${
                                                                              clickedJourney ===
                                                                              journey.ID
                                                                                ? "btn-clicked"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => {
                                                                              updatePriorityBaggageAddonsInfant(
                                                                                {
                                                                                  FUID: segment.FUID,
                                                                                  charge:
                                                                                    journey.Charge,
                                                                                  SSID: journey.ID,
                                                                                  PaxID:
                                                                                    sportUserType.index +
                                                                                    1,
                                                                                  Description:
                                                                                    journey.Description,
                                                                                  // userIndex: index + 1,
                                                                                },
                                                                                flightIndex,
                                                                                index
                                                                              );
                                                                              handleClick(
                                                                                journey.ID
                                                                              );
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                              handleMouseEnter(
                                                                                journey.ID
                                                                              )
                                                                            }
                                                                          >
                                                                            {clickedJourney ===
                                                                            journey.ID ? (
                                                                              <FaCheck />
                                                                            ) : (
                                                                              "Add"
                                                                            )}
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </OwlCarousel>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {baggageVisible && (
          <div className="offcanvas-footer py-3">
            <div className="d-flex justify-content-between align-items-center px-3 footer-final-addons">
              <div className="left-footer-section">
                <div className="d-flex align-items-center">
                  <h4 className="text-left footer-text mb-0">
                    Total amount for your Baggage{" "}
                  </h4>

                  <hr />

                  <h4 className="text-left footer-amount mb-0 px-2">
                    ₹{totalValue}
                  </h4>
                </div>
              </div>

              <div className="right-footer-section">
                <div className="d-flex gap-3">
                  <div className="text-left">
                    <h4 className="text-left footer-right-amount mb-0">
                      {" "}
                      ₹{amount}{" "}
                    </h4>
                    <p className="text-left footer-right-net mb-0">
                      Net Fare : ₹{amount}{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-login"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={onHide}
                  >
                    {" "}
                    Done{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {mealsVisible && (
          <div className="offcanvas-footer py-3">
            <div className="d-flex justify-content-between align-items-center px-3 footer-final-addons">
              <div className="left-footer-section">
                <div className="d-flex align-items-center">
                  <h4 className="text-left footer-text mb-0">
                    Total amount for your Meals{" "}
                  </h4>

                  <hr />

                  <h4 className="text-left footer-amount mb-0 px-2">
                    ₹ {totalMealValue}
                  </h4>
                </div>
              </div>

              <div className="right-footer-section">
                <div className="d-flex gap-3">
                  <div className="text-left">
                    <h4 className="text-left footer-right-amount mb-0">
                      {" "}
                      ₹{amount}{" "}
                    </h4>
                    <p className="text-left footer-right-net mb-0">
                      Net Fare : ₹{amount}{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-login"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={onHide}
                  >
                    {" "}
                    Done{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {seatsVisible && (
          <div className="offcanvas-footer py-3">
            <div className="d-flex justify-content-between align-items-center px-3 footer-final-addons">
              <div className="left-footer-section">
                <div className="d-flex align-items-center">
                  <h4 className="text-left footer-text mb-0">
                    Total amount for your seat{" "}
                  </h4>

                  <hr />

                  <h4 className="text-left footer-amount mb-0 px-2">
                    ₹ {totalSeatValue}
                  </h4>
                </div>
              </div>

              <div className="right-footer-section">
                <div className="d-flex gap-3">
                  <div className="text-left">
                    <h4 className="text-left footer-right-amount mb-0">
                      {" "}
                      ₹{amount}{" "}
                    </h4>
                    <p className="text-left footer-right-net mb-0">
                      Net Fare : ₹{amount}{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-login"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={onHide}
                  >
                    {" "}
                    Done{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {sportBaggageVisible && (
          <div className="offcanvas-footer py-3">
            <div className="d-flex justify-content-between align-items-center px-3 footer-final-addons">
              <div className="left-footer-section">
                <div className="d-flex align-items-center">
                  <h4 className="text-left footer-text mb-0">
                    Total amount for your Sport{" "}
                  </h4>

                  <hr />

                  <h4 className="text-left footer-amount mb-0 px-2">
                    ₹{totalSportBaggageValue}
                  </h4>
                </div>
              </div>

              <div className="right-footer-section">
                <div className="d-flex gap-3">
                  <div className="text-left">
                    <h4 className="text-left footer-right-amount mb-0">
                      {" "}
                      ₹{amount}{" "}
                    </h4>
                    <p className="text-left footer-right-net mb-0">
                      Net Fare : ₹{amount}{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-login"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={onHide}
                  >
                    {" "}
                    Done{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {priorityBaggageVisible && (
          <div className="offcanvas-footer py-3">
            <div className="d-flex justify-content-between align-items-center px-3 footer-final-addons">
              <div className="left-footer-section">
                <div className="d-flex align-items-center">
                  <h4 className="text-left footer-text mb-0">
                    Total amount for your Priority{" "}
                  </h4>

                  <hr />

                  <h4 className="text-left footer-amount mb-0 px-2">
                    ₹{totalPriorityBaggageValue}
                  </h4>
                </div>
              </div>

              <div className="right-footer-section">
                <div className="d-flex gap-3">
                  <div className="text-left">
                    <h4 className="text-left footer-right-amount mb-0">
                      {" "}
                      ₹{amount}{" "}
                    </h4>
                    <p className="text-left footer-right-net mb-0">
                      Net Fare : ₹{amount}{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-login"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={onHide}
                  >
                    {" "}
                    Done{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
