import femalePng from "../../../assets/images/foundation_female.png";
import rightImg from "../../../assets/images/right.png";
import LazyImage from "../../../pages/LazyImage";

interface PoliciesDetailProps {
  roomData: any;
}

export const PoliciesDetail = ({ roomData }: PoliciesDetailProps) => {
  return (
    <section id="hotel_policies_section" style={{ display: "block" }}>
      <div className="heading_text">
        <h3 className="h3-bold mb-0">Hotel Policies</h3>
      </div>
      <div className="check-in check-out">
        <div className="block block-section mb-0">
          <span className="text-left text-body">
            Check-in: <b>2 PM</b>
          </span>
        </div>
        <div className="block block-section mb-0">
          <span className="text-left text-body">
            Check-out: <b>12 PM</b>
          </span>
        </div>
      </div>

      <div className="row femail_row pb-4">
        <div className="col-sm-4 col-md-4 femail_col">
          <div className="row body-container">
            <div className="card mx-2">
              <div className="d-flex card_body">
                <div className="circle-image">
                  <span>
                    <LazyImage className="female-icon" src={femalePng} alt="" />
                  </span>
                  <span>
                    <LazyImage className="female-icon" src={femalePng} alt="" />
                  </span>
                </div>
                <div className="image_body">
                  <h3 className="h3-bold py-2 mb-0">Couple, Bachelor Rules</h3>
                  <span className="text-left text-body">
                    {" "}
                    Unmarried couples/guests with Local IDs are allowed.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-4">
          <div className="row body-container">
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                {" "}
                Passport, Aadhar, Driving License and Govt. ID are accepted as
                ID proof(s)
              </span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body"> Pets are not allowed</span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                {" "}
                Smoking within the premises is not allowed
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-4">
          <div className="row body-container">
            <div className="content-body">
              <h3 className="h3-bold py-3 mb-0">Guest Profile</h3>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                {" "}
                Unmarried couples allowed
              </span>
            </div>
            <div className="image_body d-flex">
              <LazyImage className="right-icon" src={rightImg} alt="" />
              <span className="text-left text-body">
                {" "}
                Guests below 18 years of age are allowed
              </span>
            </div>
          </div>
        </div>
        <div className="check-in check-out">
          <div className="block block-section mb-0">
            {roomData.hotelData.descriptions.map((description: any) => (
              <div key={description.type}>
                <h6
                  style={{
                    fontWeight: "600",
                    borderBottom: "1px solid #ccc",
                    padding: "4px",
                    fontSize: "larger",
                    textAlign: "center",
                  }}
                >
                  {description.type}
                </h6>
                {description.texts.map((text: any) => (
                  <p
                    key={text.language}
                    className="text-left text-body"
                    dangerouslySetInnerHTML={{ __html: text.text }}
                    style={{ paddingLeft: "20px", paddingRight: "10px" }}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
