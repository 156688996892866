import React from "react";
import { useState } from "react";

import "./DestinationFeedback.component.css";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

export const DestinationFeedback = () => {
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);

    const handleLike = () =>{
        setLike(true);
        setDislike(false);
    }

    const handleDislike = () =>{
        setLike(false);
        setDislike(true);
    }

  return (
    <>
      <div className="feedback-con">
        {!like && !dislike && (
          <>
            <p>Were these results helpful?</p>
            <div className="like" onClick={handleLike}>
              <FaRegThumbsUp />
            </div>
            <div className="dislike" onClick={handleDislike}>
              <FaRegThumbsDown />
            </div>
          </>
        )}

        {like && (
          <div className="like-con">
            <p>Thankyou for your feedback</p>
          </div>
        )}
        {dislike && (
          <div className="dislike-con">
            <p>How can we improve your experience?</p>
            <textarea />
            <button>Submit</button>
          </div>
        )}
      </div>
    </>
  );
};
