import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { TalkToAgents } from "../components/TalkToAgent";

export const NotFound = () => {
  return (
    <AppLayout>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="text-center row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="page_not_found">404</h1>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <p className="fs-3">
                <span className="text-danger">Oops!</span> Page not found.
              </p>
              <p className="lead">The page you're looking for doesn't exist.</p>
              <a href="/" className="btn btn-login">
                Go Home
              </a>
            </div>
          </div>
        </div>
      </div>
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
