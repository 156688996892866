import { useState, useRef, useEffect } from "react";
import React from "react";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import './ActivityOverview.component.css';

export const ActivityOverview = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState("0px");
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
      if (contentRef.current) {
        setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
      }
    }, [isOpen]);

    return (
      <>
        <div className="activity-content">
          <div className="overview-header">
            <h3>Overview</h3>
          </div>
          <div className="overview-desc">
            <p>
              Miami is known as Magic City for many reasons, one of which is its
              exceptional number of astounding mansions. The million-dollar
              homes on Hibiscus Island are just a few of the impressive sights
              you’ll see on this cruise departing from Biscayne Bay. Sail around
              the city and see the famous Venetian Islands, Sunset Island, and
              Star Island where Al Capone’s mansion is located. This yacht has a
              cash bar as well so you can cruise with a cocktail.
            </p>
          </div>
        </div>

        <div className="activity-content">
          <div
            className="overview-header"
            onClick={toggleAccordion}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>What's Included</h3>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div
            className={`overview-accordion ${isOpen ? "open" : ""}`}
            ref={contentRef}
            style={{ height: height }}
          >
            <div className="overview-list">
              <ul>
                <li className="yes">
                  <div className="list-content">
                    <IoCheckmarkOutline />
                    <p>90 min sightseeing cruise of Miami</p>
                  </div>
                  <div className="list-content">
                    <IoCheckmarkOutline />
                    <p>90 min sightseeing cruise of Miami</p>
                  </div>
                </li>
                <li className="no">
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="activity-content">
          <div
            className="overview-header"
            onClick={toggleAccordion}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>What to expect</h3>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div
            className={`overview-accordion ${isOpen ? "open" : ""}`}
            ref={contentRef}
            style={{ height: height }}
          >
            <div className="overview-list">
              <ul>
                <li className="yes">
                  <div className="list-content">
                    <IoCheckmarkOutline />
                    <p>90 min sightseeing cruise of Miami</p>
                  </div>
                  <div className="list-content">
                    <IoCheckmarkOutline />
                    <p>90 min sightseeing cruise of Miami</p>
                  </div>
                </li>
                <li className="no">
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                  <div className="list-content">
                    <IoCloseOutline />
                    Alcoholic drinks (available to purchase)
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="activity-content">
          <div
            className="overview-header"
            onClick={toggleAccordion}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>Questions ?</h3>
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div
            className={`overview-accordion ${isOpen ? "open" : ""}`}
            ref={contentRef}
            style={{ height: height }}
          >
            <div className="overview-list">
              <div className="overview-button">
                <button>Help Center</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
