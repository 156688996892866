import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";
import { HeroSection } from "../components/HeroSection";
import { Offers } from "../components/Offers";
import { TalkToAgents } from "../components/TalkToAgent";
import React, { useEffect } from "react";
import { getWebsettings } from "../api/FlightAPI"; // Adjust the path accordingly

interface WebSettingsPayloadProps {
  ClientID: string;
  TUI: any;
}
export const Home = () => {
  useEffect(() => {
    const fetchWebSettingsData = async () => {
      try {
        const webSettingsPayload: WebSettingsPayloadProps = {
          ClientID: "API19201",
          TUI: "",
        };
        const webSettingsData = await getWebsettings();
        console.log("Web settings data:", webSettingsData);
      } catch (error) {
        console.error("Error getting web settings:", error);
      }
    };

    fetchWebSettingsData();
  }, []);

  return (
    <AppLayout>
      <HeroSection />
      {/* <Offers /> */}
      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
