import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminLoginAPIs } from "../api/AdminLoginAPIs";
export const AdminLogin: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const response = await AdminLoginAPIs(email, password);

      if (response.data.token) {
        localStorage.setItem("admin_token", response.data.token);

        setError("Login Successfully");
        setTimeout(() => {
          navigate("/admin");
          window.location.reload();
        }, 1000);
      } else {
        setError(response.data.errorMessage);
      }
    } catch (err) {
      setError("Error logging in. Please try again");
      console.error(err);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2 className="text-center text-dark mt-5">Admin Login </h2>
          <div className="card my-5">
            <form className="card-body cardbody-color p-lg-5">
              <div className="text-center">
                <img
                  src="https://cdn.pixabay.com/photo/2016/03/31/19/56/avatar-1295397__340.png"
                  className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                  width="200px"
                  alt="profile"
                />
              </div>

              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="User Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  id="password"
                  placeholder="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="text-center">
                <div
                  className="btn btn-login px-5 mb-5 w-100"
                  onClick={handleLogin}
                >
                  Login
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
