import { useState } from "react";
import galleryImg from "../../../assets/images/image 80.png";
import galleryIcon2 from "../../../assets/images/image 82.png";
import galleryIcon3 from "../../../assets/images/image 83.png";
import OwlCarousel from "react-owl-carousel";
import LazyImage from "../../../pages/LazyImage";
export const PhotoDetails = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1199: {
        items: 1,
      },
    },
  };
  const [showImage, setShowImage] = useState(false);
  const toggleImage = () => {
  setShowImage(!showImage);
  };
  return (
    <>
      <section id="photos_section" style={{ display: "block" }}>
        <div className="row row_photo m-auto px-2">
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3">
              <LazyImage className="gallery-icon" src={galleryImg} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup"/>
            </div>
          </div>
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3" style={{ paddingLeft: "10px" }}>
              <LazyImage className="gallery-icon" src={galleryIcon3} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup"/>
            </div>
          </div>
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3" style={{ paddingLeft: "10px" }}>
              <LazyImage className="gallery-icon" src={galleryIcon2} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup"/>
            </div>
          </div>
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3" style={{ paddingLeft: "10px" }}>
              <LazyImage className="gallery-icon" src={galleryIcon3} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup"/>
            </div>
          </div>
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3" style={{ paddingLeft: "10px" }}>
              <LazyImage className="gallery-icon" src={galleryImg} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup" />
            </div>
          </div>
          <div className="col-sm-2 px-1">
            <div className="photo-gallery py-3" style={{ paddingLeft: "10px" }}>
              <LazyImage className="gallery-icon" src={galleryIcon2} alt="" data-bs-toggle="modal"
                  data-bs-target="#photo_popup" />
            </div>
          </div>
        </div>
      </section>
      {/* {showImage && ( */}
      <div className="modal fade photos_popup"
      id="photo_popup"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel_photo_popup"
      aria-hidden="true"
      >
        <div className="overlay"> </div>
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="modal-header" style={{ border: "none" }}>
              <button
                type="button"
                className="btn-close close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="carousel-owl owl-carousel-mobile"
              style={{ display: "block" }}
              >
                <OwlCarousel
                  className="owl-carousel owl-theme photo-theme"
                  {...options}
                >
                  {/* <div className="owl-carousel owl-theme photo-theme"> */}
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryImg}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryIcon3}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryIcon2}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryIcon3}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryImg}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="offer-card">
                      <div className="offer-col">
                        <div className="col-left">
                          <div
                            className="photo-gallery py-3"
                            style={{ paddingLeft: "10px" }}
                          >
                            <LazyImage
                              className="gallery-icon"
                              src={galleryIcon2}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
