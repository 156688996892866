export const accordionData = [
    {
      title: '1 Room x 1 Night',
      subtitle: '₹ 4,099',
      content: `Base Price`,
     contentsecond: `₹ 4,099`

    },
    {
      title: 'Total Discount',
      subtitle: '₹ 410',
      content: `Total Discount`,
      contentsecond: `₹ 410`
    },
    {
      title: ' Price after Discount',
      subtitle: '₹ 3,689',
      content: ` Price after Discount`,
      contentsecond: `₹ 3,689`
    },
      {
      title: 'Hotel Taxes',
      subtitle: '₹ 443',
      content: `Hotel Taxes`,
      contentsecond: `₹ 443`
    },
     {
      title: 'Total Amount',
      subtitle: '₹ 4,137',
    },
  ];