import React, { createContext, useContext, useState, ReactNode } from 'react';
interface AddonsContextProps {
  children: ReactNode;
}

interface AddonsContextValue {
  addonDetailsData: any[]; 
  setAddonDetailsData: React.Dispatch<React.SetStateAction<any[]>>;
  mealAddonDetailsData: any[]; 
  setMealAddonDetailsData: React.Dispatch<React.SetStateAction<any[]>>; 
  seatAddonDetailsData: any[]; 
  setSeatAddonDetailsData: React.Dispatch<React.SetStateAction<any[]>>;
  finalAmount : any[];
  setFinalAmount :React.Dispatch<React.SetStateAction<number[]>>;
  totalValue: number[];
  setTotalValue: React.Dispatch<React.SetStateAction<number[]>>;
  totalAmount: number[];
  setTotalAmount: React.Dispatch<React.SetStateAction<number[]>>;
  totalMealValue: number[];
  setTotalMealValue: React.Dispatch<React.SetStateAction<number[]>>;
  totalSeatValue: number[];
  setTotalSeatValue: React.Dispatch<React.SetStateAction<number[]>>;
  totalSportBaggageValue: number[];
  setTotalSportBaggageValue: React.Dispatch<React.SetStateAction<number[]>>;
  totalPriorityBaggageValue: number[];
  setTotalPriorityBaggageValue: React.Dispatch<React.SetStateAction<number[]>>;
  totalSSRAmount: number[];
  setTotalSSRAmount: React.Dispatch<React.SetStateAction<number[]>>;
}

const AddonsContext = createContext<AddonsContextValue | undefined>(undefined);

export const AddonsProvider: React.FC<AddonsContextProps> = ({ children }) => {
  const [addonDetailsData, setAddonDetailsData] = useState<any[]>([]); 
  const [mealAddonDetailsData, setMealAddonDetailsData] = useState<any[]>([]);
  const [seatAddonDetailsData, setSeatAddonDetailsData] = useState<any[]>([]); 
  const [finalAmount, setFinalAmount] = useState<any[]>([]); 
  const [totalValue, setTotalValue] = useState<number[]>([]); // Initialize as an empty array
  const [totalAmount, setTotalAmount] = useState<number[]>([]); // Initialize as an empty array
  const [totalMealValue, setTotalMealValue] = useState<number[]>([]); // Initialize as an empty array
  const [totalSeatValue, setTotalSeatValue] = useState<number[]>([]); // Initialize as an empty array
  const [totalSportBaggageValue, setTotalSportBaggageValue] = useState<number[]>([]); // Initialize as an empty array
  const [totalPriorityBaggageValue, setTotalPriorityBaggageValue] = useState<number[]>([]); // Initialize as an empty array
  const [totalSSRAmount, setTotalSSRAmount] = useState<number[]>([]); // Initialize as an empty arrayuseState<number[]>([]);

  const value: AddonsContextValue = {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalAmount,
    setTotalAmount,
    totalMealValue,
  setTotalMealValue,
  totalSeatValue,
  setTotalSeatValue,
  totalSportBaggageValue,
  setTotalSportBaggageValue,
  totalPriorityBaggageValue,
  setTotalPriorityBaggageValue,
  totalSSRAmount,
  setTotalSSRAmount,
  };

  return (
    <AddonsContext.Provider value={value}>
      {children}
    </AddonsContext.Provider>
  );
};

export const useAddons = (): AddonsContextValue => {
  const context = useContext(AddonsContext);
  if (!context) {
    throw new Error('useAddons must be used within an AddonsProvider');
  }
  return context;
};

// AddonsContext.js


// import React, { createContext, useContext, useState, ReactNode } from 'react';

// interface AddonsContextProps {
//   children: ReactNode;
// }

// interface Addon {
//   charge: number;
//   ssrID: number;
//   Description: any;
// }

// interface AddonsContextValue {
//   addonDetailsData: Addon[][];
//   setAddonDetailsData: React.Dispatch<React.SetStateAction<Addon[][]>>;
//   mealAddonDetailsData: any[];
//   setMealAddonDetailsData: React.Dispatch<React.SetStateAction<any[]>>;
//   seatAddonDetailsData: any[];
//   setSeatAddonDetailsData: React.Dispatch<React.SetStateAction<any[]>>;
//   finalAmount: number;
//   setFinalAmount: React.Dispatch<React.SetStateAction<number>>;
// }

// const AddonsContext = createContext<AddonsContextValue | undefined>(undefined);

// export const AddonsProvider: React.FC<AddonsContextProps> = ({ children }) => {
//   const [addonDetailsData, setAddonDetailsData] = useState<Addon[][]>([]);
//   const [mealAddonDetailsData, setMealAddonDetailsData] = useState<any[]>([]);
//   const [seatAddonDetailsData, setSeatAddonDetailsData] = useState<any[]>([]);
//   const [finalAmount, setFinalAmount] = useState<number>(0);

//   const value: AddonsContextValue = {
//     addonDetailsData,
//     setAddonDetailsData,
//     mealAddonDetailsData,
//     setMealAddonDetailsData,
//     seatAddonDetailsData,
//     setSeatAddonDetailsData,
//     finalAmount,
//     setFinalAmount,
//   };

//   return (
//     <AddonsContext.Provider value={value}>
//       {children}
//     </AddonsContext.Provider>
//   );
// };

// export const useAddons = (): AddonsContextValue => {
//   const context = useContext(AddonsContext);
//   if (!context) {
//     throw new Error('useAddons must be used within an AddonsProvider');
//   }
//   return context;
// };
