import { grey, lightBlue, orange, red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";

const white = "#FFFFFF";

const black = "#000000";

// Toggle colors:
//   Active: dfeeff
//   Hover:  eff6ff

const primary = {
  contrastText: white,
  dark: "#1b3a61",
  main: "#2B8ACB",
  light: "#eff6ff",
  toggle: {
    active: "#dfeeff",
    hover: "#eff6ff",
  },
} as const;

const secondary = {
  contrastText: white,
  dark: "#00325b",
  main: "#003F72",
  light: "#33658e",
} as const;

const palette = createPalette({
  common: {
    black,
    white,
  },
  primary,
  secondary,
  success: {
    contrastText: white,
    dark: "#00a000",
    main: "#11D300",
    light: "#68ff4d",
  },
  info: {
    contrastText: white,
    dark: lightBlue[900],
    main: lightBlue[700],
    light: lightBlue[500],
  },
  warning: {
    contrastText: white,
    dark: orange[900],
    main: "#ED6C02",
    light: orange[500],
  },
  error: {
    contrastText: white,
    dark: "rgb(243, 47, 52)",
    main: "rgb(245, 84, 89)",
    light: red[400],
  },
  text: {
    primary: black,
    // link: primary.main,
  },
  background: {
    // default: '#F8F8F8',
    default: "#F8F8F8",
    paper: white,
  },
  // icon: colors.blueGrey[600],
  divider: grey[200],
});

const theme: Theme = createTheme({
  palette,
  spacing: 8,
  // overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
