import LazyImage from "../../pages/LazyImage";
// import facebookLogo from "../../assets/images/facebook-logo-1.svg";
import googleLogo from "../../assets/images/GoogleLogo.svg";
import airportImage from "../../assets/images/airport.jpg";
import { OTPModal } from "./OTPModal";
import { SuccessfulSignIn } from "./SuccessfulSignIn";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  LoginAndRegisterEmail,
  LoginAndRegisterMobile,
} from "../../api/LoginAndSignInAPIs";
import CountryCodeAutocomplete from "../forms/CountryCodeAutocomplete";
import { emailRegex, mobileRegex } from "../../utils/validation";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

interface LoginOrRegisterProps {
  openLoginModal: boolean;
}

export const LoginOrRegister = ({ openLoginModal }: LoginOrRegisterProps) => {
  const [mobileOrEmail, setMobileOrEmail] = useState("");
  const [showPhoneCodes, setShowPhoneCodes] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [inputError, setInputError] = useState(true);
  const [countryCodes, setCountryCodes] = useState([]);
  const [tokenHandled, setTokenHandled] = useState(false);

  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const getResponseCountryCode = () => {
      axios
        .get(
          "https://www.makemytrip.com/api/countryList?region=in&language=eng&currency=inr"
        )
        .then((response) => {
          setCountryCodes(response.data);
        });
    };

    const handleValidation = () => {
      if (emailRegex.test(mobileOrEmail.toLowerCase())) {
        setInputError(false);
      } else if (
        selectedCountryCode !== "" &&
        mobileRegex.test(mobileOrEmail.toLowerCase())
      ) {
        setInputError(false);
      } else {
        setInputError(true);
      }
    };
    getResponseCountryCode();
    handleValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileOrEmail, selectedCountryCode]);

  function getCookie(name: string) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return cookie[1];
      }
    }
    return null;
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const token = urlSearchParams.get("token");

    if (token && !tokenHandled) {
      localStorage.setItem("token", token);
      setTokenHandled(true);

      const lastRoute = getCookie("lastRoute");

      window.location.replace(String(lastRoute));
    }
  }, [location, tokenHandled]);

  const mobileOrEmailHandler = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    setMobileOrEmail(inputValue);

    const isEmail = inputValue.includes("@");

    // Check for mobile validation (7 digits)
    const isMobile = /^\d{7,}$/.test(inputValue);

    setShowPhoneCodes(isMobile && !isEmail);
  };

  const onSubmitLogin = () => {
    if (emailRegex.test(mobileOrEmail.toLowerCase())) {
      LoginAndRegisterEmail(mobileOrEmail.toLowerCase())
        .then((response) => {
          console.log("User registration status:", response);
          localStorage.setItem("loginId", mobileOrEmail.toLowerCase());
          toast.success(response.message, {
            toastId: "2errorInLoginIdToast",
          });
          console.log(response, "OTP response");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            toastId: "1errorInLoginIdEmailToast",
          });
        });
    } else {
      localStorage.setItem("country-code", selectedCountryCode);
      LoginAndRegisterMobile(mobileOrEmail.toLowerCase(), selectedCountryCode)
        .then((response) => {
          console.log("User registration status:", response);
          localStorage.setItem("loginId", mobileOrEmail.toLowerCase());
          toast.success(response.message, {
            toastId: "2errorInLoginIdToast",
          });
          console.log(response, "OTP response");
        })
        .catch((error) => {
          console.log(error, "error");
          toast.error(error.response.data.message, {
            toastId: "2errorInLoginIdToast",
          });
        });
    }
  };

  const googleAuthHandler = () => {
    document.cookie = `lastRoute=${location.pathname}; path=/`;

    window.open(
      `http://api.maashree.com/api/users/auth/google?returnTo=${location.pathname}`,
      "_self"
    );
  };

  const resetValues = () => {
    setMobileOrEmail("");
    setSelectedCountryCode("");
  };

  return (
    <>
      <div
        className="modal fade"
        id="modalLogin"
        aria-hidden="true"
        aria-modal="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
      >
        <div
          className="modal-dialog modal-xl  modal-dialog-centered"
          // style={{ display: "block" }}
        >
          <div className="modal-content site-modal">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetValues}
              ></button>

              <div className="row modal-row1">
                <div className="col-sm-6">
                  <div className="modal-left">
                    <h2 className="modal-head">Login or Register</h2>
                    {/* <div className="mb-4 text-left1">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email or Mobile Number
                      </label>

                      {showPhoneCodes && (
                        <CountryCodeAutocomplete
                          countryCodes={countryCodes}
                          setSelectedCountryCode={setSelectedCountryCode}
                        />
                      )}

                      <input
                        type="text"
                        className="form-control"
                        value={mobileOrEmail}
                        onChange={mobileOrEmailHandler}
                        id="exampleFormControlInput1"
                        placeholder="Enter Email or Mobile Number"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (!inputError && buttonRef.current) {
                              buttonRef.current.click();
                            }
                          }
                        }}
                      />
                    </div> */}

                    {/* <div className="mb-4 text-left">
                      <button
                        ref={buttonRef}
                        className="btn lg-btn"
                        onClick={() => onSubmitLogin()}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        data-bs-dismiss="modal"
                        disabled={inputError}
                      >
                        Continue
                      </button>
                    </div> */}

                    <div className="mb-4 signin-text">
                      <span>Sign in with your email id</span>
                    </div>

                    <div className="mb-3 text-left">
                      <button
                        type="submit"
                        className="btn lg-btn google-btn"
                        onClick={googleAuthHandler}
                      >
                        <LazyImage src={googleLogo} alt="" /> Continue with
                        Google
                      </button>
                    </div>

                    {/* <div className="mb-4 text-left">
                      <button
                        type="submit"
                        className="btn lg-btn fb-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        data-bs-dismiss="modal"
                      >
                        <LazyImage src={facebookLogo} alt="" /> Continue with
                        Facebook
                      </button>
                    </div> */}
                    <p style={{ fontSize: "small" }}>
                      By clicking Continue, you agree to our{" "}
                      <a href="/terms-of-use" target="_blank">
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="modal-right">
                    <LazyImage src={airportImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OTPModal resetValues={resetValues} />

      <SuccessfulSignIn />
    </>
  );
};
