import axios from "axios";
import {
  otpVerifyURL,
  registerOrLoginURL,
  resendOtpURL,
  sendOTPURL,
} from "./endpoints";

// Login or register

export async function LoginAndRegisterEmail(loginId: string): Promise<any> {
  const payload = {
    loginId,
  };

  try {
    const response = await axios.post(registerOrLoginURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error checking user registration:", error);
    throw error;
  }
}

export async function LoginAndRegisterMobile(
  loginId: string,
  code: string
): Promise<any> {
  const payload = {
    loginId,
    countryCode: code,
  };

  try {
    const response = await axios.post(registerOrLoginURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error checking user registration:", error);
    throw error;
  }
}

// OTP

export async function OTPVerifyEmail(
  isLogin: boolean,
  loginId: string,
  code: string,
  email?: string
) {
  const payload = {
    isLogin,
    loginId,
    code,
    email,
  };

  try {
    const response = await axios.post(otpVerifyURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error confirming OTP:", error);
    throw error;
  }
}

export async function OTPVerifyMobile(
  isLogin: boolean,
  loginId: string,
  code: string,
  countryCode: string,
  mobileNumber?: string
) {
  const payload = {
    isLogin,
    loginId,
    code,
    countryCode,
    mobileNumber,
  };

  try {
    const response = await axios.post(otpVerifyURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error confirming OTP:", error);
    throw error;
  }
}

export async function OTPResendEmail(loginId: string) {
  const payload = {
    loginId,
  };

  try {
    const response = await axios.post(resendOtpURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error Resend OTP:", error);
    throw error;
  }
}

export async function OTPResendMobile(loginId: string, countryCode: string) {
  const payload = {
    loginId,
    countryCode,
  };

  try {
    const response = await axios.post(resendOtpURL, payload);
    return response.data;
  } catch (error) {
    console.error("Error Resend OTP:", error);
    throw error;
  }
}

export async function sendOTPVerifyEmail(loginId: string, countryCode: string) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    loginId,
    countryCode,
  };

  try {
    const response = await axios.post(sendOTPURL, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error in OTP:", error);
    throw error;
  }
}

export async function sendOTPVerifyMobile(
  loginId: string,
  countryCode: string
) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    loginId,
    countryCode,
  };

  try {
    const response = await axios.post(sendOTPURL, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error in OTP:", error);
    throw error;
  }
}
