// AvailableForm.jsx
import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const AvailableForm = () => {
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="available-form">
      <div className="wrapper" onClick={toggleCalendar}>
        <CiCalendar />
        <input type="text" />
      </div>
      {showCalendar && (
        <div className="calendar-container">
          <Calendar />
        </div>
      )}
    </div>
  );
};
