import React, { useState } from 'react';
import LazyImage from "../../pages/LazyImage";
const Accordion = ({ title, subtitle, content, contentsecond }: any) => {
const [isActive, setIsActive] = useState(false);
    return (
        <div className="main-box" onClick={() => setIsActive(!isActive)}>
            <div className='hotel-arrow-icon'>
                {isActive ?
              <LazyImage
                className="image-arrow active"
                width={18}
                height={18}
                src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                alt="collapse-arrow"
            /> : 
            <LazyImage
                className="image-arrow"
                width={18}
                height={18}
                src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                alt="collapse-arrow"
            />}
            </div>
            <div className="box-header accordion"
                id="acc_image_first"
            >
                <h3 className="box-heading">{title}</h3>
                <h3 className="box-heading">{subtitle}</h3>

            </div>
            {isActive &&
             <ul
                className="w3-animate-left"

            >
                <li className="box-content">
                    <div className="box-subcontent">
                        <p className="box-list">{content}</p>
                        <p className="box-list">{contentsecond}</p>
                    </div>
                </li>
            </ul>

            }
        </div>
    );
};

export default Accordion;