import { AppLayout } from "../components/AppLayout";
import Footer from "../components/Footer";

export const PrivacyPolicy = () => {
  return (
    <AppLayout>
      <div className="container px-3 pt-5" style={{ marginTop: "5rem" }}>
        <h2 className="mb-4" style={{ textAlign: "center" }}>
          Privacy & Security
        </h2>

        <p>
          Welcome to our Maashree Tours And Travels World's Privacy and Security
          page. Here, we outline our commitment to safeguarding your privacy and
          explain how we collect, process, store, and share your data. By using
          our website, you agree to the terms of this policy.
        </p>

        <h3 className="mt-4">Comprehensive Privacy Policy</h3>
        <p>
          For detailed information on how we handle your data, please read our{" "}
          Privacy Policy. This document covers our data practices, user rights,
          and contact information for privacy-related queries.
        </p>

        <h3 className="mt-4">Data Collection and Consent</h3>
        <p>
          When you use our website, we may collect various types of data,
          including personal information, cookies, IP addresses, and device
          information. This data helps us enhance your experience and provide
          better services.
        </p>
        <p>
          We obtain explicit consent from you before collecting and processing
          your data, in line with applicable data protection laws. By using our
          travel website, you agree to our data collection practices.
        </p>

        <h3 className="mt-4">Cookie Policy</h3>
        <p>
          Cookies are small text files stored on your device that help improve
          your browsing experience. They allow us to remember your preferences
          and provide a seamless user experience.
        </p>
        <p>
          Our website uses cookies for various purposes, such as analytics,
          personalization, and site functionality. By visiting our website, you
          consent to the use of cookies as outlined in our{" "}
          <a href="/cookie-policy" target="_blank">
            Cookie Policy
          </a>
          .
        </p>
        <p>
          You have the option to manage your cookie preferences. You can adjust
          your settings to accept or reject certain types of cookies. Please
          refer to our Cookie Policy for detailed instructions.
        </p>

        <h3 className="mt-4">Security Measures</h3>
        <p>
          Protecting your data is a top priority for us. We have implemented
          robust security measures to prevent unauthorized access, data
          breaches, and other potential threats. Your data is encrypted and
          stored securely to ensure its confidentiality and integrity.
        </p>
        <p>
          Rest assured, we take all necessary precautions to maintain the safety
          of your information. However, remember that no online platform can
          guarantee complete security.
        </p>

        <p>
          Your privacy matters to us. If you have any questions or concerns
          about our privacy practices or the security of your data, please feel
          free to{" "}
          <a href="/support" target="_blank">
            contact us
          </a>
          . We are here to assist you and ensure that your experience on our
          travel website is safe and enjoyable.
        </p>

        {/* privacy policy */}
        <h2 className="mt-5">Privacy Policy</h2>
        <p>
          Welcome to our travel website's Privacy Policy page. At{" "}
          <a href="/">Maashree</a>, we are committed to protecting your privacy
          and ensuring the security of your personal information. This policy
          outlines how we collect, use, process, and safeguard your data. By
          accessing or using our website, you agree to the practices described
          below.
        </p>

        <h3 className="mt-4">1. Information We Collect:</h3>
        <p>
          We may collect various types of information from you when you interact
          with our website, including:
        </p>
        <ul>
          <li>
            Personal Information: Such as your name, email address, phone
            number, and billing information, which you provide when making a
            booking or creating an account / update in my profile.
          </li>
          <li>
            Usage Data: Including your IP address, browser type, device
            information, pages visited, and actions taken on our website.
          </li>
          <li>
            Cookies and Tracking Technologies: We use cookies and similar
            technologies to enhance your browsing experience and gather usage
            data. For more details, please see our Cookie Policy section below.
          </li>
        </ul>

        <h3 className="mt-4">8. Contact Us:</h3>
        <p>
          For any questions, concerns, or requests regarding your data and this
          policy, please{" "}
          <a href="/support">contact our Data Protection Officer</a>.
        </p>

        <p>
          Thank you for trusting <a href="/">Maashree</a> with your travel
          plans. Your privacy is our priority, and we are dedicated to providing
          you with a safe and enjoyable experience.
        </p>

        {/* cookie policy */}
        <h2 className="mt-5">Cookie Policy</h2>
        <p>
          Welcome to the Cookie Policy of <a href="/">Maashree</a>. This policy
          explains how we use cookies and similar technologies on our website to
          enhance your browsing experience. By continuing to use our website,
          you consent to the use of cookies as described below.
        </p>

        <h3 className="mt-4">1. What Are Cookies:</h3>
        <p>
          Cookies are small text files that are placed on your device when you
          visit a website. They help us gather information about your
          interactions with our site, remember your preferences, and improve
          your overall experience.
        </p>

        <h3 className="mt-4">2. Types of Cookies We Use:</h3>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These cookies are necessary for
            the basic functioning of our website. They enable you to navigate
            the site and use its features.
          </li>
          <li>
            <strong>Analytics Cookies:</strong> We use these cookies to collect
            anonymous data about how visitors use our website. This information
            helps us analyze trends and make improvements.
          </li>
          <li>
            <strong>Functionality Cookies:</strong> These cookies remember your
            preferences, such as language and location settings, to provide a
            personalized experience.
          </li>
        </ul>

        <h3 className="mt-4">3. How We Use Cookies:</h3>
        <ul>
          <li>
            <strong>Improving User Experience:</strong> Cookies allow us to
            remember your preferences, making it easier for you to navigate our
            site and access the content you're interested in.
          </li>
          <li>
            <strong>Website Analytics:</strong>We use cookies to understand how
            users interact with our website, which pages are visited most
            frequently, and where improvements can be made.
          </li>
          <li>
            <strong>Personalization:</strong> Cookies enable us to offer you
            personalized content, such as recommended travel destinations and
            relevant offers.
          </li>
        </ul>

        <h3 className="mt-4">4. Managing Cookie Preferences:</h3>
        <p>You have the option to manage your cookie preferences. You can:</p>
        <ul>
          <li>
            <strong>Accept Cookies:</strong> By continuing to use our website,
            you consent to the use of cookies as described in this policy.
          </li>
          <li>
            <strong>Modify Settings:</strong> Most web browsers allow you to
            control how cookies are handled. You can choose to block or delete
            cookies from your device.
          </li>
          <li>
            <strong>Opt-Out of Tracking:</strong> You can opt-out of certain
            types of cookies, such as advertising cookies, by using the settings
            provided by third-party advertising networks.
          </li>
        </ul>
        <p>
          Please note that blocking or deleting cookies may affect your ability
          to use certain features of our website.
        </p>

        <h3 className="mt-4">5. Third-Party Cookies:</h3>
        <p>
          Some cookies on our website may be placed by third-party service
          providers, such as analytics or advertising partners. These parties
          may use cookies to collect information about your online activities
          over time and across different websites.
        </p>

        <h3 className="mt-4">6. Changes to this Policy:</h3>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in technology or legal requirements. Any changes will be posted on our
          website with an updated effective date.
        </p>

        <p>
          Thank you for choosing <a href="/">Maashree</a>. We use cookies to
          provide you with an improved online experience while ensuring your
          privacy is respected. If you have any questions or concerns about our
          use of cookies, please{" "}
          <a href="/support" target="_blank">
            contact us
          </a>
          .
        </p>
      </div>
      <hr style={{ margin: "4%" }} />
      <Footer />
    </AppLayout>
  );
};
