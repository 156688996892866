import fblogo from "../assets/images/facebook-logo-2.svg";
import instaLogo from "../assets/images/instagram-logo-1.svg";
import twitter from "../assets/images/twitter-logo.svg";
import upiImage from "../assets/images/upi-ar21-1.svg";
import masterCard from "../assets/images/Mastercard-logo-1.svg";
import visa from "../assets/images/Visa_Inc_logo-1.svg";
import googlePay from "../assets/images/Google_Pay_Logo_1.svg";
import mainLogo from "../assets/images/logo.png";
import rupayLogo from "../assets/images/logo-rupay.svg";
import amex from "../assets/images/american-express.svg";
import diner from "../assets/images/diners-club.svg";
import whatsapp from "../assets/images/logo-whatsapp_11zon-removebg-preview .png";
import { Link } from "react-router-dom";
import planeScroll from "../assets/images/icons8-plane-30.png";
import { useEffect } from "react";
import LazyImage from "../pages/LazyImage";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 10,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  useEffect(() => {
    // Function to show/hide the scroll-to-top button
    const handleScroll = () => {
      const scrollTopButton = document.getElementById("spnTop");
      if (scrollTopButton) {
        if (window.pageYOffset > 200) {
          scrollTopButton.style.visibility = "visible";
          scrollTopButton.style.transform = "translateY(0px)";
          scrollTopButton.style.opacity = "1";
        } else {
          scrollTopButton.style.visibility = "hidden";
          scrollTopButton.style.transform = "translateY(-40px)";
          scrollTopButton.style.opacity = "0.8";
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the scroll event listener when component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <footer>
      <button
        type="button"
        className="btn scrollTop"
        id="spnTop"
        onClick={scrollToTop}
      >
        <span className="d-flex justify-content-center">
          <LazyImage src={planeScroll} alt="scroll up" />{" "}
        </span>
      </button>
      <div className="container">
        <div className="row footer-row">
          <div className="col-sm-3">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="/terms-of-use" target="_blank">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h4>Support</h4>
            <ul>
              <li>
                <Link to="/support" target="_blank">
                  Help Center
                </Link>
              </li>
              <li>
                <Link to="/about" target="_blank">
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h4>Social Media</h4>
            <ul className="social-media">
              {/* <li>
                <a href="/">
                  <LazyImage src={fblogo} alt="" />
                </a>
              </li> */}
              <li>
                <Link
                  target="_blank"
                  to="https://instagram.com/maashree_online?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                >
                  <LazyImage src={instaLogo} alt="" />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://twitter.com/maashree">
                  <LazyImage src={twitter} alt="" style={{ width: "27px" }} />
                </Link>
              </li>

              <li>
                <Link
                  target="_blank"
                  to="https://whatsapp.com/channel/0029VaDH5cr30LKNZHOlvM2d"
                >
                  <LazyImage src={whatsapp} alt="" style={{ width: "27px" }} />
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h4>Payment Methods</h4>
            <ul className="social-media payment-method">
              <li>
                <LazyImage src={upiImage} alt="" />
              </li>
              <li>
                <LazyImage src={visa} className="visa" alt="" />
              </li>
              <li>
                <LazyImage src={masterCard} alt="" />
              </li>
              <li>
                <LazyImage src={rupayLogo} alt="" />
              </li>
              <li className="">
                <LazyImage src={amex} alt="" />
              </li>
              <li className="">
                <LazyImage src={diner} alt="" />
              </li>
              <li>
                <LazyImage src={googlePay} alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div className="whats-app-icon">
          <Link to="https://wa.me/+919028129888 " target="_blank">
            <LazyImage src={whatsapp} alt="" />
          </Link>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {" "}
            <div className="copyright">
              <LazyImage src={mainLogo} height={29} alt="" />{" "}
              <span>© 2023 Maashree. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
