import image_3 from "../../../assets/images/image_3.png";
import image_2 from "../../../assets/images/image_2.png";
import image_1 from "../../../assets/images/image_1.png";
import booking from "../../../assets/images/booking.png";
import calender from "../../../assets/images/calender.svg";
import status from "../../../assets/images/status.png";
import LazyImage from "../../../pages/LazyImage";
export const HotelManagement = () => {
  const length: number = 0;
  return (
    <>
      {/* <!-- BookingManagement --> */}
      <section className="upcoming_section mt-4" id="HotelManagement_section">
        {/* <!-- first --> */}
        <div className="card card_upcmoing">
          <div className="card-body">
            <div className="row">
              {length !== 0 ? (
                <>
                  <div className="col-sm-2 col-md-2">
                    <div className="block">
                      <div className="card-img">
                        <LazyImage
                          className="img-fluid travel_images"
                          src={image_3}
                          alt="Emirates"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-10">
                    <div className="travel_section mb-3">
                      <div className="text-left">
                        <h3 className="fs-5 mb-0">
                          James Smith x 2
                          <span
                            style={{
                              padding: "0px 10px",
                            }}
                          >
                            Ref. No
                          </span>
                          <span>123456788</span>
                        </h3>
                      </div>
                      <div className="block_btn d-flex align-items-center">
                        <div className="date_icon">
                          <LazyImage
                            className="date_imatge"
                            src={calender}
                            alt=""
                          />
                          <span className="date_text"> Wed, 16 Aug</span>
                        </div>

                        <div className="card-content">
                          <button className="btn btn-cancel" type="button">
                            {" "}
                            Booking cancelled
                          </button>
                        </div>
                        <div className="card-content">
                          <button
                            className="btn btn-refund-status"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#payment"
                          >
                            <span className="mx-2">
                              <LazyImage
                                className="cross_imatge"
                                src={status}
                                alt=""
                              />
                            </span>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="main_content col_section">
                      <div className="block">
                        <div className="card-content">
                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                            CHECK IN
                          </p>
                          <h3 className="block_heading"> 14 Aug 23 </h3>
                          <p className="mb-0"> 12:00 PM</p>
                        </div>
                      </div>
                      <div className="block">
                        <div className="card-content">
                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                            CHECK OUT
                          </p>
                          <h3 className="block_heading">15 Aug 23</h3>
                          <p className="mb-0"> 2:00 PM</p>
                        </div>
                      </div>
                      <div className="block">
                        <div className="card-content">
                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                            <span className="image_bookin">
                              <LazyImage src={booking} alt="" />
                            </span>
                            Booked
                          </p>
                          <h3 className="block_heading">Hotel </h3>
                          <p className="card-text">Sheraton, Paris</p>
                        </div>
                      </div>
                      <div className="block">
                        <div className="card-content">
                          <h3 className="block_heading">Room Category</h3>
                          <p className="card-text">luxury </p>
                        </div>
                      </div>
                      <div className="block">
                        <div className="card-content">
                          <h3 className="block_heading">No. Of Rooms Booked</h3>
                          <p className="card-text">2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <h4> No Hotel Bookings Found</h4>
              )}
            </div>
          </div>
        </div>
        {/* <!-- third --> */}
        {/* <div className="card card_upcmoing">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="block">
                  <div className="card-img">
                    <LazyImage
                      className="img-fluid travel_images"
                      src={image_1}
                      alt="Emirates"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-10 col-md-10">
                <div className="travel_section mb-3">
                  <div className="text-left">
                    <h3 className="fs-5 mb-0">
                      James Smith x 2
                      <span
                        style={{
                          padding: "0px 10px",
                        }}
                      >
                        Ref. No
                      </span>
                      <span>123456788</span>
                    </h3>
                  </div>
                  <div className="block_btn d-flex align-items-center">
                    <div className="date_icon">
                      <LazyImage
                        className="date_imatge"
                        src={calender}
                        alt=""
                      />
                      <span className="date_text"> Wed, 16 Aug</span>
                    </div>

                    <div className="card-content">
                      <button className="btn btn-cancel" type="button">
                        {" "}
                        Booking cancelled
                      </button>
                    </div>
                    <div className="card-content">
                      <button
                        className="btn btn-refund-status"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#payment"
                      >
                        <span className="mx-2">
                          <LazyImage
                            className="cross_imatge"
                            src={status}
                            alt=""
                          />
                        </span>
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main_content col_section">
                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                        CHECK IN
                      </p>
                      <h3 className="block_heading"> 14 Aug 23 </h3>
                      <p className="mb-0"> 12:00 PM</p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                        CHECK OUT
                      </p>
                      <h3 className="block_heading">15 Aug 23</h3>
                      <p className="mb-0"> 2:00 PM</p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                        <span className="image_bookin">
                          <LazyImage src={booking} alt="" />
                        </span>
                        Booked
                      </p>
                      <h3 className="block_heading">Hotel </h3>
                      <p className="card-text">Sheraton, Paris</p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <h3 className="block_heading">Room Category</h3>
                      <p className="card-text">luxury </p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="card-content">
                      <h3 className="block_heading">No. Of Rooms Booked</h3>
                      <p className="card-text">2</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {/* <!-- pagination --> */}
      {/* <div id="pagination">
        <div className="d-flex justify-content-between align-items-center mt-3 pagination_data">
          <div className="col-sm-3 d-flex gap-2 align-items-center">
            <div className="page-item">
              <p className="mb-0">Page Item</p>
            </div>

            <select
              className="form-select"
              aria-label="Default select example"
              style={{
                width: "70px",
              }}
            >
              <option>10</option>
              <option value="1">15</option>
              <option value="2">20</option>
              <option value="3">25</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link" href="#" aria-disabled="true">
                  Previous
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="payment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="paymentLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="paymentLabel">
                Payment Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="block">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">txnId :</h3>
                  <h3 className="block_heading mb-0">123</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">status :</h3>
                  <h3 className="block_heading mb-0">successfully</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">amount :</h3>
                  <h3 className="block_heading mb-0">1000</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0"> productInfo :</h3>
                  <h3 className="block_heading mb-0"> productInfo</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">
                    mihpayid (PayU txn id) :
                  </h3>
                  <h3 className="block_heading mb-0">123</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">mode :</h3>
                  <h3 className="block_heading mb-0">UPI</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">discount :</h3>
                  <h3 className="block_heading mb-0">150</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">
                    net_amount_debit (Exact debited amount by Payu) :
                  </h3>
                  <h3 className="block_heading mb-0">150</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">
                    payment_source ( PayU ) :
                  </h3>
                  <h3 className="block_heading mb-0">150</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">
                    bank_ref_num (Bank Ref. no.) :
                  </h3>
                  <h3 className="block_heading mb-0">150</h3>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="block_heading mb-0">
                    error_Message (Message) :
                  </h3>
                  <h3 className="block_heading mb-0">Erro</h3>
                </div>
              </div>
              <div className="input-group mb-0">
                <button
                  className="btn btn-refund-status dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Refund status
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Pending
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Processing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      In Progress
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Refund Initiated
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Refund Failed
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Partially Refunded
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Completed
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-login search_record"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
