import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";

interface AutoCompleteCityProps {
  label: string;
  value: any;
  type?: string;
  onChange: ({
    cityName,
    code,
    airportName,
  }: {
    cityName: string;
    code: string;
    airportName: string;
  }) => void;
  imageIcon?: string;
  triggerOnToggleLocation?: () => void;
  locationIcon?: boolean;
  focusNextInput: () => void;
  forwardedRef: React.RefObject<HTMLInputElement>;
}

const AutoCompleteCity: React.FC<AutoCompleteCityProps> = ({
  label,
  value,
  type,
  forwardedRef,
  onChange = () => {},
  imageIcon,
  triggerOnToggleLocation = () => {},
  locationIcon,
  focusNextInput = () => {},
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const airportListRef = useRef<HTMLUListElement | null>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const getAirPortsJSON = localStorage.getItem("allAirports");

  const handleDocumentClick = (e: { target: any }) => {
    if (
      showDropdown &&
      airportListRef.current &&
      !airportListRef.current.contains(e.target)
    ) {
      onChange({ cityName: "", code: "", airportName: "" });
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  const handleListItemKeyDown = (e: any) => {
    if (!showDropdown) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (
        highlightedIndex === -1 ||
        highlightedIndex === filteredAirports.length - 1
      ) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex(highlightedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlightedIndex === -1 || highlightedIndex === 0) {
        setHighlightedIndex(filteredAirports.length - 1);
      } else {
        setHighlightedIndex(highlightedIndex - 1);
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedAirport = filteredAirports[highlightedIndex];
        onChange({
          cityName: selectedAirport.CityName,
          code: selectedAirport.Code,
          airportName: selectedAirport.Name,
        });

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex !== -1) {
        const selectedAirport = filteredAirports[highlightedIndex];
        onChange({
          cityName: selectedAirport.CityName,
          code: selectedAirport.Code,
          airportName: selectedAirport.Name,
        });

        setShowDropdown(false);
        setHighlightedIndex(-1);
        focusNextInput && focusNextInput();
      }
      focusNextInput && focusNextInput();
    }
  };

  const filteredAirports = useMemo(() => {
    return JSON.parse(String(getAirPortsJSON))?.filter(
      ({ CityName, Code }: any) =>
        `${CityName} ${Code}`
          .toLowerCase()
          .includes(value.cityName?.toLowerCase())
    );
  }, [getAirPortsJSON, value.cityName]);

  return (
    <>
      <div className="border px-2 py-2 rounded-2 border-dark">
        <div className="d-flex align-items-center gap-2">
          {type === "From" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              viewBox="0 0 256 256"
              data-testid="originIcon"
            >
              <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.1,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.1.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              viewBox="0 0 256 256"
              data-testid="destinationIcon"
            >
              <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
            </svg>
          )}

          <span className="fw-bold fs-6">{type}</span>
        </div>

        <div className="input_heading">
          <input
            className="fw-bold border-0 fs-4"
            style={{ width: "100%" }}
            ref={forwardedRef}
            autoComplete="off"
            placeholder={label}
            value={value.cityName}
            type="search"
            aria-label="Search"
            onChange={(e) => {
              onChange({ cityName: e.target.value, code: "", airportName: "" });
              setShowDropdown(true);
            }}
            onKeyDown={handleListItemKeyDown}
          />
        </div>

        {locationIcon && (
          <FontAwesomeIcon
            icon={faLocationCrosshairs}
            style={{
              color: "#b1b1b1",
              position: "absolute",
              top: "12px",
              right: "16px",
              zIndex: "999",
            }}
            onClick={() => {
              triggerOnToggleLocation();
            }}
          />
        )}

        <div className="code_heading">
          <span className="code-des d-inline-block text-truncate w-100">
            {value.code !== undefined &&
              `${value.code === "" ? "-" : `[${value.code}]`} ${
                value.airportName !== undefined ? value.airportName : ""
              }`}
          </span>
        </div>
      </div>

      {showDropdown && value.cityName !== "" && (
        <ul
          ref={airportListRef}
          style={{
            margin: "2px",
            border: "1px solid #ccc",
            padding: "6px",
            position: "absolute",
            backgroundColor: "white",
            width: "100%",
            borderRadius: "5px",
            zIndex: 999,
            maxHeight: "20rem",
            overflowY: "auto",
          }}
        >
          {filteredAirports?.map(
            ({ CityName, Code, Name, Country }: any, idx: number) => (
              <li
                key={idx}
                style={{
                  listStyleType: "none",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  paddingTop: "5px",
                  cursor: "pointer",
                  backgroundColor:
                    idx === highlightedIndex ? "#ffebeb" : "white",
                  borderRadius: idx === highlightedIndex ? "10px" : "",
                  paddingLeft: "7px",
                }}
                onClick={() => {
                  onChange({
                    cityName: CityName,
                    code: Code,
                    airportName: Name,
                  });
                  setShowDropdown(false);
                  if (focusNextInput) {
                    focusNextInput();
                  }
                }}
                tabIndex={0}
              >
                <span
                  style={{
                    fontSize: "11px",
                    border: "2px solid",
                    padding: "2px",
                    marginRight: "10px",
                  }}
                >
                  {Code}
                </span>{" "}
                <span>{CityName}</span>
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "13px",
                      marginTop: "7px",
                      color: "#717171",
                    }}
                  >
                    {Name}
                  </span>
                  <span
                    style={{
                      marginLeft: "auto",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#757575",
                      textTransform: "uppercase",
                      width: "45%",
                      marginTop: "5px",
                      textAlign: "end",
                    }}
                  >
                    {Country}
                  </span>
                </div>
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};

export default AutoCompleteCity;
