import calender from "../../../assets/images/calender.svg";
import status from "../../../assets/images/status.png";
import location from "../../../assets/images/location.png";
import LazyImage from "../../../pages/LazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import "./travelAndHotel.css";
import { useEffect, useState } from "react";
import { canceledTripsList } from "../../../api/UserProfile";
import moment from "moment";
import { processMAC } from "../../../utils/helpers";

const statusMappings = [
  { name: "Not Applicable", status: 0 },
  { name: "Pending", status: 1 },
  { name: "Processing", status: 2 },
  { name: "In Progress", status: 3 },
  { name: "Refund Initiated", status: 4 },
  { name: "Refund Failed", status: 5 },
  { name: "Partially Refunded", status: 6 },
  { name: "Cancel Penalties Completed", status: 7 },
];

export const CanceledTrips = () => {
  const [getCanceledTripsList, setGetCanceledTripsList] = useState([]);
  useEffect(() => {
    canceledTripsList()
      .then((res) => {
        if (res.data) {
          setGetCanceledTripsList(res.data);
          console.log("res-data", res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getStatusName = (status: any) => {
    const matchingStatus = statusMappings.find(
      (mapping) => mapping.status === status
    );
    return matchingStatus ? matchingStatus.name : "Unknown Status";
  };

  return (
    <section className="upcoming_section mt-4" id="upcoming_trips_section">
      {/* <!-- first --> */}
      <div className="card card_upcmoing">
        <div className="card-body">
          {getCanceledTripsList.length > 0 ? (
            <>
              {getCanceledTripsList.map((trip: any) => (
                <div className="row">
                  <div className="card card_upcmoing border-0">
                    <div className="card-body px-0">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="travel_section mb-3">
                            <div className="text-left d-flex gap-2">
                              <div className="image_bookin">
                                <span className="image_bookin">
                                  <FontAwesomeIcon icon={faPlaneUp} />{" "}
                                </span>
                              </div>
                              <h3 className="name-title mb-0">
                                {trip.flightBookingResponseData.ContactInfo[0]
                                  .FName +
                                  " " +
                                  trip.flightBookingResponseData.ContactInfo[0]
                                    .LName}{" "}
                                x {trip?.flightBookingResponseData?.Pax?.length}
                                <span
                                  style={{
                                    padding: "0px 10px",
                                  }}
                                >
                                  Ref. No
                                </span>
                                <span>{trip.TransactionID} </span>
                              </h3>
                            </div>
                            <div className="block_btn d-flex align-items-center">
                              <div className="date_icon">
                                <LazyImage
                                  className="date_imatge"
                                  src={calender}
                                  alt=""
                                />
                                <span className="date_text">
                                  {" "}
                                  {moment(
                                    trip.flightBookingResponseData.BookingDate
                                  ).format("Do MMM YYYY")}
                                </span>
                              </div>

                              <div className="card-content">
                                <button
                                  className="btn btn-cancel"
                                  type="button"
                                >
                                  {" "}
                                  Booking cancelled
                                </button>
                              </div>
                              <div className="card-content">
                                <button
                                  className="btn btn-refund-status"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#payment"
                                >
                                  <span className="mx-2">
                                    <LazyImage
                                      className="cross_imatge"
                                      src={status}
                                      alt={trip.payment.refundStatus}
                                    />
                                  </span>
                                  {getStatusName(trip.payment?.refundStatus)}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="main_content col_section">
                            {trip.flightBookingResponseData.Trips.map(
                              (flight: any) => (
                                <>
                                  {flight.Journey.map((journey: any) => (
                                    <div
                                      className="main_content "
                                      style={{ columnGap: "110px" }}
                                    >
                                      <div className="block">
                                        <div className="card-content">
                                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                            <span className="image_bookin">
                                              <LazyImage
                                                src={location}
                                                alt=""
                                              />
                                            </span>
                                            Departure city{" "}
                                          </p>
                                          <h3 className="block_heading">
                                            {" "}
                                            {
                                              journey.Segments[0].Flight.DepAirportName.split(
                                                "|"
                                              )[1]
                                            }
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                            <span className="image_bookin">
                                              <LazyImage
                                                src={calender}
                                                alt=""
                                              />
                                            </span>
                                            Arrival city
                                          </p>
                                          <h3 className="block_heading">
                                            {
                                              journey.Segments[0].Flight.ArrAirportName.split(
                                                "|"
                                              )[1]
                                            }
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                            <span className="image_bookin">
                                              <LazyImage
                                                src={calender}
                                                alt=""
                                              />
                                            </span>
                                            Arrival Date
                                          </p>
                                          <h3 className="block_heading">
                                            {moment(
                                              journey.Segments[0].Flight
                                                .ArrivalTime
                                            ).format("DD MMM YYYY")}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <p className="card-text mb-2 d-flex gap-2  align-items-centergap-2">
                                            <span className="image_bookin">
                                              <LazyImage
                                                src={calender}
                                                alt=""
                                              />
                                            </span>
                                            Departure Date
                                          </p>
                                          <h3 className="block_heading">
                                            {moment(
                                              journey.Segments[0].Flight
                                                .DepartureTime
                                            ).format("DD MMM YYYY")}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="block_heading">
                                            Flight No.{" "}
                                          </h3>
                                          <p className="card-text">
                                            {processMAC(
                                              journey.Segments[0].Flight.MAC
                                            )}
                                            {" - "}
                                            {
                                              journey.Segments[0].Flight
                                                .FlightNo
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="block_heading">
                                            Travel Class
                                          </h3>
                                          <p className="card-text">
                                            {" "}
                                            {journey.Segments[0].Flight
                                              .Cabin === "E"
                                              ? "Economy"
                                              : journey.Segments[0].Flight
                                                  .Cabin === "PE"
                                              ? "Premium Economy"
                                              : journey.Segments[0].Flight
                                                  .Cabin === "B"
                                              ? "Business"
                                              : "First Class"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h5> No Cancelled Trips</h5>
          )}
        </div>
      </div>
    </section>
  );
};
