import React from "react";
import "./AirplaneLoader.css";

const AirplaneLoader = () => {
  return (
    <div
      className="content"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 17%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "99999999",
      }}
    >
      <svg
        version="1.1"
        id="airplane-loader"
        xmlns="http://www.w3.org/2000/svg"
        width="144"
        height="48"
        viewBox="0 0 144 48"
      >
        <path
          id="airplane-take-off"
          fill="#002F5F"
          d="M59.124,34L56,29h-4l2.947,11H89c1.657,0,3-1.343,3-3s-1.343-3-3-3H78.998L69,18h-4l4.287,16H59.124z"
        />
        <rect id="ground" x="52" y="44" fill="#002F5F" width="40" height="4" />
      </svg>
      <svg
        version="1.1"
        id="airplane-loader"
        xmlns="http://www.w3.org/2000/svg"
        width="144"
        height="48"
        viewBox="0 0 144 48"
      >
        <path
          id="airplane-landing"
          fill="#002F5F"
          d="M59.124,34L56,29h-4l2.947,11H89c1.657,0,3-1.343,3-3s-1.343-3-3-3H78.998L69,18h-4l4.287,16H59.124z"
        />
        <rect id="ground" x="52" y="44" fill="#002F5F" width="40" height="4" />
      </svg>
    </div>
  );
};

export default AirplaneLoader;
