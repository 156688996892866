import {useState} from "react";
import React from "react";
import { FaStar } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { CiStar, CiHeart, CiClock2, CiCircleCheck } from "react-icons/ci";

import TravelImage from "../../../assets/images/travel.jpg";
import {DestinationFeedback} from "./DestinationFeedback";
import {DestinationFaq} from "./DestinationFaq";

import "./FilterCards.component.css";

export const FilterCards = () =>{
    return (
      <>
        <div className="card-wrapper">
          <a href="activity-page">
            <div className="card-inner">
              <div className="card-sec-one">
                <div className="image-sec">
                  <img src={TravelImage} alt="" />
                </div>
                <div className="card-fav">
                  <CiHeart />
                </div>
                <div className="card-sell-out">
                  <p>Likely to sell out</p>
                </div>
              </div>

              <div className="card-sec-two">
                <h4>
                  Eiffel Tower Second Floor Tour and Summit Option by Elevator
                </h4>

                <div className="card-ratings">
                  <div className="rating">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <CiStar />
                  </div>
                  <p>3,526</p>
                </div>

                <div className="card-summary">
                  <p>
                    Live a unique experience with local guides. Start your tour
                    of the Eiffel Tower by meeting your guide, who will give you
                    an introduction to the tower and the history of its
                    construction. Ascend to the 2nd floor by elevator and take
                    in the sweeping city views as you ascend this iconic
                    landmark. At the end of the 2nd floor visit, take the
                    elevator that
                  </p>
                </div>

                <div className="card-extras">
                  <div className="card-ex-con">
                    <CiClock2 />
                    <p>2 hours</p>
                  </div>
                  <div className="card-ex-con">
                    <CiCircleCheck />
                    <p>Free Cancellation</p>
                  </div>
                </div>
              </div>

              <div className="card-sec-three">
                <p className="normal">from</p>
                <p className="prize">$12,267.21</p>
                <p className="normal">prize varies by</p>
                <p className="normal">group size</p>
              </div>
            </div>
          </a>
        </div>

        {/* Two */}
        <div className="card-wrapper">
          <a href="activity-page">
            <div className="card-inner">
              <div className="card-sec-one">
                <div className="image-sec">
                  <img src={TravelImage} alt="" />
                </div>
                <div className="card-fav">
                  <CiHeart />
                </div>
              </div>

              <div className="card-sec-two">
                <h4>
                  Eiffel Tower Second Floor Tour and Summit Option by Elevator
                </h4>

                <div className="card-ratings">
                  <div className="rating">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <CiStar />
                  </div>
                  <p>3,526</p>
                </div>

                <div className="card-summary">
                  <p>
                    Live a unique experience with local guides. Start your tour
                    of the Eiffel Tower by meeting your guide, who will give you
                    an introduction to the tower and the history of its
                    construction. Ascend to the 2nd floor by elevator and take
                    in the sweeping city views as you ascend this iconic
                    landmark. At the end of the 2nd floor visit, take the
                    elevator that
                  </p>
                </div>

                <div className="card-extras">
                  <div className="card-ex-con">
                    <CiClock2 />
                    <p>2 hours</p>
                  </div>
                  <div className="card-ex-con">
                    <CiCircleCheck />
                    <p>Free Cancellation</p>
                  </div>
                </div>
              </div>

              <div className="card-sec-three">
                <p className="normal">from</p>
                <p className="prize">$12,267.21</p>
              </div>
            </div>
          </a>
        </div>

        {/* Three */}
        <div className="card-wrapper">
          <a href="activity-page">
            <div className="card-inner">
              <div className="card-sec-one">
                <div className="image-sec">
                  <img src={TravelImage} alt="" />
                </div>
                <div className="card-fav">
                  <CiHeart />
                </div>
                <div className="card-sell-out">
                  <p>Likely to sell out</p>
                </div>
              </div>

              <div className="card-sec-two">
                <h4>
                  Eiffel Tower Second Floor Tour and Summit Option by Elevator
                </h4>

                <div className="card-ratings">
                  <div className="rating">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <CiStar />
                  </div>
                  <p>3,526</p>
                </div>

                <div className="card-summary">
                  <p>
                    Live a unique experience with local guides. Start your tour
                    of the Eiffel Tower by meeting your guide, who will give you
                    an introduction to the tower and the history of its
                    construction. Ascend to the 2nd floor by elevator and take
                    in the sweeping city views as you ascend this iconic
                    landmark. At the end of the 2nd floor visit, take the
                    elevator that
                  </p>
                </div>

                <div className="card-extras">
                  <div className="card-ex-con">
                    <CiClock2 />
                    <p>2 hours</p>
                  </div>
                  <div className="card-ex-con">
                    <CiCircleCheck />
                    <p>Free Cancellation</p>
                  </div>
                </div>
              </div>

              <div className="card-sec-three">
                <p className="normal">from</p>
                <p className="prize">$12,267.21</p>
              </div>
            </div>
          </a>
        </div>

        <div className="pagination-section">
          <Stack spacing={2}>
            <Pagination count={10} />
          </Stack>
        </div>

        {/* FeedBack */}
        <div className="card-feedback">
          <DestinationFeedback />
        </div>

        {/* FAQ Section */}
        <div className="card-faq">
          <DestinationFaq />
        </div>
      </>
    );
}