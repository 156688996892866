import { useEffect, useRef, useState } from "react";
import loginSVG from "../assets/images/signin.svg";
import userImg from "../assets/images/UserCircle.svg";
import caretDownImg from "../assets/images/CaretDown.svg";
import signOut from "../assets/images/SignOut.svg";
import { LoginOrRegister } from "./modal/LoginOrRegister";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserProfile } from "../api/UserProfile";
import { getAdminProfile } from "../api/AdminProfile";
import { useRefreshUser } from "./context/RefreshUserContext";
import { toast } from "react-toastify";
import LazyImage from "../pages/LazyImage";
export const AdminNavbar = () => {
  const [userValues, setUserValues] = useState<{ fullName: string }>();
  const [adminValues, setAdminValues] = useState<{ adminName: string }>();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [defaultName, setDefaultName] = useState("");
  const [logoutUser, setLogoutUSer] = useState(false);
  const storedToken = localStorage.getItem("token");
  const admin_token = localStorage.getItem("admin_token");
  const [adminName, setAdminName] = useState("");
  const [logoutAdmin, setLogoutAdmin] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { refreshUser, setRefreshUser } = useRefreshUser();
  const { refreshAdmin, setRefreshAdmin } = useRefreshUser();
  const [showMenu, setShowMenu] = useState(false);

  const profileMenuRef = useRef<HTMLDivElement | null>(null);
  const toggleButtonRef = useRef<HTMLButtonElement | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (profileMenuRef.current && toggleButtonRef.current) {
        if (toggleButtonRef.current.contains(event.target as Node)) {
          if (showMenu) {
            setShowMenu(false);
          } else {
            setShowMenu(true);
          }
        } else if (profileMenuRef.current.contains(event.target as Node)) {
          setShowMenu(!showMenu);
        } else {
          setShowMenu(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const LogoutUserHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginId");
    localStorage.removeItem("country-code");
    localStorage.removeItem("defaultName");
    localStorage.removeItem("newVerifyId");
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("userImageKey");
    toast.error("You have been logged out", {
      toastId: "logoutToast",
    });

    setTimeout(() => {
      navigate(0);
    }, 1000);
  };

  useEffect(() => {
    if (storedToken || logoutUser || (storedToken && refreshUser)) {
      getUserProfile()
        .then((response) => {
          setUserValues({ fullName: response.data.fullName });
          if (!response.data.fullName) {
            setDefaultName("Traveler");
          }
          setRefreshUser(false);
        })
        .catch((err) => {
          if (
            (err.response && err.response.status === 403) ||
            (err.response && err.response.status === 404)
          ) {
            LogoutUserHandler();
          }
        });
      setLogoutUSer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUser, storedToken, logoutUser]);

  // admin dashboard
  const LogoutAdminHandler = () => {
    localStorage.removeItem("admin_token");
    toast.error("You have been logged out", {
      toastId: "logoutToast",
    });

    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  useEffect(() => {
    if (admin_token || logoutAdmin || (admin_token && refreshAdmin)) {
      getAdminProfile()
        .then((response) => {
          setAdminValues({ adminName: response.data.adminName });
          if (!response.data.adminName) {
            setAdminName("Admin");
          }
          setRefreshAdmin(false);
        })
        .catch((err) => {
          if (
            (err.response && err.response.status === 403) ||
            (err.response && err.response.status === 404)
          ) {
            LogoutAdminHandler();
          }
        });
      setLogoutAdmin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdmin, admin_token, logoutAdmin]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (windowWidth > 992) {
      setShowMenu(false);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLocation = location.pathname !== "/";

  return (
    <>
      <nav
        className={
          // scrolled || checkLocation
          scrolled
            ? "navbar navbar-expand-lg mb-4 tnb-fixed sticky w3-animate-top tnb-fixed-background"
            : "navbar navbar-expand-lg mb-4 tnb-fixed"
        }
        id="myHeader"
      >
        <div className="container justify-content-end" ref={profileMenuRef}>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={toggleButtonRef}
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {showMenu && windowWidth < 992 && (
            <div
              className={
                showMenu
                  ? "collapse navbar-collapse tnb show"
                  : "collapse navbar-collapse tnb hide"
              }
              id="navbarCollapse"
              ref={profileMenuRef}
            >
              <form className="d-flex ml-auto dropdown res_form ">
                {/* admin menu */}
                {admin_token ? (
                  <>
                    <button
                      className="btn btn-login dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <LazyImage src={userImg} alt="" /> Hi,{" "}
                      <span>
                        {adminValues?.adminName
                          ? adminValues.adminName.split(" ")[0].length > 14
                            ? adminValues.adminName
                                .split(" ")[0]
                                .substring(0, 14) + "..."
                            : adminValues.adminName.split(" ")[0]
                          : adminName.length > 15
                          ? adminName.substring(0, 15) + "..."
                          : adminName}{" "}
                      </span>
                      <LazyImage src={caretDownImg} alt="" />{" "}
                    </button>

                    <ul className="dropdown-menu dropdown-menu2 res_profile w3-animate-top1">
                      <li
                        onClick={(event) => {
                          event.preventDefault();
                          LogoutAdminHandler();

                          setLogoutAdmin(true);
                        }}
                      >
                        <span
                          className="dropdown-item res_item"
                          style={{ cursor: "pointer" }}
                        >
                          <LazyImage src={signOut} alt="" /> Log out
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-login"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#modalLogin"
                      onClick={() => setOpenLoginModal(true)}
                    >
                      <LazyImage src={loginSVG} alt="" /> Login or Register{" "}
                    </button>
                  </>
                )}
              </form>
            </div>
          )}
          {windowWidth > 992 && (
            <div
              className="collapse navbar-collapse tnb"
              id="navbarCollapse"
              ref={profileMenuRef}
            >
              <form className="d-flex ml-auto dropdown res_form ">
                {/* admin menu */}
                {admin_token ? (
                  <>
                    <button
                      className="btn btn-login dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <LazyImage src={userImg} alt="" /> Hi,{" "}
                      <span>
                        {adminValues?.adminName
                          ? adminValues.adminName.split(" ")[0].length > 14
                            ? adminValues.adminName
                                .split(" ")[0]
                                .substring(0, 14) + "..."
                            : adminValues.adminName.split(" ")[0]
                          : adminName.length > 15
                          ? adminName.substring(0, 15) + "..."
                          : adminName}{" "}
                      </span>
                      <LazyImage src={caretDownImg} alt="" />{" "}
                    </button>

                    <ul className="dropdown-menu dropdown-menu2 res_profile w3-animate-top1">
                      <li
                        onClick={(event) => {
                          event.preventDefault();
                          LogoutAdminHandler();

                          setLogoutAdmin(true);
                        }}
                      >
                        <span
                          className="dropdown-item res_item"
                          style={{ cursor: "pointer" }}
                        >
                          <LazyImage src={signOut} alt="" /> Log out
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-login"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#modalLogin"
                      onClick={() => setOpenLoginModal(true)}
                    >
                      <LazyImage src={loginSVG} alt="" /> Login or Register{" "}
                    </button>
                  </>
                )}
              </form>
            </div>
          )}
        </div>
      </nav>

      <LoginOrRegister openLoginModal={openLoginModal} />
    </>
  );
};
