import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AppLayout } from "../../AppLayout";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
import {
  CiLocationOn,
  CiHeart,
  CiCalendar,
  CiClock1,
  CiMobile1,
} from "react-icons/ci";
import { IoCarSportOutline } from "react-icons/io5";
import { TiMessages } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { AvailableForm } from "./AvailableForm";
import { AvailableCount } from "./AvailableCount";
import { ActivityOverview } from "./ActivityOverview";
import { ActivityCheckout } from "./ActivityCheckout";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export const ActivityPage = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [open, setOpen] = useState(false);
  const [showActivityFloat, setShowActivityFloat] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const handleAnimate = () => {
    setIsAnimated(!isAnimated);
  };

  const handleCheckAvailability = () => {
    setShowCheckout(true); // Show the checkout component
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowActivityFloat(true);
      } else {
        setShowActivityFloat(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <AppLayout>
      <section className="activity">
        <div className="container">
          <div className="activity-heading">
            <ul>
              <li>
                <Link to="/">
                  <p>Home</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="activity-content">
        <div className="container">
          <h3>Activity heading will be displayed here</h3>
          <div className="loc-section">
            <span>
              <CiLocationOn />
            </span>
            <p>location</p>
          </div>

          <div className="activity-gal">
            <div className="row">
              {/* Gallery Section start */}
              <div className="col-lg-8">
                <div className="activity-gallery">
                  <ImageGallery
                    items={images}
                    thumbnailPosition="left"
                    showPlayButton={false}
                    autoPlay={true}
                    lazyLoad={true}
                  />
                  <div
                    className={`wishlist ${isAnimated ? "animate" : ""}`}
                    onClick={handleAnimate}
                  >
                    <div className="icon-block">
                      <CiHeart />
                    </div>
                    <div className="text-block">
                      <p>
                        {isAnimated ? "Added to wishlist" : "Add to wishlist"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Gallery Section End */}
              {/* Calender section start */}
              <div className="col-lg-4">
                <div className="available-content">
                  <span>
                    <p>Likely to sell out</p>
                  </span>
                  <h4>From $1,538.17</h4>
                  <div className="available-modal">
                    <p onClick={onOpenModal}>Lowest Prize Guarantee</p>
                    <Modal open={open} onClose={onCloseModal} center>
                      <h2>Lowest Price Guarantee</h2>

                      <p className="p">
                        We guarantee the lowest price on all 200,000+
                        experiences we sell. If you find the same product
                        cheaper within three days of booking or while you're in
                        your destination, we'll refund the difference.
                      </p>

                      <h3>It's Easy To Do</h3>
                      <ul>
                        <li>
                          <p>
                            <b>Gather your information</b>
                            <br />
                            Assemble your Viator Booking Number or Itinerary
                            Reference Number and the proof of the lower price
                            you found.
                          </p>
                        </li>
                      </ul>
                    </Modal>
                  </div>

                  <h4>Select Date and Travelers</h4>

                  <div className="available-form-main">
                    <AvailableForm />
                    <AvailableCount />
                  </div>

                  <button className="check" onClick={handleCheckAvailability}>Check Availability</button>

                  <div className="cancel">
                    <FaCheckCircle />
                    <p>
                      <span>Free cancellation</span>
                      up to 24 hours before the experience starts (local time)
                    </p>
                  </div>
                </div>
              </div>
              {/* Calender section end */}
            </div>
          </div>
        </div>
      </section>

      <section className="activity-desc">
        <div className="container">
          <div className="desc-list">
            <ul>
              <li className="desc-content">
                <CiClock1 />
                <p>3 to 4 hrs (approx.)</p>
              </li>
              <li className="desc-content">
                <CiMobile1 />
                <p>Mobile Ticket</p>
              </li>
              <li className="desc-content">
                <IoCarSportOutline />
                <p>Pickup Offered</p>
              </li>
              <li className="desc-content">
                <TiMessages />
                <p>Offered in ENglish</p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {showCheckout && (
        <section className="activity-checkout">
          <div className="container">
            <ActivityCheckout />
          </div>
        </section>
      )}

      <section className="activity-overview">
        <div className="container">
          <ActivityOverview />
        </div>
      </section>

      <section
        className={`activity-float ${showActivityFloat ? "visible" : "hidden"}`}
      >
        <div className="container">
          <div className="activity-float-con">
            <div className="activity-price">
              <p>
                from <span>$1,539.52</span>
              </p>
              <p className="underline">lowest price guarantee</p>
            </div>
            <div className="activity-button">
              <button onClick={handleCheckAvailability}>Check Availability</button>
              <CiHeart />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </AppLayout>
  );
};
